import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {Modal,Fade,Backdrop} from '@mui/material';

import {
  Button,
  Paper,
  TextField,
  Grid,
} from "@material-ui/core";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { getSyncData } from "../../../actions/LimsAdminActions";
let width = window.innerWidth;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    marginTop: "2rem",
    padding: "0.5rem 2rem",
    width: "90%",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));
interface Props { }
type ModalProps = {
  openMainMeberSyncModal: any;
  setMainMemberSyncModal: any;
  bookingByBarcode: any;
  syncId: any;
  getSyncData: any;
  barcodeId: any;
  getBookingByBarcode: any;
  syncLoading: any
};

const CreatePhlebo: React.FC<ModalProps> = ({
  openMainMeberSyncModal,
  setMainMemberSyncModal,
  bookingByBarcode,
  syncId,
  getSyncData,
  barcodeId,
  getBookingByBarcode,
  syncLoading,
}) => {
  const classes = useStyles();
  const [passportNumber, setPassportNumber] = useState<any>("");
  const [adharNumber, setAdharNumber] = useState<any>("");
  const [srfNumber, setSrfNumber] = useState<any>("");
  const [barcode, setBarcode] = useState<any>("");
  const [syncSubmit, setSyncSubmit] = useState<boolean>(false);
  const handleClose = () => {
    setMainMemberSyncModal(false);
  };

  const submitForm = async (e: any) => {
    setSyncSubmit(true);
    e.preventDefault();
    const body = {
      customer_aadhar: adharNumber,
      passport_number: passportNumber,
      srf_number: srfNumber,
      barcode: barcode,
    };
    const body2 = {
      customer_aadhar: adharNumber,
      passport_number: passportNumber,
      srf_number: srfNumber,
      barcode: barcode,
      is_additional: true,
    };

    await getSyncData(
      `${syncId}/`,
      bookingByBarcode?.type === "mainbooking" ? body : body2
    );
    setTimeout(() => {
      getBookingByBarcode(barcodeId);
      setSyncSubmit(false)
    }, 1000);

    setMainMemberSyncModal(false);
  };

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setAdharNumber(bookingByBarcode.customer_aadhar);
    setPassportNumber(bookingByBarcode.passport_number);
    setBarcode(barcodeId);
  }, [openMainMeberSyncModal]);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openMainMeberSyncModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openMainMeberSyncModal}>
          <div className={classes.paper}>
            <Paper elevation={5} className={classes.paper}>
              <div className={classes.head}>
                <h4
                  style={{
                    marginBottom: "1rem",
                  }}
                  id="transition-modal-title"
                >
                  SYNC {bookingByBarcode?.customer_name} BOOKING ({syncId})
                </h4>
                <CloseSharpIcon onClick={() => setMainMemberSyncModal(false)} />
              </div>
              <form onSubmit={submitForm} onLoad={syncLoading}>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  alignItems="center"
                  justify="center"
                >
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="adhar_number"
                      type="number"
                      onWheel={(e: any) => e.target.blur()}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 12);
                      }}
                      label="AADHAAR NUMBER"
                      value={adharNumber}
                      variant="outlined"
                      onChange={(e) => setAdharNumber(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="passport_number"
                      type="text"
                      label="PASSPORT NUMBER"
                      value={passportNumber}
                      variant="outlined"
                      onChange={(e) => setPassportNumber(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="Srf_Number"
                      type="text"
                      label="SRF NUMBER"
                      value={srfNumber}
                      variant="outlined"
                      onChange={(e) => setSrfNumber(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="barcode"
                      type="text"
                      label="BARCODE"
                      value={barcode}
                      variant="outlined"
                      onChange={(e) => setBarcode(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    {syncSubmit ? "" : (
                      <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                      // disabled={syncSubmit}
                      >
                        SYNC BOOKING
                      </Button>
                    )}

                  </Grid>
                </Grid>
              </form>
            </Paper>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {
  getSyncData,
})(CreatePhlebo);
