import React, { useEffect,useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import { generateBookingFilterUrl } from "../../../helpers/generateUrl";
import { connect } from "react-redux";
import { getFailedReportData } from "../../actions/LimsAdminActions";
import { useNavigate,useNavigationType } from "react-router-dom";
import Loader from "../../components/loader";
import BookingTable from "./table";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

interface Props {
  children: any;
  loading: boolean;
  failedReport: any;
  getFailedReportData: any;
  getPaymentInfo: any;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
  getPaymentInfo,
  getFailedReportData,
  failedReport,
  loading,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const navigationType=useNavigationType();
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const booking_date="";
  const [name, setName] = useState("");
  const [lead, setLeadId] = useState<any>("");
  const phone="";
  const [bookingId, setBookingId] = useState<any>("");
  const sample_registered="none"
  const collection_date=""
  const report_status="none";
  const [paymentId, setPaymentId] = useState<any>(0);
  const [page, setPage] = useState(0);
  const [booking_type, setbooking_type] = useState<String>("none");
  const [status, setStatus] = useState<String>("none");

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (navigationType !== "POP") {
      getFailedReportData();
    }
  }, []);

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (paymentId !== 0) {
      getPaymentInfo(`?booking=${paymentId}`);
    }
  }, [paymentId]);

  const filterBookings = () => {
    const body: any = {
      name,
      lead,
      phone,
      start_date,
      end_date,
      bookingId,
      sample_registered,
      report_status,
      booking_type: booking_type,
      report_not_status: status,
    };
    const url = generateBookingFilterUrl(body).substring(2);
    getFailedReportData(url);
    setPage(0);
  };
  return (
    <>
    <div style={{ width: "95%" }} className="data-table">
        <div className={classes.toolbar} />
        <h2>Report Not Delivered </h2>
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "left" }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="name"
              type="number"
              label="Booking Id"
              value={bookingId}
              variant="outlined"
              onChange={(e) => setBookingId(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="leadId"
              type="number"
              label="Lead Id"
              value={lead}
              variant="outlined"
              onChange={(e) => setLeadId(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="name"
              type="text"
              label="Name"
              value={name}
              variant="outlined"
              onChange={(e) => setName(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="start_date"
              type="date"
              label="Start Date"
              value={
                booking_date === "" || collection_date === "" ? start_date : ""
              }
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setStart_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="end_date"
              type="date"
              value={
                booking_date === "" || collection_date === "" ? end_date : ""
              }
              className="input"
              label="End Date"
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setEnd_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="finance_status"
              variant="outlined"
              value={booking_type}
              style={{ width: "100%",height:"40px" }}
              onChange={(e) => setbooking_type(e.target.value as String)}
            >
              <MenuItem disabled value="none">
                Booking Type
              </MenuItem>
              <MenuItem value={"b2b"}>B2B</MenuItem>
              <MenuItem value={"b2c"}>B2C</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="finance_status"
              variant="outlined"
              value={status}
              style={{ width: "100%" ,height:"40px"}}
              onChange={(e) => setStatus(e.target.value as String)}
            >
              <MenuItem disabled value="none">
                Status
              </MenuItem>
              <MenuItem value={"pending"}>Pending</MenuItem>
              <MenuItem value={"confirmed"}>Confirm</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              
              fullWidth
              onClick={filterBookings}
              disabled={loading}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              
              fullWidth
              onClick={() => navigate(0)}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <br />
        {loading ? (
          <Loader />
        ) : (
          failedReport.results &&
          failedReport.results.length > 0 && (
            <Box>
              <Container maxWidth={false}>
                <Grid container spacing={3}>
                  <BookingTable
                    page={page}
                    setPage={setPage}
                    loading={loading}
                    paymentId={paymentId}
                    getFailedReportData={getFailedReportData}
                    setPaymentId={setPaymentId}
                    failedReport={failedReport}
                  />
                </Grid>
              </Container>
            </Box>
          )
        )}
        {failedReport.results && failedReport.results.length === 0 && (
          <h6 style={{ textAlign: "center" }}>No Data Found</h6>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    failedReport: state.LimsAdminReducer.failedReport,
  };
};

export default connect(mapStateToProps, {
  getFailedReportData,
})(CustomerLeadFilterPage);
