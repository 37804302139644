import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {Modal,Fade,Backdrop} from '@mui/material';
import { Button, MenuItem, TextField, Grid } from "@material-ui/core";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { raiseNewTicektDisposition } from "../../actions/LimsAdminActions";
import { useEffect } from "react";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '10%',
        left: '10%',
        overflow: 'scroll',
        height: '100%',
        // display:'block'

    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px", zIndex:1,
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem",
    },
}));

type ModalProps = {
    open: boolean;
    bookingId: number;
    raiseNewTicektDisposition: any;
    complaint: any;
    leadId: number;
    setOpen: Function;
    bookingIdList: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
    open,
    bookingId,
    leadId,
    raiseNewTicektDisposition,
    complaint,
    setOpen,
    bookingIdList,
}) => {
    const classes = useStyles();

    const [query, setQuery] = useState("");
    const [description, setDescription] = useState("");
    const [memberType, setMemberType] = useState<any>("")

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
        if (bookingIdList?.pk === query) {
            setMemberType("main")
        }
        else {
            setMemberType("additional")
        }
    }, [query])


    const handleClose = () => {
        setOpen(false);
    };

    const raiseComplaint = () => {
        const body: any =
        {
            query: description,
            disposition: "document_required",
            booking_id: query,
        }
        const body1: any =
        {
            query: description,
            disposition: "document_required",
            additional_member_id: query
        }
        if(memberType==="main"){
            raiseNewTicektDisposition(body,"");
        }
        else{
            raiseNewTicektDisposition(body1,"");
        }
        
        setDescription("");
    };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
        if (complaint) {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [complaint]);
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.head}>
                            <h4
                                style={{
                                    marginBottom: "1rem",
                                }}
                                id="transition-modal-title"
                            >
                                Add Description For Document Required
                            </h4>
                            <CloseSharpIcon onClick={() => setOpen(false)} />
                        </div>
                        <Grid
                            container
                            direction="row"
                            alignItems="flex-start"
                            justify="space-between"
                            spacing={3}
                            style={{ margin: "1rem auto" }}
                        >
                            <Grid item xs={12} md={12}>
                                <TextField
                                    className="input"
                                    name="action"
                                    select
                                    label="Booking Details"
                                    variant="outlined"
                                    value={query}
                                    style={{ width: "100%" }}
                                    onChange={(e) => setQuery(e.target.value as string)}
                                >
                                    <MenuItem value={bookingIdList?.pk}>{bookingIdList?.customer_name}</MenuItem>
                                    {bookingIdList?.additional_members &&
                                        bookingIdList?.additional_members.length > 0 &&
                                        bookingIdList?.additional_members.map((data: any) => {
                                            return (
                                                <MenuItem value={data?.id}>{data?.customer_name}</MenuItem>
                                            );
                                        })}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="comment"
                                    type="text"
                                    multiline
                                    rows={3}
                                    value={description}
                                    variant="outlined"
                                    placeholder="Document Required for Report"
                                    onChange={(e) => setDescription(e.target.value as string)}
                                    style={{ width: "100%" }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ height: "50px" }}
                                    onClick={raiseComplaint}
                                    disabled={!description}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    complaint: state.LimsAdminReducer.complaint,
});

export default connect(mapStateToProps, {
    raiseNewTicektDisposition,
})(CommentsModal2);
