import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  Box,
  Button,
  Stack,
  Fade,
  Modal,
  Backdrop,
  Typography
} from '@mui/material';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Autocomplete from '@mui/material/Autocomplete';
import QuizRoundedIcon from '@mui/icons-material/QuizRounded';
import { generateBookingFilterUrl } from "../../helpers/generateUrl";
import { connect } from "react-redux";
import {
  getAgentList,
  getLeadSource,
  getPartners,
  getAllBookings,
  getCities,
  getPhlebos,
  getPaymentInfo,
  getQRCodeData,
  getZone,
} from "../actions/LimsAdminActions";
import "./filter.sass";
import { useNavigate,useNavigationType } from "react-router-dom";
import BookingsTable from "../tables/bookingsTable";
import Loader from "../components/Loader2";
import PaymentModal from "../components/Comments/paymentforModal";
import TubeModal from "../components/Comments/tubeModal";
import SyncModal from "../components/Comments/syncModal";
import AdditionalSyncModal from "../components/Comments/additionMemberSyncModal";
import BookingCommentsModal from '../components/Comments/bookingComments'

const BILLING_STATUS = [
  {
    text: "Pending",
    value: "pending",
  },
  {
    text: "Confirmed",
    value: "confirmed",
  },
  {
    text: "Cancelled",
    value: "cancelled",
  },
  {
    text: "Rescheduled",
    value: "rescheduled",
  },
  {
    text: "Resampling",
    value: "resampling",
  },
  {
    text: "Sample Not Received",
    value: "sample not received",
  },
  {
    text: "Sample Not Received Due To Payment Information",
    value: "sample not received due to payment information",
  },
  {
    text: "Sample Received",
    value: "sample received",
  },
  {
    text: "Partial Received",
    value: "partial received",
  },
  {
    text: "Order booked",
    value: "order booked",
  },
  {
    text: "Verified",
    value: "verified",
  },
  {
    text: "Phlebo Assigned",
    value: "phlebo assigned",
  },
  {
    text: "Phlebo Reached",
    value: "phlebo reached",
  },
  {
    text: "Phlebo Started",
    value: "phlebo started",
  },
  {
    text: "Sample Collected",
    value: "sample collected",
  },
  {
    text: "Sample Received in Lab",
    value: "sample received in lab",
  },
  {
    text: "Reports Sent",
    value: "reports sent",
  }
];
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);

interface Props {
  children: any;
  agentsList: any;
  bookingList: any;
  partnerList: any;
  getAgentList: any;
  getPartners: any;
  getAllBookings: any;
  getCities: any;
  cities: any;
  getPhlebos: any;
  phleboList: any;
  getLeadSource: any;
  getPaymentInfo: any;
  paymentInformation: any;
  lead_source: any;
  getQRCodeData: any;
  SyncLoading: any;
  qrcode: any;
  loading: boolean;
  zoneList: any;
  getZone: any;
  bookingListLoader:boolean;
}

const LimsAdminFilterPage: React.FC<Props> = ({
  agentsList,
  partnerList,
  getAgentList,
  getPartners,
  getAllBookings,
  bookingList,
  getCities,
  cities,
  getPhlebos,
  phleboList,
  getLeadSource,
  SyncLoading,
  getPaymentInfo,
  paymentInformation,
  getQRCodeData,
  getZone,
  qrcode,
  lead_source,
  zoneList,
  loading,
  bookingListLoader,
}) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const navigationType=useNavigationType();
  const [openModal, setopenModal] = useState<boolean>(false);
  const [bookingId, setBookingId] = useState<any>("");
  const [childId, setChildId] = useState<any>("");
  const [status, setStatus] = useState<any>([]);
  const [source, setSource] = useState<any>([]);
  const [partner, setPartner] = useState<any>([]);
  const [agent, setAgent] = useState<number>(0);
  const [city, setCity] = useState<any>([]);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [booking_date, setBooking_date] = useState("");
  const [collection_date, setCollection_date] = useState("");
  const [verificationStatus, setVerificationStatus] = useState<any>([]);
  const [pickup_status, setPickup_status] = useState<any>("none");
  const [name, setName] = useState("");
  const [phlebo, setPhlebo] = useState<number>(0);
  const [phone, setPhone] = useState("");
  const [sample_registered, setSampleRegisterd] = useState<any>("none");
  const [report_status, setReportStatus] = useState<any>("none");
  const [page, setPage] = useState(0);
  const [paymentId, setPaymentId] = useState<any>(0);
  const [openPaymentModal, setOpenPaymentModal] = useState<any>(false);
  const [defaultStatus, setDefaultStatus] = useState<any>([]);
  const [openTubeModal, setOpenTubeModal] = useState<any>(false);
  const [bId, setBId] = useState<any>("");
  const [tubeId, setTubeId] = useState<any>("");
  const [updateTube, setUpdateTube] = useState<any>("");
  const timer = useRef<any>(0);
  const [openSyncModal, setSyncOpenModal] = useState<boolean>(false);
  const [syncId, setSyncId] = useState<any>("");
  const [bookingData, setBookingData] = useState({});
  const [openAdditionalSyncModal, setSyncAdditionalOpenModal] =
    useState<boolean>(false);
  const [syncAdditionalId, setAdditionalSyncId] = useState<any>("");
  const [bookingAdditionalData, setAdditonalBookingData] = useState({});
  const [totalCount, setTotalCount] = useState<any>("");
  const [booking_type, setbooking_type] = useState<String>("none");
  const [pageNumber, setPageNumber] = useState<any>("");
  const [barcode, setBarcode] = useState<any>("");
  const [barcodeFilter, setBarcodeFilter] = useState<boolean>(false);
  const [bookingType, setBookingType] = useState<any>("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (navigationType === "POP") {
      let ceBookingFilter: any;
      ceBookingFilter = localStorage.getItem("CEBookingFilter");
      if (ceBookingFilter) {
        ceBookingFilter = JSON.parse(ceBookingFilter);
        const statusD = ceBookingFilter?.status?.split(",");
        type StatusType={text:String; value: String;}
        const statusTemp: StatusType[] = [];
        BILLING_STATUS.map((item: any) => {
          statusD.map((itm: any) => {
            if (item.value===itm) {
              statusTemp.push(item);
            }
          });
        });
        setDefaultStatus(statusTemp);
        ceBookingFilter?.status &&
          setStatus(ceBookingFilter?.status?.split(","));
        ceBookingFilter?.source &&
          setSource(ceBookingFilter?.source?.split(","));
        ceBookingFilter?.partner &&
          setPartner(ceBookingFilter?.partner?.split(","));
        ceBookingFilter?.city && setCity(ceBookingFilter?.city?.split(","));
        ceBookingFilter?.start_date &&
          setStart_date(ceBookingFilter?.start_date);
        setCollection_date(ceBookingFilter?.collection_date);
        ceBookingFilter?.verificationStatus &&
          setVerificationStatus(
            ceBookingFilter?.verificationStatus?.split(",")
          );
        ceBookingFilter?.pickup_status &&
          setPickup_status(ceBookingFilter?.pickup_status);
        setName(ceBookingFilter?.name);
        setPhlebo(ceBookingFilter?.phlebo);
        setPhone(ceBookingFilter?.phone);
        setBookingId(ceBookingFilter?.bookingId);
        setSampleRegisterd(ceBookingFilter.sample_registered);
        setReportStatus(ceBookingFilter.report_status);
        setBarcode(ceBookingFilter.barcode);
        const url = generateBookingFilterUrl(ceBookingFilter).substring(2);
        const pageNo = page ? page : 1;
        getAllBookings(`${url}&page=${pageNo}&optimize=true`);
      }
    } else {
      // getAgentList();
      // getPartners();
      // getAllBookings();
      // getCities();
      // getPhlebos();
      getLeadSource();
      localStorage.removeItem("CEBookingFilter");
      setDefaultStatus([]);
    }
  }, [setDefaultStatus]);
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (paymentId !== 0) {
      getPaymentInfo(`?booking=${paymentId}`);
    }
  }, [paymentId]);
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getAllBookings(`optimize=true`);
  }, []);
  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      filterBookings(e);
    }
  };
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (tubeId !== "" && bId !== "") {
      if (bId === tubeId) {
        getQRCodeData(`?booking_id=${tubeId}`);
        setOpenTubeModal(true);
      } else {
        getQRCodeData(`?add_booking_id=${tubeId}`);
        setOpenTubeModal(true);
      }
    }
  }, [tubeId, updateTube]);

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (barcodeFilter) {
      if (
        bookingList &&
        bookingList?.results &&
        bookingList?.results.length === 1
      ) {
        navigate(
          `/dashboard/la/booking-view/${bookingList?.results[0]?.pk}`
        );
      }
    }
  }, [bookingList]);

  const resethandler = () => {

    getAllBookings(`optimize=true`)
    navigate("/dashboard/la/booking_list")
  }

  const filterBookings = (e: any) => {
    const body: any = {
      status: status && status ? status.join(",") : "",
      verificationStatus:
        verificationStatus && verificationStatus
          ? verificationStatus?.join(",")
          : "",
      source: source && source ? source?.join(",") : "",
      agent,
      city: city && city ? city?.join(",") : "",
      start_date,
      end_date,
      booking_date,
      collection_date,
      pickup_status,
      name,
      phlebo,
      partner: partner ? partner.join(",") : "",
      phone,
      sample_registered,
      report_status,
      booking_type: booking_type,
      bookingId,
      childId: childId,
      barcode,
    };
    localStorage.setItem("barcode", barcode);
    localStorage.setItem("CEBookingFilter", JSON.stringify(body));
    const url = generateBookingFilterUrl(body).substring(2);
    if (url.includes("barcode")) {
      setBarcodeFilter(true);
    } else {
      setBarcodeFilter(false);
    }

    getAllBookings(`${url ? `${url}&optimize=true` : "optimize=true"}`);
    setPage(0);
  };
  const handleCross = () => {
    setOpen(false)
  }

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setTotalCount(bookingList?.count);
  }, [bookingList]);

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Stack direction="row" spacing={2} >
        <Grid item xs={12} sm={8}>
            <h4 style={{ fontWeight: "bold" }}>BOOKING LIST <QuizRoundedIcon color="primary" onClick={handleOpen} /></h4>
          </Grid>
          <Grid item xs={12} sm={4}>
            <h4 style={{ fontWeight: "bold" }}>BOOKING COUNT : {totalCount}</h4>
          </Grid>
        </Stack>
     

        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "left",marginTop:"0.5rem"}}
        >
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="barcode"
              type="text"
              label="Barcode"
              value={barcode}
              variant="outlined"
              onChange={(e) => setBarcode(e.target.value)}
              style={{ width: "100%" }}
              onKeyDown={onKeyDown}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="name"
              type="number"
              label="Booking Id"
              value={bookingId}
              variant="outlined"
              onChange={(e) => setBookingId(e.target.value)}
              style={{ width: "100%" }}
              onKeyDown={onKeyDown}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="name"
              type="number"
              label="Child Id"
              value={childId}
              variant="outlined"
              onChange={(e) => setChildId(e.target.value)}
              style={{ width: "100%" }}
              onKeyDown={onKeyDown}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="name"
              type="text"
              label="Name"
              value={name}
              variant="outlined"
              onChange={(e) => setName(e.target.value as string)}
              style={{ width: "100%" }}
              onKeyDown={onKeyDown}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="phone"
              type="number"
              label="Phone"
              value={phone}
              onInput={(e: any) => {
                e.target.value = Math.max(
                  0,
                  parseInt(e.target.value)
                )
                  .toString()
                  .slice(0, 10);
              }}
              variant="outlined"
              onChange={(e) => setPhone(e.target.value as string)}
              style={{ width: "100%" }}
              onKeyDown={onKeyDown}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setCity(sourceIds);
              }}
              options={cities || []}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              onKeyDown={onKeyDown}
              getOptionLabel={(option: any) => option.name}
              getOptionDisabled={(option: any) => {
                return city.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCities(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setCity([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="City"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="status"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setStatus(sourceIds);
              }}
              options={BILLING_STATUS || []}
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              onKeyDown={onKeyDown}
              getOptionDisabled={(option: any) => {
                return status.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="input"
                  label="Booking Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
              defaultValue={defaultStatus}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="partner"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setPartner(sourceIds);
              }}
              freeSolo
              blurOnSelect
              limitTags={1}
              multiple
              options={partnerList?.results || []}
              onKeyDown={onKeyDown}
              getOptionDisabled={(option: any) => {
                return partner.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) => option.user && option.user.name}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPartners(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPartner([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Partner"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setSource(sourceIds);
              }}
              multiple
              id="checkboxes-tags-demo"
              options={lead_source || []}
              disableCloseOnSelect
              onKeyDown={onKeyDown}
              limitTags={1}
              disableClearable
              getOptionLabel={(option: any) => option?.name}
              renderOption={(option: any, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon/>}
                    checkedIcon={<CheckBoxIcon />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Source"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="agentname"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setAgent(obj.id);
                }
              }}
              options={agentsList.results || []}
              freeSolo
              blurOnSelect
              aria-required
              onKeyDown={onKeyDown}
              getOptionLabel={(option: any) => option.user && option.user.name}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getAgentList(`${newInputValue.replace(" ", "")}`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setAgent(0);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Agent Name"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="agentname"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setPhlebo(obj.id);
                }
              }}
              options={
                phleboList.results
                  ? [
                    ...phleboList.results,
                    { id: 0, user: { username: "NoPhlebo" } },
                  ]
                  : []
              }
              freeSolo
              blurOnSelect
              aria-required
              onKeyDown={onKeyDown}
              getOptionLabel={(option: any) =>
                option.user && option.user.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPhlebos(`code=${newInputValue}`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPhlebo(0);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Phlebo Name"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setVerificationStatus(sourceIds);
              }}
              options={[
                {
                  text: "Pending",
                  value: "pending",
                },
                {
                  text: "Confirmed",
                  value: "confirmed",
                },
                {
                  text: "Cancelled",
                  value: "cancelled",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              onKeyDown={onKeyDown}
              getOptionDisabled={(option: any) => {
                return verificationStatus.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Verification Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="status"
              variant="outlined"
              value={pickup_status}
              style={{ width: "100%", height: "40px" }}
              onKeyDown={onKeyDown}
              onChange={(e) => setPickup_status(e.target.value as string)}
            >
              <MenuItem disabled value="none">
                Pickup Status
              </MenuItem>
              <MenuItem value={"pending"}>Pending</MenuItem>
              <MenuItem value={"confirmed"}>Confirmed</MenuItem>
              <MenuItem value={"cancelled"}>Cancelled</MenuItem>
              <MenuItem value={"hold"}>Hold</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="start_date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              label="Start Date"
              value={
                booking_date === "" || collection_date === "" ? start_date : ""
              }
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onKeyDown={onKeyDown}
              onChange={(e) => setStart_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="end_date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={
                booking_date === "" || collection_date === "" ? end_date : ""
              }
              className="input"
              label="End Date"
              variant="outlined"
              onKeyDown={onKeyDown}
              disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setEnd_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="booking_date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={start_date === "" || end_date === "" ? booking_date : ""}
              className="input"
              label="Booking Date"
              variant="outlined"
              onKeyDown={onKeyDown}
              disabled={start_date !== "" || end_date !== ""}
              onChange={(e) => setBooking_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="collection_date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={
                start_date === "" || end_date === "" ? collection_date : ""
              }
              className="input"
              label="Collection Date"
              variant="outlined"
              onKeyDown={onKeyDown}
              disabled={start_date !== "" || end_date !== ""}
              onChange={(e) => setCollection_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="sampleregisterd"
              variant="outlined"
              value={sample_registered}
              onKeyDown={onKeyDown}
              style={{ width: "100%", height: "40px" }}
              onChange={(e) => setSampleRegisterd(e.target.value as String)}
            >
              <MenuItem disabled value="none">
                Sample Type
              </MenuItem>
              <MenuItem value={"true"}>Registerd</MenuItem>
              <MenuItem value={"false"}>Not Registerd</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="sampleregisterd"
              variant="outlined"
              value={report_status}
              onKeyDown={onKeyDown}
              style={{ width: "100%", margin: "0", height: "40px" }}
              onChange={(e) => setReportStatus(e.target.value as String)}
            >
              <MenuItem disabled value={"none"}>
                Report Status
              </MenuItem>
              <MenuItem value={"na"}>None</MenuItem>
              <MenuItem value={"pending"}>Pending</MenuItem>
              <MenuItem value={"partial"}>Partial</MenuItem>
              <MenuItem value={"consolidate"}>Consolidate</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="finance_status"
              variant="outlined"
              value={booking_type}
              style={{ width: "100%", height: "40px" }}
              onChange={(e) => setbooking_type(e.target.value as String)}
            >
              <MenuItem disabled value="none">
                Booking Type
              </MenuItem>
              <MenuItem value={"b2b"}>B2B</MenuItem>
              <MenuItem value={"b2c"}>B2C</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterBookings}
              disabled={bookingListLoader}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              disabled={bookingListLoader} 
              fullWidth
              onClick={() => resethandler()}
            >
              Reset
            </Button>
          </Grid>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div style={{ position: "absolute", left: "10%", right: "15%", top: "25%", minHeight: "400", minWidth: "500", backgroundColor: "white" }}>
                <Box style={{}}>
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    <div style={{ margin: "0rem 2rem", paddingTop: "1rem", display: "flex", justifyContent: "space-between" }}>
                      <h4>Note:</h4>
                      <CloseSharpIcon onClick={handleCross} />
                    </div>
                  </Typography>
                  <Typography id="transition-modal-description" sx={{ mt: 1 }}>
                    <div style={{ margin: "1rem" }}>
                      <div style={{ alignItems: "left", display: "inline-block", margin: "1rem" }}>
                        <small>
                          <ul style={{ paddingLeft: "15px" }}>
                            <li style={{ fontWeight: "bold", color: "default" }}>
                              You can filter by booking date or start and end date.
                            </li>
                            <li style={{ fontWeight: "bold", color: "default" }}>
                              T: Total Additional Booking count
                            </li>
                            <li style={{ fontWeight: "bold", color: "default" }}>
                              P: Pending count for additional member
                            </li>
                            <li style={{ fontWeight: "bold", color: "default" }}>
                              C: Confirmed count for additional member
                            </li>
                            <li style={{ fontWeight: "bold", color: "default" }}>
                              For B2B booking first select Booking Type b2b then apply
                              any filter option
                            </li>
                            <li style={{ fontWeight: "bold", color: "default" }}>
                              For Sync Covid Report SRF and Barcode is Required
                            </li>
                            <li style={{ fontWeight: "bold", color: "default" }}>
                              You Can Sync other sample without filling details{" "}
                            </li>
                          </ul>
                        </small>
                      </div>
                      <div style={{ alignItems: "left", display: "inline-block", margin: "1rem" }}>
                        <small>
                          <ul style={{ paddingLeft: "15px" }}>
                            <li style={{ fontWeight: "bold", color: "#ffe082" }}>
                              B2B booking and Payable Amount is Zero
                            </li>
                            <li style={{ fontWeight: "bold", color: "#f48fb1" }}>
                              B2B booking and Payable Amount is not Zero
                            </li>
                            <li style={{ fontWeight: "bold", color: "#64ffda" }}>
                              B2C booking and Payable Amount is Zero
                            </li>
                            <li style={{ fontWeight: "bold", color: "default" }}>
                              B2C booking and Payable Amount is not Zero
                            </li>
                            <li style={{ fontWeight: "bold", color: "default" }}>
                              Additional Member Booking Filter Added in Booking Table
                            </li>
                            <li style={{ fontWeight: "bold", color: "default" }}>
                              Additional Member Sync Button added in Additional Member bodal
                            </li>
                          </ul>
                        </small>
                      </div>
                    </div>
                  </Typography>
                </Box>
              </div>
            </Fade>
          </Modal>
        </Grid>
        <PaymentModal
          openModalP={openPaymentModal}
          setOpenModalP={setOpenPaymentModal}
          paymentInformation={paymentInformation}
        />
        <br />
        {bookingListLoader ? (
            <Loader />
          ) : (
          bookingList.results &&
          bookingList.results.length > 0 && (
            <Box>
              <Container maxWidth={false}>
                <Grid container spacing={3}>
                  <BookingsTable
                    setopenModal={setopenModal}
                    page={page}
                    setPage={setPage}
                    getBookings={getAllBookings}
                    bookingList={bookingList}
                    setPaymentId={setPaymentId}
                    setOpenPaymentModal={setOpenPaymentModal}
                    setOpenTubeModal={setOpenTubeModal}
                    setTubeId={setTubeId}
                    setSyncOpenModal={setSyncOpenModal}
                    setSyncId={setSyncId}
                    setBookingData={setBookingData}
                    setSyncAdditionalOpenModal={setSyncAdditionalOpenModal}
                    setAdditionalSyncId={setAdditionalSyncId}
                    setAdditonalBookingData={setAdditonalBookingData}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                    SyncLoading={SyncLoading}
                    setBId={setBId}
                    setBookingType={setBookingType}
                    setBookingId={setBookingId}
                    getZone={getZone}
                    zoneList={zoneList}
                    bookingListLoader={bookingListLoader}
                  />
                </Grid>
              </Container>
              <TubeModal
                setOpenTubeModal={setOpenTubeModal}
                openTubeModal={openTubeModal}
                qrcode={qrcode}
                getQRCodeData={getQRCodeData}
                tubeId={tubeId}
                setTubeId={setTubeId}
                setUpdateTube={setUpdateTube}
                bookingType={bookingType}
              />
              <SyncModal
                openSyncModal={openSyncModal}
                setSyncOpenModal={setSyncOpenModal}
                syncId={syncId}
                bookingData={bookingData}
                getAllBookings={getAllBookings}
                filterBookings={filterBookings}
              />
              <AdditionalSyncModal
                openAdditionalSyncModal={openAdditionalSyncModal}
                setSyncAdditionalOpenModal={setSyncAdditionalOpenModal}
                syncId={syncAdditionalId}
                bookingData={bookingAdditionalData}
                filterBookings={filterBookings}
              />
            </Box>
          )
        )}
        {bookingList.results && bookingList.results.length === 0 && (
          <h6 style={{ textAlign: "center" }}>No Data Found</h6>
        )}
        <BookingCommentsModal openModal={openModal} setopenModal={setopenModal} bookingId={bookingId} />
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  agentsList: state.LimsAdminReducer.agentsList,
  partnerList: state.LimsAdminReducer.partnerList,
  bookingList: state.LimsAdminReducer.bookingList,
  cities: state.LimsAdminReducer.cities,
  syncLoading: state.LimsAdminReducer.syncLoading,
  phleboList: state.LimsAdminReducer.phleboList,
  loading: state.LimsAdminReducer.loading,
  paymentInformation: state.LimsAdminReducer.paymentInformation,
  qrcode: state.LimsAdminReducer.qrcode,
  lead_source: state.LimsAdminReducer.lead_source,
  zoneList: state.LimsAdminReducer.zoneList,
  bookingListLoader: state.LimsAdminReducer.bookingListLoader,
});

export default connect(mapStateToProps, {
  getAgentList,
  getPartners,
  getCities,
  getPhlebos,
  getLeadSource,
  getPaymentInfo,
  getAllBookings,
  getQRCodeData,
  getZone,
})(LimsAdminFilterPage);
