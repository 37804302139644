export const GENDER_CHOICE = [
  {
    display_name: "Mrs",
    db_name: "Mrs",
    gender: "female",
  },
  {
    display_name: "Miss",
    db_name: "Miss",
    gender: "female",
  },
  {
    display_name: "Ms",
    db_name: "Ms",
    gender: "female",
  },
  {
    display_name: "Smt",
    db_name: "Smt",
    gender: "female",
  },
  {
    display_name: "Mr",
    db_name: "Mr",
    gender: "male",
  },
  {
    display_name: "Baby Boy",
    db_name: "Master",
    gender: "male",
  },
  {
    display_name: "Baby Girl",
    db_name: "Master",
    gender: "female",
  },
  {
    display_name: "Master",
    db_name: "Master",
    gender: "male",
  },
  {
    display_name: "Baby",
    db_name: "Master",
    gender: "female",
  },
];
