
import React, { useEffect, useState, useRef } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import moment from "moment";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import logo from "../../../redcliffe_logo.jpeg";
import callIcon from "../../../callicon.png";
import maillIcon from "../../../mailicon.png";
import qcCode from "../../../qrcode.png";
import iso from "../../../iso.png";
import browserIcon from "../../../browsericon.png";
import ReactToPrint from "react-to-print";
import './report.css'
const useStyles = makeStyles((theme) =>
    createStyles({
        paper: {
            width: "100%",
        },
        centerIt: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        fontWeight: {
            fontWeight: "bold",
        },
        textCenter: {
            textAlign: "center",
        },
        endIt: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
        },
        belowIt: {
            display: "flex",
            alignItems: "flex-end",
        },
        line: {
            borderBottom: "5px solid red",
            marginTop: "5px",
            width: "90%",
        }
    })
);


interface Props {

}
const NbsReport: React.FC<Props> = ({

}) => {
    const componentRef = useRef<any>(null);
    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        return <button style={{ marginLeft: "10px" }}>Print Receipt</button>;
    }, []);

    return (
        <div style={{ margin: "55px auto" }}>
            <div>
                <div style={{ height: "20px", }}>
                    <div>
                        <ReactToPrint
                            content={reactToPrintContent}
                            trigger={reactToPrintTrigger}
                        />
                    </div>
                </div>
            </div>
            <div
                id="PAGE"
                ref={componentRef}
                style={{
                    width: 1122,
                    background: "#fff",

                }}
            >
                {/* initial page  */}

                <div className="page-header">
                    <div style={{ padding: "20px" }}>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                background: "#fff",

                            }}
                        >
                            <div style={{ width: "20%", textAlign: "left", padding: 15 }}>
                                <h3>
                                    <img src={iso} alt="logo" style={{ width: "70px" }} />
                                </h3>
                            </div>
                            <div style={{ width: "60%", padding: 15 }}>
                                <div style={{ color: "black", fontWeight: "bold", fontSize: "40px", textAlign: "center" }}>
                                    LABORATORY REPORT
                                </div>
                            </div>
                            <div style={{ width: "20%", textAlign: "left", padding: 15 }}>
                                <h3>
                                    <img src={logo} alt="logo" style={{ width: "200px" }} />
                                </h3>
                            </div>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                background: "#fff",

                            }}
                        >
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <div style={{ color: "#b52155", fontWeight: "bold", fontSize: "30px" }}>
                                    NEWBORN SCREENING PROFILE
                                </div>
                            </div>
                        </div>
                        {/* header section */}
                        <div style={{ width: "100%", display: "flex", margin: "10px 0px", textAlign: "start" }}>
                            <div style={{ width: "33%", marginLeft: 8 }}>Name of Baby:B/O MEGHA</div>
                            <div style={{ width: "33%", marginLeft: 8 }}>Mothers Name:MEGHA</div>
                            <div style={{ width: "33%", marginLeft: 8 }}>Test/Package:Baby Screen -7</div>
                        </div>
                        <div style={{ width: "100%", display: "flex", margin: "10px 0px", textAlign: "start" }}>
                            <div style={{ width: "33%", marginLeft: 8 }}>Visit No:</div>
                            <div style={{ width: "33%", marginLeft: 8 }}>Barcode No:</div>
                            <div style={{ width: "33%", marginLeft: 8 }}>Sample Type:</div>
                        </div>
                        <div style={{ width: "100%", display: "flex", margin: "10px 0px", textAlign: "start" }}>
                            <div style={{ width: "33%", marginLeft: 8 }}>Gender:</div>
                            <div style={{ width: "33%", marginLeft: 8 }}>Baby Weight(Kg):</div>
                            <div style={{ width: "33%", marginLeft: 8 }}>Age(in hrs):</div>
                        </div>
                        <div style={{ width: "100%", display: "flex", margin: "10px 0px", textAlign: "start" }}>
                            <div style={{ width: "33%", marginLeft: 8 }}>Date Of Birth:</div>
                            <div style={{ width: "33%", marginLeft: 8 }}>Time Of Birth(24 hrs):</div>
                            <div style={{ width: "33%", marginLeft: 8 }}>Report Date & Time:</div>
                        </div>
                        <div style={{ width: "100%", display: "flex", margin: "10px 0px", textAlign: "start" }}>
                            <div style={{ width: "33%", marginLeft: 8 }}>Collection Date & Time:</div>
                            <div style={{ width: "33%", marginLeft: 8 }}>Received Date & Time:</div>
                            <div style={{ width: "33%", marginLeft: 8 }}>Specimen Notes:</div>
                        </div>
                        <div style={{ width: "100%", display: "flex", margin: "10px 0px", textAlign: "start" }}>
                            <div style={{ width: "33%", marginLeft: 8 }}>Referred By :</div>
                            <div style={{ width: "33%", marginLeft: 8 }}>Hospital Name:</div>
                            <div style={{ width: "33%", marginLeft: 8 }}>Special Feed:</div>
                        </div>
                        <div style={{ width: "100%", display: "flex", margin: "10px 0px", textAlign: "start" }}>
                            <div style={{ width: "33%", marginLeft: 8 }}>Blood Transfusion :</div>
                        </div>
                    </div>
                    {/* header section closed */}
                </div>
                <div className="content">

                    {/* table data */}
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            background: "#fff",
                            padding: "30px"
                        }}
                    >
                        <div style={{ width: "100%", textAlign: "left" }}>
                            <div style={{ color: "#fff", fontWeight: "bold", fontSize: "20px", borderRadius: "0px 10px", backgroundColor: "#2b0980", width: "120px", textAlign: "center", borderRight: "4px solid #e00241" }}>
                                RESULT
                            </div>

                            <TableContainer style={{ borderRadius: "8px", marginTop: "5px" }}>
                                <Table stickyHeader aria-label="simple">
                                    <TableHead >
                                        <TableRow>
                                            <TableCell
                                                align="center"
                                                style={{ backgroundColor: "rgb(28 35 131)", color: "#fff" }}
                                            >
                                                Disorder
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                style={{ backgroundColor: "rgb(28 35 131)", color: "#fff" }}
                                            >
                                                Parameters
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                style={{ backgroundColor: "rgb(28 35 131)", color: "#fff" }}
                                            >
                                                Results
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                style={{ backgroundColor: "rgb(28 35 131)", color: "#fff" }}
                                            >
                                                Method
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                style={{ backgroundColor: "rgb(28 35 131)", color: "#fff" }}
                                            >
                                                Unit
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                style={{ backgroundColor: "rgb(28 35 131)", color: "#fff" }}
                                            >
                                                Reference Interval
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableCell
                                            align="center"
                                        >
                                            G6PD Deficiency
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            Glucose-6-Phosphate Dehydrogenase
                                            Enzyme Activity
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            5.85
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            FEIA
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            μg/L
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            normal
                                        </TableCell>
                                    </TableBody>
                                    <TableBody>
                                        <TableCell
                                            align="center"
                                        >
                                            Congenital Adrenal Hyperplasia
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            17-Hydroxy Progesterone (17-OHP)
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            5.85
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            FEIA
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            IU/gHb
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            normal
                                        </TableCell>
                                    </TableBody>
                                    <TableBody>
                                        <TableCell
                                            align="center"
                                        >
                                            Cystic Fibrosis
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            Immunoreactive Trypsinogen (IRT)
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            5.85
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            FEIA
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            ng/mL
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            normal
                                        </TableCell>
                                    </TableBody>
                                    <TableBody>
                                        <TableCell
                                            align="center"
                                        >
                                            Galactosemia
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            Total Galactose (TGAL)
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            2.12
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            FEIA
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            μg/L
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            normal
                                        </TableCell>
                                    </TableBody>
                                    <TableBody>
                                        <TableCell
                                            align="center"
                                        >
                                            Congenital Hypothyroidism
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            Thyroid Stimulating Hormone (TSH)
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            24.7
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            FEIA
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            ng/mL
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            normal
                                        </TableCell>
                                    </TableBody>
                                    <TableBody>
                                        <TableCell
                                            align="center"
                                        >
                                            Biotinidase Deficiency
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            Biotinidase (BIOT) Enzyme Activity
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            0.01
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            FEIA
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            ng/mL
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            normal
                                        </TableCell>
                                    </TableBody>
                                    <TableBody>
                                        <TableCell
                                            align="center"
                                        >
                                            Phenylketonuria (PKU)
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            Phenylalanine
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            0.71
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            FEIA
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            ng/mL
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                        >
                                            normal
                                        </TableCell>
                                    </TableBody>

                                </Table>
                            </TableContainer>

                        </div>
                    </div>
                    {/* table data close */}
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            background: "#fff",
                            padding: "25px"
                        }}
                    >
                        <div style={{ color: "#fff", fontWeight: "bold", fontSize: "20px", borderRadius: "0px 10px", backgroundColor: "#2b0980", width: "270px", textAlign: "center", borderRight: "4px solid #e00241" }}>
                            METHODOLOGY
                        </div>
                        <div style={{ width: "90%", marginLeft: "10px" }}>
                            <h6>FEIA-Flurometric Enzyme Immunoassay</h6>
                        </div>
                    </div>

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            background: "#fff",
                            padding: "25px"
                        }}
                    >
                        <div style={{ color: "#fff", fontWeight: "bold", fontSize: "20px", borderRadius: "0px 10px", backgroundColor: "#2b0980", width: "270px", height: "30px", textAlign: "center", borderRight: "4px solid #e00241" }}>
                            REPORTS SUMMARY
                        </div>
                        <div style={{ width: "90%", marginLeft: "10px" }}>
                            <h6>The tested sample is screen NEGATIVE for the list of disorders mentioned above.
                                Please correlate the result with other clinical & Diagnostics finding.</h6>
                        </div>
                    </div>


                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            background: "#fff",
                            padding: "20px"

                        }}
                    >
                        <div style={{ width: "22%" }}>
                            <h5>
                                Initial Report<input type="checkbox" style={{ marginLeft: "10px", width: "30px", height: "20px" }} />
                            </h5>
                        </div>
                        <div style={{ width: "25%" }}>
                            <h5>
                                Duplicate Report<input type="checkbox" style={{ marginLeft: "10px", width: "30px", height: "20px" }} />
                            </h5>
                        </div>
                        <div style={{ width: "25%", }}>
                            <h5>
                                Revision Report<input type="checkbox" style={{ marginLeft: "10px", width: "30px", height: "20px" }} />
                            </h5>
                        </div>
                        <div style={{ width: "25%" }}>
                            <h5>
                                Version No<input type="checkbox" style={{ marginLeft: "10px", width: "30px", height: "20px" }} />
                            </h5>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        background: "#fff",
                        padding: "30px",
                    }}
                >
                    <div className="tablecontent" style={{ width: "100%", textAlign: "left" }}>
                        <TableContainer style={{ borderRadius: "8px", marginTop: "5px" }}>
                            <Table stickyHeader aria-label="simple">
                                <TableHead style={{ backgroundColor: "#b3b3b3" }}>
                                    <TableRow>
                                        <TableCell
                                            align="center"
                                            style={{ backgroundColor: "rgb(28 35 131)", color: "#fff" }}
                                        >
                                            Disorder
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{ backgroundColor: "rgb(28 35 131)", color: "#fff" }}
                                        >
                                            Parameters
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{ backgroundColor: "rgb(28 35 131)", color: "#fff" }}
                                        >
                                            Results
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{ backgroundColor: "rgb(28 35 131)", color: "#fff" }}
                                        >
                                            Results Value
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{ backgroundColor: "rgb(28 35 131)", color: "#fff" }}
                                        >
                                            Reference
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{ backgroundColor: "rgb(28 35 131)", color: "#fff" }}
                                        >
                                            Remarks
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableCell
                                        align="center"
                                    >
                                        Congental Hypothyroidism
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        TSH
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        <progress value="3.74" max="10" />
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        3.74
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        NMT 10 μu/ml
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        normal
                                    </TableCell>
                                </TableBody>
                                <TableBody>
                                    <TableCell
                                        align="center"
                                    >
                                        Congential Adrenal Hyperplasia
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        17-OHP
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        <progress value="3.8" max="10" />
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        3.8
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        NMT 10 μu/ml
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        normal
                                    </TableCell>
                                </TableBody>
                                <TableBody>
                                    <TableCell
                                        align="center"
                                    >
                                        Glucose 6 Phosphate Dehydrogenase deficiency
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        G6PD
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        <progress value="26" max="100" />
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        26.2
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        NTL 3 U/dl
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        normal
                                    </TableCell>
                                </TableBody>
                                <TableBody>
                                    <TableCell
                                        align="center"
                                    >
                                        Classical Galactosemia
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        T GAL
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        <progress value="2.1" max="25" />
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        2.1
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        NMT 25 mg/dl
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        normal
                                    </TableCell>
                                </TableBody>
                                <TableBody>
                                    <TableCell
                                        align="center"
                                    >
                                        Profound Biotinidase deficiency
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        BTD
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        <progress value="50.3" max="100" />
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        50.3
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        NLT 15 U/dl
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        normal
                                    </TableCell>
                                </TableBody>
                                <TableBody>
                                    <TableCell
                                        align="center"
                                    >
                                        Cystic Fibrosis
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        IRT
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        <progress value="30.5" max="90" />
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        30.5
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        NMT 90 ng/mL
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        normal
                                    </TableCell>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div style={{ color: "#fff", fontWeight: "bold", marginTop: "20px", fontSize: "20px", borderRadius: "0px 10px", backgroundColor: "#2b0980", width: "300px", height: "25px", textAlign: "center", borderRight: "4px solid #e00241" }}>
                            REPORTS SUMMARY
                        </div>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                background: "#fff",
                                padding: "25px"
                            }}
                        >
                            Obervation:<br />
                            All the analytes are within the normal range.<br />
                            Interpretation:<br />
                            The tested sample is screened Negative for the list of disorders tabulated in page 2.<br />
                        </div>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                background: "#fff",
                                padding: "25px"
                            }}
                        >
                            Recommendation:<br />
                            Please correlate the results with other clinnical and daigonostic findings.

                        </div>
                        <div style={{ color: "#fff", fontWeight: "bold", fontSize: "20px", borderRadius: "0px 10px", backgroundColor: "#2b0980", width: "270px", height: "25px", textAlign: "center", borderRight: "4px solid #e00241" }}>
                            Disclaimer-
                        </div>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            background: "#fff",
                            padding: "20px"
                        }}>
                            1. The testing laboratory does not bear responsibility for the details provided in the TRF.<br />
                            2. It is presumed that the received specimen belongs to the patient named or identified in the test request form.<br />
                            3. This is a screening test only. Negative screening does not rule out the possibility of underlying metabolic / genetic disorder.<br />
                            4. The metabolites and health condition mapping is not mutually unique and there can be other forms in which an abnormality maybe apparent.<br />
                            5.The health condition may not exclusively be an outcome of the disorder in consideration. If the new bom is premature, retest is suggested when adjust-
                            ed gestational age is 40 weeks.<br />
                            6.  Allinvestigations have their limitations which depend on the sensitivity and specificity of assay procedures as well as the quality of specimen received
                            by the laboratory.<br />
                            7. The baby’s health should be monitored in case of any unhealthy condition with in or beyond the scope of this test.<br />
                            8. This report is not subject to use for any medicolegal purpose.
                        </div>

                        <div style={{ padding: "20px" }}>
                            <div className="tablesignature"
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    background: "#fff",

                                }}
                            >
                                <div style={{ width: "70%", textAlign: "left", padding: 15 }}>
                                    <p>
                                        Dummy Singnature<br />

                                    </p>
                                </div>
                                <div style={{ width: "30%", padding: 15, textAlign: "left" }}>
                                    Name<br />
                                    Dr. Sohini Sengupta<br />
                                    MD (CI Biochemistry), DNB,
                                    FNB (Lab Medicine)<br />
                                    Medical Laboratory Director<br />
                                    HOD (Biochemistry & Special Assays QR Position)
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="page-footer">

                    {/* footer area */}
                    <div style={{ padding: "20px" }}>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                background: "#fff",
                            }}
                        >
                            <div style={{ width: "15%", textAlign: "left", padding: 15 }}>
                                <h3>
                                    <img src={qcCode} alt="logo" style={{ width: "180px" }} />
                                </h3>
                            </div>
                            <div style={{ width: "85%", textAlign: "left", padding: 15 }}>
                                <div style={{ width: "100%", display: "flex", margin: "10px 0px" }}>
                                    <div style={{ width: "33%", marginLeft: 8 }}><img src={callIcon} alt="logo" style={{ width: "20px", margin: "2px" }} /> <span style={{ color: "red" }}>898-898-8787</span></div>
                                    <div style={{ width: "33%", marginLeft: 8 }}><img src={maillIcon} alt="logo" style={{ width: "20px", margin: "2px" }} /><span style={{ color: "red" }}>care@redcliffelabs.com</span></div>
                                    <div style={{ width: "33%", marginLeft: 8 }}><img src={browserIcon} alt="logo" style={{ width: "20px", margin: "2px" }} /><span style={{ color: "red" }}>www.redcliffelabs.com</span></div>
                                </div>
                                <div style={{ width: "100%", display: "flex", margin: "10px 0px", textAlign: "left" }}>
                                    <h6 style={{ marginLeft: "16px" }}> Redcliffe Lifetech Pvt. Ltd. (Unit of Redcliffe Lifetech inc,USA)Khasra No. 186 First Floor Sonakpur Pargana Kanth Road,
                                        Moradabad U.P 244001</h6>
                                </div>
                                <div style={{ width: "100%", display: "flex", margin: "10px 0px", marginLeft: "16px", textAlign: "left" }}>
                                    <p> All Lab are subjected to clinical interpretations by qualified medical professional and this report is not subject to use for any medico-legal purpose.</p>
                                </div>
                                <hr style={{ backgroundColor: "red", width: "100%", height: "8px" }} />
                            </div>
                        </div>
                        {/* second page  */}

                    </div>
                    {/* footer area closed */}

                </div>
                <div
                    style={{
                        // width: 1122,
                        // height: 1532,
                        background: "#fff",

                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            background: "#fff",
                            padding: "25px"
                        }}
                    >
                        <div style={{ backgroundColor: "#82158a", width: "100%", textAlign: "center", fontWeight: "bold", color: "white", height: "30px", fontSize: "20px" }}>
                            CONDITIONS OF REPORTING
                        </div>
                    </div>
                    <div 
                        style={{
                            width: "100%",
                            display: "flex",
                            background: "#fff",
                            padding: "25px"
                        }}
                    >
                        1. It is Presumed that specimen belongs to patient named or identified, such verification being carried out at
                        the point of generation of said specimen<br />

                        2. A test might not be performed due to following reason:<br />

                        - Specimen Quantity not sufficient (Inadequate collection/spillage during transit)<br />

                        - Specimen Quality not acceptable (Hemolysis/clotted/lipemic.)<br />

                        - Incorrect sample type<br />

                        - Test cancelled either on request of patient or doctor<br />

                        In any of the above case a fresh specimen will be required for testing and reporting<br />

                        3. The results of the tests may vary from lab to lab ; time to time for the same patient<br />

                        4. The reported results are dependent on individual assay methods, equipment, method sensitivity,
                        specificity and quality of the specimen received<br />

                        5. Partial representation of report is not allowed<br />

                        6. The reported tests are for the notification of the referring doctor, only to assist him/her in the
                        diagnosis and management of the patient

                        7. lf Sample collection date is not stated on test requisition form, the current date will be printed by
                        default as the date of collection.<br />

                        8. Report with status “Preliminary” means one or more test are yet to be reported
                        9. This report is not valid for Medico Legal Purpose<br />

                        10. Applicable Jurisdiction will be of “Delhi” for any dispute/claim concerning the test(s) & results of the test (s)<br />
                        11. Neonatal 17-OH-Progesterone test enables the quantification of 17-OH-Progestrone steroid present in blood. Deficiency of the
                        enzyme 21-hydroxylase is the most frequent cause of Congenital Adrenal Hyperplasia (CAH), and results in high level of circulating 17-OH-Progesterone.
                        Symptoms of CAH range from hypoglycemia, water loss, salt-losing tendency to masculinization in varying combinations,
                        and can be effectively treated by steroid substitution therapy if detected in early stage of life.<br />

                        12. Neonatal hTSH enables the quantitative determination of human thyrotropin (hTSH) in blood.
                        It is a primary test for screening of babies for Congenital Hypothyroidism (CH), characterized by decreased levels of thyroid hormones: thyroxine (T4) and triiodothyronine (T3).
                        The condition, if undiagnosed and untreated, leads to severe mental retardation, as well as to growth and developmental disturbances. Due to the availability of effective replacement therapy,
                        screening for CH in newborns is recommended to facilitate early intervention where appropriate.<br />

                        13. Neonatal G6PD test is designed for the quantitative determination of glucose-6-phosphate dehydrogenase activity in blood.
                        G6PD deficiency is the most common human enzyme deficiency, resulting in a condition called Hemolytic Anemia. In some cases,
                        neonatal jaundice occurs, which is severe enough to cause death or permanent neurologic damage. Identification of the deficiency
                        therefore becomes critical for prevention of acute episodes, precipitated by certain oxidative drugs, fava beans, or infections.<br />

                        14. Neonatal Biotinidase determines human Biotinidase enzyme activity in blood. Profound Biotinidase deficiency can lead to a decrease in biotin
                        availability and if untreated, is associated with neurologic and cutaneous consequences, including development delays, seizures, ataxia, hypotonia,
                        metabolic compromises etc. and may be life threatening. Biotin supplementation can prevent the development of symptoms of biotinidase deficiency, which makes early diagnosis crucial.<br />  
                    </div>
                </div>
            </div>
            {/* last Page */}
        </div>

    )
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {

})(NbsReport);
