import React, {useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
import { exportSyncedBookings } from "../../actions/LimsAdminActions";
import moment from "moment";
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: "90%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 10px 0px",
    },
    contentFilter: {
      width: "100%",
      padding: "0px 10px 10px 0px",
    },
    contentTable: {
      width: "100%",
      padding: "0px 0px 10px 0px",
      borderLeft: "solid 1px #0e416b",
      borderRight: "solid 1px #0e416b",
      borderBottom: "solid 1px #0e416b",
      marginTop: "20px",
      minHeight: "300px",
    },
    table: {
      minWidth: 700,
    },
    tableRow: {
      padding: "5px 0 5px 5px !important",
    },
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
interface Props {
  exportSyncedBookings: any;
  syncedBookings: any;
  loading: any;
}
const ExportBookings: React.FC<Props> = ({
  exportSyncedBookings,
  syncedBookings,
  loading,
}) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const navigate = useNavigate();

  const searchHandler = (e: any) => {
    e.preventDefault();
    exportSyncedBookings(`?start_date=${startDate}&end_date=${endDate}`);
  };
  const columns: GridColDef[] = [
    { field: "id", headerName: "Sr. No.", width: 150 },
    { field: "bDate", headerName: "Booking Date", width: 170 },
    { field: "bId", headerName: "Booking Id", width: 150 },
    { field: "cId", headerName: "Child Id", width: 150 },
    { field: "lId", headerName: "LIMS Id", width: 150 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "age", headerName: "Age", width: 150 },
    { field: "gender", headerName: "Gender", width: 150 },
    { field: "rStatus", headerName: "Report Status", width: 170 },
    { field: "cNameCode", headerName: "Centre Name(code)", width: 220 },
    { field: "cType", headerName: "Centre Type", width: 170 },
    { field: "agent", headerName: "Agent", width: 150 },
    { field: "tests", headerName: "Tests", width: 150 },
    { field: "tat", headerName: "TAT(hours)", width: 150 },
  ];

  let rows = [];

  if (syncedBookings && syncedBookings.data && syncedBookings.data.length > 0) {
    rows = syncedBookings.data.map((qual: any, index: any) => {
      return {
        bId: qual?.booking_id,
        id: index + 1,
        bDate: qual?.booking_date,
        cId: qual?.child_id,
        lId: qual?.bill_id,
        name: qual?.customer_name,
        age: qual?.customer_age,
        gender: qual?.customer_gender,
        rStatus: qual?.report_status,
        cNameCode: qual?.center_name
          ? qual?.center_name + "(" + qual?.center_code + ")"
          : "",
        cType: qual.center_type,
        agent: qual.agent_name,
        tests: String(qual.items),
        tat: qual.tat_hours,
      };
    });
  }

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.contentHeader}>
          <h4>Export Synced Bookings</h4>
        </div>

        <div className={classes.root}>
          <Grid container spacing={2}>
            <form onSubmit={searchHandler} style={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3} lg>
                  <TextField
                    label="From Date"
                    id="contract-date"
                    type="date"
                    className="input"
                    value={startDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: moment().format("YYYY-MM-DD"),
                    }}
                    onChange={(e) => setStartDate(e.target.value)}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={3} lg>
                  <TextField
                  className="input"
                    label="To Date"
                    id="contract-date"
                    type="date"
                    value={endDate}
                    disabled={startDate===""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: moment(startDate).add("days",6).format("YYYY-MM-DD"),
                      min: moment(startDate).format("YYYY-MM-DD"),
                    }}
                    onChange={(e) => setEndDate(e.target.value)}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ height: "40px" }}
                    type="submit"
                    // onClick={searchHandler}
                    disabled={loading}
                  >
                    Filter
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    style={{ height: "40px" }}
                    onClick={() =>
                      navigate(0)
                    }
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </form>

            <Grid container item xs={12}>
              <div style={{ height: 550, width: "100%" }}>
                <DataGrid
                  rowsPerPageOptions={[]}
                  rows={rows}
                  columns={columns}
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  pageSize={50}
                  loading={loading}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  syncedBookings: state.LimsAdminReducer.syncedBookings,
  loading: state.LimsAdminReducer.loading,
});

export default connect(mapStateToProps, {
  exportSyncedBookings,
})(ExportBookings);
