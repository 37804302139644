import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import {
  Grid,
  Typography,
  InputLabel,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormGroup,
  TableFooter,
} from "@material-ui/core";

interface PaymentModeType {
  link_type: string;

  amount: number;
  paymentid: any;

  isChecked: boolean;
}

const PAYMENT_MODE = [
  {
    link_type: "cash",

    amount: 0,
    paymentid: null,

    isChecked: false,
  },
  {
    link_type: "online",

    amount: 0,
    paymentid: null,

    isChecked: false,
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    formControl: {
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    margin: {
      margin: theme.spacing(1),
    },
    table: {
      minWidth: "100%",
    },
    center: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    start: {
      display: "flex",
      justifyContent: "flex-start",
    },
    end: {
      display: "flex",
      justifyContent: "flex-end",
    },
    gap: {
      fontSize: "14px",
      margin: "0 -40px 0 40px",
    },
    select: {
      height: "40px",
    },
    textFieldGap: {
      padding: "0 10px",
    },
    formContorlLabel: {
      padding: "0 0 0 25px",
      margin: "0 0 0 15px",
      color: "rgba(0, 0, 0, 0.54)",
    },
    hide: {
      display: "none",
    },
    show: {
      display: "block",
    },
    autocomplete: {},
    modal: {
      position: "absolute",
      width: "90%",

      top: "35%",
      left: "58%",
      transform: "translate(-59%, -7%)",
    },
    modalTest: {
      position: "absolute",
      width: "40%",
      top: "35%",
      left: "58%",
      transform: "translate(-59%, -7%)",
    },
    spaceBetween: {
      display: "flex",
      justifyContent: "space-between",
    },
    floatIt: {
      position: "fixed",
      backgroundColor: "#c8d6e5",
      borderRadius: "7px",
      padding: "5px",
      zindex: 3333,
    },
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

interface Props {
  totalPrice: any;
  setPaymentInfo: any;
  setPaid: any;
  setDue: any;
  autoPay: any;
}

const Payment: React.FC<Props> = ({
  totalPrice,
  setPaymentInfo,
  setPaid,
  setDue,
  autoPay,
}) => {
  const classes = useStyles();

  const [totalAmount, setTotalAmount] = useState<any>(0);

  const [paidAmount, setPaidAmount] = useState<any>(0);
  const [dueAmount, setDueAmount] = useState<any>(0);

  const [paymentMode, setPaymentMode] =
    useState<PaymentModeType[]>(PAYMENT_MODE);

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setPaymentInfo(paymentMode);
  }, [paymentMode]);
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setPaid(paidAmount);
    setDue(dueAmount);
  }, [paidAmount, dueAmount]);

  const checkPaymentMode = (checkIndex: number, checkState: boolean) => {
    let paymentData = new Array();
    paymentMode.map((paymentMode: PaymentModeType, index: number) => {
      if (index===checkIndex) {
        paymentData.push({
          link_type: paymentMode.link_type,

          amount: paymentMode.amount,
          paymentid: paymentMode.paymentid,
          isChecked: checkState,
        });
      } else {
        paymentData.push(paymentMode);
      }
    });
    setPaymentMode(paymentData);
  };

  const setPaymentAmount = (setIndex: number, setState: any) => {
    let paymentData = new Array();
    paymentMode.map((paymentMode: PaymentModeType, index: number) => {
      if (index===setIndex) {
        paymentData.push({
          link_type: paymentMode.link_type,

          amount: parseFloat(setState),
          paymentid: paymentMode.paymentid,
          isChecked: paymentMode.isChecked,
        });
      } else {
        paymentData.push(paymentMode);
      }
    });

    setPaymentMode(paymentData);
  };
  const setTransactionNumber = (setIndex: number, setTraNo: string) => {
    let paymentData = new Array();
    paymentMode.map((paymentMode: PaymentModeType, index: number) => {
      if (index===setIndex) {
        paymentData.push({
          link_type: paymentMode.link_type,

          amount: paymentMode.amount,
          paymentid: setTraNo,
          isChecked: paymentMode.isChecked,
        });
      } else {
        paymentData.push(paymentMode);
      }
    });

    setPaymentMode(paymentData);
  };

  const addTotal = () => {
    setTotalAmount(totalPrice);
  };

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    addTotal();
  }, [totalPrice]);

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    calcDue();
  }, [paidAmount, totalAmount]);
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (autoPay) {
      checkPaymentMode(0, true);
      if (totalAmount > 0) {
        setPaymentAmount(0, Number(totalAmount));
      }
    }
  }, [autoPay, totalAmount]);
  //demo change
  const PaidAmt = () => {
    var amtPaid: any = 0;
    paymentMode.map((data: any) => {
      if (data.isChecked===true) {
        amtPaid = amtPaid + data.amount;
        setPaidAmount(amtPaid);
      } else {
        amtPaid = amtPaid + 0;
        setPaidAmount(amtPaid);
      }
    });
  };
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    PaidAmt();
  }, [paymentMode]);

  //calc. due
  const calcDue = () => {
    var due: any = 0;

    due = totalAmount - paidAmount;

    setDueAmount(due);
  };

  // const resetPayment = () => {
  //   const paymentData = new Array();
  //   paymentMode.map((paymentMode: PaymentModeType, index: number) => {
  //     paymentData.push({
  //       link_type: paymentMode.link_type,

  //       amount: 0,
  //       paymentid: null,

  //       isChecked: false,
  //     });
  //   });

  //   setPaymentMode(paymentData);
  // };

  return (
    <div style={{ width: "100%" }} className="data-table">
      {/* payment details */}
      <Grid container xs={12} spacing={3}>
        <Grid item container md={12}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h1" gutterBottom align="left">
              Payment
            </Typography>
          </Grid>

          <Grid container xs={12}>
            <Grid item>
              <InputLabel style={{ paddingTop: 12 }}>
                Select Payment Modes:{" "}
              </InputLabel>
            </Grid>
            <Grid item>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  {paymentMode.length > 0 &&
                    paymentMode.map(
                      (paymentMode: PaymentModeType, index: number) => {
                        return (
                          <FormControlLabel
                            key={index}
                            value={paymentMode.isChecked}
                            control={<Checkbox color="primary" />}
                            label={paymentMode.link_type}
                            checked={paymentMode.isChecked}
                            onChange={() =>
                              checkPaymentMode(index, !paymentMode.isChecked)
                            }
                          />
                        );
                      }
                    )}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TableContainer component={Paper} elevation={10}>
              <Table className={classes.table} stickyHeader aria-label="simple">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Mode</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Transaction Number</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentMode.length > 0 &&
                    paymentMode.map(
                      (payMode: PaymentModeType, index: number) => {
                        return (
                          <>
                            {payMode.isChecked && (
                              <TableRow>
                                <TableCell align="center">
                                  {payMode.link_type}
                                </TableCell>
                                <TableCell align="center">
                                  <TextField
                                    key={index}
                                    id="t-no"
                                    fullWidth
                                    required
                                    size="small"
                                    variant="outlined"
                                    type="number"
                                    style={{ width: "100px" }}
                                    value={
                                      payMode.amount===0 ? "" : payMode.amount
                                    }
                                    onChange={(e) =>
                                      setPaymentAmount(index, e.target.value)
                                    }
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <TextField
                                    disabled={payMode.link_type==="cash"}
                                    required
                                    key={index}
                                    size="small"
                                    id="t-no"
                                    variant="outlined"
                                    style={{ width: "250px" }}
                                    value={payMode.paymentid}
                                    onChange={(e) =>
                                      setTransactionNumber(
                                        index,
                                        e.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        );
                      }
                    )}
                </TableBody>
              </Table>
              <TableFooter>
                <p
                  style={{
                    color: "red",
                    padding: "10px 10px 0px 10px",
                    display: dueAmount < 0 ? "block" : "none",
                  }}
                >
                  {" *Paid amount should not be greater than : "}
                  {totalAmount}
                </p>
                <p
                  style={{
                    padding: " 0px 10px",
                    display: totalAmount===0 ? "block" : "none",
                  }}
                >
                  {" *Select Test to proceed with payment"}
                </p>
              </TableFooter>
            </TableContainer>
          </Grid>
        </Grid>

        <Grid item container md={12}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h1" gutterBottom align="left">
              Payment details
            </Typography>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <TextField
              className="input"
                id="total-amount"
                fullWidth
                label="Total Amount"
                variant="outlined"
                aria-readonly
                type="number"
                defaultValue={totalAmount}
                value={totalAmount}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
              className="input"
                id="paid-amount"
                fullWidth
                label="Paid Amount"
                variant="outlined"
                type="number"
                value={paidAmount}
                defaultValue={paidAmount}
                aria-readonly
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
              className="input"
                id="due-amount"
                fullWidth
                label="Due Amount"
                variant="outlined"
                type="number"
                aria-readonly
                defaultValue={dueAmount}
                value={dueAmount}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* 6g */}
    </div>
  );
};
const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(Payment);
