import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from '@mui/material/Typography';
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import { connect } from "react-redux";
import TextField from '@mui/material/TextField';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  postSampleCollection,
  getBookingsByCentre,
  getTubeByBooking,
  getReceivedPackages,
} from "../../actions/LimsAdminActions";
import {
  Chip,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  MenuItem,
  TableCell,
  TableRow,
} from "@material-ui/core";
import Loader from "../../components/loader/index";

const StyledTableCell:any= withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
      padding: "5px",
      textTransform: "capitalize",
    },
  })
)(TableCell);

const StyledTableRow:any= withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
    button: {
      display: "block",
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    container: {
      position: "sticky",
    },
    select: {
      margin: "0 0 0 -8px",
      minWidth: "145px",
      width: "100%",
      height: "40px",
    },
    textField: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

interface Props {
  getReceivedPackages: any;
  receivedPackages: any;
  postSampleCollection: any;
  getTubeByBooking: any;
  tubeByBooking: any;
  getBookingsByCentre: any;
  bookingsByCentre: any;
  loading: boolean;
}
const SampleCollection: React.FC<Props> = ({
  getReceivedPackages,
  receivedPackages,
  postSampleCollection,
  getTubeByBooking,
  tubeByBooking,
  getBookingsByCentre,
  bookingsByCentre,
  loading,
}) => {
  const navigate = useNavigate();
  const [barcode, setBarcode] = React.useState<any>("");
  const [visitNo, setVisitNo] = React.useState<any>("");
  const [visitID, setVisitID] = React.useState<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [patientName, setPatientName] = React.useState<string>("");
  // const [keyP, setKeyP] = React.useState("k1");
  const [sampleStatus, setSampleStatus] = React.useState<any>("none");
  const [fromDate, setFromDate] = React.useState<any>("");
  const [toDate, setToDate] = React.useState<any>("");

  const classes = useStyles();

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getBookingsByCentre(`?start_date=${fromDate}&end_date=${toDate}`);
  }, []);

  const filterForm = async (e: any) => {
    e.preventDefault();

    getBookingsByCentre(
      `?customer_name=${patientName}&sample_collected=${sampleStatus}${
        fromDate && toDate ? `&start_date=${fromDate}&end_date=${toDate}` : ""
      }${barcode === "" ? "" : "&qrcode__code=" + barcode}${
        visitNo === "" ? "" : "&id=" + visitNo
      }`
    );
  };

  const clearForm = () => {
    navigate("/dashboard/la/sample_collection");
  };

  const [page, setPage] = useState(0);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        bookingsByCentre.links && bookingsByCentre.links.next.split("?")[1];
      getBookingsByCentre(`?${url}`);
    } else if (newPage < page) {
      let url =
        bookingsByCentre.links && bookingsByCentre.links.previous.split("?")[1];
      getBookingsByCentre(`?${url}`);
    }
    setPage(newPage as number);
  };
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (visitID) {
      getTubeByBooking(visitID);
      getReceivedPackages(visitID);
    }
  }, [visitID]);
  return (
    <div style={{ width: "90%", margin: "80px auto" }}>
      <Grid container spacing={1}>
        {/* <Grid container item xs={12} className={classes.center}>
          <Typography variant="h4" component="h1" align="left">
            Sample Collection
          </Typography>
        </Grid> */}
        <h2>Sample Collection</h2>

        <form
          onSubmit={filterForm}
          autoComplete="off"
          style={{ width: "100%" }}
        >
          <Grid
            container
            item
            xs={12}
            spacing={1}
            //className={classes.center}
            style={{ padding: "20px 0" }}
          >
            <Grid item xs={12} sm={6} md={2}>
              <TextField
              className="input"
                fullWidth
                id="outlined-basic"
                label="Barcode No."
                variant="outlined"
                value={barcode}
                style={{ height: "40px" }}
                onChange={(e) => setBarcode(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
              className="input"
                fullWidth
                id="outlined-basic"
                type="number"
                label="Booking ID"
                variant="outlined"
                autoComplete="off"
                value={visitNo}
                style={{ height: "40px" }}
                onChange={(e) => setVisitNo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
              className="input"
                fullWidth
                id="outlined-basic"
                label="Patient Name"
                variant="outlined"
                value={patientName}
                onChange={(e) => setPatientName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Select
                variant="outlined"
                id="payment-mode"
                value={sampleStatus}
                style={{ width: "100%",height: "40px" }}
                onChange={(e) => setSampleStatus(e.target.value)}
                fullWidth
              >
                <MenuItem disabled value={"none"}>Sample Status</MenuItem>
                <MenuItem value={"true"}>Sample Collected</MenuItem>
                <MenuItem value={"false"}>Not Collected</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
               className="input"
                id="date"
                fullWidth
                label="From Date"
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <TextField
              className="input"
                id="date"
                fullWidth
                label="To Date"
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                style={{ width: "100%", height:"40px"  }}
              >
                Search
              </Button>
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <button
                onClick={clearForm}
                className=" MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary MuiButton-containedSizeLarge MuiButton-sizeLarge"
                style={{ width: "100%", height:"40px" }}
              >
                Clear
              </button>
            </Grid>
            </Grid>
        

          <Grid container xs={12}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </form>
        <Grid container item xs={12} style={{ padding: "10px 0" }}>
          <Typography variant="h5" component="h5" align="left">
            Patient Details
          </Typography>
        </Grid>

        <Grid container xs={12}>
          <TableContainer
            className={classes.container}
            style={{ maxHeight: "430px" }}
            component={Paper}
          >
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Sr.No.</StyledTableCell>
                  <StyledTableCell align="center">
                    <b>Booking Id.</b>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <b>Visit Date</b>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <b>Patient Name</b>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <b>Age</b>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <b>Gender</b>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <b>Mobile No.</b>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Other Details
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {bookingsByCentre &&
                  bookingsByCentre.results &&
                  bookingsByCentre.count > 0 &&
                  bookingsByCentre.results.map((row: any, index: any) => (
                    <Row
                      key={row.id}
                      row={row}
                      index={index}
                      tubeByBooking={tubeByBooking}
                      getTubeByBooking={getTubeByBooking}
                      getReceivedPackages={getReceivedPackages}
                      receivedPackages={receivedPackages}
                      postSampleCollection={postSampleCollection}
                      visitID={visitID}
                      setVisitID={setVisitID}
                      open={open}
                      setOpen={setOpen}
                      loading={loading}
                    />
                  ))}
              </TableBody>

              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={3}
                count={bookingsByCentre.count}
                rowsPerPage={bookingsByCentre.page_size}
                page={page}
                onPageChange={handleChangePage}
              />
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

function Row(props: {
  getReceivedPackages: any;
  receivedPackages: any;
  row: any;
  index: any;
  tubeByBooking: any;
  getTubeByBooking: any;
  postSampleCollection: any;
  visitID: any;
  setVisitID: any;
  open: any;
  setOpen: any;
  loading: boolean;
}) {
  const {
    getReceivedPackages,
    receivedPackages,
    row,
    tubeByBooking,
    getTubeByBooking,
    postSampleCollection,
    index,
    visitID,
    setVisitID,
    open,
    setOpen,
    loading,
  } = props;

  const setOpenTest = (visitId: number) => {
    setVisitID(visitId);
    setOpen(open);
  };

  const [testArrayScanned, setTestArrayScanned] = useState<any>([]);
  const [testArrayUnscanned, setTestArrayUnscanned] = useState<any>([]);

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setTestArrayUnscanned([]);
    if (row.id === visitID) {
      let newObj: any = [...testArrayUnscanned];

      tubeByBooking &&
        tubeByBooking.length > 0 &&
        tubeByBooking.map((data: any) => {
          newObj.push({
            bId: visitID,
            tId: data?.package?.id,
            bcode: "",
            sample_collection_date_time: moment().format("YYYY-MM-DDTHH:mmZ"),
            sample_collected: false,
            container: data?.tube?.tube?.name,
            containerId: data?.tube?.id,
          });
        });

      setTestArrayUnscanned(newObj);
    }
  }, [tubeByBooking]);

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setTestArrayScanned([]);
    if (row.id === visitID) {
      let newObj: any = [...testArrayScanned];

      receivedPackages &&
        receivedPackages.results &&
        receivedPackages.results.length > 0 &&
        receivedPackages.results.map((data: any) => {
          newObj.push({
            bId: visitID,
            tId: data?.package?.id,
            bcode: data?.code,
            sample_collection_date_time: moment(data?.created_at).format(
              "YYYY-MM-DDTHH:mmZ"
            ),
            sample_collected: true,
            container: data?.tube?.tube?.name,
            containerId: data?.tube?.id,
          });
        });

      setTestArrayScanned(newObj);
    }
  }, [receivedPackages]);


  const updateResultData = (cID: any, tId: any, key: any, val: any) => {
    let temp = [...testArrayUnscanned];
    testArrayUnscanned.map((val1: any, index1: any) => {
      if (cID===val1.containerId && tId===val1.tId) {
        temp[index1][key] = val;
        setTestArrayUnscanned(temp);
      }
    });
  };
  const submitCollectionStatus = async (val: any) => {
    const arr: any = [];
    if (val.containerId !== "") {
      arr.push({
        tube: val.containerId,
        package: val.tId,
        barcode: val.bcode,
      });
    }
    const data: any = { code: arr };
    if (arr.length > 0) {
      await postSampleCollection(val.bId, data);
    }
    setTestArrayScanned([]);
    setTestArrayUnscanned([]);
    getTubeByBooking(visitID);
    getReceivedPackages(visitID);
  };

  const handleDOBForAge = (dob: any, bdate: any) => {
    const a: any = moment(bdate);
    const b: any = moment(dob);
    var years = a.diff(b, "year");
    b.add(years, "years");
    var months = a.diff(b, "months");
    b.add(months, "months");
    var days = a.diff(b, "days");
    var age;
    if (months !== 0) {
      age = months + "Months " + days + "Days";
    } else {
       age = days + "Days";
    }

    return age;
  };
  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell align="center">{index + 1}</StyledTableCell>
        <StyledTableCell align="center">{row.id}</StyledTableCell>
        <StyledTableCell align="center">{row.booking_date}</StyledTableCell>
        <StyledTableCell align="center">{row.customer_name}</StyledTableCell>
        <StyledTableCell align="center">
          {row?.customer_age === 0
            ? handleDOBForAge(row?.dob, row?.booking_date)
            : row?.customer_age + " Years"}
        </StyledTableCell>
        <StyledTableCell align="center">{row.customer_gender}</StyledTableCell>
        <StyledTableCell align="center">
          {row.customer_phonenumber}
        </StyledTableCell>
        <StyledTableCell align="center">
          {row.packages.length === 0 ? (
            "No Tests!!"
          ) : (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpenTest(row.id)}
              style={{ background: "rgb(63 81 181 / 43%)" }}
            >
              {visitID===row.id ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          )}
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={10}
        >
          <Collapse
            in={visitID===row.id ? !open : open}
            timeout="auto"
            unmountOnExit
            key={row.id}
          >
            <Box margin={1}>
              <Grid container xs={12}>
                <Grid item xs={11}>
                  <Typography variant="h6" gutterBottom component="div">
                    Patient Test Details
                  </Typography>
                </Grid>
                <Grid item xs={1} style={{ textAlign: "right" }}></Grid>
              </Grid>

              {loading ? (
                <Loader />
              ) : (
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="center">
                        <b>Container Name</b>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <b>Barcode</b>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <b>Collection Date & Time</b>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <b>Collection status</b>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <b>Save</b>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {testArrayUnscanned &&
                      testArrayUnscanned.length > 0 &&
                      testArrayUnscanned.map((qual: any) => {
                        return (
                          <StyledTableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {qual.container}
                            </StyledTableCell>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <TextField
                                variant="outlined"
                                fullWidth
                                style={{ width: "100px" }}
                                value={qual.bcode}
                                onChange={(e) =>
                                  updateResultData(
                                    qual.containerId,
                                    qual.tId,
                                    "bcode",
                                    String(e.target.value).replace(/\s+/g, "")
                                  )
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <TextField
                                key={qual.containerId}
                                id="datetime-local"
                                variant="outlined"
                                type="datetime-local"
                                fullWidth
                                disabled
                                value={moment().format("YYYY-MM-DDTHH:mm")}
                              />
                            </StyledTableCell>

                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <p style={{ marginBottom: 4 }}>
                                <Chip
                                  size="small"
                                  label="Not Collected"
                                  style={{
                                    backgroundColor: "#f44336",
                                    color: "#fff",
                                  }}
                                />
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={() => submitCollectionStatus(qual)}
                              >
                                Collect
                              </Button>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    {testArrayScanned &&
                      testArrayScanned.length > 0 &&
                      testArrayScanned.map((qual: any) => {
                        return (
                          <StyledTableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {qual.container}
                            </StyledTableCell>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <TextField
                                variant="outlined"
                                fullWidth
                                disabled
                                style={{ width: "100px" }}
                                value={qual.bcode}
                              />
                            </StyledTableCell>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <TextField
                                key={qual.containerId}
                                id="datetime-local"
                                variant="outlined"
                                type="datetime-local"
                                fullWidth
                                disabled
                                value={moment(
                                  qual.sample_collection_date_time
                                ).format("YYYY-MM-DDTHH:mm")}
                              />
                            </StyledTableCell>

                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <p style={{ marginBottom: 4 }}>
                                <Chip
                                  size="small"
                                  label="Collected"
                                  style={{
                                    backgroundColor: "#4caf50",
                                    color: "#fff",
                                  }}
                                />
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled
                              >
                                Collected
                              </Button>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              )}
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

const mapStateToProps = (state: any) => ({
  bookingsByCentre: state.LimsAdminReducer.bookingsByCentre,
  receivedPackages: state.LimsAdminReducer.receivedPackages,
  tubeByBooking: state.LimsAdminReducer.tubeByBooking,
  loading: state.LimsAdminReducer.loading,
});

export default connect(mapStateToProps, {
  getBookingsByCentre,
  getTubeByBooking,
  postSampleCollection,
  getReceivedPackages,
})(SampleCollection);
