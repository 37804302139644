import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Container,
    Grid,
    TextField,
    Select,
    MenuItem,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import { generateTaskFilterUrl } from "../../helpers/generateUrl";
import moment from "moment";
import { connect } from "react-redux";
import TicketCommentsModal from '../components/Comments/ticketComments'
import {
    getReceivedTicketTasks,
    getUserFromCategory,
    getUserList,
    getTicketSubCategories,
} from "../actions/LimsAdminActions";
import "./filter.sass";
import { useNavigate } from "react-router";
import TicketTable from "../tables/receivedTicketTable";
// import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        card: {
            height: "100%",
            padding: theme.spacing(1),
        },
        card_grid: {
            // wordSpacing:3,
            justifyContent: "space-between",
        },
        input: {
            padding: "1px",
        },
        paper: {
            border: "5px solid black",
            padding: "0px",
        },
    })
);

interface Props {
    getReceivedTicketTasks: any;
    getUserFromCategory: any;
    receivedTasks: any
    loading: boolean;
    getUserList: any;
    getTicketSubCategories: any;
    usersByUsergroup: any;
    ticketSubCategories: any;
    userDetails: any;
    userFromCategories: any;
}

const ReceivedTicketPage: React.FC<Props> = ({
    getReceivedTicketTasks,
    getUserFromCategory,
    receivedTasks,
    loading,
    getUserList,
    getTicketSubCategories,
    usersByUsergroup,
    ticketSubCategories,
    userDetails,
    userFromCategories,

}) => {
    const classes = useStyles();

    const navigate = useNavigate();

    const [openModal, setopenModal] = useState<boolean>(false);
    const [resolved_by, setResolved_by] = useState<any>("");
    const [subCategory, setSubCategory] = useState<any>("");
    const [category, setCategory] = useState<any>("");
    const [status, setStatus] = useState<String>("none");
    const [id, setId] = useState<any>("");
    const [priority, setPriority] = useState<String>("none");
    const [created_at, setCreated_at] = useState("");
    const [completed_date, setCompleted_date] = useState("");
    const booking_date=""
    const collection_date=""
    const description=""
    const [search, setSearch] = useState("");
    const [user, setUser] = useState<any>("");
    const [page, setPage] = React.useState(0);
    const [ticketIds, setTicketIds] = useState<any>([]);


    const timer = useRef<any>(0);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
        getUserList("");
    }, 
    [userDetails])

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
        if (userDetails?.profile?.usergroup) {
            getReceivedTicketTasks(`?usergroup=${userDetails?.profile?.usergroup}`);
            let arr: any = [];
            receivedTasks && receivedTasks?.results && receivedTasks?.results?.length > 0 && receivedTasks?.results.map((ticket: any, index: any) => {
                arr.push(ticket?.id)
            })
            setTicketIds(arr);
            const str = arr.join(',');
            if (str !== ""){getUserFromCategory(`categories=${str}`)};
        }
    }, [userDetails?.profile?.usergroup]);

    const onKeyDown = (e: any) => {
        if (e.key === "Enter") {
            filterTask(e);
        }
    };
    const exportData = () => {
        var rows = [];

        //iterate through rows of table
        let row = userFromCategories.results;
        rows.push(
            [
                "Ticket Number",
                "Category",
                "Sub Category",
                "Title",
                "Status",
                "Created At",
                "Raised By",
                "Resolved At",
                "Resolved By",
                "Priority",
            ]
        );
        for (var i = 0; i < row.length; i++) {
            rows.push(
                [
                    row[i].id,
                    row[i].category,
                    row[i].sub_category,
                    row[i].title,
                    row[i].status,
                    moment(row[i]?.created_at).format("DD-MM-YYYY"),
                    row[i].created_by_name,
                    row[i].completed_date,
                    row[i].assigned_to_name,
                    row[i].priority,
                ]
            );
        }
        let csvContent = "data:text/csv;charset=utf-8,";

        rows.forEach(function (rowArray) {
            row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Received_Report.csv");
        document.body.appendChild(link);

        link.click();
    }

    const filterTask = (e: any) => {
        const body: any = {

            created_by_paneluser: user,
            assigned_to_paneluser: resolved_by,
            status,
            priority,
            search,
            description,
            created_at,
            completed_date,
            sub_category: subCategory,
            category,
            id,
        };
        const url = generateTaskFilterUrl(body).substring(2);
        getUserFromCategory(`${ticketIds?`categories=${ticketIds}&`:""}${url}`);
        setPage(0);

    };

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <h2 style={{ fontWeight: "bold", paddingBottom: "20px" }}>REDTECH TICKETS</h2>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    style={{ alignItems: "right" }}
                >
                    <Grid container spacing={1} direction="row" style={{ alignItems: "left" }}>
                        <Grid item xs={12} sm={4} md={2}>
                            <Autocomplete
                                id="createdBy"
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                        setUser(obj.id);
                                    }

                                }}
                                freeSolo
                                blurOnSelect
                                limitTags={1}
                                options={usersByUsergroup?.results || []}
                                disableClearable
                                disableCloseOnSelect
                                getOptionLabel={(option: any) => option.user && option.user.name}
                                onInputChange={(event, newInputValue) => {
                                    clearTimeout(timer.current);
                                    timer.current = setTimeout(() => {
                                        getUserList(`?code=${newInputValue}`);
                                    }, 1000);
                                    if (newInputValue.length === 0) {
                                        setUser("");
                                    }
                                }}
                                // classes={{ paper: classes.paper }}
                                renderInput={(params) => (
                                    <TextField
                                       className="input"
                                        {...params}
                                        label="Created By"
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0", padding: "0" }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Autocomplete
                                id="resolvedBy"
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                        setResolved_by(obj.id);
                                    }

                                }}
                                freeSolo
                                blurOnSelect
                                limitTags={1}
                                options={usersByUsergroup?.results || []}
                                disableClearable
                                disableCloseOnSelect
                                getOptionLabel={(option: any) => option.user && option.user.name}
                                onInputChange={(event, newInputValue) => {
                                    clearTimeout(timer.current);
                                    timer.current = setTimeout(() => {
                                        getUserList(`?code=${newInputValue}`);
                                    }, 1000);
                                    if (newInputValue.length === 0) {
                                        setResolved_by("");
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                    className="input"
                                        {...params}
                                        label="Resolved By"
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0", padding: "0" }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Autocomplete
                                id="category"
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                        setCategory(obj.id);
                                    }

                                }}
                                freeSolo
                                blurOnSelect
                                limitTags={1}
                                options={receivedTasks?.results || []}
                                disableClearable
                                disableCloseOnSelect
                                getOptionLabel={(option: any) => option.title}
                                onInputChange={(event, newInputValue) => {
                                    clearTimeout(timer.current);
                                    timer.current = setTimeout(() => {
                                        getReceivedTicketTasks(`?code=${newInputValue}`);
                                    }, 1000);
                                    if (newInputValue.length === 0) {
                                        setCategory("");
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                    className="input"
                                        {...params}
                                        label="Category"
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0", padding: "0" }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Autocomplete
                                id="subcategory"
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                        setSubCategory(obj.id);
                                    }

                                }}
                                freeSolo
                                blurOnSelect
                                limitTags={1}
                                options={ticketSubCategories?.results || []}
                                disableClearable
                                disableCloseOnSelect
                                getOptionLabel={(option: any) => option.title}
                                onInputChange={(event, newInputValue) => {
                                    clearTimeout(timer.current);
                                    timer.current = setTimeout(() => {
                                        getTicketSubCategories(`?code=${newInputValue}`);
                                    }, 1000);
                                    if (newInputValue.length === 0) {
                                        setSubCategory("");
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                       className="input"
                                        {...params}
                                        label="Sub Category"
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0", padding: "0" }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <TextField
                                className="input"
                                name="name"
                                type="text"
                                label="Search By Title/Description"
                                value={search}
                                variant="outlined"
                                onChange={(e) => setSearch(e.target.value as string)}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Select
                                className="input"
                                name="status"
                                variant="outlined"
                                value={status}
                                style={{ width: "100%", height: "40px" }}
                                onChange={(e) => setStatus(e.target.value as String)}
                            >
                                <MenuItem disabled value="none">
                                    Status
                                </MenuItem>
                                <MenuItem value="To Do">To Do</MenuItem>
                                <MenuItem value="In Review">In Review</MenuItem>
                                <MenuItem value="Reopen">Reopen</MenuItem>
                                <MenuItem value="In Quality assessment">In Quality Assessment</MenuItem>
                                <MenuItem value="Done">Done</MenuItem>
                                <MenuItem value="Rejected">Rejected</MenuItem>
                                <MenuItem value="No Issue Found">No Issue Found</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Select
                                className="input"
                                name="priority"
                                variant="outlined"
                                value={priority}
                                style={{ width: "100%",height: "40px" }}
                                onChange={(e) => setPriority(e.target.value as String)}
                            >
                                <MenuItem disabled value="none">
                                    Priority
                                </MenuItem>
                                <MenuItem value={0}>0</MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <TextField
                                className="input"
                                name="created_at"
                                type="date"
                                label="Created Date"
                                value={
                                    // booking_date === "" || collection_date === "" ? created_at : ""
                                    created_at
                                }
                                variant="outlined"
                                // disabled={booking_date !== "" || collection_date !== ""}
                                onChange={(e) => setCreated_at(e.target.value as string)}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <TextField
                                name="completed_date"
                                type="date"
                                value={
                                    booking_date === "" || collection_date === "" ? completed_date : ""
                                }
                                className="input"
                                label="Resolved Date"
                                variant="outlined"
                                disabled={booking_date !== "" || collection_date !== ""}
                                onChange={(e) => setCompleted_date(e.target.value as string)}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Button
                                variant="contained"
                                color="secondary"

                                fullWidth
                                onClick={filterTask}
                                disabled={loading}
                            >
                                Filter
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Button
                                variant="contained"
                                color="primary"

                                fullWidth
                                onClick={() => navigate(0)}
                            >
                                Reset
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Button
                                variant="contained"
                                color="primary"
disabled={!(userFromCategories&&userFromCategories.results&&userFromCategories?.results?.length>0)}
                                fullWidth
                                onClick={exportData}
                            >
                                Export
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <br />
                {
                    userFromCategories.results &&
                    userFromCategories.results.length > 0 && (
                        <Box>
                            <Container maxWidth={false}>
                                <Grid container spacing={3}>
                                    <TicketTable
                                        setopenModal={setopenModal}
                                        getUserFromCategory={getUserFromCategory}
                                        userFromCategories={userFromCategories}
                                        setIds={setId}
                                        page={page}
                                        setPage={setPage}

                                    />
                                </Grid>
                            </Container>
                        </Box>
                    )
                }
                {userFromCategories.results && userFromCategories.results.length === 0 && (
                    <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                )}
                <TicketCommentsModal openModal={openModal} setopenModal={setopenModal} id={id} />
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    receivedTasks: state.LimsAdminReducer.receivedTasks,
    usersByUsergroup: state.LimsAdminReducer.usersByUsergroup,
    ticketSubCategories: state.LimsAdminReducer.ticketSubCategories,
    loading: state.LimsAdminReducer.loading,
    userDetails: state.loginReducer.userDetails,
    userFromCategories: state.LimsAdminReducer.userFromCategories,
});

export default connect(mapStateToProps, {

    getReceivedTicketTasks,
    getUserFromCategory,
    getUserList,
    getTicketSubCategories,
})(ReceivedTicketPage);
