//Authentication
import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import MainLayout from "../Hoc/MainLayout";
import SnackBar from "../components/SnackBar";
import Login from "../containers/login";
import SecureStorage from "./SecureStorage";
import Dash from "../containers/dash";
import jwt_decode from "jwt-decode";
import { checkUser } from "../actions/loginActions";
import { connect } from "react-redux";
import { messageBox } from "../actions/notificationAction";

// Sample accessioning panel
import LimsAdminDash from "../containers/LimsAdmin-agent";
import LimsAdminHome from "../LimsAdmin-Panel/components/Home/home"
import LimsAdminFilterPage from "../LimsAdmin-Panel/pages/filter";
import LimsAdminOfflineFilterPage from "../LimsAdmin-Panel/pages/offlineFilter";
import LimsAdminReceivedTicketPage from "../LimsAdmin-Panel/pages/receivedTicket";
import LimsAdminSampleCollection from "../LimsAdmin-Panel/pages/SampleCollection/index";
import LimsAdminBooking from "../LimsAdmin-Panel/components/Bookings/NewBooking/index";
import LimsAdminViewBooking from "../LimsAdmin-Panel/components/Bookings/ViewBooking/index";
import LimsAdminBillPdf from "../LimsAdmin-Panel/components/BillPdf/index";
import LimsAdminShahbazkarBillPdf from "../LimsAdmin-Panel/components/BillPdf/shahbazkarBill";
import LimsAdminViewOfflineBooking from "../LimsAdmin-Panel/components/Bookings/ViewBooking/offlineView";
import LimsAdminEditBookingForm from "../LimsAdmin-Panel/components/Bookings/EditBookingForm/EditBookingForm";
import LimsAdminDownload from "../LimsAdmin-Panel/components/Bookings/DownloadBooking/Trf";
import SAComplaintsBooking from "../LimsAdmin-Panel/tables/queryTicketsBooking";
import SampleAccCenterInfo from "../LimsAdmin-Panel/tables/centerTable";
import SAInbox from "../LimsAdmin-Panel/components/notificationInbox/index";
import SampleAccSampleHandOver from "../LimsAdmin-Panel/tables/sampleHandOverTable";
import SampleAccSampleHandOver2 from "../LimsAdmin-Panel/tables/sampleHandoverOld";
import LimsAdminTestPackages from "../LimsAdmin-Panel/pages/testPackages";
import SampleUnregisterBooking from "../LimsAdmin-Panel/tables/unregisterdBooking";
import LimsAdminHIV from "../LimsAdmin-Panel/components/Bookings/ViewBooking/Hivreport";
import SABiomarkerScreening from "../LimsAdmin-Panel/components/Bookings/ViewBooking/BiomarkerScreeningreport";
import LimsAdminPhaRiderCashDue from "../LimsAdmin-Panel/pages/PhleboRiderDue";
import LimsAdminHelpDesk from "../LimsAdmin-Panel/pages/HelpDesk";
import LimsAdminReportNotDeliverd from "../LimsAdmin-Panel/pages/ConsolidateReportBooking/filter";
import SampleAccessingUploadTrf from "../LimsAdmin-Panel/components/Bookings/ViewBooking/trfUpload";
import SampleAccessingExportBookings from "../LimsAdmin-Panel/pages/ExportBookings/exportBooking";
import LimsAdminhistoryform from "../LimsAdmin-Panel/components/Bookings/ViewBooking/ViewHistoryform";
import LimsAdminNbsForm from "../LimsAdmin-Panel/components/Bookings/ViewBooking/ViewNbsForm";
import LimsAdminAnalyticsDashboard from '../LimsAdmin-Panel/pages/AnalyticsData';
import LimsAdminNbsReport from "../LimsAdmin-Panel/components/Report/nbsReport";
import LimsAdminNbsReport1 from "../LimsAdmin-Panel/components/Report1";


function AuthenticatedRoutes(props) {
  if (window) {
    var path = window.location.pathname;
    var query = window.location.search;
  }
  const token = SecureStorage.getItem("token");
  console.log(token, "token")
  // const decoded = decode(token);
  if (token) {
    return (
      <Routes>
        <Route
          path="/"
          element={<Dash path={path} query={query} />}
        />
      </Routes>)
  } else {
    return <Route render={() => <Navigate to="/" />} />;
  }
}

const HasRole = ({ children, role }) => {
  const token = SecureStorage.getItem("token");
  if (!token) {
    window.location = "/";
  }
  const decoded = jwt_decode(token);
  if (decoded.user_group !== role) {
    window.location = "/dashboard";
  }else{
    return children;
  }
  return <div>Redirecting....</div>;
}

function AppRoutes(props) {

  return (
    <BrowserRouter>
      <MainLayout>
        <SnackBar />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="dashboard"
            element={<AuthenticatedRoutes />}
          />
          <Route
            path="dashboard/la/*"
            element={
              // <HasRole role="LimsAdmin">
                <LimsAdminDash>
                  <Routes>
                    <Route
                      path="/"
                      element={<LimsAdminHome />}
                    />
                    <Route
                      path="booking_list"
                      element={<LimsAdminFilterPage />}
                    />
                    <Route
                      path="offline_bookings"
                      element={
                        <LimsAdminOfflineFilterPage />
                      }
                    />
                    <Route
                      path="received_ticket"
                      element={
                        <LimsAdminReceivedTicketPage />
                      }
                    />
                    <Route
                      path="booking-view/:id"
                      element={<LimsAdminViewBooking />}
                    />
                    <Route
                      path="cc_bill_pdf/:id"
                      element={<LimsAdminBillPdf />}
                    />
                    <Route
                      path="shahbazker_bill_pdf/:id"
                      element={<LimsAdminShahbazkarBillPdf />}
                    />
                    <Route
                      path="offline-booking-view/:id"
                      element={
                        <LimsAdminViewOfflineBooking />
                      }
                    />
                    <Route
                      path="booking"
                      element={<LimsAdminBooking />}
                    />
                    <Route
                      path="sample_collection"
                      element={
                        <LimsAdminSampleCollection />
                      }
                    />

                    <Route
                      path="booking-edit/:id/edit"
                      element={
                        <LimsAdminEditBookingForm />
                      }
                    />
                    <Route
                      path="download/:id/download"
                      element={<LimsAdminDownload />}
                    />
                    <Route
                      path="sampleaccessiong-hiv/:id"
                      element={<LimsAdminHIV />}
                    />
                    <Route
                      path="sampleaccessiong-BiomarkerScreeningreport/:id"
                      element={<SABiomarkerScreening />}
                    />
                    <Route
                      path="historyform/:id"
                      element={<LimsAdminhistoryform />}
                    />
                    <Route
                      path="nbsform/:id"
                      element={<LimsAdminNbsForm />}
                    />
                    <Route
                      path="booking/tickets"
                      element={<SAComplaintsBooking />}
                    />
                    <Route
                      path="centerInfo"
                      element={<SampleAccCenterInfo />}
                    />
                    <Route
                      path="inbox"
                      element={<SAInbox />}
                    />
                    <Route
                      path="SampleHandOver"
                      element={<SampleAccSampleHandOver />}
                    />
                    <Route
                      path="SampleHandOverold"
                      element={<SampleAccSampleHandOver2 />}
                    />
                    <Route
                      path="testPackages"
                      element={<LimsAdminTestPackages />}
                    />
                    <Route
                      path="unregister-booking"
                      element={<SampleUnregisterBooking />}
                    />
                    <Route
                      path="cash-due-on-pha-rider"
                      element={
                        <LimsAdminPhaRiderCashDue />
                      }
                    />
                    <Route
                      path="report_not_deliverd"
                      element={
                        <LimsAdminReportNotDeliverd />
                      }
                    />
                    <Route
                      path="uploadTrf/:id"
                      element={<SampleAccessingUploadTrf />}
                    />
                    <Route
                      path="export-bookings"
                      element={<SampleAccessingExportBookings />}
                    />

                    <Route
                      path="helpdesk"
                      element={<LimsAdminHelpDesk />}
                    />
                    <Route
                      path="analytics_data"
                      element={<LimsAdminAnalyticsDashboard />}
                    />
                    <Route
                      path="report"
                      element={<LimsAdminNbsReport />}
                    />
                    <Route
                      path="report1"
                      element={<LimsAdminNbsReport1 />}
                    />
                  </Routes>
                </LimsAdminDash>
              // </HasRole>
            } />
        </Routes>
        {/* <Modal /> */}
        {/* <SecondModal /> */}
      </MainLayout>
    </BrowserRouter>
  );
}
export default connect(null, { checkUser, messageBox })(AppRoutes);
