import React, {useEffect } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles, Grid } from '@material-ui/core';
import Button from '@mui/material/Button';
import {useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useNavigate } from "react-router";
import Loader from '../../loader/index';
import { TrfUploadForm, getUploadTrf } from "../../../actions/LimsAdminActions"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface Props {
    TrfUploadForm: any;
    uploadTrf: any;
    getUploadTrf: any;
    loading: Boolean;


}

const UploadTable: React.FC<Props> = ({
    TrfUploadForm,
    getUploadTrf,
    uploadTrf,
    loading,



}) => {
    const classes = useStyles()
    const { id } = useParams<any>();
    const navigate = useNavigate();
    const [file64, setFile64] = React.useState<any>([]);
    const [fileUpload, setFileUpload] = React.useState<any>([]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
        getUploadTrf(id);
    }, [id]);
    const toBase64 = (file: any) =>

        new Promise((resolve, reject) => {
            const reader: any = new FileReader();
            if (file) {
                reader.readAsDataURL(file);
            }

            reader.onload = () => resolve(reader.result);
            reader.onerror = (error: any) => reject(error);
        });

    const uploadFile = async (e: any) => {
        e.preventDefault()
        const data = {
            booking: id,
            images: file64,
        }
        await TrfUploadForm(data);
        getUploadTrf(id);
        navigate(`/dashboard/la/uploadTrf/${id}`);


    }
    const fileUploadHandler = (e: React.ChangeEvent<{ files: any }>) => {
        setFileUpload(e.target.files);

    };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
        const list: any = file64;
        if (fileUpload.length > 0) {

            for (let i = 0; i <= fileUpload.length; i++) {
                toBase64(fileUpload[i]).then((data: any) => {
                    let extention="."+String(data.split(';')[0].split('/')[1])||"";
                    let b64=String(data.split(",")[1])
                    list.push({"base64":b64,"ext":extention});
                });
            }

        }
        setFile64(list);
    }, [fileUpload]);

    return (
        <div style={{ width: "100%" }} className="data-table">
            <div className={classes.toolbar} />
            <h4 style={{ fontWeight: "bold" }}>UPLOAD TRF </h4>
            {loading ? <Loader /> : (
                <form onSubmit={uploadFile}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={3}>
                            <input
                                className="input"
                                style={{
                                    width: "100%",
                                    margin: "0",
                                    padding: "18.5px 14px",
                                    border: "1px solid rgba(0, 0, 0, 0.23)",
                                    borderRadius: "5px",
                                }}
                                type="file"
                                name="fileUp"
                                id="fileUp"
                                multiple
                                required
                                onChange={fileUploadHandler}
                            />

                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <Button
                                color="primary"
                                variant="contained"
                                fullWidth
                                type="submit"
                                style={{ height: "50px" }}

                            >
                                Upload
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            )}
            {/* <Paper elevation={15} className={classes.paper}> */}
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "700px" }}>

                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell align="center">Booking Id</StyledTableCell>
                            <StyledTableCell align="center">Patient Name</StyledTableCell>
                            <StyledTableCell align="center"> Test Name</StyledTableCell>
                            <StyledTableCell align="center"> Download Trf</StyledTableCell>


                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {uploadTrf && uploadTrf?.data && uploadTrf?.data?.length > 0 && uploadTrf?.data?.map((data: any, index: any) => {
                            return (
                                <StyledTableRow key={index} >
                                    <StyledTableCell align="center">{data?.booking_id}</StyledTableCell>
                                    <StyledTableCell align="center">{data?.customer_name}</StyledTableCell>
                                    <StyledTableCell align="center">{data?.packages && data?.packages.length > 0 && data?.packages?.map((data: any) => {
                                        return (
                                            <Chip size="small" label={data} />
                                        )
                                    })}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        {data?.images && data?.images.length > 0 && data?.images.map((data: any) => {
                                            return (
                                                <><a href={data} target="_blank" rel="noopener noreferrer">{data}</a><br /></>

                                            )
                                        })}
                                    </StyledTableCell>
                                </StyledTableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* </Paper> */}

        </div >
    )
}

const mapStateToProps = (state: any) => ({
    uploadTrf: state.LimsAdminReducer.uploadTrf,
    loading: state.LimsAdminReducer.loading,


})

export default connect(mapStateToProps, {
    TrfUploadForm,
    getUploadTrf,

})(UploadTable);

