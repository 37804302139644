import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import Modal from '@mui/material/Modal';
import { connect } from "react-redux";
import moment from "moment";
import { MessageRounded } from "@material-ui/icons";
import Bs from "../components/Comments/changeTicketStatus";
import TicketViewModal from "../components/Comments/TicketViewModal";
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
// import Loader from "../../components/loader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      textTransform: "capitalize",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    imageSM: {
      width: "60px",
      height: "60px",
      border: "2px solid #000 ",
      borderRadius: "50%"
    },
    imagePaper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[1],
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

interface Props {
  getUserFromCategory: any;
  userFromCategories: any;
  setopenModal: any;
  setIds: any;
  page: any;
  setPage: any;

}

const ReceivedTicketTable: React.FC<Props> = ({
  getUserFromCategory,
  userFromCategories,
  setopenModal,
  setIds,
  page,
  setPage,

}) => {
  const classes = useStyles();
  const [openImage, setImageOpen] = React.useState(false);
  const imageUrl=""
  const [modalStyle] = React.useState(getModalStyle);
  const [openBatch, setOpenBatch] = useState<boolean>(false);
  const [ticketId, setTicketId] = useState<number>(0);

  const handleClick = (id: number) => {
    setopenModal(true);
    setIds(id);
  };
  // const handleDeskModal = (e: any) => {
  //   setImageUrl(e)
  //   setImageOpen(true)
  // }

  const handleImageClose = () => {
    setImageOpen(false)
  }
  const handleClickToBatch = (id: number) => {
    setOpenBatch(true);
    setTicketId(id);
  };

  const body = (
    <div style={modalStyle} className={classes.imagePaper}>
      <img src={imageUrl}
        alt="popup"
        style={{ width: "1050px", height: "700px", alignItems: "center", marginBottom: "50px" }}
      />
    </div>
  );
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        userFromCategories && userFromCategories?.next?.split("?")[1];
      getUserFromCategory(url);
    } else if (newPage < page) {
      let url =
        userFromCategories &&
        userFromCategories?.previous?.split("?")[1];
      getUserFromCategory(url);
    }
    setPage(newPage as number);
  };

  return (
    <div style={{ width: "100%" }} className="data-table">
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "560px" }}
      >
        {/* {loading ? (
          <Loader />
        ) : ( */}
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="center">Ticket Number</StyledTableCell>
              <StyledTableCell align="center">Category</StyledTableCell>
              <StyledTableCell align="center">Sub Category</StyledTableCell>
              <StyledTableCell align="center">Title</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Created At</StyledTableCell>
              <StyledTableCell align="center">Raised By</StyledTableCell>
              <StyledTableCell align="center">Resolved At</StyledTableCell>
              <StyledTableCell align="center">Resolved By</StyledTableCell>
              {/* <StyledTableCell align="center">Description</StyledTableCell> */}
              {/* <StyledTableCell align="center">Attachments</StyledTableCell> */}
              <StyledTableCell align="center">Priority</StyledTableCell>
              <StyledTableCell align="center">Comments</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {userFromCategories.results &&
              userFromCategories.results.length > 0 &&
              userFromCategories.results.map((ticket: any, index: any) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center">
                      {ticket?.id}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {ticket?.category}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {ticket?.sub_category}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {ticket?.title}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {ticket?.status}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {moment(ticket?.created_at).format("DD-MM-YYYY")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {ticket?.created_by_name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {ticket?.completed_date}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {ticket?.assigned_to_name}
                    </StyledTableCell>
                    {/* <StyledTableCell align="center">
                      {ticket?.description}
                    </StyledTableCell> */}
                    {/* {ticket.screen_shot !== null ? (
                      <StyledTableCell align="center" onClick={() => handleDeskModal(ticket?.screen_shot)}><img key={ticket?.screen_shot} src={ticket?.screen_shot} alt="startimage" className={classes.imageSM} /></StyledTableCell>
                    ) : <StyledTableCell align="center">NA</StyledTableCell>} */}
                    <StyledTableCell align="center">
                      {ticket?.priority}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                          <div>
                            <MessageRounded
                              onClick={() =>
                                handleClick(
                                  ticket?.pk ? ticket?.pk : ticket?.id
                                )
                              }
                            />
                          </div>
                        </StyledTableCell>
                    <StyledTableCell align="center">
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Bs id={ticket.id} note="received_ticket" />
                        <Button variant="contained" color="secondary" style={{ marginLeft: "5px" }} onClick={() => handleClickToBatch(ticket.id)}><VisibilityTwoToneIcon /></Button>
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <StyledTableRow>
              <TablePagination
                colSpan={3}
                count={userFromCategories?.count || 0}
                rowsPerPageOptions={[]}
                rowsPerPage={userFromCategories?.page_size || 20}
                page={page}
                SelectProps={{
                  native: true,
                }}
                onPageChange={handleChangePage}
              />
            </StyledTableRow>
          </TableFooter>
        </Table>
        {/* // )} */}
      </TableContainer>
      {userFromCategories.results && userFromCategories.results.length === 0 && (
        <h6 style={{ textAlign: "center" }}>No Data Found</h6>
      )}
      <TicketViewModal openBatch={openBatch} setOpenBatch={setOpenBatch} setopenModal={setopenModal} setIds={setIds} ticketId={ticketId} />
      <Modal
        open={openImage}
        onClose={handleImageClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
 
});



export default connect(mapStateToProps, {

})(ReceivedTicketTable);
