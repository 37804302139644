import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Card,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Dialog,
} from "@material-ui/core";
import {
  getPinCode,
  getPackage,
  updateBooking,
  getBookingById,
  getCoupons,
  getAvailableSlots,
  getMapMyIndiaAccessToken,
  // checkGeoFenceArea,
  checkGeoFenchMapMyIndia,
  getTimeslots,
  getRefDoctor,
  getCentre,
  getUserDetails,
  getPartners,
  updateTheBooking,
} from "../../../actions/LimsAdminActions";
import Button from "@mui/material/Button";
import "./index.sass";
import { connect } from "react-redux";
import AddPackage from "./addPackage";
import RemovePackage from "./removePackage";
import moment from "moment";
import { useParams } from "react-router-dom";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router";
import { Chip } from "@material-ui/core";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import AddDoctor from "../../AddDoctor/addDoctor";
import Loader from "../../Loader2";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { GENDER_CHOICE } from "../../constant";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 10px 10px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      padding: "20px 20px 20px 20px",
      marginTop: "20px",
      width: "100%",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
  })
);

interface Props {
  getPinCode: any;
  pin_code: any;
  getPackage: any;
  packageList: any;
  couponList: any;
  getCoupons: any;
  updateBooking: any;
  match: any;
  booking: any;
  getBookingById: any;
  booking_slots: any;
  getAvailableSlots: any;
  loading: boolean;
  checkGeoFenceArea: any;
  checkGeoFenceStatus: boolean;
  checkGeoFenchMapMyIndia: any;
  geoFencesMapMyIndia: any;
  getMapMyIndiaAccessToken: any;
  mapmyindiaAccessToken: any;
  getTimeslots: any;
  timeslots: any;
  userDetails: any;
  getUserDetails: any;
  getRefDoctor: any;
  refDoctor: any;
  getCentre: any;
  centreData: any;
  updateTheBooking: any;
  getPartners: any;
  partnerList: any;
}

const EditBookingForm: React.FC<Props> = ({
  getPinCode,
  pin_code,
  packageList,
  getPackage,
  couponList,
  getCoupons,
  updateBooking,
  match,
  booking,
  getBookingById,
  booking_slots,
  getAvailableSlots,
  loading,
  // checkGeoFenceArea,
  // checkGeoFenceStatus,
  checkGeoFenchMapMyIndia,
  geoFencesMapMyIndia,
  getMapMyIndiaAccessToken,
  mapmyindiaAccessToken,
  getTimeslots,
  timeslots,
  userDetails,
  getUserDetails,
  getRefDoctor,
  refDoctor,
  centreData,
  updateTheBooking,
  getCentre,
  getPartners,
  partnerList,
}) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const cType = "percentage";
  const [cValue, setCValue] = useState<any>("");
  const [collection_date, setCollection_date] = useState<String>(
    booking.collection_date
  );
  const [booking_date, setBooking_date] = useState<string>(
    booking.booking_date
  );
  const [timeSlots, setTimeSlots] = useState<Array<Object>>([]);
  const [selectedSlot, setSelectedSlot] = useState<Number>(
    booking.collection_slot && booking.collection_slot.id
  );
  const [batchNo, setBatchNo] = useState<any>("");
  const [customer_name, setCustomer_name] = useState<String>(
    booking.customer_name
  );

  const [designation, setDesignation] = useState<String>();

  const [customer_gender, setCustomer_gender] = useState<string>();
  const [customer_phonenumber, setCustomer_phonenumber] = useState<String>(
    booking?.customer_phonenumber
  );
  const [openDocModal, setOpenDocModal] = useState<any>(false);
  const [newPatientId, setNewPatientId] = useState<String>("");
  const [customer_alternatenumber, setCustomer_alternatenumber] =
    useState<String>(booking.customer_altphonenumber);
  const [customer_whatsapppnumber, setCustomer_whatsapppnumber] =
    useState<String>(booking.customer_whatsapppnumber);
  const [customer_email, setCustomer_email] = useState<String>(
    booking.customer_email
  );
  const [customer_landmark, setCustomer_landmark] = useState<String>(
    booking?.customer_landmark
  );
  // const [sublocality, setSubLocality] = useState<any>(booking?.landmark)
  const [landmark, setLandmark] = useState<any>(null);
  // const [geoLocation, setGeoLocation] = useState<any>([]);
  const [customer_longitude, setCustomerLongitude] = useState<any>("");
  const [customer_latitude, setCustomerLatitude] = useState<any>("");
  const [customer_areapincode, setCustomer_areapincode] = useState<Number>(0);
  const [customer_address, setCustomer_address] = useState<String>(
    booking.customer_address && booking.customer_address
  );
  const [addressLine2, setAddressLine2] = useState<string>(
    booking?.address_line2 && booking?.address_line2
  );
  const [labRemarkComment, setLabRemarkComment] = useState<string>(
    booking?.remarks
  );
  const [favoritePlace, setFavoritePlace] = useState<any>(
    booking?.landmark && booking?.landmark
  );
  const [customer_aadhar, setCustomer_aadhar] = useState<String>(
    booking.customer_aadhar
  );
  const [partnerName, setPartnerName] = useState<any>("");
  const patientId = booking?.patientId;
  const [referringDoctor, setReferringDoctor] = useState<any>("");
  const [partner, setPartner] = useState<String>(
    booking.partner && booking.partner.id
  );
  const [packageDetails, setPackageDetails] = useState<any>(booking.packages);
  const [packages, setPackages] = useState<Number[]>(
    booking.packages &&
      booking.packages.map((pack: any) => {
        return pack.id;
      })
  );
  const [doctorDef, setDoctorDef] = useState<any>("");
  const [centreDef, setCentreDef] = useState<any>("");

  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [price, setPrice] = useState<any>(
    booking.discounted_price && booking.discounted_price.total_price_package
  );
  const [discount, setDiscount] = useState<any>(
    booking.discounted_price && booking.discounted_price.counpon_discount
  );
  const [sampleReceivingDate, setSampleReceivingDate] = useState<any>("");
  const [totalPrice, setTotalPrice] = useState<any>(
    booking.discounted_price && booking.discounted_price.final_total_price
  );
  const [centre, setCentre] = useState<any>("");
  const [extraCharge, setExtraCharge] = useState<boolean>(
    booking.discounted_price && booking.discounted_price.phlebo_cost !== 0
  );
  const [cityId, setCityId] = useState<any>(booking && booking.cityid);
  const [report_status, setReportStatus] = useState<string>(
    booking.report_status
  );
  const [aId, setAId] = useState<any>("");
  const [open1, setOpen1] = React.useState(false);
  const [srfNo, setSrfNo] = useState<any>("");
  const [zoneId, setZoneId] = useState<any>("");
  const [passport, setPassport] = useState<any>(
    booking?.passport_number === null ? "" : booking?.passport_number
  );
  const [verification_status, setVerification_status] = useState<string>(
    booking?.verification_status
  );
  const [booking_status, setBooking_status] = useState<string>(
    booking?.booking_status
  );
  const [verification_remark, setVerification_remark] = useState<string>(
    booking?.verification_remark
  );
  const [sampleCollectionDate, setSampleCollectionDate] = useState<any>("");
  const [dateOfBirth, setDateOfBirth] = useState<any>(
    booking.dob === null ? "" : booking.dob
  );
  const [customer_age, setCustomer_age] = useState<any>(
    Number(booking?.customer_age)
  );
  const [customer_ageMon, setCustomer_ageMon] = useState<any>("");
  const [customer_ageDays, setCustomer_ageDays] = useState<any>("");
  const [aadhar, setAadhar] = useState<any>(booking?.customer_aadhar);
  const [bookingFor, setBookingFor] = useState<any>(booking?.book_for);
  const [isPrescribed, setIsPrescribed] = useState<any>(
    booking?.is_prescription
  );
  const bill_id = booking?.bill_id;
  const [labRemark, setLabRemark] = useState<string>(booking?.remarks);
  const [mobileValidation, setMobileValidation] = useState({
    customer_alternatenumber: true,
    customer_phonenumber: true,
    customer_whatsapppnumber: true,
  });
  const [newBillId, setNewBillId] = useState<String>("");
  const { id } = useParams();
  const bookingId = id;
  const timer = useRef<any>(0);
  const [type, setType] = useState<any>("");
  const [openAddModal, setOpenAddModal] = useState<any>(false);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getBookingById(`${bookingId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getUserDetails();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (booking) {
      setCollection_date(booking?.collection_date);
      setBooking_date(booking?.booking_date);
      setSelectedSlot(booking.collection_slot && booking.collection_slot.id);
      setCustomer_name(booking?.customer_name);
      setCustomer_age(booking.customer_age);
      setCustomer_phonenumber(booking?.customer_phonenumber);
      setCustomer_alternatenumber(booking.customer_altphonenumber);
      setCustomer_whatsapppnumber(booking.customer_whatsapppnumber);
      setCustomer_email(booking.customer_email);
      setCustomer_landmark(booking?.customer_landmark);
      setCustomer_address(booking.customer_address && booking.customer_address);
      setAddressLine2(booking?.address_line2 && booking?.address_line2);
      setFavoritePlace(booking?.landmark && booking?.landmark);
      setCustomer_aadhar(booking.customer_aadhar);
      setPartner(booking.partner && booking.partner.id);
      setPartnerName(
        booking?.partner?.partner_name ? booking?.partner?.partner_name : ""
      );
      setPackageDetails(booking.packages || []);
      setPackages(
        booking.packages &&
          booking.packages.map((pack: any) => {
            return pack.id;
          })
      );
      setPrice(
        booking.discounted_price && booking.discounted_price.total_price_package
      );
      setDiscount(
        booking.discounted_price && booking.discounted_price.counpon_discount
      );
      setTotalPrice(
        booking.discounted_price && booking.discounted_price.final_total_price
      );
      setExtraCharge(
        booking.discounted_price && booking.discounted_price.phlebo_cost !== 0
      );
      setCityId(booking && booking.cityid);
      setPassport(
        booking?.passport_number === null ? "" : booking?.passport_number
      );
      setAadhar(booking?.customer_aadhar);
      setBookingFor(booking?.book_for);
      setIsPrescribed(booking?.is_prescription);
      setCentreDef(
        booking?.centre_address?.name +
          "(" +
          booking?.centre_address?.code +
          ")"
      );

      setCentre(booking?.center?.id);
      getPinCode(
        booking?.customer_areapincode &&
          `${booking?.customer_areapincode?.pincode}&show_code=true`
      );
      setSelectedSlot(booking?.collection_slot && booking?.collection_slot?.id);
      // getArea(
      //   booking?.collection_date,
      //   booking?.customer_areapincode && booking?.customer_areapincode?.pincode
      // );
      // setCustomer_areapincode(booking?.customer_areapincode?.id);
    }
  }, [booking]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getCentre(`?optimize=true&center_type=collection_center`);
    if (booking?.coupon_applied) {
      setCoupon(booking?.coupon_applied.id);
      setCouponCode(booking?.coupon_applied?.name);
    }
    if (booking?.designation && booking?.customer_gender) {
      const findGender = GENDER_CHOICE.filter(
        (el: any) => el.db_name === booking?.designation
      );
      if (
        booking?.designation === "Master" &&
        booking?.customer_gender === "male"
      ) {
        setDesignation("Baby Boy");
      } else if (
        booking?.designation === "Master" &&
        booking?.customer_gender === "female"
      ) {
        setDesignation("Baby Girl");
      } else {
        findGender.length > 0 && setDesignation(findGender[0].display_name);
      }
      setCustomer_gender(booking?.customer_gender);
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (booking?.customer_gender !== undefined)
      getPackage(
        `city_id=${booking?.cityid}&gender=${booking?.customer_gender}`
      );
    getCoupons("?org_type=homedx");
    getMapMyIndiaAccessToken();
    getPartners();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getRefDoctor();
  }, []);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (booking?.customer_gender !== undefined || pin_code?.length > 0) {
      getPackage(
        `city_id=${booking?.cityid}&gender=${booking?.customer_gender}`
      );
      getArea(
        booking?.collection_date,
        booking?.customer_areapincode && booking?.customer_areapincode?.pincode,
        booking?.customer_zone
      );
      setCustomer_areapincode(booking?.customer_areapincode?.id);
    }
  }, [pin_code]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const slots = booking_slots && booking_slots.results;
    setTimeSlots(slots && slots[0].zone_data && slots[0].zone_data.time_slots);
  }, [booking_slots]);

  const getArea = (collection_date: String, pincode: String, zoneId: any) => {
    const zone =
      pin_code.results &&
      pin_code.results.find((code: any) => code.pincode === pincode);
    if (zone && zone.zone_data && zone.zone_data.id) {
      if (booking?.centre_address === null) {
        getAvailableSlots(collection_date, zoneId);
      } else {
        getTimeslots();
      }
    }

    setCustomer_areapincode(zone && (zone.id as Number));
    setZoneId(zoneId);
  };

  const checkNormalPackage = (packages: any) => {
    let isNormalPackage = false;
    packages &&
      packages.length > 0 &&
      packages.map((item: any) => {
        if (!item.is_addon) {
          isNormalPackage = true;
        }
      });
    return isNormalPackage;
  };

  const getNoofPackageBuyOneGetOne = () => {
    let noOfBuyOneGetOne = 0;
    booking.additional_members &&
      booking.additional_members.map((item: any) => {
        if (item?.packages && item?.packages.length > 0) {
          item?.packages.map((packageItem: any) => {
            if (packageItem?.code === "PL50") {
              noOfBuyOneGetOne = noOfBuyOneGetOne + 1;
            }
          });
        }
      });
    return noOfBuyOneGetOne;
  };

  const handlePackages = (customerPackage: any) => {
    
    
    console.log(packages,"pkgs")
    const min_phlebo_cost =
      packageList &&
      packageList?.results &&
      packageList?.results.length > 0 &&
      packageList?.results[0].min_booking_amount_for_phlebo;
    setPackageDetails(customerPackage);
    let accumulatedPrice: any = 0;
    let packIds: any = [];

    if (customerPackage && customerPackage.length > 0) {
      let atLeastOneNormalPackage = checkNormalPackage(customerPackage);
      let pl50Count =
        packageDetails?.length > 0 ? getNoofPackageBuyOneGetOne() : 0;
      let priceApplicable = pl50Count > 0 ? pl50Count % 2 : 0;
      customerPackage.map((item: any) => {
        packIds.push(item.id);
        if (pl50Count > 0 && priceApplicable === 1 && item?.code === "PL50") {
          accumulatedPrice = accumulatedPrice + 0;
        } else {
          if (
            atLeastOneNormalPackage &&
            item.is_addon &&
            item.addon_price &&
            item.addon_price > 0
          ) {
            accumulatedPrice = accumulatedPrice + item?.addon_price;
          } else {
            if (item?.package_city_prices === null) {
              accumulatedPrice = accumulatedPrice + item?.offer_price;
            } else {
              accumulatedPrice =
                accumulatedPrice + item?.package_city_prices?.offer_price;
            }
          }
        }
        if (accumulatedPrice < min_phlebo_cost) {
          setExtraCharge(true);
        } else {
          setExtraCharge(false);
        }
      });
    }
    // Edit Family Members
    if (
      booking?.additional_members &&
      booking?.additional_members?.length > 0
    ) {
      booking.additional_members.map((item: any) => {
        if (item.packages) {
          let atLeastOneNormalPackage = checkNormalPackage(item?.packages);
          item.packages.map((pack: any) => {
            if (
              atLeastOneNormalPackage &&
              pack.is_addon &&
              pack.addon_price &&
              pack.addon_price > 0
            ) {
              accumulatedPrice = accumulatedPrice + pack.addon_price;
            } else {
              if (pack.package_city_prices === null) {
                accumulatedPrice = accumulatedPrice + pack.offer_price;
              } else {
                accumulatedPrice =
                  accumulatedPrice + pack.package_city_prices.offer_price;
              }
              if (accumulatedPrice < min_phlebo_cost) {
                setExtraCharge(true);
              } else {
                setExtraCharge(false);
              }
            }
          });
        }
      });
    }
    setPackages(packIds);
    setPrice(Math.floor(accumulatedPrice));
    console.log('packIds',packIds);
  };
  
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (extraCharge) {
      setTotalPrice(Math.floor(price - discount + 200));
    } else {
      setTotalPrice(Math.floor(price - discount));
    }
  }, [price, discount, extraCharge]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (totalPrice < 0) {
      setTotalPrice(0);
    }
  }, [totalPrice]);

  const applyCoupon = () => {
    if (couponCode === "") {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      return;
    }
    const appliedCoupon =
      couponList?.results?.find((coup: any) => coup?.id === coupon) ||
      couponList?.results?.find((coup: any) => coup?.code === couponCode);
    if (appliedCoupon && appliedCoupon?.minimum_price < totalPrice) {
      setCoupon(appliedCoupon.id);
      if (appliedCoupon?.discount_type.toLowerCase() === "percentage") {
        if (appliedCoupon?.is_red_cash) {
          setDiscount(0);
        } else {
          setDiscount(Math.floor((price * appliedCoupon?.discount) / 100));
        }
      }
      if (appliedCoupon.discount_type.toLowerCase() === "amount") {
        if (appliedCoupon.is_red_cash) {
          setDiscount(0);
        } else {
          setDiscount(Math.floor(appliedCoupon?.discount));
        }
      }
    } else {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
    }
  };
  const handleAddDoctor = () => {
    setOpenDocModal(true);
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (booking?.ref_doctor && booking?.ref_doctor?.name) {
      setDoctorDef(booking?.ref_doctor?.name);
    }
  }, [booking]);
  const applyCouponPerc = () => {
    if (cValue === "") {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      return;
    }
    const appliedCoupon = { cType: cType, cValue: cValue };
    if (appliedCoupon.cValue < totalPrice) {
      if (appliedCoupon.cType === "percentage") {
        if (appliedCoupon.cValue > 100) {
          alert("Please Select Valid Number");
        }
        setDiscount(Math.floor((price * appliedCoupon.cValue) / 100));
      }
      if (appliedCoupon.cType === "amount") {
        setDiscount(Math.floor(appliedCoupon.cValue));
      }
    } else {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
    }
  };
  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(customer_phonenumber && customer_phonenumber[0]) < 6 ||
        (customer_phonenumber && customer_phonenumber.length !== 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_phonenumber: false,
        }));
      }
    }
    if (type === "alternate") {
      if (
        parseInt(customer_alternatenumber && customer_alternatenumber[0]) < 6 ||
        (customer_alternatenumber && customer_alternatenumber.length !== 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_alternatenumber: false,
        }));
      }
    }
    if (type === "whatsapp") {
      if (
        parseInt(customer_whatsapppnumber && customer_whatsapppnumber[0]) < 6 ||
        (customer_whatsapppnumber && customer_whatsapppnumber.length !== 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_whatsapppnumber: false,
        }));
      }
    }
  };
  const LAB_REMARKS: any = [
    {
      text: "Test code mismatch with Test name",
      value: "Test code mismatch with Test name",
    },
    {
      text: "Client code mismatch with Client name written on the TRF",
      value: "Client code mismatch with Client name written on the TRF",
    },
    {
      text: "Wrong sample Type Received",
      value: "Wrong sample Type Received",
    },
    {
      text: "Illegible information provided on TRF/History (Blurred details)",
      value: "Illegible information provided on TRF/History (Blurred details)",
    },
    {
      text: "Rejected - due to prolonged sample collection (Received after >72 Hrs)",
      value:
        "Rejected - due to prolonged sample collection (Received after >72 Hrs)",
    },
    {
      text: "Without Barcode",
      value: "Without Barcode",
    },
    {
      text: "Clinical History",
      value: "Clinical History",
    },
    {
      text: "DOC & Time",
      value: "DOC & Time",
    },
    {
      text: "Correct TRF",
      value: "Correct TRF",
    },
    {
      text: "Test Confirmation",
      value: "Test Confirmation",
    },
    {
      text: "Conception Details",
      value: "Conception Details",
    },
    {
      text: "Improper USG Detail",
      value: "Improper USG Detail",
    },
    {
      text: "Form G /Form E Not Received",
      value: "Form G /Form E Not Received",
    },
    {
      text: "DOB/Age Confirmation",
      value: "DOB/Age Confirmation",
    },
    {
      text: "Referred Doctor Name",
      value: "Referred Doctor Name",
    },
    {
      text: "Clinician Mail ID / Contact Number(NGS Samples)",
      value: "Clinician Mail ID / Contact Number(NGS Samples)",
    },
    {
      text: "Platform Confirmation",
      value: "Platform Confirmation",
    },
    {
      text: "Incomplete/Improper TRF",
      value: "Incomplete/Improper TRF",
    },
    {
      text: "Rejected - Sales Request",
      value: "Rejected - Sales Request",
    },
    {
      text: "Rejected - Sample quality (Hemolyzed/Lipemic/Wrong Container/Leaked)",
      value:
        "Rejected - Sample quality (Hemolyzed/Lipemic/Wrong Container/Leaked)",
    },
    {
      text: "Rejected - No Sample Received(Only TRF Received)",
      value: "Rejected - No Sample Received(Only TRF Received)",
    },
    {
      text: "Rejected - Sample Received Without Proper Identification",
      value: "Rejected - Sample Received Without Proper Identification",
    },
    {
      text: "Rejected - Sample in Expired Vial",
      value: "Rejected - Sample in Expired Vial",
    },
    {
      text: "Sample Received Without TRF",
      value: "Sample Received Without TRF",
    },
    {
      text: "USG Not Received",
      value: "USG Not Received",
    },
    {
      text: "USG Not Received &Test Not Mentioned",
      value: "USG Not Received &Test Not Mentioned",
    },
    {
      text: "USG Not Received & Without TRF",
      value: "USG Not Received & Without TRF",
    },
    {
      text: "Sanger Variant Detail Confirmation/Not Received",
      value: "Sanger Variant Detail Confirmation/Not Received",
    },
    {
      text: "USG Not Received &Test Not Mentioned",
      value: "USG Not Received &Test Not Mentioned",
    },
    {
      text: "Client Code / Name Not Mentioned",
      value: "Client Code / Name Not Mentioned",
    },
    {
      text: "Repeat Sample : Technical Reason",
      value: "Repeat Sample : Technical Reason",
    },
    {
      text: "Other",
      value: "Other",
    },
  ];
  const submitForm = async (e: any) => {
    e.preventDefault();
    const data: any = {
      verification_status: verification_status,
      booking_status: booking_status,
      verification_remark: verification_remark,
      customer_name: customer_name,
      customer_age: customer_age,
      customer_gender: customer_gender,
      customer_email: customer_email,
      customer_address: customer_address,
      customer_landmark: customer_landmark,
      customer_longitude: customer_longitude,
      customer_latitude: customer_latitude,
      customer_whatsapppnumber: customer_whatsapppnumber,
      customer_alternatenumber: customer_alternatenumber,
      customer_aadhar: customer_aadhar,
      passport_number: passport === null ? "" : passport,
      collection_date: collection_date,
      collection_slot: selectedSlot,
      packages: packages,
      coupon_applied: coupon,
      designation: designation,
      bill_id: newBillId,
      patientId: newPatientId,
      report_status: report_status,
      address_line2: addressLine2,
      landmark: favoritePlace,
      ref_doctor: referringDoctor,
      srf_number: srfNo,
      sample_receiving_time: sampleReceivingDate,
      sample_collection_time: sampleCollectionDate,
      batch_id: batchNo,
      book_for: bookingFor,
      is_prescription: isPrescribed,
      dob: dateOfBirth === null ? "" : moment(dateOfBirth).format("YYYY-MM-DD"),
      partner: partner,
      customer_areapincode: customer_areapincode,
      center: centre,
      remarks: labRemark !== "Other" ? labRemark : labRemarkComment,
      save_remarks: labRemark ? true : false,
    };
    if (!centre || booking?.pickup_status === "confirmed") {
      delete data.center;
    }
    if (dateOfBirth === null || dateOfBirth === "") {
      delete data.dob;
    }
    if (labRemark === null || labRemark === "") {
      delete data["remarks"];
    }
    if (batchNo === "") {
      delete data["batch_id"];
    }
    if (labRemark === null || labRemark === "") {
      delete data["remarks"];
    }

    if (partner === "") {
      delete data["partner"];
    }
    if (sampleReceivingDate === "") {
      delete data.sample_receiving_time;
    }
    if (sampleCollectionDate === "") {
      delete data.sample_collection_time;
    }
    if (srfNo === "") {
      delete data.srf_number;
    }
    if (data["coupon_applied"] === 0 || couponCode === "") {
      delete data["coupon_applied"];
    }
    if (referringDoctor === "") {
      delete data["ref_doctor"];
    }
    if (data["customer_whatsapppnumber"] === null) {
      delete data["customer_whatsapppnumber"];
    }
    if (data["customer_alternatenumber"] === null) {
      delete data["customer_alternatenumber"];
    }
    if (data["verification_remark"] === null || verification_remark === "") {
      delete data["verification_remark"];
    }
    if (
      booking?.center?.center_type === "corporate" ||
              booking?.center?.center_type === "direct_client" ||
              (booking?.center?.center_type === "GE Lab" &&
                booking?.org_type === "genomics" &&
                booking?.agent?.usergroup === "Phlebos" &&
                booking?.report_verification_status === "sample_sync") ||
              (booking?.center?.center_type === "GE Lab" &&
                booking?.org_type === "genomics" &&
                booking?.agent?.usergroup !== "Phlebos")
    ) {
      delete data["packages"];
    }
    //   if (removeCoupn && !data['coupon_applied']) {
    //     data['remove_coupon'] = "true"
    // }
    if (
      booking?.center?.center_type === "corporate" ||
      booking?.center?.center_type === "direct_client" ||
      (booking?.center?.center_type === "GE Lab" &&
        booking?.org_type === "genomics" &&
        booking?.agent?.usergroup === "Phlebos" &&
        booking?.report_verification_status === "sample_sync") ||
      (booking?.center?.center_type === "GE Lab" &&
        booking?.org_type === "genomics" &&
        booking?.agent?.usergroup !== "Phlebos")
    ) {
      await updateTheBooking(data, bookingId);
    } else {
      await updateBooking(data, bookingId);
    }

    navigate(`/dashboard/la/booking-view/${bookingId}`);
  };
  const changeCollectionDate = (e: any) => {
    setSelectedSlot(0);
    setCollection_date(e.target.value as string);
    if (zoneId) {
      getPackage(`city_id=${cityId}&gender=${customer_gender}`);
      if (booking?.centre_address === null) {
        getAvailableSlots(e.target.value, zoneId);
      } else {
        getTimeslots();
      }
    }
  };
  // useEffect(() => {
  //   if (dateOfBirth !== "" && dateOfBirth !== null) {
  //     let today = new Date();
  //     let birthDate = new Date(dateOfBirth);
  //     let age = today.getFullYear() - birthDate.getFullYear();
  //     var m = today.getMonth() - birthDate.getMonth();
  //     if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
  //       age--;
  //     }
  //     (age);
  //   }
  // }, [dateOfBirth]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setCustomer_landmark(landmark ? landmark.label : booking.customer_landmark);
    if (landmark?.label) {
      geocodeByAddress(landmark?.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          lat && setCustomerLatitude(lat);
          lng && setCustomerLongitude(lng);
        });
    }
  }, [landmark]);

  const titleSelection = (title: string) => {
    const findGender = GENDER_CHOICE.filter(
      (el: any) => el.display_name === title
    );
    setDesignation(title);
    getPackage(
      `city_id=${booking?.cityid}&org_type=homedx&gender=${customer_gender}`
    );
    setCustomer_gender(findGender[0].gender);
  };
  //mapmyindia
  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [mapAddressObject, setMapAddressObject] = useState<any>({});
  const [accessToken, setAccessToken] = useState<any>("");
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
      setCustomerLatitude(geoFencesMapMyIndia?.latitude);
      setCustomerLongitude(geoFencesMapMyIndia?.longitude);
    }
  }, [geoFencesMapMyIndia]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (mapmyindiaAccessToken?.accesstoken) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken);
    }
  }, [mapmyindiaAccessToken]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (mapAddressObject?.eLoc) {
      getPinCode(mapAddressObject?.addressTokens?.pincode);
      setCustomer_landmark(mapAddressObject?.placeAddress);
      checkGeoFenchMapMyIndia(mapAddressObject?.eLoc, "NA");
    }
  }, [mapAddressObject]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (pin_code?.results && pin_code?.results.length > 0) {
      // setCityId(pin_code?.results[0].cityid);
    }
  }, [pin_code]);
  const handleCloseAddModal = () => {
    setOpen1(false);
    setOpenAddModal(false);
    getBookingById(`${bookingId}`);
  };

  const handleAddTest = (bid: any, aid: any, type: any) => {
    setOpenAddModal(true);
    setType(type);
    setAId(aid);
  };
  const handleRemoveModel = (bid: any, aid: any, type: any) => {
    setOpen1(true);
    setType(type);
    setAId(aid);
  };

  const locationAutoCompleteSearch = (val: string) => {
    if (val.length > 2) {
      setSuggestedAddres([]);
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        window[callbackName] = (data: any) => {
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          resolve(data?.suggestedLocations);
          setSuggestedAddres(data?.suggestedLocations);
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    }
  };

  //mapmyindia
  const handleDOBForAge = (dob: any) => {
    const a: any = moment();
    const b: any = moment(dob);
    var years = a.diff(b, "year");
    b.add(years, "years");
    var months = a.diff(b, "months");
    b.add(months, "months");
    var days = a.diff(b, "days");
    setCustomer_ageMon(months);
    setCustomer_ageDays(days);
  };
  const handleInputChange = (e: any) => {
    setDateOfBirth(e.target.value);
    if (e.target.name === "dateOfBirth") {
      handleDOBForAge(e.target.value);
      let today = new Date();
      let birthDate = new Date(e.target.value);
      let age = today.getUTCFullYear() - birthDate.getUTCFullYear();
      var m = today.getUTCMonth() - birthDate.getUTCMonth();
      if (m < 0 || (m === 0 && today.getUTCDate() < birthDate.getUTCDate())) {
        age--;
      }
      setCustomer_age(age);
    }
  };
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Dialog
        open={openDocModal}
        onClose={() => setOpenDocModal(false)}
        fullWidth
        maxWidth="lg"
      >
        <AddDoctor
          getRefDoctor={getRefDoctor}
          setOpenDocModal={setOpenDocModal}
        />
      </Dialog>
      <Dialog open={openAddModal}>
        <Grid container md={12} style={{ padding: "15px 20px 0 20px" }}>
          <Grid item xs={6} style={{ textAlign: "left" }}>
            <h4>Test Addon</h4>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCloseAddModal}
            >
              Close
            </Button>
          </Grid>
        </Grid>

        <AddPackage
          id={bookingId}
          aid={aId}
          type={type}
          packageDetails={packageDetails}
          setPackages={setPackages}
        />
      </Dialog>
      <Dialog open={open1}>
        <Grid container md={12} style={{ padding: "15px 20px 0 20px" }}>
          <Grid item xs={6} style={{ textAlign: "left" }}>
            <h4>Remove Test</h4>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCloseAddModal}
            >
              Close
            </Button>
          </Grid>
        </Grid>

        <RemovePackage
          id={bookingId}
          aid={aId}
          type={type}
          packageDetails={packageDetails}
          setPackages={setPackages}
        />
      </Dialog>
      <div className={classes.contentHeader}>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justify="space-between"
          spacing={3}
          // style={{ margin: "1rem auto" }}
        >
          <Grid item xs={6} sm={6} md={6}>
            <h3
              style={{
                paddingTop: "10px",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              EDIT BOOKING
            </h3>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <h3
              style={{
                paddingTop: "10px",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              {booking?.centre_address !== null
                ? "CENTRE : " +
                  String(booking?.centre_address?.name).toUpperCase()
                : "HOME-DX BOOKING"}
            </h3>
          </Grid>
        </Grid>
      </div>
      {Object.keys(booking).length === 0 ? (
        <Loader />
      ) : (
        <Paper className={classes.paper} elevation={15}>
          <form onSubmit={submitForm}>
            <Grid container spacing={3} style={{ margin: "auto" }}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>Booking date</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <input
                      className="input"
                      name="collection_date"
                      type="date"
                      value={booking_date || ""}
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "18.5px 14px",
                        height: "40px",
                      }}
                      required
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>Sample Collection date</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <input
                      className="input"
                      name="collection_date"
                      type="date"
                      value={
                        (collection_date && collection_date.toString()) || ""
                      }
                      // onChange={(e) => setCollection_date(e.target.value as string)}
                      onChange={(e) => changeCollectionDate(e)}
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "18.5px 14px",
                        height: "40px",
                      }}
                      // min={`${new Date().getFullYear()}-${(new Date().getMonth() + 1) < 10 ? `0${(new Date().getMonth() + 1)}` : (new Date().getMonth() + 1)}-${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()}`}
                      required
                      disabled
                    />
                  </Grid>
                </Grid>
                {/* <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>Pin Code</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="pincode"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          handlePinCode(obj.pincode as String);
                        }
                      }}
                      defaultValue={
                        customer_areapincodeDef || {
                          id: "",
                          pincode: "",
                          area: "",
                        }
                      }
                      options={pin_code.results}
                      freeSolo
                      blurOnSelect
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getPinCode(newInputValue.split(",")[0]);
                        }, 1000);
                      }}
                      getOptionLabel={(option: any) => `${option.pincode}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Pin Code"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          className="input"
                          disabled={booking.phlebo !== null}
                          required
                        />
                      )}
                      disabled
                    />
                  </Grid>
                </Grid> */}
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  spacing={0}
                  style={{
                    padding: "10px",
                    background: "#e1f5fe",
                    marginLeft: "-10px",
                  }}
                >
                  <Grid item xs={12} md={6}>
                    <p>Locality </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>
                      : <strong>{customer_landmark}</strong>
                      &nbsp;{" "}
                    </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>House No./Plot No./Flat No./Door No./Shop/C/-</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>
                      : <strong>{customer_address}</strong>
                    </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>
                      Apartment/Building/Colony/Block/Sector/
                      Street/Gali/Road/Chawl
                    </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>
                      : <strong>{addressLine2}</strong>
                    </p>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <p>Landmark/Sublocality</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>
                      : <strong>{favoritePlace}</strong>
                    </p>
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <p>Area Name </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>
                      : <strong>{zoneName}</strong>
                    </p>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-end"
              spacing={3}
              style={{ margin: "1rem auto", background: "#eff" }}
            >
              <Grid item md={3}>
                <p>Mobile Number</p>
                <TextField
                  name="customer_phonenumber"
                  type="text"
                  // onWheel={(e: any) => e.target.blur()}
                  // onInput={(e: any) => {
                  //   e.target.value = Math.max(0, parseInt(e.target.value))
                  //     .toString()
                  //     .slice(0, 10);
                  // }}
                  value={customer_phonenumber}
                  placeholder="Enter Mobile Number"
                  className="input"
                  variant="outlined"
                  disabled
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) =>
                    setCustomer_phonenumber(e.target.value as String)
                  }
                  onBlur={() => verifyMobile("mobile")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      customer_phonenumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.customer_phonenumber &&
                    "Incorrect Phone Number"
                  }
                  required
                />
              </Grid>
              <Grid item md={3}>
                <p>Alternate Mobile Number</p>
                <TextField
                  name="customer_alternatenumber"
                  type="text"
                  // onWheel={(e: any) => e.target.blur()}
                  // onInput={(e: any) => {
                  //   e.target.value = Math.max(0, parseInt(e.target.value))
                  //     .toString()
                  //     .slice(0, 10);
                  // }}
                  value={customer_alternatenumber}
                  placeholder="Enter Alternate Mobile Number"
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) =>
                    setCustomer_alternatenumber(e.target.value as String)
                  }
                  onBlur={() => verifyMobile("alternate")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      customer_alternatenumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.customer_alternatenumber &&
                    "Incorrect alternate Number"
                  }
                />
              </Grid>
              <Grid item md={3}>
                <p>Whatsapp Number</p>
                <TextField
                  name="customer_whatsapppnumber"
                  type="text"
                  // onWheel={(e: any) => e.target.blur()}
                  // onInput={(e: any) => {
                  //   e.target.value = Math.max(0, parseInt(e.target.value))
                  //     .toString()
                  //     .slice(0, 10);
                  // }}
                  value={customer_whatsapppnumber}
                  placeholder="Enter Whatsapp Mobile Number"
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) =>
                    setCustomer_whatsapppnumber(e.target.value as String)
                  }
                  onBlur={() => verifyMobile("whatsapp")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      customer_whatsapppnumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.customer_whatsapppnumber &&
                    "Incorrect Whatsapp Number"
                  }
                />
              </Grid>
              <Grid item md={3}>
                <p>Email</p>
                <TextField
                  name="customer_email"
                  type="email"
                  placeholder="Enter Email"
                  value={customer_email}
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) => setCustomer_email(e.target.value as String)}
                  required
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              spacing={3}
              style={{ margin: "1rem auto", background: "#e1f5fe" }}
            >
              <Grid item md={2}>
                <p>Title</p>
                <Select
                  className="input"
                  name="customer_designation"
                  variant="outlined"
                  value={designation || ""}
                  style={{ width: "100%", margin: "0", height: "40px" }}
                  onChange={(e) => titleSelection(e.target.value as string)}
                  required
                >
                  <MenuItem value="Mrs">Mrs</MenuItem>
                  <MenuItem value="Miss">Miss</MenuItem>
                  <MenuItem value="Ms">Ms</MenuItem>
                  <MenuItem value="Smt">Smt</MenuItem>
                  <MenuItem value="Mr">Mr</MenuItem>
                  <MenuItem value="Baby Boy">Baby Boy</MenuItem>
                  <MenuItem value="Baby Girl">Baby Girl</MenuItem>
                </Select>
              </Grid>
              <Grid item md={2}>
                <p>Customer Name</p>
                <TextField
                  name="customer_name"
                  type="text"
                  placeholder="Enter Name"
                  value={customer_name}
                  className="input"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "100%", margin: "0" }}
                  onChange={(e) => setCustomer_name(e.target.value as String)}
                  required
                />
              </Grid>

              <Grid item md={2} xs={12}>
                <p>DOB</p>
                <TextField
                  className="input"
                  name="dateOfBirth"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={dateOfBirth}
                  style={{ width: "100%", margin: "0", height: "40px" }}
                  onChange={(e) => handleInputChange(e)}
                  fullWidth
                  
                  inputProps={{
                    max: moment().subtract(1, "days").format("YYYY-MM-DD"),
                  }}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <p>Age*</p>
                <TextField
                  className="input"
                  name="customerAge"
                  type="number"
                  onWheel={(e: any) => e.target.blur()}
                  required
                  label="Years"
                  placeholder="Enter Age"
                  disabled={dateOfBirth}
                  value={customer_age}
                  variant="outlined"
                  InputProps={{ inputProps: { min: 0 } }}
                  style={{ width: "33%", margin: "0", height: "40px" }}
                  onChange={(e) => setCustomer_age(e.target.value)}
                />
                <TextField
                  className="input"
                  name="customerAgeMonths"
                  type="number"
                  label="Months"
                  disabled
                  value={customer_ageMon}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "33%", margin: "0", height: "40px" }}
                />
                <TextField
                  className="input"
                  name="customerAgeDays"
                  type="number"
                  label="Days"
                  disabled
                  value={customer_ageDays}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "33%", margin: "0", height: "40px" }}
                />
              </Grid>
              <Grid item md={2}>
                <p>Gender</p>
                <Select
                  className="input"
                  name="customer_gender"
                  variant="outlined"
                  value={customer_gender || ""}
                  style={{
                    width: "100%",
                    margin: "0",
                    padding: "0",
                    height: "40px",
                  }}
                  onChange={(e) => setCustomer_gender(e.target.value as string)}
                  required
                  disabled
                >
                  <MenuItem selected hidden value={""}>
                    Please Select
                  </MenuItem>
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              </Grid>
              {booking?.center?.center_type === "corporate" ||
              booking?.center?.center_type === "direct_client" ||
              (booking?.center?.center_type === "GE Lab" &&
                booking?.org_type === "genomics" &&
                booking?.agent?.usergroup === "Phlebos" &&
                booking?.report_verification_status === "sample_sync") ||
              (booking?.center?.center_type === "GE Lab" &&
                booking?.org_type === "genomics" &&
                booking?.agent?.usergroup !== "Phlebos") ? (
                <>
                  <Grid item md={2}>
                    <p>Add Package</p>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ width: "100%", margin: "0", height: "40px" }}
                      onClick={() =>
                        handleAddTest(
                          booking?.id === undefined ? booking?.pk : booking?.id,
                          "aid",
                          "booking"
                        )
                      }
                    >
                      Add Package
                    </Button>
                  </Grid>
                  <Grid item md={2}>
                    <p>Remove Package</p>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ width: "100%", margin: "0", height: "40px" }}
                      onClick={() =>
                        handleRemoveModel(
                          booking?.id === undefined ? booking?.pk : booking?.id,
                          "aid",
                          "booking"
                        )
                      }
                    >
                      Remove
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item md={2}>
                    <p>Package Name</p>
                    <Autocomplete
                      id="package"
                      key={booking?.packages}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let packs: any = [];
                          newValue?.map((val: any) => {
                            let obj = JSON.parse(
                              JSON.stringify(val, null, " ")
                            );
                            packs.push(obj);
                          });
                          handlePackages(packs);
                        }
                      }}
                      multiple
                      options={packageList?.results || []}
                      value={packageDetails}
                      disableClearable
                      limitTags={0}
                      getOptionLabel={(option: any) =>
                        option?.name && option?.package_city_prices
                          ? option?.name.toString() +
                            option?.package_city_prices?.tat_time +
                            "(" +
                            option?.code +
                            ")"
                          : option?.name +
                            ", " +
                            option?.tat_time +
                            "(" +
                            option?.code +
                            ")"
                      }
                      getOptionDisabled={(option: any) => {
                        const packID = packageDetails?.map(
                          (pack: any) => pack.id
                        );
                        return packID.includes(option.id);
                      }}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getPackage(`${newInputValue}&city_id=${cityId}`);
                        }, 1000);
                      }}
                      freeSolo
                      disableCloseOnSelect
                      renderTags={(value: string[], getTagProps) =>
                        value.map((option: any, index: number) => (
                          <Chip
                            variant="default"
                            color="primary"
                            label={
                              option?.name && option?.package_city_prices
                                ? option?.name.toString() +
                                  ", " +
                                  option?.package_city_prices?.tat_time +
                                  "(" +
                                  option?.code +
                                  ")"
                                : option?.name +
                                  ", " +
                                  option?.tat_time +
                                  "(" +
                                  option?.code +
                                  ")"
                            }
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Package"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          inputProps={{
                            ...params.inputProps,
                            onKeyDown: (e) => {
                              if (e.key === "Enter") {
                                e.stopPropagation();
                              }
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              <Grid item md={2}>
                <p>Partner</p>
                <TextField
                  name="partner"
                  type="text"
                  value={partner}
                  placeholder="Enter Order Id"
                  className="input"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setPartner(e.target.value as String)}
                  style={{ width: "100%", margin: "0" }}
                />
              </Grid>
              <Grid item md={2}>
                <p>Passport Number</p>
                <TextField
                  className="input"
                  name="passport"
                  type="text"
                  placeholder="Enter Passport Number"
                  value={passport}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "100%", margin: "0" }}
                  onChange={(e: any) => setPassport(e.target.value)}
                />
              </Grid>
              <Grid item md={2}>
                <p>Aadhar Number</p>
                <TextField
                  className="input"
                  name="aadhar"
                  type="number"
                  onWheel={(e: any) => e.target.blur()}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 16);
                  }}
                  placeholder="Enter Aadhar Number"
                  value={aadhar}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "100%", margin: "0" }}
                  onChange={(e: any) => setAadhar(e.target.value)}
                />
              </Grid>
              <Grid item md={3}>
                <p>Booking For</p>
                <Select
                  className="input"
                  name="bookingFor"
                  variant="outlined"
                  value={bookingFor ? bookingFor : ""}
                  style={{ width: "100%", margin: "0", height: "40px" }}
                  required
                  onChange={(e: any) => setBookingFor(e.target.value)}
                >
                  <MenuItem value="self">Self</MenuItem>
                  <MenuItem value="father_in_law">
                    Father/Father in law
                  </MenuItem>
                  <MenuItem value="mother_in_law">
                    Mother/Mother in law
                  </MenuItem>
                  <MenuItem value="son_in_law">Son/Son in law</MenuItem>
                  <MenuItem value="daughter_in_law">
                    Daughter/Daughter in law
                  </MenuItem>
                  <MenuItem value="spouse">Spouse</MenuItem>
                  <MenuItem value="friend">Friend</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </Grid>
              <Grid item md={3}>
                <p>Is Prescribed</p>
                <Select
                  className="input"
                  name="is_prescribed"
                  variant="outlined"
                  value={
                    isPrescribed === true || isPrescribed === false
                      ? isPrescribed
                      : ""
                  }
                  style={{ width: "100%", margin: "0", height: "40px" }}
                  onChange={(e: any) => setIsPrescribed(e.target.value)}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </Grid>
              {booking?.partner?.usertype === "b2b" ? (
                <Grid item md={3}>
                  <p>Partner</p>
                  <Autocomplete
                    key={booking?.partner?.id}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setPartner(obj.id);
                      }
                    }}
                    options={partnerList?.results || []}
                    freeSolo
                    blurOnSelect
                    getOptionLabel={(option: any) =>
                      option.user && option.user.name
                    }
                    onInputChange={(event, newInputValue) => {
                      setPartnerName(newInputValue);
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getPartners(newInputValue);
                      }, 1000);
                      if (newInputValue.length === 0) {
                        setPartner("");
                      }
                    }}
                    inputValue={partnerName}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Partner"
                        variant="outlined"
                        fullWidth
                        disabled={
                          userDetails?.profile?.executive_type !== "Admin"
                        }
                      />
                    )}
                  />
                </Grid>
              ) : (
                ""
              )}

              <Grid item md={3}>
                <p>Sample Collection Date & Time</p>
                <TextField
                  className="input"
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={sampleCollectionDate}
                  variant="outlined"
                  onChange={(e) => setSampleCollectionDate(e.target.value)}
                  style={{ width: "100%", height: "40px" }}
                />
              </Grid>

              <Grid item md={3}>
                <p>Sample Receiving Date & Time</p>
                <TextField
                  className="input"
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={sampleReceivingDate}
                  variant="outlined"
                  onChange={(e) => setSampleReceivingDate(e.target.value)}
                  style={{ width: "100%", height: "40px" }}
                />
              </Grid>

              {booking?.center?.center_type === "direct_client" ||
              booking?.center?.center_type === "collection_center" ? (
                <>
                  <Grid item md={3}>
                    <p>Referring Doctor</p>
                    <Autocomplete
                      style={{ width: "90%" }}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setReferringDoctor(obj?.id);
                        }
                      }}
                      freeSolo
                      blurOnSelect
                      options={refDoctor?.results || []}
                      disableCloseOnSelect
                      disableClearable
                      getOptionLabel={(option: any) =>
                        option?.name ? option?.name : ""
                      }
                      onInputChange={(event, newInputValue) => {
                        setDoctorDef(newInputValue);
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getRefDoctor(`?name=${newInputValue}`);
                        }, 1000);
                      }}
                      loading={loading}
                      inputValue={doctorDef}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Doctor's Name"
                          variant="outlined"
                        />
                      )}
                    />

                    <Grid item md={1} xs={1}>
                      <AddCircleIcon
                        style={{ height: "auto", width: "40px" }}
                        onClick={handleAddDoctor}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                ""
              )}
              {booking?.center?.center_type === "collection_center" &&
              userDetails?.profile?.executive_type === "Admin" ? (
                <>
                  <Grid item md={3}>
                    <p>Centre</p>
                    <Autocomplete
                      key={booking.pk}
                      id="center"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setCentre(obj.id);
                        }
                      }}
                      freeSolo
                      blurOnSelect
                      options={centreData?.results || []}
                      inputValue={centreDef}
                      disableCloseOnSelect
                      getOptionLabel={(option: any) =>
                        (option?.display_name === null
                          ? option?.name
                          : option?.display_name) +
                        (option?.center_code
                          ? "(" + option?.center_code + ")"
                          : "")
                      }
                      onInputChange={(event, newInputValue) => {
                        setCentreDef(newInputValue);
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getCentre(
                            `?optimize=true&center_type=collection_center&name_or_code=${newInputValue}`
                          );
                        }, 1000);
                      }}
                      disabled={booking?.pickup_status === "confirmed"}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Centre"
                          variant="outlined"
                          fullWidth
                          disabled={booking?.pickup_status === "confirmed"}
                        />
                      )}
                    />
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>

            <Grid
              container
              spacing={3}
              direction="row"
              justify="center"
              alignItems="center"
              style={{ margin: "1rem auto" }}
            >
              <Grid item xs={12} md={6}>
                <Card
                  className="sampleCollectionTime"
                  style={{ minHeight: "490px", overflow: "auto" }}
                >
                  <p style={{ marginBottom: "1rem" }}>Sample Collection Time</p>
                  <div className={classes.slotContainer}>
                    {booking?.centre_address === null
                      ? timeSlots &&
                        timeSlots.length > 0 &&
                        timeSlots.map((slot: any) => {
                          return (
                            slot.available_slots > 0 && (
                              <Card
                                key={slot.id}
                                className={
                                  slot.id === selectedSlot
                                    ? classes.selectedSlot
                                    : classes.slot
                                }
                                onClick={() =>
                                  setSelectedSlot(slot.id as Number)
                                }
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "black",
                                    fontSize: "14px",
                                  }}
                                >
                                  {slot.start_time1hr}-{slot.end_time1hr}
                                </p>
                                {/* <p style={{ textAlign: "center", color: "black" }}>{moment(`${slot.start_time}`, ["HH.mm.ss"]).format("hh:mm A")} - {moment(`${slot.end_time}`, ["HH.mm.ss"]).format("hh:mm A")}</p> */}
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "green",
                                  }}
                                >
                                  {" "}
                                  Availabe slots: {slot.available_slots}
                                </p>
                              </Card>
                            )
                          );
                        })
                      : timeslots &&
                        timeslots?.results &&
                        timeslots.results.length > 0 &&
                        timeslots.results.map((slot: any) => {
                          return (
                            <Card
                              key={slot.id}
                              className={
                                slot.id === selectedSlot
                                  ? classes.selectedSlot
                                  : classes.slot
                              }
                              onClick={() => setSelectedSlot(slot.id as Number)}
                            >
                              <p
                                style={{
                                  textAlign: "center",
                                  color: "black",
                                  fontSize: "14px",
                                }}
                              >
                                {slot.start_time}-{slot.end_time}
                              </p>
                              {/* <p style={{ textAlign: "center", color: "black" }}>{moment(`${slot.start_time}`, ["HH.mm.ss"]).format("hh:mm A")} - {moment(`${slot.end_time}`, ["HH.mm.ss"]).format("hh:mm A")}</p> */}
                            </Card>
                          );
                        })}
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: "1rem" }}>
                <div className="priceDetails" style={{ padding: "1rem" }}>
                  <table style={{ width: "100%", margin: "0" }}>
                    <thead>
                      <tr>
                        <td className="cell">Customer Name</td>
                        <td className="cell">Packages</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="cell">{customer_name}</td>
                        <td className="cell">
                          <ul>
                            {packageDetails &&
                              packageDetails?.length &&
                              packageDetails.map((pack: any, index: any) => {
                                return (
                                  <li key={index}>
                                    {pack.name}
                                    <br />
                                    DESCRIPTION : {pack.description}
                                    <br />
                                    TaT:{" "}
                                    {pack.package_city_prices
                                      ? pack.package_city_prices.tat_time
                                      : pack.tat_time}
                                    <br />
                                    Price:{" "}
                                    {pack.package_city_prices
                                      ? pack.package_city_prices.offer_price
                                      : pack.offer_price}
                                  </li>
                                );
                              })}
                          </ul>
                        </td>
                      </tr>
                      {booking?.additional_members &&
                        booking?.additional_members.map(
                          (member: any, index: any) => {
                            return (
                              <tr key={index}>
                                <td className="cell">{member.customer_name}</td>
                                <td className="cell">
                                  <ul>
                                    {member.packages &&
                                      member.packages.map(
                                        (pack: any, index: any) => {
                                          return (
                                            <li key={index}>
                                              {pack.name}
                                              <br />
                                              DESCRIPTION : {pack.description}
                                              <br />
                                              TaT:{" "}
                                              {pack.package_city_prices
                                                ? pack.package_city_prices
                                                    .tat_time
                                                : pack.tat_time}
                                              <br />
                                              Price:{" "}
                                              {pack.package_city_prices
                                                ? pack.package_city_prices
                                                    .offer_price
                                                : pack.offer_price}
                                            </li>
                                          );
                                        }
                                      )}
                                  </ul>
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
                <div
                  className="priceDetails"
                  style={{ padding: "1rem 1rem 0 1rem" }}
                >
                  <h6 className="totalPrice">
                    Price: ₹ {Math.floor(price)} {extraCharge ? "+ 200" : ""}
                  </h6>
                  <h6 className="totalPrice">
                    RedCash Discount: ₹{" "}
                    {Math.floor(booking?.redcash_discounted_amount)}
                  </h6>
                  <h6 className="totalPrice">Discount: ₹ {discount}</h6>
                  <h6 className="totalPrice">
                    Total Amount: ₹{" "}
                    {totalPrice -
                      Math.floor(booking?.redcash_discounted_amount)}
                  </h6>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ margin: "1rem auto" }}
                  >
                    <Grid container spacing={1} direction="row">
                      <Grid item xs={12}>
                        <p>Enter Coupon</p>
                      </Grid>
                      <Grid item xs={8}>
                        <Autocomplete
                          id="coupon"
                          onChange={(event, newValue) => {
                            if (newValue) {
                              let obj = JSON.parse(
                                JSON.stringify(newValue, null, " ")
                              );
                              setCoupon(obj?.id as Number);
                              setCouponCode(obj?.code as string);
                              // setCouponType(obj.is_red_cash);
                            }
                          }}
                          inputValue={couponCode}
                          options={couponList?.results || []}
                          freeSolo
                          blurOnSelect
                          onInputChange={(event, newInputValue) => {
                            clearTimeout(timer.current);
                            setCouponCode(newInputValue);
                            timer.current = setTimeout(() => {
                              getCoupons(
                                `?code=${newInputValue}&org_type=homedx`
                              );
                            }, 1000);
                            if (newInputValue === "") {
                              setCoupon(0);
                              setDiscount(0);
                            }
                          }}
                          getOptionLabel={(option: any) => option?.code}
                          getOptionDisabled={(option: any) =>
                            option?.minimum_price > totalPrice
                          }
                          renderInput={(params) => (
                            <TextField
                              className="input"
                              {...params}
                              placeholder="Enter Coupon"
                              variant="outlined"
                              style={{
                                width: "100%",
                                margin: "0",
                                padding: "0",
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          variant="contained"
                          fullWidth
                          color="primary"
                          onClick={applyCoupon}
                        >
                          Apply
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              direction="row"
              justify="center"
              alignItems="center"
              style={{ margin: "10px auto" }}
            >
              <Grid item xs={12}>
                {/* <Grid item xs={12}>
                  <h3
                    style={{
                      borderTop: "dotted 1px #cccc",
                      paddingTop: "10px",
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    Address
                  </h3>
                </Grid> */}
                <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                  {/* <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>{CITY}</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="city"
                        type="text"
                        value={
                          (booking.customer_areapincode && 
                            booking.customer_areapincode?.city?.display_name) ||
                          ""
                        }
                        disabled
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>{LOCALITY}</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Autocomplete
                        id="colony-area-sector"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            setMapAddressObject(obj);
                          }
                        }}
                        options={suggestedAddress ? suggestedAddress : []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        getOptionLabel={(option: any) => `${option?.placeAddress}`}
                        filterOptions={(option: any) => option}
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            locationAutoCompleteSearch(newInputValue);
                          }, 500);
                        }}
                        disabled
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            placeholder="Search Address"
                            variant="outlined"
                            style={{ width: "100%", margin: "0", padding: "0" }}
                            required
                          />
                        )}
                        defaultValue={{ "placeAddress": customer_landmark }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>{ADDRESS_LINE1}</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="customer_address"
                        type="text"
                        value={customer_address}
                        onChange={(e) => setCustomer_address(e.target.value as string)}
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>{ADDRESS_LINE2}</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="customer_address"
                        type="text"
                        value={addressLine2}
                        onChange={(e) =>
                          setAddressLine2(e.target.value as string)
                        }
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>{LANDMARK}</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="customer_address"
                        type="text"
                        value={favoritePlace}
                        onChange={(e) => setFavoritePlace(e.target.value as string)}
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  
                  
                  {/* <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Area</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="areaName"
                        type="text"
                        disabled
                        value={
                          (pin_code.results &&
                            pin_code.results.length > 0 &&
                            pin_code.results[0].city) ||
                          ""
                        }
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid> */}
                  {/* <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>{PINCODE}</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="pincode"
                        type="text"
                        disabled
                        value={
                          (booking.customer_areapincode &&
                            booking.customer_areapincode.pincode) ||
                          ""
                        }
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>  */}
                  <Grid
                    container
                    direction="row"
                    spacing={3}
                    style={{ margin: "1rem auto" }}
                  >
                    <Grid item md={2}>
                      <p>Verification Remark</p>
                      {booking?.booking_type === "b2c" &&
                      booking?.center?.center_type === "collection_center" ? (
                        <TextField
                          className="input"
                          name="verification_remark"
                          placeholder="Enter Remark"
                          type="text"
                          value={verification_remark}
                          variant="outlined"
                          style={{ margin: "0", width: "100%" }}
                          onChange={(e) =>
                            setVerification_remark(e.target.value as string)
                          }
                        />
                      ) : (
                        <TextField
                          className="input"
                          name="verification_remark"
                          placeholder="Enter Remark"
                          type="text"
                          value={verification_remark}
                          variant="outlined"
                          style={{ margin: "0", width: "100%" }}
                          onChange={(e) =>
                            setVerification_remark(e.target.value as string)
                          }
                          disabled
                        />
                      )}
                    </Grid>
                    <Grid item md={2}>
                      <p>Verification Status</p>

                      {booking?.booking_type === "b2c" &&
                      booking?.center?.center_type === "collection_center" ? (
                        <Select
                          className="input"
                          name="verification_status"
                          variant="outlined"
                          value={verification_status || "pending"}
                          style={{
                            width: "100%",
                            margin: "0",
                            padding: "0",
                            height: "40px",
                          }}
                          onChange={(e) =>
                            setVerification_status(e.target.value as string)
                          }
                          required
                        >
                          <MenuItem value={"pending"}>Pending</MenuItem>
                          <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                          <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                        </Select>
                      ) : (
                        <Select
                          className="input"
                          name="verification_status"
                          variant="outlined"
                          value={verification_status || "pending"}
                          style={{
                            width: "100%",
                            margin: "0",
                            padding: "0",
                            height: "40px",
                          }}
                          onChange={(e) =>
                            setVerification_status(e.target.value as string)
                          }
                          required
                          disabled
                        >
                          <MenuItem value={"pending"}>Pending</MenuItem>
                          <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                          <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                        </Select>
                      )}
                    </Grid>
                    <Grid item md={2}>
                      <p>Booking Status</p>
                      <Select
                        className="input"
                        name="verification_status"
                        variant="outlined"
                        defaultValue={booking_status}
                        value={booking_status}
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "0",
                          height: "40px",
                        }}
                        onChange={(e) =>
                          setBooking_status(e.target.value as string)
                        }
                        required
                      >
                        <MenuItem disabled value={booking_status}>
                          {booking_status}
                        </MenuItem>
                        <MenuItem
                          value={
                            "Sample Not Received Due To Payment Information"
                          }
                        >
                          Sample Not Received Due To Payment Information
                        </MenuItem>
                        <MenuItem value={"sample received"}>
                          Sample Received
                        </MenuItem>
                        <MenuItem value={"partial received"}>
                          Partial Received
                        </MenuItem>
                      </Select>
                    </Grid>
                    <Grid item md={2}>
                      <p>Bill Id</p>

                      <TextField
                        className="input"
                        name="bill_id"
                        type="text"
                        placeholder="Bill Id"
                        value={bill_id}
                        variant="outlined"
                        disabled={booking?.bill_id !== null}
                        style={{ margin: "0", width: "100%" }}
                        onChange={(e) => setNewBillId(e.target.value as String)}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <p>Patient</p>
                      <TextField
                        className="input"
                        name="patientId"
                        type="text"
                        placeholder="Patient Id"
                        value={patientId}
                        disabled={patientId !== null}
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                        onChange={(e) =>
                          setNewPatientId(e.target.value as String)
                        }
                        // required
                      />
                    </Grid>

                    <Grid item md={2}>
                      <p>Report Status</p>
                      <Select
                        className="input"
                        name="report_status"
                        variant="outlined"
                        defaultValue={booking.report_status}
                        value={report_status || "pending"}
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "0",
                          height: "40px",
                        }}
                        onChange={(e) =>
                          setReportStatus(e.target.value as string)
                        }
                        // required
                      >
                        <MenuItem value={"none"}>None</MenuItem>
                        <MenuItem value={"pending"}>Pending</MenuItem>
                        <MenuItem value={"partial"}>Partial</MenuItem>
                        <MenuItem value={"consolidate"}>Consolidate</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item md={2}>
                      <p>SRF Number</p>
                      <TextField
                        className="input"
                        name="srfNo"
                        type="text"
                        value={srfNo}
                        placeholder="SRF No."
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                        onChange={(e) => setSrfNo(e.target.value)}
                      />
                    </Grid>
                    <Grid item md={2}>
                      {booking?.center?.center_type === "direct_client" ? (
                        <Grid container spacing={3}>
                          <p>Batch Number</p>
                          <TextField
                            name="batch"
                            type="number"
                            placeholder="Batch Number"
                            value={batchNo}
                            variant="outlined"
                            fullWidth
                            onChange={(e) => setBatchNo(e.target.value)}
                          />
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item md={2}>
                      <p>History Remark</p>
                      <Select
                        className="input"
                        name="labRemark"
                        variant="outlined"
                        value={labRemark}
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "0",
                          height: "40px",
                        }}
                        onChange={(e) => setLabRemark(e.target.value as string)}
                      >
                        {LAB_REMARKS.map((item: any) => {
                          return (
                            <MenuItem key={item.text} value={`${item.text}`}>
                              {item.value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                    <Grid item md={2}>
                      <p>Lab Remark</p>
                      <TextareaAutosize
                        aria-label="minimum height"
                        disabled={
                          labRemark?.includes("Other") === true ? false : true
                        }
                        minRows={3}
                        placeholder=" "
                        value={labRemarkComment || ""}
                        className="input"
                        style={{ width: "100%", margin: "0", height: "37px" }}
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                          setLabRemarkComment(e.target.value as string)
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                disabled={
                  loading ||
                  !selectedSlot ||
                  packages.length === 0 ||
                  !mobileValidation.customer_alternatenumber ||
                  !mobileValidation.customer_phonenumber ||
                  !mobileValidation.customer_whatsapppnumber
                }
              >
                Update booking
              </Button>
            </div>
          </form>
        </Paper>
      )}
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  pin_code: state.LimsAdminReducer.pin_code,
  packageList: state.LimsAdminReducer.packageList,
  couponList: state.LimsAdminReducer.couponList,
  booking: state.LimsAdminReducer.booking,
  booking_slots: state.LimsAdminReducer.booking_slots,
  loading: state.LimsAdminReducer.loading,
  refDoctor: state.LimsAdminReducer.refDoctor,
  // checkGeoFenceStatus: state.LimsAdminReducer.checkGeoFenceStatus,
  geoFencesMapMyIndia: state.LimsAdminReducer.geoFencesMapMyIndia,
  mapmyindiaAccessToken: state.LimsAdminReducer.mapmyindiaAccessToken,
  timeslots: state.LimsAdminReducer.timeslots,
  userDetails: state.LimsAdminReducer.userDetails,
  centreData: state.LimsAdminReducer.centreData,
  partnerList: state.LimsAdminReducer.partnerList,
});

export default connect(mapStateToProps, {
  getPinCode,
  getPackage,
  updateBooking,
  getCoupons,
  getBookingById,
  getAvailableSlots,
  // checkGeoFenceArea,
  updateTheBooking,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken,
  getTimeslots,
  getUserDetails,
  getRefDoctor,
  getCentre,
  getPartners,
})(EditBookingForm);
