import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, Select } from "@material-ui/core";
import image from "../../../../images/logo1.png";
import { getBookingByHistoryForm,getBookingById } from "../../../actions/LimsAdminActions";
import HistoryForm from "../../../pages/Historyform";
import Autocomplete from '@mui/material/Autocomplete';
import { Button,Dialog } from "@material-ui/core";
import { connect } from "react-redux";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
const useStyles = makeStyles({
  table: {
    height: "40px",
  },
  left: {
    margin: 600,
    width: "50%",
  },
  fontsize13: {
    fontSize: "13px",
  },
  rightContentHeader: {
    float: "left",
    textAlign: "left",
    paddingRight: "20px",
  },
  style: {
    background: "#AD70A6",
    color: "#fff",
    padding: 10,
    marginLeft: 5,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
    marginTop: "40px",
  },
});
interface Props {
  match: any;
  booking: any;
  getBookingById:any;
  getBookingByHistoryForm: any;
  bookinghistoryform: any;
  relevantClinicalHistoryform: any;
  setRelevantClinicalHistoryForm: any;
  biospyPackageCode: any;
  setBiospyPackageCode: any;
  clinicalDiagnosisform: any;
  setClinicalDiagnosisform: any;
  radiologyFindingsform: any;
  setRadiologyFindingsform: any;
  operativeFindingsform: any;
  setOperativeFindingsform: any;
}
const HistoFormView: React.FC<Props> = ({
  match,
  booking,
  getBookingById,
  getBookingByHistoryForm,
  bookinghistoryform,
  relevantClinicalHistoryform,
  setRelevantClinicalHistoryForm,
  biospyPackageCode,
  setBiospyPackageCode,
  clinicalDiagnosisform,
  setClinicalDiagnosisform,
  radiologyFindingsform,
  setRadiologyFindingsform,
  operativeFindingsform,
  setOperativeFindingsform
}) => {
  const bookingId = match?.params?.id;
  const [dataId, setDataId] = useState<any>({});
  const classes = useStyles();
  const componentRef = useRef<any>(null);
  const [relevantClinicalHistory, setRelevantClinicalHistory] =
    useState<any>("");
  const [clinicalDiagnosis, setClinicalDiagnosis] = useState<any>("");
  const [radiologyFindings, setRadiologyFindings] = useState<any>("");
  const [operativeFindings, setOperativeFindings] = useState<any>("");
  const [name, setName] = useState<string>("");
  const [contactNumber, setContactNumber] = useState<string>("");
  const [sizeofbiopsy, setSizeOfBiopsy] = useState<string>("");
  const [dateOfProcedure, setDateOfProcedure] = useState<string>("");
  const [refDoctorName, setRefDoctorName] = useState<string>("");
  const [refDoctorNumber, setRefDoctorNumber] = useState<string>("");
  const [centreName, setCentreName] = useState<string>("");
  const [biospypackage, setBiospypackage] = useState<string>("");
  const [biospySamples, setBiospySamples] = useState<any>("");
  const [biospySampleStatus, setBiospySampleStatus] = useState<any>("");

  const [personName, setPersonName] = useState<any>("main");

  const [openHistoryForm, SetOpenHistoryForm] = useState<boolean>(false);

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (bookingId){ getBookingByHistoryForm(bookingId);
      getBookingById(bookingId)}
   
  }, [bookingId]);

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (bookinghistoryform) {
      setDataId(bookinghistoryform[0]?.id);
      setName(bookinghistoryform[0]?.patient_name);
      setContactNumber(bookinghistoryform[0]?.contact_number);
      setSizeOfBiopsy(bookinghistoryform[0]?.biopsy_size);
      setDateOfProcedure(bookinghistoryform[0]?.procedure_date);
      setRefDoctorName(bookinghistoryform[0]?.ref_doctor_name);
      setRefDoctorNumber(bookinghistoryform[0]?.ref_doctor_phone);
      setCentreName(bookinghistoryform[0]?.center_lab_name);
      setRelevantClinicalHistory(
        bookinghistoryform[0]?.relevant_clinical_history
      );
      setClinicalDiagnosis(bookinghistoryform[0]?.clinical_diagnosis);
      setRadiologyFindings(bookinghistoryform[0]?.radiology_findings);
      setOperativeFindings(bookinghistoryform[0]?.operative_findings);
      setBiospypackage(bookinghistoryform[0]?.type_of_specimen);
      setBiospySamples(bookinghistoryform[0]?.samples_acceptable);
      setBiospySampleStatus(bookinghistoryform[0]?.site_specification)
    }
  }, [bookinghistoryform]);


  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="contained" color="secondary" style={{width:250}}>Print HISTOPATHOLOGY FORM</Button>;
  }, []);

  const createHistopathologyBookingModel = () => {
    SetOpenHistoryForm(true);
  };
  return (
    <>
      <div  style={{
          width: 270,
        }}>
        <div style={{ height: "20px", marginTop: "35%" }}>
          <div className={classes.rightContentHeader}>
            <Select
              className="input"
              name="priority"
              variant="outlined"
              style={{
                width: "250px",
                marginBottom: "1rem",
                alignItems: "flex-start",
              }}
              value={personName}
              onChange={(e) => setPersonName(e.target.value)}
            >
              {/* <MenuItem disabled selected value={'none'}>Customer Name</MenuItem> */}
              <MenuItem value={"main"}>{booking?.customer_name}</MenuItem>
              {booking?.additional_members &&
                booking?.additional_members.length > 0 &&
                booking?.additional_members.map((data: any) => {
                  return (
                    <MenuItem value={data?.id}>{data?.customer_name}</MenuItem>
                  );
                })}
            </Select>
            <br/>
            <ReactToPrint
              content={reactToPrintContent}
              trigger={reactToPrintTrigger}
              documentTitle={name+"-"+bookingId}
            />
            
              <Button
                variant="contained"
                color="primary"
                onClick={createHistopathologyBookingModel}
                style={{width:250,marginTop:15}}
              >
               EDIT HISTOPATHOLOGY FORM
              </Button>
        
          </div>
        </div>
      </div>
      <div
        style={{
          width: 842,
          background: "#f1f1f1",
          margin: "50px auto",
        }}
      >
        <div ref={componentRef} style={{ padding: 15 }}>
          <div
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <div style={{ width: "50%", textAlign: "left" }}>
              <h3>
                <img src={image} alt="logo" style={{ width: "150px" }} />
              </h3>
            </div>
            <div style={{ width: "50%", textAlign: "right" }}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                HISTOPATHOLOGY HISTORY FORM
              </div>
            </div>
          </div>
          <hr />
          <div style={{ width: "100%", display: "flex", margin: "20px 0px" }}>
            <div style={{ width: "20%" }}>Name of the Patient:</div>
            <input
              value={name}
              style={{ width: "30%", height: "30px", marginLeft: 5 }}
            />

            <div style={{ width: "20%", marginLeft: 8 }}>Contact No:</div>
            <input
              value={contactNumber}
              type="number"
              style={{ width: "30%", height: "30px", marginLeft: 3 }}
            />
          </div>

          <div style={{ width: "100%", display: "flex", margin: "20px 0px" }}>
            <div style={{ width: "20%" }}>Size of Biopsy:</div>
            <input
              type="number"
              value={sizeofbiopsy}
              style={{ width: "30%", height: "30px", marginLeft: 5 }}
            />

            <div style={{ width: "20%", marginLeft: 8 }}>
              Date of Procedure:
            </div>

            <input
              type="date"
              value={dateOfProcedure}
              style={{ width: "30%", height: "30px", marginLeft: 3 }}
            />
          </div>
          <div style={{ width: "100%", display: "flex", margin: "20px 0px" }}>
            <div style={{ width: "20%" }}>Ref Doctor Name:</div>
            <input
              type="text"
              value={refDoctorName}
              style={{ width: "30%", height: "30px", marginLeft: 5 }}
            />

            <div style={{ width: "20%", marginLeft: 8 }}>
            Ref Doctor Mobile No.:
            </div>

            <input
              type="text"
              value={refDoctorNumber}
              style={{ width: "30%", height: "30px", marginLeft: 3 }}
            />
          </div>
          
          <div style={{ width: "100%", display: "flex", margin: "20px 0px" }}>
            <div style={{ width: "20%" }}>Name of Collection Center/Lab:</div>
            <input
              type="text"
              value={centreName}
              style={{ width: "80%", height: "30px", marginLeft: 3 }}
            />
          </div>
          <div style={{ width: "100%", display: "flex", margin: "20px 0px" }}>
            <div style={{ width: "20%", marginLeft: 5 }}>
              Relevant Clinical History:
            </div>
            <TextareaAutosize
              aria-label="Relevant Clinical History"
              minRows={3}
              value={relevantClinicalHistory}
              onChange={(e) =>
                setRelevantClinicalHistory(e.target.value as string)
              }
              placeholder="Relevant Clinical History.."
              style={{ width: "100%", height: "70px", marginLeft: 25 }}
            />
          </div>
          <div style={{ width: "100%", display: "flex", margin: "20px 0px" }}>
            <div style={{ width: "20%", marginLeft: 5 }}>
              Clinical Diagnosis:
            </div>
            <TextareaAutosize
              aria-label="Clinical Diagnosis"
              minRows={3}
              value={clinicalDiagnosis}
              onChange={(e) => setClinicalDiagnosis(e.target.value as string)}
              placeholder="Clinical Diagnosis.."
              style={{ width: "100%", height: "70px", marginLeft: 25 }}
            />
          </div>
          <div style={{ width: "100%", display: "flex", margin: "20px 0px" }}>
            <div style={{ width: "20%", marginLeft: 5 }}>
              Radiology Findings if any:
            </div>
            <TextareaAutosize
              aria-label="Radiology Findings"
              minRows={3}
              value={radiologyFindings}
              onChange={(e) => setRadiologyFindings(e.target.value as string)}
              placeholder="Radiology Findings if any.."
              style={{ width: "100%", height: "70px", marginLeft: 25 }}
            />
          </div>
          <div style={{ width: "100%", display: "flex", margin: "20px 0px" }}>
            <div style={{ width: "20%", marginLeft: 5 }}>
              Operative Findings if any:
            </div>
            <TextareaAutosize
              aria-label="Operative Findings"
              minRows={3}
              value={operativeFindings}
              onChange={(e) => setOperativeFindings(e.target.value as string)}
              placeholder="Operative Findings if any.."
              style={{ width: "100%", height: "70px", marginLeft: 25 }}
            />
          </div>

          <div style={{ width: "100%", display: "flex", margin: "20px 0px" }}>
            <div style={{ width: "20%" }}>Type of Specimen:</div>
            <input
              type="text"
              value={biospypackage}
              style={{ width: "80%", height: "30px", marginLeft: 5 }}
            />

           
          </div>
         
          <div style={{ width: "100%", display: "flex", margin: "20px 0px" }}>
          <div style={{ width: "20%" }}>
            Samples Acceptable
            </div>

            <input
              type="text"
              value={biospySamples}
              style={{ width: "30%", height: "30px", marginLeft: 5 }}
            />
            <div style={{ width: "20%" , marginLeft: 8}}>Site Specification:</div>
            <input
              type="text"
              value={biospySampleStatus}
              style={{ width: "30%", height: "30px", marginLeft: 3 }}
            />
          </div>
          <div className={classes.buttonContainer}></div>
          <div>
            <div style={{ fontWeight: "bold", color: "blue" }}></div>
            <div style={{ padding: 10, width: "100%", fontWeight: "bold" }}>
              <p>
                Note: &nbsp;&nbsp;Please furnish complete clinical History
                Immerse Specimen completely in 10% Formalin
              </p>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              background: "#AD70A6",
              color: "#fff",
              textAlign: "center",
              padding: 10,
            }}
          >
            <h3>Redcliffe Life Tech Pvt.Ltd.</h3>
            <h6>
              H-55, Sector-63, Electronic City Noida, Noida-201301 (U.P) INDIA,
              Phone: 898 898 1777
              <br /> www.redcliffelabs.com
            </h6>
          </div>
        </div>
      </div>
      <Dialog
        open={openHistoryForm}
        onClose={() => SetOpenHistoryForm(false)}
        fullWidth
        maxWidth="md"
      >
        <HistoryForm
          dataId={dataId}
          SetOpenHistoryForm={SetOpenHistoryForm}
          customerName={name}
          setSizeOfBiopsy={setSizeOfBiopsy}
          sizeofbiopsy={sizeofbiopsy}
          radiologyFindings={radiologyFindings}
          setRadiologyFindings={setRadiologyFindings}
          setDateOfProcedure={setDateOfProcedure}
          dateOfProcedure={dateOfProcedure}
          operativeFindings={operativeFindings}
          setOperativeFindings={setOperativeFindings}
          setClinicalDiagnosis={setClinicalDiagnosis}
          clinicalDiagnosis={clinicalDiagnosis}
          setRelevantClinicalHistory={setRelevantClinicalHistory}
          relevantClinicalHistory={relevantClinicalHistory}
          centerName={centreName}
          biospyPackageCode={biospyPackageCode}
          setBiospyPackageCode={setBiospyPackageCode}
          customer_phonenumber={contactNumber}
          setBiospyTrueOrFalse={setBiospySampleStatus}
          biospyTrueOrFalse={biospySampleStatus}
          setBiospySampleName={setBiospySamples}
          biospySampleName={biospySamples}
          setBiospyPackageName={setBiospypackage}
          biospyPackageName={biospypackage}
          relevantClinicalHistoryform={relevantClinicalHistoryform}
          setRelevantClinicalHistoryForm={setRelevantClinicalHistoryForm} 
          clinicalDiagnosisform={clinicalDiagnosisform}
          setClinicalDiagnosisform={setClinicalDiagnosisform}
          radiologyFindingsform={radiologyFindingsform}
          setRadiologyFindingsform={setRadiologyFindingsform}
          operativeFindingsform={operativeFindingsform}
          setOperativeFindingsform={setOperativeFindingsform}
          />
      </Dialog>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  booking: state.LimsAdminReducer.booking,
  loading: state.LimsAdminReducer.loading,
  bookinghistoryform: state.LimsAdminReducer.bookinghistoryform,
});

export default connect(mapStateToProps, {
  getBookingByHistoryForm,
  getBookingById
})(HistoFormView);
