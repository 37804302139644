import React, { useEffect, useState } from "react";
import { MenuItem, Select, Button } from "@material-ui/core";
import {
  getBiospypackages,
  updateHistopathologyForm,
} from "../actions/LimsAdminActions";
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from "@material-ui/core";
import { connect } from "react-redux";
import TextareaAutosize from "@mui/material/TextareaAutosize";
interface Props {
  SetOpenHistoryForm: any;
  getBiospypackages: any;
  biospypackages: any;
  loading: boolean;
  setSizeOfBiopsy: any;
  sizeofbiopsy: any;
  radiologyFindings: any;
  setRadiologyFindings: any;
  setDateOfProcedure: any;
  dateOfProcedure: any;
  setOperativeFindings: any;
  operativeFindings: any;
  setClinicalDiagnosis: any;
  clinicalDiagnosis: any;
  relevantClinicalHistory: any;
  setRelevantClinicalHistory: any;
  centerName: any;
  customer_phonenumber: any;
  customerName: any;
  setBiospyTrueOrFalse: any;
  biospyTrueOrFalse: any;
  setBiospySampleName: any;
  biospySampleName: any;
  setBiospyPackageName: any;
  setBiospyPackageCode:any;
  biospyPackageName: any;
  biospyPackageCode: any;
  dataId: any;
  updateHistopathologyForm: any;
  relevantClinicalHistoryform: any;
  setRelevantClinicalHistoryForm: any;
  clinicalDiagnosisform: any;
  setClinicalDiagnosisform: any;
  radiologyFindingsform: any;
  setRadiologyFindingsform: any;
  operativeFindingsform: any;
  setOperativeFindingsform: any;
}
const LimsAdminViewBooking: React.FC<Props> = ({
  getBiospypackages,
  biospypackages,
  loading,
  SetOpenHistoryForm,
  setSizeOfBiopsy,
  sizeofbiopsy,
  radiologyFindings,
  setRadiologyFindings,
  setDateOfProcedure,
  dateOfProcedure,
  setOperativeFindings,
  operativeFindings,
  setClinicalDiagnosis,
  clinicalDiagnosis,
  relevantClinicalHistory,
  setRelevantClinicalHistory,
  centerName,
  customer_phonenumber,
  customerName,
  setBiospyTrueOrFalse,
  biospyTrueOrFalse,
  setBiospySampleName,
  biospySampleName,
  setBiospyPackageName,
  setBiospyPackageCode,
  biospyPackageName,
  biospyPackageCode,
  dataId,
  updateHistopathologyForm,
  relevantClinicalHistoryform,
  setRelevantClinicalHistoryForm,
  clinicalDiagnosisform,
  setClinicalDiagnosisform,
  radiologyFindingsform,
  setRadiologyFindingsform,
  operativeFindingsform,
  setOperativeFindingsform
}) => {
  const [biospySamples, setBiospySamples] = useState<any>([]);
  const [biospySampleStatus, setBiospySampleStatus] = useState<boolean | null>(
    null
  );
  // const [code, setCode] = useState<any>("");
  const [file1, setFile1] = React.useState<any>("");
  const [file64, setFile64] = React.useState<any>([]);
  const [fileUpload, setFileUpload] = React.useState<any>([]);

  // const toBase64 = (file: any) =>

  //     new Promise((resolve, reject) => {
  //         const reader: any = new FileReader();
  //         if (file) {
  //             reader.readAsDataURL(file);
  //         }

  //         reader.onload = () => resolve(reader.result);
  //         reader.onerror = (error: any) => reject(error);
  //     });

  const fileSelectedHandler1 = async (e: React.ChangeEvent<{ files: any }>) => {
    const base64 = await convertBase64(e.target.files[0]);
    setFile1(base64);
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (biospyPackageCode) {
      getBiospypackages(biospyPackageCode);
    }
  }, [biospyPackageCode]);
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (biospyPackageCode&& biospypackages && biospypackages.acceptableSamples && biospypackages.acceptableSamples.length > 0) {
      
          setBiospySamples(biospypackages.acceptableSamples);
        
      
    }
  }, [ biospypackages]);
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (biospySamples && biospySamples.length > 0 && dataId) {
      biospySamples.map((val: any) => {
        if (val.name === biospySampleName) {
          setBiospySampleStatus(val.status);
        }
      });
    }
  }, [biospySamples, dataId]);
  const proceedTo = async (e: any) => {
    if (dataId) {
      const data = {
        biopsy_size: sizeofbiopsy,
        procedure_date: dateOfProcedure ? dateOfProcedure : null,
        center_lab_name: centerName,
        relevant_clinical_history: relevantClinicalHistory,
        clinical_diagnosis: clinicalDiagnosis,
        radiology_findings: radiologyFindings,
        operative_findings: operativeFindings,
        type_of_specimen: biospyPackageName&biospyPackageCode,
        samples_acceptable: biospySampleName,
        site_specification: biospyTrueOrFalse,
        clinical_diagnosis_form: clinicalDiagnosisform,
        radiology_findings_form: radiologyFindingsform,
        operative_findings_form: operativeFindingsform,
        relevant_clinical_history_form: relevantClinicalHistoryform,
      };
      await updateHistopathologyForm(dataId, data);
      SetOpenHistoryForm(false);
    } else {
      SetOpenHistoryForm(false);
    }
  };

  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //       const list: any = file64;
  //       if (fileUpload.length > 0) {

  //           for (let i = 0; i <= fileUpload.length; i++) {
  //               toBase64(fileUpload[i]).then((data: any) => {
  //                   let extention="."+String(data.split(';')[0].split('/')[1])||"";
  //                   let b64=String(data.split(",")[1])
  //                   list.push({"base64":b64,"ext":extention});
  //               });
  //           }

  //       }
  //       setFile64(list);
  //   }, [fileUpload]);

  return (
    <div
      style={{
        background: "#f1f1f1",
        padding: "20px",
      }}
    >
      <div>
        <div
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <div style={{ width: "100%", textAlign: "left" }}>
            <h3>HISTOPATHOLOGY HISTORY FORM</h3>
          </div>
        </div>
        <div style={{ width: "100%", display: "flex", margin: "20px 0px" }}>
          {/* {inputList.map((x: any, index: number) => { */}
          <div style={{ width: "20%" }}>Name of the Patient:</div>

          <TextField
            variant="outlined"
            size="small"
            value={customerName}
            style={{ width: "30%", height: "30px", marginLeft: 5 }}
          />

          <div style={{ width: "20%", marginLeft: 8 }}>Contact No:</div>
          <TextField
            variant="outlined"
            size="small"
            value={customer_phonenumber}
            type="number"
            aria-readonly
            style={{ width: "30%", height: "30px", marginLeft: 3 }}
          />
        </div>

        <div style={{ width: "100%", display: "flex", margin: "20px 0px" }}>
          <div style={{ width: "20%" }}>Size of Biopsy:</div>
          <TextField
            variant="outlined"
            size="small"
            type="number"
            value={sizeofbiopsy}
            onChange={(e) => setSizeOfBiopsy(e.target.value as string)}
            style={{ width: "30%", height: "30px", marginLeft: 5 }}
            // value={
            //   hivpackageList &&
            //   hivpackageList?.length > 0 &&
            //   hivpackageList[0]?.age
            // }
          />

          <div style={{ width: "20%", marginLeft: 8 }}>Date of Procedure:</div>

          <TextField
            variant="outlined"
            size="small"
            type="date"
            value={dateOfProcedure}
            onChange={(e) => setDateOfProcedure(e.target.value as string)}
            style={{ width: "30%", height: "30px", marginLeft: 3 }}
            // value={
            //   hivpackageList &&
            //   hivpackageList?.length > 0 &&
            //   hivpackageList[0]?.sex
            // }
          />
        </div>

        <div style={{ width: "100%", display: "flex", margin: "20px 0px" }}>
          <div style={{ width: "20%" }}>Name of Collection Center</div>
          <TextField
            size="small"
            value={centerName}
            aria-readonly
            variant="outlined"
            style={{ width: "30%", height: "30px", marginLeft: 5 }}
          />
        </div>
        <div style={{ width: "100%", display: "flex", margin: "20px 0px" }}>
          <div style={{ width: "20%", marginLeft: 5 }}>
            Relevant Clinical History:<sup>*</sup>
          </div>
          <TextareaAutosize
            aria-label="Relevant Clinical History"
            minRows={3}
            value={relevantClinicalHistory}
            onChange={(e) =>
              setRelevantClinicalHistory(e.target.value as string)
            }
            placeholder="Relevant Clinical History.."
            style={{ width: "50%", height: "70px", marginLeft: 25 }}
          />
          <div>
            <div style={{ width: "20%", marginLeft: 5 }}>
              <sup
                style={{
                  fontSize: "11px",
                  marginLeft: "15px",
                  // marginTop: "15px",
                  background: "#fff",
                  color: "rgba(0, 0, 0, 0.54)",
                  letterSpacing: "0.00938em",
                }}
              >
                Upload Receipt<sup>*</sup>
              </sup>
              <input
                className="input"
                style={{
                  width: "300%",
                  marginTop: "-15px",
                  padding: "6px 14px",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  borderRadius: "5px",
                }}
                type="file"
                name="file"
                id="file"
                multiple
                required
                onChange={fileSelectedHandler1}
              />
            </div>
          </div>
          {/* <img style={{ width: "150px", height: "auto" }} src={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.adhaar_card} /> */}
        </div>
        <div style={{ width: "100%", display: "flex", margin: "20px 0px" }}>
          <div style={{ width: "20%", marginLeft: 5 }}>Clinical Diagnosis:</div>
          <TextareaAutosize
            aria-label="Clinical Diagnosis"
            minRows={3}
            value={clinicalDiagnosis}
            onChange={(e) => setClinicalDiagnosis(e.target.value as string)}
            placeholder="Clinical Diagnosis.."
            style={{ width: "50%", height: "70px", marginLeft: 25 }}
          />
           <div>
            <div style={{ width: "20%", marginLeft: 5 }}>
              <sup
                style={{
                  fontSize: "11px",
                  marginLeft: "15px",
                  // marginTop: "15px",
                  background: "#fff",
                  color: "rgba(0, 0, 0, 0.54)",
                  letterSpacing: "0.00938em",
                }}
              >
                Upload Receipt<sup>*</sup>
              </sup>
              <input
                className="input"
                style={{
                  width: "300%",
                  marginTop: "-15px",
                  padding: "6px 14px",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  borderRadius: "5px",
                }}
                type="file"
                name="file"
                id="file"
                multiple
                required
                onChange={fileSelectedHandler1}
              />
            </div>
          </div>
          {/* <img style={{ width: "150px", height: "auto" }} src={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.adhaar_card} /> */}
        </div>
        <div style={{ width: "100%", display: "flex", margin: "20px 0px" }}>
          <div style={{ width: "20%", marginLeft: 5 }}>
            Radiology Findings if any:
          </div>
          <TextareaAutosize
            aria-label="Radiology Findings"
            minRows={3}
            value={radiologyFindings}
            onChange={(e) => setRadiologyFindings(e.target.value as string)}
            placeholder="Radiology Findings if any.."
            style={{ width: "50%", height: "70px", marginLeft: 25 }}
          />
           <div>
            <div style={{ width: "20%", marginLeft: 5 }}>
              <sup
                style={{
                  fontSize: "11px",
                  marginLeft: "15px",
                  // marginTop: "15px",
                  background: "#fff",
                  color: "rgba(0, 0, 0, 0.54)",
                  letterSpacing: "0.00938em",
                }}
              >
                Upload Receipt<sup>*</sup>
              </sup>
              <input
                className="input"
                style={{
                  width: "300%",
                  marginTop: "-15px",
                  padding: "6px 14px",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  borderRadius: "5px",
                }}
                type="file"
                name="file"
                id="file"
                multiple
                required
                onChange={fileSelectedHandler1}
              />
            </div>
          </div>
          {/* <img style={{ width: "150px", height: "auto" }} src={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.adhaar_card} /> */}
        </div>
        <div style={{ width: "100%", display: "flex", margin: "20px 0px" }}>
          <div style={{ width: "20%", marginLeft: 5 }}>
            Operative Findings if any:
          </div>
          <TextareaAutosize
            aria-label="Operative Findings"
            minRows={3}
            value={operativeFindings}
            onChange={(e) => setOperativeFindings(e.target.value as string)}
            placeholder="Operative Findings if any.."
            style={{ width: "50%", height: "70px", marginLeft: 25 }}
          />
           <div>
            <div style={{ width: "20%", marginLeft: 5 }}>
              <sup
                style={{
                  fontSize: "11px",
                  marginLeft: "15px",
                  // marginTop: "15px",
                  background: "#fff",
                  color: "rgba(0, 0, 0, 0.54)",
                  letterSpacing: "0.00938em",
                }}
              >
                Upload Receipt<sup>*</sup>
              </sup>
              <input
                className="input"
                style={{
                  width: "300%",
                  marginTop: "-15px",
                  padding: "6px 14px",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  borderRadius: "5px",
                }}
                type="file"
                name="file"
                id="file"
                multiple
                required
                onChange={fileSelectedHandler1}
              />
            </div>
          </div>
          {/* <img style={{ width: "150px", height: "auto" }} src={hivpackageList && hivpackageList?.length > 0 && hivpackageList[0]?.adhaar_card} /> */}
        </div>

        <div style={{ width: "100%", display: "flex", margin: "20px 0px" }}>
          <div style={{ width: "20%" }}>
            Type of Specimen:<sup>*</sup>
          </div>

          <Autocomplete
            style={{ width: "30%", marginLeft: 5 }}
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setBiospySamples(obj?.acceptableSamples);
                setBiospyPackageName(obj?.name);
              }
            }}
            freeSolo
            blurOnSelect
            defaultValue={{ name: biospyPackageName,code:biospyPackageCode }}
            options={biospypackages || []}
            loading={loading}
            disableCloseOnSelect
            disableClearable
            getOptionLabel={(option: any) => {return (
             option?.name +
             option?.code
              );
            }}
            disabled
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Test Name"
                variant="outlined"
                style={{
                  height: "30px",
                  marginLeft: 3,
                 
                }}
              />
            )}
          />
          <div style={{ width: "20%", marginLeft: 8 }}>
            Samples Acceptable:<sup>*</sup>
          </div>
          <Autocomplete
            style={{ width: "30%", marginLeft: 3 }}
            disabled={biospySamples?.length === 0}
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setBiospySampleStatus(obj.status);
                setBiospySampleName(obj.name);
              }
            }}
            freeSolo
            blurOnSelect
            options={biospySamples || []}
            loading={loading}
            defaultValue={{ name: biospySampleName }}
            disableCloseOnSelect
            disableClearable
            getOptionLabel={(option: any) => option?.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Samples Acceptable"
                variant="outlined"
                style={{
                  height: "30px",
                  marginLeft: 3,
                }}
              />
            )}
          />
        </div>
        <div style={{ width: "100%", display: "flex", margin: "20px 0px" }}>
          <div style={{ width: "20%" }}>Site Specification:</div>

          <Select
            style={{ height: "40px", width: "30%", marginLeft: 5 }}
            disabled={biospySampleStatus !== true}
            // required={biospySampleStatus === true}
            fullWidth
            id="category"
            label="Site Specification"
            variant="outlined"
            value={biospyTrueOrFalse}
            onChange={(e) => setBiospyTrueOrFalse(e.target.value)}
          >
            <MenuItem disabled value={""}>
              Site Specification
            </MenuItem>
            <MenuItem value={"Left"}>Left</MenuItem>
            <MenuItem value={"Right"}>Right</MenuItem>
          </Select>
        </div>
        <div>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{ marginLeft: "1rem", marginBottom: "20px" }}
            onClick={proceedTo}
            disabled={
              biospyPackageName === "" ||
              biospySampleName === "" ||
              (biospySampleStatus === true && biospyTrueOrFalse === "") ||
              relevantClinicalHistory === ""
            }
          >
            {dataId ? "Update Form" : "Proceed For Booking"}
          </Button>
        </div>
        <div>
          <div style={{ fontWeight: "bold", color: "blue" }}></div>
          <div style={{ padding: 10, width: "100%", fontWeight: "bold" }}>
            <p>
              Note: &nbsp;&nbsp;Please furnish complete clinical History Immerse
              Specimen completely in 10% Formalin
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  loading: state.LimsAdminReducer.loading,
  biospypackages: state.LimsAdminReducer.biospypackages,
});

export default connect(mapStateToProps, {
  getBiospypackages,
  updateHistopathologyForm,
})(LimsAdminViewBooking);
