import React, { useEffect, useState, useRef } from "react";
import logo from "../../../images/logo1.png";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { getBookingById } from "../../actions/LimsAdminActions";
import ReactToPrint from "react-to-print";

interface PropsCR {
  booking: any;
  getBookingById: any;
}

const CashRecipt: React.FC<PropsCR> = ({ booking, getBookingById }) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<any>({
    bId: "",
    iDate: "",
    cDate: "",
    cName: "",
    cAge: "",
    cAgeDob: "",
    cAddr: "",
    packages: [],
    totalPrice: "",
    totalDiscount: "",
    centre: "",
    centreAddr: "",
    payment: [],
  });
  const [paid, setPaid] = useState<any>("");
  const price_in_words = (price: any) => {
    let sglDigit = [
        "Zero",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ],
      dblDigit = [
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ],
      tensPlace = [
        "",
        "Ten",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ],
      handle_tens = function (dgt: number, prevDgt: number) {
        return 0===dgt
          ? ""
          : " " + (1===dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
      },
      handle_utlc = function (dgt: number, nxtDgt: number, denom: string) {
        return (
          (0 !== dgt && 1 !== nxtDgt ? " " + sglDigit[dgt] : "") +
          (0 !== nxtDgt || dgt > 0 ? " " + denom : "")
        );
      };

    let str = "";
    let digitIdx: number = 0;
    let digit: number;
    let nxtDigit: number = 0;
    let words = [];
    if (((price += ""), isNaN(parseInt(price)))) str = "";
    else if (parseInt(price) > 0 && price.length <= 10) {
      for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
        switch (
          ((digit = price[digitIdx] - 0),
          (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0),
          price.length - digitIdx - 1)
        ) {
          case 0:
            words.push(handle_utlc(digit, nxtDigit, ""));
            break;
          case 1:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 2:
            words.push(
              0 !== digit
                ? " " +
                    sglDigit[digit] +
                    " Hundred" +
                    (0 !== price[digitIdx + 1] && 0 !== price[digitIdx + 2]
                      ? " and"
                      : "")
                : ""
            );
            break;
          case 3:
            words.push(handle_utlc(digit, nxtDigit, "Thousand"));
            break;
          case 4:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 5:
            words.push(handle_utlc(digit, nxtDigit, "Lakh"));
            break;
          case 6:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 7:
            words.push(handle_utlc(digit, nxtDigit, "Crore"));
            break;
          case 8:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 9:
            words.push(
              0 !== digit
                ? " " +
                    sglDigit[digit] +
                    " Hundred" +
                    (0 !== price[digitIdx + 1] || 0 !== price[digitIdx + 2]
                      ? " and"
                      : " Crore")
                : ""
            );
        }
      str = words.reverse().join("");
    } else str = "";
    return str;
  };
  const addPrice = (data: any) => {
    let add: number = 0;
    data.length > 0 &&
      data.map((val: any) => {
        if (val.link_type !== "link") {
          add = add + val.amount;
          setPaid(add);
        }
      });
  };
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getBookingById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (Object.keys(booking).length !== 0) {
      setData({
        bId: booking?.id === undefined ? booking?.pk : booking?.id,
        iDate: moment(booking?.created_at).format("DD MMM,YYYY"),

        cAge: booking?.customer_age,
        cAgeDob: booking?.dob,
        cName: booking?.designation + " " + booking?.customer_name,
        cAddr:
          (booking?.customer_areapincode?.area === undefined
            ? " "
            : booking?.customer_areapincode?.area + ",") +
          (booking?.customer_areapincode?.city_name === undefined
            ? " "
            : booking?.customer_areapincode?.city_name + ", ") +
          (booking?.customer_areapincode?.pincode === undefined
            ? " "
            : "Pin-Code:" + booking?.customer_areapincode?.pincode),
        packages: booking?.packages,
        centre: booking?.center?.name,
        centreAddr:
          booking?.centre_address !== undefined
            ? booking?.centre_address?.address
            : "",
        totalPrice: booking?.final_packages_price,
        totalDiscount: booking?.final_discount,
        payment: booking?.payment_links.length>0?booking?.payment_links:[{
         
          "payment_mode": booking?.payment_mode,
          "created_at": booking?.pickup_date,
          "amount": booking?.final_packages_price,
         
      }],
      });
      addPrice(booking?.payment_links);
    }
  }, [booking]);

  const componentRef = useRef<any>(null);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const reactToPrintTrigger = React.useCallback(() => {
    return <button>Print Receipt</button>;
  }, []);
  const handleBack = () => {
    localStorage.removeItem("addonPrice");
    navigate(-1);
  };

  const handleDOBForAge = (dob: any, bdate: any) => {
    const a: any = moment(bdate);
    const b: any = moment(dob);
    var years = a.diff(b, "year");
    b.add(years, "years");
    var months = a.diff(b, "months");
    b.add(months, "months");
    var days = a.diff(b, "days");
    var age;
    if (months !== 0) {
       age = months + "Months " + days + "Days";
    } else {
       age = days + "Days";
    }

    return age;
  };
  return (
    <div
      style={{
        margin: "50px auto",
      }}
    >
      <button
        style={{
          width: "120px",
          borderRadius: "5%",
          backgroundColor: "#ef5350",
          color: "white",
        }}
        onClick={handleBack}
      >
        {"<< Go Back"}
      </button>
      <ReactToPrint
        content={reactToPrintContent}
        trigger={reactToPrintTrigger}
      />
      <div
        style={{
          width: "842px",
          border: "1px solid grey",
          margin: 20,
        }}
      >
        <div
          ref={componentRef}
          style={{
            margin: 20,
          }}
        >
          <div>
            <meta charSet="UTF-8" style={{ fontFamily: "sans-serif" }} />
            <meta
              name="viewport"
              // content="width=device-width, initial-scale=1.0"
              style={{ fontFamily: "sans-serif" }}
            />
            <title style={{ fontFamily: "sans-serif" }}>Invoice</title>
            <div
              className="invoiceContainer"
              style={{
                fontFamily: "sans-serif",
                marginInline: "5vw",
                marginTop: 0,
              }}
            >
              <div id="divToPrint" style={{ fontFamily: "sans-serif" }}>
                <hr
                  className="headerLine"
                  style={{
                    border: "5px solid #47cacc",
                    fontFamily: "sans-serif",
                    width: "100%",
                  }}
                />
                <div
                  className="logoContainer"
                  style={{
                    display: "flex",
                    padding: "1rem",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <img
                      className="invoiceImage"
                      src={logo}
                      alt="description of image"
                      style={{ fontFamily: "sans-serif", height: "70px" }}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <span
                      className="invoiceHeading"
                      style={{
                        float: "right",
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      <strong style={{ fontFamily: "sans-serif" }}>
                        {data?.centre}
                      </strong>
                      <br />
                      CIN No. : U85100UP2021PTC140992
                      <br />
                      Address : {data?.centreAddr}
                    </span>
                  </div>
                </div>
                <hr style={{ fontFamily: "sans-serif" }} />
                <div
                  className="invoiceGrid"
                  style={{ marginTop: "-8px", display: "flex" }}
                >
                  <div style={{ width: "50%" }}>
                    <span style={{ display: "flex" }}>
                      <span style={{ width: 100 }}>Patient Name:</span>
                      <b>{data?.cName}</b>
                    </span>

                    <span style={{ display: "flex" }}>
                      <span style={{ width: 100 }}>Booking Id:</span>
                      <b>{data?.bId}</b>{" "}
                    </span>

                    {/* <span style={{ display: "flex" }}>
                      <span style={{ width: 100 }}>Address:</span> {data?.cAddr}
                    </span> */}
                  </div>
                  <div style={{ width: "50%", marginLeft: "15%" }}>
                    <span style={{ display: "flex" }}>
                      <span style={{ width: 100 }}>Patient Age:</span>
                      <b>
                        {data?.cAge === 0
                          ? handleDOBForAge(data?.cAgeDob, data?.iDate)
                          : data?.cAge}
                      </b>
                    </span>

                    <span style={{ display: "flex" }}>
                      <span style={{ width: 100 }}>Bill Date:</span>{" "}
                      {data?.iDate}
                    </span>

                    <span style={{ display: "flex" }}>
                      <span style={{ width: 100 }}>Invoice No.:</span>{" "}
                      {data?.bId}
                    </span>
                  </div>
                </div>
                <hr style={{ clear: "left", fontFamily: "sans-serif" }} />

                <div style={{ fontFamily: "sans-serif" }}>
                  <div
                    className="invoiceTestDescHead"
                    style={{
                      backgroundColor: "#f4f4f4",
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      fontWeight: 600,

                      padding: "1rem",
                      paddingBottom: "2rem",
                    }}
                  >
                    <div
                      style={{
                        float: "left",
                        fontFamily: "sans-serif",
                        width: "17%",
                      }}
                    >
                      TEST CODE
                    </div>
                    <div
                      style={{
                        float: "left",
                        fontFamily: "sans-serif",

                        width: "70%",
                      }}
                    >
                      TEST NAME
                    </div>
                    <div
                      style={{
                        float: "right",
                        textAlign: "right",
                        fontFamily: "sans-serif",
                        width: "13%",
                      }}
                    >
                      AMOUNT
                    </div>
                  </div>
                  {data?.packages.length > 0 &&
                    data?.packages.map((val: any) => (
                      <div
                        className="billDetailsTests"
                        style={{
                          color: "black !important",
                          fontFamily: "sans-serif",
                          fontSize: "16px",
                          padding: "1rem",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            float: "left",
                            fontFamily: "sans-serif",
                            width: "17%",
                          }}
                        >
                          <strong style={{ fontFamily: "sans-serif" }}>
                            {val?.code}
                          </strong>
                        </div>
                        <div
                          style={{
                            float: "left",
                            fontFamily: "sans-serif",
                            width: "70%",
                          }}
                        >
                          <strong style={{ fontFamily: "sans-serif" }}>
                            {val?.name}
                          </strong>
                        </div>
                        <div
                          style={{
                            float: "right",
                            textAlign: "right",
                            fontFamily: "sans-serif",
                            width: "13%",
                          }}
                        >
                          <strong style={{ fontFamily: "sans-serif" }}>
                            ₹{val?.price}
                          </strong>
                        </div>
                      </div>
                    ))}

                  <div
                    className="invoiceTestDescTotal"
                    style={{
                      backgroundColor: "#fbfbfb",
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      marginTop: "1rem",
                      padding: "1rem",
                      paddingBottom: "1rem",
                    }}
                  >
                    <div style={{ float: "left", fontFamily: "sans-serif" }}>
                      Total Amount
                    </div>
                    <div style={{ float: "right", fontFamily: "sans-serif" }}>
                      ₹{data?.totalPrice + data?.totalDiscount}
                    </div>
                  </div>
                  <div
                    className="invoiceTestDescTotal"
                    style={{
                      backgroundColor: "#fbfbfb",
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      padding: "1rem",
                      paddingBottom: "1rem",
                    }}
                  >
                    <div style={{ float: "left", fontFamily: "sans-serif" }}>
                      Discount
                    </div>
                    <div style={{ float: "right", fontFamily: "sans-serif" }}>
                      ₹{data?.totalDiscount}
                    </div>
                  </div>
                  <hr style={{ fontFamily: "sans-serif" }} />
                  <div
                    className="invoiceTestDescTotal1"
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      fontWeight: 600,
                      padding: "0 1rem 1rem 1rem",
                      paddingBottom: "1.3rem",
                    }}
                  >
                    <div style={{ float: "left", fontFamily: "sans-serif" }}>
                      Grand Total
                    </div>
                    <div style={{ float: "right", fontFamily: "sans-serif" }}>
                      ₹{data?.totalPrice}
                    </div>
                  </div>
                  <div
                    className="invoiceTestDescTotal2"
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      fontWeight: 600,
                      padding: "1rem",
                    }}
                  >
                    <div style={{ float: "left", fontFamily: "sans-serif" }}>
                      Paid Amount
                    </div>
                    <div style={{ float: "right", fontFamily: "sans-serif" }}>
                      ₹{paid}
                    </div>
                  </div>
                  <div
                    className="invoiceTestDescTotal2"
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      fontWeight: 600,
                      padding: "1rem",
                    }}
                  >
                    <div style={{ float: "left", fontFamily: "sans-serif" }}>
                    {(Number(data?.totalPrice) - Number(paid))<0?"Refundable Amount":"Due Amount"} 
                    </div>
                    <div style={{ float: "right", fontFamily: "sans-serif" }}>
                      ₹{Number(data?.totalPrice) - Number(paid)}
                    </div>
                  </div>
                </div>
                <div
                  className="invoiceSummary"
                  style={{
                    fontFamily: "sans-serif",
                    fontSize: "13px",
                    lineHeight: "1.4",
                    marginTop: "3rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    marginBottom: "11rem",
                  }}
                >
                  <div
                    style={{
                      float: "left",
                      fontFamily: "sans-serif",
                      width: "50%",
                    }}
                  >
                    <strong style={{ fontFamily: "sans-serif" }}>
                      Amount Chargable (in words)
                    </strong>
                    <div style={{ fontFamily: "sans-serif" }}>
                      <i style={{ fontFamily: "sans-serif" }}>
                        Rupees
                        {price_in_words(data?.totalPrice)}
                        Only
                      </i>
                    </div>
                    <div
                      className="blueColor"
                      style={{
                        color: "#11406b",
                        fontFamily: "sans-serif",
                        fontSize: "15px",
                      }}
                    >
                      Company's PAN No.{" "}
                      <span style={{ fontFamily: "sans-serif" }}>
                        AAKCR7631M
                      </span>
                    </div>
                  </div>
                  <div
                    className="paymentSummary"
                    style={{
                      float: "right",
                      fontFamily: "sans-serif",
                      textAlign: "end",
                      width: "50%",
                    }}
                  >
                    <strong style={{ fontFamily: "sans-serif" }}>
                      Payment Mode Summary
                    </strong>
                    <table
                      className="invoiceSummaryTable"
                      style={{
                        borderCollapse: "collapse",
                        fontFamily: "sans-serif",
                        fontSize: "12px",
                        marginTop: "1rem",
                        textAlign: "left",
                        width: "100%",
                      }}
                    >
                      <tbody>
                        <tr
                          style={{
                            backgroundColor: "#f1f1f1",
                            fontFamily: "sans-serif",
                          }}
                        >
                          <td
                            style={{
                              fontFamily: "sans-serif",
                              padding: ".6rem",
                              paddingRight: "1.6rem",
                            }}
                          >
                            Payment Date
                          </td>
                          <td
                            style={{
                              fontFamily: "sans-serif",
                              padding: ".6rem",
                              paddingRight: "1.6rem",
                            }}
                          >
                            Payment Mode
                          </td>
                          <td
                            style={{
                              fontFamily: "sans-serif",
                              padding: ".6rem",
                            }}
                          >
                            Amount
                          </td>
                        </tr>
                        {data?.payment.length > 0 &&
                          data?.payment.map((val: any) => {
                            if (val?.link_type !== "link") {
                              return (
                                <tr
                                  style={{
                                    backgroundColor: "#f9f9f9",
                                    fontFamily: "sans-serif",
                                  }}
                                >
                                  <td
                                    style={{
                                      fontFamily: "sans-serif",
                                      padding: ".6rem",
                                      paddingRight: "1.6rem",
                                    }}
                                  >
                                    {moment(val?.created_at).format(
                                      "DD MMM,YYYY"
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      fontFamily: "sans-serif",
                                      padding: ".6rem",
                                      paddingRight: "1.6rem",
                                    }}
                                  >
                                    {val?.amount<0?"refund":val?.payment_mode}
                                  </td>
                                  <td
                                    style={{
                                      fontFamily: "sans-serif",
                                      padding: ".6rem",
                                    }}
                                  >
                                    ₹{val?.amount}
                                  </td>
                                </tr>
                              );
                            }
                          })}
                      </tbody>
                    </table>
                    <hr style={{ fontFamily: "sans-serif" }} />
                  </div>
                </div>
                <div
                  className="invoiceNotes"
                  style={{
                    clear: "left",
                    backgroundColor: "#fbfbfb",
                    fontFamily: "sans-serif",
                    fontSize: "12px",
                    lineHeight: "1.4",
                    marginBottom: "1rem",
                    marginTop: "1.6rem",
                    padding: ".5rem",
                  }}
                >
                  <strong style={{ fontFamily: "sans-serif" }}>
                    Important Notes:
                  </strong>
                  <ul style={{ fontFamily: "sans-serif" }}>
                    <li style={{ fontFamily: "sans-serif" }}>
                      This invoice can be used for tax exemption under section
                      80-D (Preventive Health Checkup)
                    </li>
                    <li style={{ fontFamily: "sans-serif" }}>
                      Reports sent on registered mail ID according to the
                      respective TAT and can also be downloaded through
                      Redcliffe Labs Application
                    </li>
                    <li style={{ fontFamily: "sans-serif" }}>
                      All dispute / claims concerning to reports are subject to
                      the courts of Delhi only
                    </li>
                    <li style={{ fontFamily: "sans-serif" }}>
                      Redcliffe Labs assumes no liability towards any delays
                      beyond its control
                    </li>
                    <li style={{ fontFamily: "sans-serif" }}>
                      It is recommended that you consult your treating
                      Doctor/Physician before choosing any treatment based on
                      your report
                    </li>
                    <li style={{ fontFamily: "sans-serif" }}>
                      Maximum liability of the Company shall not exceed the
                      amount charged by the Company for the services availed
                    </li>
                    <li style={{ fontFamily: "sans-serif" }}>
                      This is a computer generated receipt and does not require
                      signature/stamp
                    </li>
                  </ul>
                </div>
                <div
                  className="invoiceFooter"
                  style={{
                    backgroundColor: "#47cacc",
                    color: "#0a2d4e",
                    fontFamily: "sans-serif",
                    fontSize: "16px",
                    padding: "1.8rem",
                    paddingBottom: "2.9rem",
                  }}
                >
                  <div
                    className="invoiceFooterItem"
                    style={{
                      float: "left",
                      width: "33.33%",
                      alignItems: "center",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <span style={{ color: "#e2124f", fontWeight: 700 }}>
                      &nbsp;898-898-8787
                    </span>
                  </div>
                  <div
                    className="invoiceFooterItem"
                    style={{
                      float: "left",
                      width: "33.33%",
                      alignItems: "center",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <span style={{ color: "#e2124f", fontWeight: 700 }}>
                      &nbsp;care@redcliffelabs.com
                    </span>
                  </div>
                  <div
                    className="invoiceFooterItem"
                    style={{
                      float: "right",
                      width: "33.33%",
                      alignItems: "center",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <span style={{ color: "#e2124f", fontWeight: 700 }}>
                      &nbsp;www.redcliffelabs.com
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  booking: state.LimsAdminReducer.booking,
});

export default connect(mapStateToProps, {
  getBookingById,
})(CashRecipt);
