import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { getBookingById } from "../../actions/LimsAdminActions";
import ReactToPrint from "react-to-print";
import header from "./shahbazkarHeader.jpg";
import footer from "./shahbazkarFooter.jpg";
interface PropsCR {
  booking: any;
  getBookingById: any;
}

const CashRecipt: React.FC<PropsCR> = ({ booking, getBookingById }) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<any>({
    bId: "",
    iDate: "",
    cDate: "",
    cName: "",
    cAge: "",
    cAgeDob: "",
    cAddr: "",
    packages: [],
    totalPrice: "",
    totalDiscount: "",
    centre: "",
    centreAddr: "",
    payment: [],
  });
  const [paid, setPaid] = useState<any>("");

  const price_in_words = (price: any) => {
    let sglDigit = [
        "Zero",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ],
      dblDigit = [
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ],
      tensPlace = [
        "",
        "Ten",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ],
      handle_tens = function (dgt: number, prevDgt: number) {
        return 0===dgt
          ? ""
          : " " + (1===dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
      },
      handle_utlc = function (dgt: number, nxtDgt: number, denom: string) {
        return (
          (0 !== dgt && 1 !== nxtDgt ? " " + sglDigit[dgt] : "") +
          (0 !== nxtDgt || dgt > 0 ? " " + denom : "")
        );
      };

    let str = "";
    let digitIdx: number = 0;
    let digit: number;
    let nxtDigit: number = 0;
    let words = [];
    if (((price += ""), isNaN(parseInt(price)))) str = "";
    else if (parseInt(price) > 0 && price.length <= 10) {
      for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
        switch (
          ((digit = price[digitIdx] - 0),
          (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0),
          price.length - digitIdx - 1)
        ) {
          case 0:
            words.push(handle_utlc(digit, nxtDigit, ""));
            break;
          case 1:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 2:
            words.push(
              0 !== digit
                ? " " +
                    sglDigit[digit] +
                    " Hundred" +
                    (0 !== price[digitIdx + 1] && 0 !== price[digitIdx + 2]
                      ? " and"
                      : "")
                : ""
            );
            break;
          case 3:
            words.push(handle_utlc(digit, nxtDigit, "Thousand"));
            break;
          case 4:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 5:
            words.push(handle_utlc(digit, nxtDigit, "Lakh"));
            break;
          case 6:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 7:
            words.push(handle_utlc(digit, nxtDigit, "Crore"));
            break;
          case 8:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 9:
            words.push(
              0 !== digit
                ? " " +
                    sglDigit[digit] +
                    " Hundred" +
                    (0 !== price[digitIdx + 1] || 0 !== price[digitIdx + 2]
                      ? " and"
                      : " Crore")
                : ""
            );
        }
      str = words.reverse().join("");
    } else str = "";
    return str;
  };
  const addPrice = (data: any) => {
    let add: number = 0;
    data.length > 0 &&
      data.map((val: any) => {
        if (val.link_type !== "link") {
          add = add + val.amount;
          setPaid(add);
        }
      });
  };
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getBookingById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (Object.keys(booking).length !== 0) {
      setData({
        bId: booking?.id === undefined ? booking?.pk : booking?.id,
        iDate: moment(booking?.created_at).format("DD/MM/YY"),
        iTime: moment(booking?.created_at).format("hh:mm A"),
        cAge: booking?.customer_age,
        cGender: booking?.customer_gender,
        cNumber: booking?.number_for_template,
        cEmail: booking?.customer_email,
        cAgeDob: booking?.dob,
        cRefDr: booking?.ref_doctor?.name ? booking?.ref_doctor?.name : "",
        cName: booking?.designation + " " + booking?.customer_name,
        cAddr:
          (booking?.customer_areapincode?.area === undefined
            ? " "
            : booking?.customer_areapincode?.area + ",") +
          (booking?.customer_areapincode?.city_name === undefined
            ? " "
            : booking?.customer_areapincode?.city_name + ", ") +
          (booking?.customer_areapincode?.pincode === undefined
            ? " "
            : "Pin-Code:" + booking?.customer_areapincode?.pincode),
        packages: booking?.packages,
        centre: booking?.center?.name,
        centreAddr:
          booking?.centre_address !== undefined
            ? booking?.centre_address?.address
            : "",
        totalPrice: booking?.discounted_price.total_price_package,
        finalPrice: booking?.discounted_price.final_total_price,
        totalDiscount: booking?.discounted_price.counpon_discount,
        payment:
          booking?.payment_links.length > 0
            ? booking?.payment_links
            : [
                {
                  payment_mode: booking?.payment_mode,
                  created_at: booking?.pickup_date,
                  amount: booking?.final_packages_price,
                },
              ],
      });
      addPrice(booking?.payment_links);
    }
  }, [booking]);

  const componentRef = useRef<any>(null);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const reactToPrintTrigger = React.useCallback(() => {
    return <button>Print Receipt</button>;
  }, []);
  const handleBack = () => {
    localStorage.removeItem("addonPrice");
    navigate(-1);
  };

  const handleDOBForAge = (dob: any, bdate: any) => {
    const a: any = moment(bdate);
    const b: any = moment(dob);
    var years = a.diff(b, "year");
    b.add(years, "years");
    var months = a.diff(b, "months");
    b.add(months, "months");
    var days = a.diff(b, "days");
    var age;
    if (months !== 0) {
       age = months + "Months " + days + "Days";
    } else {
       age = days + "Days";
    }

    return age;
  };
  return (
    <div
      style={{
        margin: "50px auto",
      }}
    >
      <button
        style={{
          width: "120px",
          borderRadius: "5%",
          backgroundColor: "#ef5350",
          color: "white",
        }}
        onClick={handleBack}
      >
        {"<< Go Back"}
      </button>
      <ReactToPrint
        content={reactToPrintContent}
        trigger={reactToPrintTrigger}
      />
      <div
        style={{
          width: "842px",
          border: "1px solid grey",
          margin: 20,
        }}
      >
        <div ref={componentRef}>
          <div>
            <meta charSet="UTF-8" style={{ fontFamily: "sans-serif" }} />
            <meta
              name="viewport"
              // content="width=device-width, initial-scale=1.0"
              style={{ fontFamily: "sans-serif" }}
            />
            <title style={{ fontFamily: "sans-serif" }}>Invoice</title>

            <div id="divToPrint" style={{ fontFamily: "sans-serif" }}>
              <div id="shHeader" style={{ width: "100%" }}>
                <img src={header} style={{ width: "100%" }} alt="description of image" />
              </div>
              <div
                id="shContent"
                style={{
                  height: "1150px",
                  background: "white",
                  padding: "0px 25px",
                  letterSpacing: "-1px",
                }}
              >
                <div
                  style={{
                    borderBottom: "4px solid black",
                    textAlign: "center",
                  }}
                >
                  <h2 style={{ fontWeight: "bolder" }}>BILL/RECEIPT</h2>
                </div>
                <div
                  style={{ width: "100%", display: "flex", fontSize: "20px" }}
                >
                  <div style={{ width: "15%" }}>Name:</div>
                  <div
                    style={{
                      width: "45%",
                      fontSize: "25px",
                      textTransform: "uppercase",
                    }}
                  >
                    {data.cName}
                  </div>
                  <div style={{ width: "15%" }}>REG. NO.:</div>
                  <div style={{ width: "25%" }}>{data.bId}</div>
                </div>
                <div
                  style={{ width: "100%", display: "flex", fontSize: "20px" }}
                >
                  <div style={{ width: "15%" }}>Age:</div>
                  <div style={{ width: "45%" }}>
                    {data?.cAge === 0
                      ? handleDOBForAge(data?.cAgeDob, data?.iDate)
                      : data?.cAge + " Years"}{" "}
                    / {data.cGender}
                  </div>
                 
                  <div style={{ width: "15%" }}>DATE:</div>
                  <div style={{ width: "25%", textAlign: "left" }}>
                    {data.iDate}
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    fontSize: "20px",
                    marginTop: "10px",
                  }}
                >
                   <div style={{ width: "15%" }}>Mobile:</div>
                  <div style={{ width: "45%", textAlign: "left" }}>
                    {data.cNumber}
                  </div>
                  <div style={{ width: "15%" }}>REG. TIME:</div>
                  <div style={{ width: "25%" }}>{data.iTime}</div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    fontSize: "20px",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ width: "15%" }}>Email ld:</div>
                  <div style={{ width: "85%" }}>{data.cEmail}</div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    fontSize: "20px",
                    marginTop: "10px",
                    borderBottom: "4px solid black",
                  }}
                >
                  <div style={{ width: "15%" }}>Ref. By Dr. :</div>
                  <div style={{ width: "85%", textTransform: "uppercase" }}>
                    {data?.cRefDr}
                  </div>
                </div>
                {/* //tests */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    fontSize: "20px",
                    marginTop: "10px",
                    borderBottom: "2px solid black",
                  }}
                >
                  <div style={{ width: "85%" }}>Test Name</div>
                  {/* <div style={{ width: "15%" }}>Report Date</div> */}
                  <div style={{ width: "15%", textAlign: "center" }}>
                    Charges
                  </div>
                </div>
                <div style={{ minHeight: 700 }}>
                  {data?.packages.length > 0 &&
                    data?.packages.map((val: any) => (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          fontSize: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{ width: "85%", textTransform: "uppercase" }}
                        >
                          {val?.name}
                        </div>
                        {/* <div style={{ width: "20%" }}></div> */}
                        <div style={{ width: "15%", textAlign: "right" }}>
                          {val?.price}.00
                        </div>
                      </div>
                    ))}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    fontSize: "20px",
                    borderTop: "4px solid black",
                    marginTop:10
                  }}
                >
                  <div style={{ width: "20%" }}>Total Amount (Rs.) :</div>
                  {/* <div style={{ width: "15%" }}>Report Date</div> */}
                  <div style={{ width: "80%", textAlign: "right" }}>
                    {data?.totalPrice}
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    fontSize: "20px",
                    marginTop:10
                  }}
                >
                  <div style={{ width: "20%" }}>Discount (Rs.) :</div>
                  {/* <div style={{ width: "15%" }}>Report Date</div> */}
                  <div style={{ width: "80%", textAlign: "right" }}>
                    {data?.totalDiscount}
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    fontSize: "20px",
                    marginTop:10
                  }}
                >
                  <div style={{ width: "20%" }}>Amount Paid (Rs) :</div>
                  {/* <div style={{ width: "15%" }}>Report Date</div> */}
                  <div style={{ width: "80%", textAlign: "right" }}>
                    {data?.finalPrice}
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    fontSize: "20px",
                    marginTop:15
                  }}
                >
                  <div style={{ width: "100%" ,textAlign:"center"}}>Please bring this slip to pick up your report.</div>
                </div>
              </div>
              <div id="shfooter" style={{}}>
                <img src={footer} style={{ width: "100%" }} alt="description of image"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  booking: state.LimsAdminReducer.booking,
});

export default connect(mapStateToProps, {
  getBookingById,
})(CashRecipt);
