import React from "react";
import LimsAdminDashboardLeft from "../../LimsAdmin-Panel/dashboard-left/index";
import LimsAdminDashboardRight from "../../LimsAdmin-Panel/dashboard-right/index";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        padding: "20px"
    }
});

interface Props {
    children: any;
}

const LimsAdminDash: React.FC<Props> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <LimsAdminDashboardLeft />
            <LimsAdminDashboardRight>{children && children}</LimsAdminDashboardRight>
        </div>
    );
};

export default LimsAdminDash;

