import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from '@mui/material/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button, TextField } from '@material-ui/core';
import {
    Paper,
    Table,
    withStyles,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    MenuItem,
    TableRow
}
    from '@material-ui/core';

import Loader from '../loader';
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import {
    updateReportStatus
} from "../../actions/LimsAdminActions";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow:'scroll',
        
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    paper: {
        marginTop: "2rem",
        padding: "0.5rem 2rem",
        width: "100%",
        zIndex:1
       
    },
    table: {
        maxWidth: "100%",
        margin: "auto",
        overflow:'scroll'
      
    },
    tableContainer: {
        marginTop: "2rem",
        position: "sticky",
        top: "200px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem",
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    listStyle: {
        marginLeft: "20px",
    }
}));
const StyledTableCell:any= withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow:any= withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);
interface Props {

}
type ModalProps = {
    loading: Boolean;
    openReportModal: boolean;
    updateReportStatus: any;
    setReportModal: Function;
    reportStatus: any;
};

const CreatePhlebo: React.FC<ModalProps> = ({
    openReportModal,
    updateReportStatus,
    setReportModal,
    reportStatus,
    loading
}) => {
    const classes = useStyles();
    const [reprtStatusState, setReportStatusState] = useState<any>([])

    const handleClose = () => {
        setReportModal(false);
    };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
        if (reportStatus.results) {
            setReportStatusState(reportStatus.results)
        }
    }, [reportStatus]);

    const handleEdit = (index: any, key: any, value: any) => {
        const temp = [...reprtStatusState]
        temp[index][key] = value;
        setReportStatusState(temp)
    }

    const handleSubmit = async (val: any, id: any) => {

        const body: any = {
            status: val
        }
        await updateReportStatus(id, body)

    }
    return (
        <div>
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "700px" ,}}>
                <Table stickyHeader aria-label="simple table">
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={openReportModal}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500
                        }}
                    >
                        <Fade in={openReportModal}>
                            <div className={classes.paper}>
                                <Paper elevation={15} className={classes.paper}>
                                    <TableContainer className={classes.table} component={Paper}>
                                        <div className={classes.head}>
                                            <h4
                                                style={{
                                                    marginBottom: "1rem",
                                                }}
                                                id="transition-modal-title"
                                            >
                                                REPORT STATUS
                                            </h4>
                                            <CloseSharpIcon onClick={() => setReportModal(false)} />

                                        </div>
                                        {loading ? <Loader /> : (
                                            <Table aria-label="simple table">

                                                <TableHead>
                                                    <StyledTableRow>
                                                        <StyledTableCell align="center">Booking Id </StyledTableCell>
                                                        <StyledTableCell align="center">Customer Name </StyledTableCell>
                                                        <StyledTableCell align="center">Test Code </StyledTableCell>
                                                        <StyledTableCell align="center">Test Name</StyledTableCell>
                                                        <StyledTableCell align="center">Status</StyledTableCell>
                                                        <StyledTableCell align="center">Action </StyledTableCell>
                                                    </StyledTableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        reprtStatusState && reprtStatusState.length > 0 && reprtStatusState.map((data: any, index: any) => {
                                                            if (data.booking !== null) {
                                                                return (
                                                                    <TableRow key={index}>

                                                                        <StyledTableCell style={{ height: "50px" }} align="center">{data?.booking.id}</StyledTableCell>
                                                                        <StyledTableCell style={{ height: "50px" }} align="center">{data?.booking.customer_name}</StyledTableCell>
                                                                        <StyledTableCell style={{ height: "50px" }} align="center">{data?.testCode}</StyledTableCell>
                                                                        <StyledTableCell style={{ height: "50px" }} align="center">{data?.testName}</StyledTableCell>
                                                                        <StyledTableCell style={{ height: "50px" }} align="center">
                                                                            <TextField
                                                                                name="Status"
                                                                                select
                                                                                required
                                                                                variant="outlined"
                                                                                disabled={data?.status === "ready" || data?.status === "dismissed"}
                                                                                value={data?.status}
                                                                                onChange={(e) => handleEdit(index, 'status', e.target.value)}
                                                                                fullWidth
                                                                            >
                                                                                <MenuItem value="ready">Ready</MenuItem>
                                                                                <MenuItem value="not ready">Not Ready</MenuItem>
                                                                                <MenuItem value="dismissed">Dismissed</MenuItem>
                                                                            </TextField>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell style={{ height: "50px" }} align="center">

                                                                            <Button
                                                                                color="primary"
                                                                                // disabled={data?.status === "dismissed"}
                                                                                variant="contained"
                                                                                onClick={() => handleSubmit(data?.status, data.id)}
                                                                            >
                                                                                Save
                                                                            </Button>


                                                                        </StyledTableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <TableRow key={index}>

                                                                        <StyledTableCell style={{ height: "50px" }} align="center">{data?.add_member_booking.id}</StyledTableCell>
                                                                        <StyledTableCell style={{ height: "50px" }} align="center">{data?.add_member_booking.customer_name}</StyledTableCell>
                                                                        <StyledTableCell style={{ height: "50px" }} align="center">{data?.testCode}</StyledTableCell>
                                                                        <StyledTableCell style={{ height: "50px" }} align="center">{data?.testName}</StyledTableCell>
                                                                        <StyledTableCell style={{ height: "50px" }} align="center">
                                                                            <TextField
                                                                                name="Status"
                                                                                select
                                                                                required
                                                                                variant="outlined"
                                                                                disabled={data?.status === "ready" || data?.status === "dismissed"}
                                                                                value={data?.status}
                                                                                onChange={(e) => handleEdit(index, 'status', e.target.value)}
                                                                                fullWidth
                                                                            >   <MenuItem value="ready">Ready</MenuItem>
                                                                                <MenuItem value="not ready">Not Ready</MenuItem>
                                                                                <MenuItem value="dismissed">Dismissed</MenuItem>
                                                                            </TextField>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell style={{ height: "50px" }} align="center">
                                                                            <Button
                                                                                color="primary"
                                                                                variant="contained"
                                                                                // disabled={data?.status === "dismissed"}
                                                                                onClick={() => handleSubmit(data?.status, data.id)}
                                                                            >
                                                                                Save
                                                                            </Button>
                                                                        </StyledTableCell>
                                                                    </TableRow>)
                                                            }
                                                        })}
                                                </TableBody>
                                            </Table>
                                        )}
                                    </TableContainer>

                                </Paper>
                            </div>
                        </Fade>
                    </Modal>
                </Table>
            </TableContainer>

        </div >
    );
};

const mapStateToProps = (state: any) => ({
    // area: state.LimsAdminReducer.area,
});

export default connect(mapStateToProps, {
    updateReportStatus,
})(CreatePhlebo);

function code(code: any): void {
    throw new Error('Function not implemented.');
}

