import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Grid,
    TextField,
    withStyles,
} from "@material-ui/core";
import "./index.sass"
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import MessageIcon from "@material-ui/icons/Message";
import { useNavigate } from "react-router";
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import SampleJourneyComment from '../components/Comments/sampleJourney';
import { getSampleHandOver, updateRiderToLabStatus, getRiderList, getPhlebos } from "../actions/LimsAdminActions";
import { genrateSampleHandoverFilter } from '../../helpers/generateUrl'
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
        },
        tableHead: {
            background: "rgb(146, 74, 145)!important",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        tableHeadCell: {
            color: "#fff",
            fontWeight: "bold",
            textAlign: "center",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
          },
    })
);
const StyledTableCell:any= withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow:any= withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface SampleHandOverProps {
    getSampleHandOver: any;
    sampleHandOverData: any;
    updateRiderToLabStatus: any;
    getRiderList: any;
    riderList: any;
    getPhlebos: any;
    phleboList: any;
    loading: boolean;
}

const BookingsTable: React.FC<SampleHandOverProps> = ({
    getSampleHandOver,
    sampleHandOverData,
    updateRiderToLabStatus,
    getRiderList,
    riderList,
    getPhlebos,
    phleboList,
    loading,
}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const timer = useRef<any>(0);
    const [bookingId, setBookingId] = useState<any>("")
    const [page, setPage] = React.useState(0);
    const rowsPerPage=50;
    const [open, setOpen] = useState<boolean>(false);
    const [sampleId, setSampleId] = useState<number>(0);
    const [start_date, setStart_date] = useState("")
    const [end_date, setEnd_date] = useState("")
    const [createdAt, setCreatedAt] = useState<string>("")
    const [rider, setRider] = useState<any>([]);
    const [phlebo, setPhlebo] = useState<any>([]);
    const barcode=""
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
        getSampleHandOver()
        getPhlebos()
        getRiderList(`?usergroup=Rider`);
    }, [])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = sampleHandOverData.links && sampleHandOverData.links.next.split("?")[1];
              getSampleHandOver(url);
        } else if (newPage < page) {
            let url = sampleHandOverData.links && sampleHandOverData.links.previous.split("?")[1];
              getSampleHandOver(url)
        }
        setPage(newPage as number);
    };
    const handleClick = (id: number) => {
        setOpen(true);
        setSampleId(id);
    };
    const handleRiderToLabUpdate = async (id: any) => {
        const body = {
            lab_received: "confirmed"
        }
        await updateRiderToLabStatus(body, id)
        getSampleHandOver()


    }
    const filterSampleHandover = (e:any) => {
        const body: any = {
            start_date,
            end_date,
            created_date: createdAt,
            phlebo:phlebo,
            rider,
            booking: bookingId,
            barcode,
            
            
        }
        const url = genrateSampleHandoverFilter(body).substring(2)
        getSampleHandOver(`${url}`)
        setPage(0)
    }
    const onKeyDown = (e: any) => {
        if (e.key === "Enter") {
            filterSampleHandover(e);
        }
    };
    return (
        <div style={{ width: "100%" }} className="data-table">
            <div className={classes.toolbar} />
            <h3 style={{ fontWeight: "bold" }}>Sample Receiving List</h3>
            <Grid container spacing={3} direction="row" style={{alignItems:"left"}}>
            {/* <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="barcode"
                            type="text"
                            label="Barcode"
                            value={barcode}
                            variant="outlined"
                            onChange={(e) => setBarcode(e.target.value)}
                            style={{ width: "100%" }}
                            onKeyDown={onKeyDown}
                        />
                    </Grid> */}
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        className="input"
                        name="name"
                        type="number"
                        label="Booking Id"
                        value={bookingId}
                        variant="outlined"
                        onChange={(e) => setBookingId(e.target.value)}
                        style={{ width: "100%" }}
                        onKeyDown={onKeyDown}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                        id="booking_source"
                        onChange={(event, newValue) => {
                            if (newValue) {
                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                setRider(obj.id)
                            }
                        }}
                        options={riderList.results}
                        freeSolo
                        blurOnSelect
                        aria-required
                        getOptionLabel={(option: any) => option.user && option.user.name}
                        onInputChange={(event, newInputValue) => {
                            clearTimeout(timer.current)
                            timer.current = setTimeout(() => {
                                getRiderList(`?usergroup=Rider&code=${newInputValue}`)
                            }, 1000)
                            if (newInputValue.length === 0) {
                                setRider(0)
                            }
                        }}
                        renderInput={(params) => (
                            <TextField className="input"
                                {...params}
                                placeholder="Partner"
                                variant="outlined"
                                style={{ width: "100%", margin: "0", padding: "0" }}
                                required
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                        id="phleboName"
                        onChange={(event, newValue) => {
                            if (newValue) {
                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                setPhlebo(obj.id)
                            }
                        }}
                        options={phleboList.results ? [...phleboList.results, { id: 0, user: { username: "NoPhlebo" } }] : []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        getOptionLabel={(option: any) => option.user && option.user.username}
                        onInputChange={(event, newInputValue) => {
                            clearTimeout(timer.current)
                            timer.current = setTimeout(() => {
                                getPhlebos(newInputValue)
                            }, 1000)
                            if (newInputValue.length === 0) {
                                setPhlebo([])
                            }
                        }}
                        renderInput={(params) => (
                            <TextField className="input"
                                {...params}
                                placeholder="Phlebo Name"
                                variant="outlined"
                                style={{ width: "100%", margin: "0", padding: "0" }}
                                required
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        className="input"
                        name="start_date"
                        type="date"
                        label="Start Date"
                        value={start_date}
                        variant="outlined"
                        onChange={(e) => setStart_date(e.target.value as string)}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        name="end_date"
                        type="date"
                        value={end_date}
                        className="input"
                        label="End Date"
                        variant="outlined"
                        onChange={(e) => setEnd_date(e.target.value as string)}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        className="input"
                        name="Created At"
                        type="date"
                        label="Created Date"
                        value={createdAt}
                        variant="outlined"
                        onChange={(e) => setCreatedAt(e.target.value as string)}
                        style={{ width: "100%" }}
                    />
                </Grid>
                </Grid>
                <Grid container spacing={3} direction="row" style={{alignItems:"left"}}>
                <Grid item xs={12} sm={6} md={3}>
                    <Button
                        variant="contained"
                        color="secondary"
                        
                        fullWidth
                        onClick={filterSampleHandover}
                        disabled={loading}
                    >
                        Filter
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        
                        fullWidth
                        onClick={() => navigate(0)}
                    >
                        Reset
                    </Button>
                </Grid>

            </Grid>
            {/* <Paper elevation={15} className={classes.paper}> */}
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "700px" }}>
            <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell className={classes.tableHeadCell} >Phlebo</StyledTableCell>
                                <StyledTableCell className={classes.tableHeadCell} >Sample journey</StyledTableCell>
                                <StyledTableCell className={classes.tableHeadCell}>Booking ID</StyledTableCell>
                                <StyledTableCell className={classes.tableHeadCell}>Given To</StyledTableCell>
                                <StyledTableCell className={classes.tableHeadCell}>Rider To Lab</StyledTableCell>
                                <StyledTableCell className={classes.tableHeadCell}>Lab Received</StyledTableCell>
                                <StyledTableCell className={classes.tableHeadCell}>Sample Handover</StyledTableCell>
                                <StyledTableCell className={classes.tableHeadCell}>Tube Received By Rider</StyledTableCell>
                                <StyledTableCell className={classes.tableHeadCell}>Remarks</StyledTableCell>
                                <StyledTableCell className={classes.tableHeadCell}>Amount</StyledTableCell>
                                <StyledTableCell className={classes.tableHeadCell}>Cash Received By Rider</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {sampleHandOverData && sampleHandOverData?.results && sampleHandOverData?.results.map((data: any, index: any) => {
                                return (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell align="center">
                                            {data?.phlebo?.user?.name}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <MessageIcon onClick={() => handleClick(data?.id)} />
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {/* <div> */}
                                                {data?.items?.map((bId: any) => {
                                                    return (
                                                        <span>{bId?.pk},</span>
                                                    )
                                                })}
                                            {/* </div> */}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.given_to}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.ride_to_lab}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.lab_received} {data?.lab_received === "pending" ? (<SystemUpdateAltIcon onClick={() => handleRiderToLabUpdate(data?.id)} />) : ""}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.sample_handover_time===null?"":data?.sample_handover_time.substring(0,10)}
                                            <br/>
                                            {data?.sample_handover_time===null?"":data?.sample_handover_time.substring(11,20)}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.tube_received_by_rider}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.remarks}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.amount}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.cash_received_by_rider}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                        <TableFooter>
                            <StyledTableRow>
                                <TablePagination
                                    colSpan={6}
                                    count={sampleHandOverData?.count || 0}
                                    rowsPerPageOptions={[]}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                />
                            </StyledTableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <SampleJourneyComment open={open} setOpen={setOpen} sampleId={sampleId} />
            {/* </Paper> */}
            {/* {bookingList.results && bookingList.results.length === 0 && (
        <h6 style={{ textAlign: "center" }}>No Data Found</h6>
      )} */}
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    sampleHandOverData: state.LimsAdminReducer.sampleHandOverData,
    riderList: state.LimsAdminReducer.riderList,
    phleboList: state.LimsAdminReducer.phleboList,
    loading: state.LimsAdminReducer.loading,
});
export default connect(mapStateToProps, {
    getSampleHandOver,
    updateRiderToLabStatus,
    getRiderList,
    getPhlebos,
})(BookingsTable);
