import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {Modal,Fade,Backdrop} from '@mui/material';
import {
  Button,
  Paper,
  TextField,
  Grid,
} from "@material-ui/core";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { getSyncData } from "../../actions/LimsAdminActions";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    marginTop: "2rem",
    padding: "0.5rem 2rem",
    width: "90%",
    zIndex:1
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));
interface Props {
  getSyncData: any;
}
type ModalProps = {
  setSyncOpenModal: Function;
  openSyncModal: boolean;
  syncId: any;
  getSyncData: any;
  bookingData: any;
  getAllBookings: any;
  filterBookings:any;
};

const CreatePhlebo: React.FC<ModalProps> = ({
  setSyncOpenModal,
  openSyncModal,
  syncId,
  getSyncData,
  bookingData,
  getAllBookings,
  filterBookings
}) => {
  const classes = useStyles();
  const [passportNumber, setPassportNumber] = useState<any>("");
  const [adharNumber, setAdharNumber] = useState<any>("");
  const [srfNumber, setSrfNumber] = useState<any>("");
  const [barcode, setBarcode] = useState<any>("");
  const [sampleReceivingDate, setSampleReceivingDate] = useState<any>("");
  const [sampleCollectionDate, setSampleCollectionDate] = useState<any>("");
  const [syncSubmit, setSyncSubmit] = useState<boolean>(false);
  const [syncnumber, setSyncNumber] = useState<any>("");
  const handleClose = () => {
    setSyncOpenModal(false);
  };

  const submitForm = async (e: any) => {
    e.preventDefault();
    const body = {
      customer_aadhar: adharNumber,
      passport_number: passportNumber,
      srf_number: srfNumber,
      barcode: barcode,
      sample_receiving_time: sampleReceivingDate,
      sample_collection_time: sampleCollectionDate,
      sync_number: syncnumber,
    };
    setSyncSubmit(true);
    await getSyncData(`${syncId}/`, body);
    setTimeout(() => {
      filterBookings()
      setSyncSubmit(false);
    }, 1000);
    setSyncOpenModal(false);
    getAllBookings(syncId)
  };
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setAdharNumber(bookingData.customer_aadhar);
    setPassportNumber(bookingData.passport_number);
  }, [openSyncModal]);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openSyncModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openSyncModal}>
          <div className={classes.paper}>
            <Paper elevation={5} className={classes.paper}>
              <div className={classes.head}>
                <h4
                  style={{
                    marginBottom: "1rem",
                  }}
                  id="transition-modal-title"
                >
                  RECEIVE {bookingData?.customer_name} BOOKING ({syncId})
                </h4>
                <CloseSharpIcon onClick={() => setSyncOpenModal(false)} />
              </div>
              <form onSubmit={submitForm}>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  alignItems="center"
                  justify="center"
                >
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="adhar_number"
                      type="number"
                      onWheel={(e: any) => e.target.blur()}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 12);
                      }}
                      label="AADHAAR NUMBER"
                      value={adharNumber}
                      variant="outlined"
                      onChange={(e) => setAdharNumber(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="passport_number"
                      type="text"
                      label="PASSPORT NUMBER"
                      value={passportNumber}
                      variant="outlined"
                      onChange={(e) => setPassportNumber(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="Srf_Number"
                      type="text"
                      label="SRF NUMBER"
                      value={srfNumber}
                      variant="outlined"
                      onChange={(e) => setSrfNumber(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="barcode"
                      type="text"
                      label="BARCODE"
                      value={barcode}
                      variant="outlined"
                      onChange={(e) => setBarcode(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      type="datetime-local"
                      label="Sample Collection Date & Time"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={sampleCollectionDate}
                      variant="outlined"
                      onChange={(e) => setSampleCollectionDate(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      type="datetime-local"
                      label="Sample Recieving Date & Time"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={sampleReceivingDate}
                      variant="outlined"
                      onChange={(e) => setSampleReceivingDate(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  {bookingData.is_sync===true &&bookingData?.bill_id === null? (
                    <>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          name="sync_number"
                          type="text"
                          label="SYNC NUMBER"
                          value={syncnumber}
                          variant="outlined"
                          onChange={(e) => setSyncNumber(e.target.value)}
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}

                  <Grid item xs={12} sm={6} md={3}>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={syncSubmit}
                    >
                      RECEIVE BOOKING
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {
  getSyncData,
})(CreatePhlebo);
