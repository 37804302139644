import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import Geocode from "react-geocode";
import Payment from "../../payment/index";
import NbsForm from "../../Forms/nbsForm";
import AddDoctor from "../../AddDoctor/addDoctor";
import CollectSample from "../CollectSample/index";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {
  Card,
  Typography,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  InputAdornment,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  getCampCoupons,
  checkGeoFenceArea,
  getPinCode,
  getDCPackage,
  createNewDCBooking,
  getPartners,
  getCoupons,
  getLeads,
  getLeadDetails,
  resetLeadDetails,
  getCenterInfo,
  getRefDoctor,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken,
  createHistopathologyBooking,
  createNbsBooking,
} from "../../../actions/LimsAdminActions";
import Button from "@mui/material/Button";
import "./index.sass";
import { connect } from "react-redux";
import moment from "moment";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useNavigate, useLocation } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { GENDER_CHOICE } from "../../../constant";
import HistoryForm from "../../../pages/Historyform";
Geocode.setApiKey("AIzaSyBPjDlsHYhWtjt3FxgRPnTMXN-qJZ9hz0s");
Geocode.enableDebug();
Geocode.setApiKey("AIzaSyBPjDlsHYhWtjt3FxgRPnTMXN-qJZ9hz0s");
Geocode.enableDebug();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },

    buttonContainer: {
      marginTop: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    mszContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
      marginTop: "50px",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      padding: "20px 10px 10px 10px",
      background: "#ffffff",
      margin: "10px",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    },
    packageLink: {
      color: "#ffffff",
      textDecoration: "none",
    },
    dialog: {
      zIndex: 1,
    },
  })
);

interface Props {
  getCampCoupons: any;
  campCoupon: any;
  getRefDoctor: any;
  refDoctor: any;
  getCenterInfo: any;
  centerInfo: any;
  getPinCode: any;
  pin_code: any;
  getDCPackage: any;
  dcpackageList: any;
  getPartners: any;
  couponList: any;
  getCoupons: any;
  createNewDCBooking: any;
  getLeads: any;
  leads: any;
  getLeadDetails: any;
  lead_details: any;
  booking_slots: any;
  resetLeadDetails: any;
  createDcBooking: any;
  loading: boolean;
  checkGeoFenceArea: any;
  checkGeoFenceStatus: boolean;
  checkGeoFenchMapMyIndia: any;
  geoFencesMapMyIndia: any;
  mapmyindiaAccessToken: any;
  getMapMyIndiaAccessToken: any;
  createHistopathologyBooking: any;
  createNbsBooking: any;
  relevantClinicalHistoryform: any;
  setRelevantClinicalHistoryForm: any;
  clinicalDiagnosisform: any;
  setClinicalDiagnosisform: any;
  radiologyFindingsform: any;
  setRadiologyFindingsform: any;
  operativeFindingsform: any;
  setOperativeFindingsform: any;
  // biospySampleCode: any
}

const BookingWithLocalitySearch: React.FC<Props> = ({
  getCampCoupons,
  campCoupon,
  getRefDoctor,
  refDoctor,
  getCenterInfo,
  centerInfo,
  getPinCode,
  pin_code,
  dcpackageList,
  getDCPackage,
  couponList,
  getPartners,
  getCoupons,
  createNewDCBooking,
  getLeads,
  leads,
  getLeadDetails,
  lead_details,
  booking_slots,
  resetLeadDetails,
  createDcBooking,
  checkGeoFenceArea,
  checkGeoFenceStatus,
  loading,
  checkGeoFenchMapMyIndia,
  geoFencesMapMyIndia,
  mapmyindiaAccessToken,
  getMapMyIndiaAccessToken,
  createHistopathologyBooking,
  createNbsBooking,
  relevantClinicalHistoryform,
  setRelevantClinicalHistoryForm,
  clinicalDiagnosisform,
  setClinicalDiagnosisform,
  radiologyFindingsform,
  setRadiologyFindingsform,
  operativeFindingsform,
  setOperativeFindingsform
  // biospySampleCode,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    resetLeadDetails();
    getPartners();
    getCoupons("?org_type=homedx");
    getCenterInfo(`?c_type=true`);
    getRefDoctor();
  }, []);
  const [validateNbsStatus, setValidateNbsStatus] = useState<boolean | null>(
    false
  );
  const [validateHistoStatus, setValidateHistoStatus] = useState<
    boolean | null
  >(false);
  const [inputList, setInputList] = useState<any>([
    {
      customerDesignation: "",
      designation: "",
      customerName: "",
      customerAge: "",
      customerAgeMonths: "",
      customerAgeDays: "",
      customerGender: "",
      customerPackage: [],
      packagePrice: 0,
      dateOfBirth: "",
    },
  ]);
  const [environment, setEnvironment] = useState<String>("");
  const [collection_date, setCollection_date] = useState<String>("");
  const [centre, setCentre] = useState<any>("");
  const [centreType, setCentreType] = useState<any>("");
  const [isBooked, setIsBooked] = useState<any>(false);
  const [centreData, setCentreData] = useState<any>("");

  const [rateList, setRateList] = useState<String>("");
  const [pincode, setPinCode] = useState<String>("");
  const [zoneName, setZoneName] = useState<String>("");
  const [bookingId, setBookingId] = useState<String>("");
  const [customer_phonenumber, setCustomer_phonenumber] = useState<String>("");
  const [customer_alternatenumber, setCustomer_alternatenumber] =
    useState<String>("");
  const [customer_whatsapppnumber, setCustomer_whatsapppnumber] =
    useState<String>("");
  const [customer_address, setCustomer_address] = useState<String>("");
  const [customer_email, setCustomer_email] = useState<String>("");
  const [customer_landmark, setCustomer_landmark] = useState<string>("");
  const [customerLatitude, setCustomerLatitude] = useState<any>("");
  const [customerLongitude, setCustomerLongitude] = useState<any>("");
  const [customer_areapincode, setCustomer_areapincode] = useState<Number>(0);
  const [customer_city, setCustomer_city] = useState<String>("");
  const [customer_aadhar, setCustomer_aadhar] = useState<String>("");
  const [passport, setPassport] = useState<String>("");
  const [batchNo, setBatchNo] = useState<any>("");
  const [price, setPrice] = useState<any>(0);
  const [discount, setDiscount] = useState<any>(0);
  const [totalPrice, setTotalPrice] = useState<any>(0);
  const [netPrice, setNetPrice] = useState<any>(0);
  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [campCouponCode, setCampCouponCode] = useState<string>("");
  const [perPackNbs, setPerPackNbs] = useState<any>(1);
  const [perPackArr, setPerPackArr] = useState<any>([]);
  const [cityId, setCityId] = useState<any>("");
  const [packageKey, setPackageKey] = useState<any>(0);
  const [mobileValidation, setMobileValidation] = useState({
    customer_alternatenumber: true,
    customer_phonenumber: true,
    customer_whatsapppnumber: true,
  });
  const [withoutPayment, setWithoutPayment] = useState<any>(false);
  const [openLeadModal, setOpenLeadModal] = useState<any>(false);
  const [leadSelected, setLeadSelected] = useState<any>(false);
  const [openLeadModalFlag, setOpenLeadModalFlag] = useState<any>(false);
  const [search, setSearch] = useState<String>("");
  const [zoneId, setZoneId] = useState<any>("");
  const [notificationData, setNotificationData] = useState<any>("");
  const timer = useRef<any>(0);
  const handleSearch = (e: React.ChangeEvent<{ value: unknown }>) => {
    setSearch(e.target.value as string);
  };
  const [addressLine2, setAddressLine2] = useState<string>("");
  const [favoritePlace, setFavoritePlace] = useState<any>("");
  const [openLocationModal, setOpenLocationModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [couponMessage, setCouponMessage] = useState<string>("");
  const [campCouponData, setCampCouponData] = useState<any>("");
  const [tncMessage, setTncMessage] = useState<string>("");
  const [paid, setPaid] = useState<any>(0);
  const [due, setDue] = useState<any>(0);
  const [referringDoctor, setReferringDoctor] = useState<any>("");
  const [paymentInfo, setPaymentInfo] = useState<any>([]);
  const [lead_customer_phonenumber, setLeadCustomer_phonenumber] =
    useState<string>();
  const [labRemark, setLabRemark] = useState<string>("");
  const [labRemarkComment, setLabRemarkComment] = useState<string>("");
  const [openHistoryForm, SetOpenHistoryForm] = useState<boolean>(false);
  const [relevantClinicalHistory, setRelevantClinicalHistory] =
    useState<any>("");
  const [clinicalDiagnosis, setClinicalDiagnosis] = useState<any>("");
  const [radiologyFindings, setRadiologyFindings] = useState<any>("");
  const [operativeFindings, setOperativeFindings] = useState<any>("");
  const [sizeofbiopsy, setSizeOfBiopsy] = useState<string>("");
  const [dateOfProcedure, setDateOfProcedure] = useState<string>("");
  const [refDoctorName, setRefDoctorName] = useState<string>("");
  const [refDoctorNumber, setRefDoctorNumber] = useState<string>("");
  const [biospyPackageName, setBiospyPackageName] = useState<string>("");
  const [biospyPackageCode, setBiospyPackageCode] = useState<string>("");
  const [biospySampleName, setBiospySampleName] = useState<any>("");
  const [biospyTrueOrFalse, setBiospyTrueOrFalse] = useState<any>("");
  const [openNbsModal, setOpenNbsModal] = useState<boolean>(false);
  const [lead_customer_whatsapppnumber, setLeadCustomer_whatsapppnumber] =
    useState<string>();
  const [centerName, setCenterName] = useState<any>("");
  const [dataNbs, setDataNbs] = useState<any>({
    birth_time: "",
    birth_time_hh: "00",
    birth_time_mm: "00",
    consanguinity: "",
    baby_weight: "",
    baby_admitted_in_nicu: "",
    blood_transfusion: "",
    transfusion_date: "",
    gestational_age_weeks: "",
    gestational_age_days: "",
    birth_order: "",
    type_of_feed: "",
    history: "",
    ch: "",
    cah: "",
    g6pd: "",
    gal: "",
    cp: "",
    pku: "",
    bio: "",
  });
  function inputKeyUp(e: any) {
    e.which = e.which || e.keyCode;
    if (e.which === 13) {
      searchLead();
    }
  }
  const searchLead = () => {
    let body = {
      search_by: "call_id",
      value: search,
    };

    getLeads(body, "number=true");
    setOpenLeadModalFlag(true);
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (leads?.result?.length !== 0 && openLeadModalFlag === true) {
      setOpenLeadModal(true);
    }
  }, [leads, openLeadModalFlag]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (search.length > 0) {
      var regex = /^[6-9]\d{9}$/;
      var output: any = search.match(regex);
      if (output?.length > 0 && String(output[0]).length === 10) {
        setCustomer_phonenumber(String(output[0]));
      }
    }
  }, [search]);

  const selectLead = (id: any) => {
    setLeadSelected(true);
    getLeadDetails(`${id}`);
    setOpenLeadModal(false);
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (
      String(window.location.href) ==
      "https://labs.redcliffelabs.com/#/dashboard/la/booking"
    ) {
      setEnvironment("live");
    } else {
      setEnvironment("dev");
    }
    getMapMyIndiaAccessToken();
  }, []);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (centreData !== "") {
      // setCentre(centerInfo?.results[0]?.id);
      // setRateList(centerInfo?.results[0]?.ratelist[0]?.name);
      setNotificationData({
        balance: centreData?.rcl_amount,
        minAmount: centreData?.min_amnt_for_notification,
        type: centreData?.type,
        creditLimit: centreData?.credit_limit,
      });
    }
  }, [centreData]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (centreType === "postpaid") {
      setWithoutPayment(true);
    } else if (centreType === "prepaid") {
      setWithoutPayment(false);
    }
  }, [centreType]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (Object.keys(lead_details).length !== 0 && leadSelected) {
      setCustomer_address(lead_details?.address);
      setLeadCustomer_phonenumber(lead_details?.content_number);
      setLeadCustomer_whatsapppnumber(lead_details?.whatsapp_contact);
      setInputList([
        {
          customerDesignation:
            lead_details?.calling_gender === "male" ? "Mr" : "Ms",
          designation: lead_details?.calling_gender === "male" ? "Mr" : "Ms",
          customerName: lead_details?.patient_name,
          customerAge: lead_details?.patient_age,
          customerAgeMonths: "",
          customerAgeDays: "",
          customerGender: lead_details?.calling_gender,
          customerPackage: [],
          packagePrice: 0,
          dateOfBirth: "",
        },
      ]);
    }
  }, [lead_details]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (customerLatitude && customerLongitude && zoneId) {
      // check booking is allow to not in Geo-fence
      checkGeoFenceArea(
        customerLatitude as string,
        customerLongitude as string,
        zoneId as string
      );
    }
  }, [customerLatitude, customerLongitude, zoneId]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const slots = booking_slots && booking_slots.results;
  }, [booking_slots]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (pincode && pin_code?.results?.length === 0) {
      setErrorMessage("We are not providing service on this pincode.");
      resetAddressComponent();
    }
    if (pincode && pin_code?.results?.length > 0) {
      const findPinCode = pin_code?.results.find(
        (code: any) => code.pincode === pincode
      );
      if (findPinCode) {
        setErrorMessage("");
        getArea(pincode);
        const { id, area, city, cityid } = pin_code.results.find(
          (code: any) => code.pincode === pincode
        );
        // getDCPackage(`city_id=${cityid}&org_type=homedx`);
        // getDCPackage();
        setCityId(cityid);
        setCustomer_areapincode(id as Number);
        setCustomer_city(city as String);
        setPackageKey(cityid);
      } else {
        setErrorMessage("We are not providing service on this pincode.");
      }
    }
  }, [pin_code, pincode]);

  const getArea = (pincode: String) => {
    const zone =
      pin_code?.results &&
      pin_code?.results.find((code: any) => code?.pincode === pincode);
    setZoneName(zone && (zone?.area as String));
    setZoneId(zone?.zone_data?.id);
  };

  const resetAddressComponent = () => {
    setZoneId("");
    setCityId("");
    setPackageKey(0);
    setZoneName("");
    setCustomer_city("");
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (totalPrice < 0) {
      setTotalPrice(0);
    }
  }, [totalPrice]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setNetPrice(totalPrice - discount);
  }, [totalPrice, discount]);
  const applyCoupon = () => {
    if (couponCode === "") {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      setCouponMessage("");
      setTncMessage("");
      return;
    }
    const appliedCoupon =
      couponList.results.find((coup: any) => coup.id === coupon) ||
      couponList.results.find((coup: any) => coup.code === couponCode);
    if (appliedCoupon && appliedCoupon.minimum_price < totalPrice) {
      setCoupon(appliedCoupon.id);
      if (appliedCoupon.discount_type.toLowerCase() === "percentage") {
        setDiscount(Math.floor((price * appliedCoupon.discount) / 100));
        setCouponMessage(
          `Coupon ${appliedCoupon.code}  has been applied successfully`
        );
        setTncMessage(
          `${appliedCoupon.tnc !== null ? `TNC : ${appliedCoupon.tnc}` : ""}`
        );
      }
      if (appliedCoupon.discount_type.toLowerCase() === "amount") {
        setDiscount(Math.floor(appliedCoupon.discount));
        setCouponMessage(
          `Coupon ${appliedCoupon.code}  has been applied successfully`
        );
        setTncMessage(
          `${appliedCoupon.tnc !== null ? `TNC : ${appliedCoupon.tnc}` : ""}`
        );
      }
    } else {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      setCouponMessage("");
      setTncMessage("");
    }
  };
  const verifyMobile = (type: any) => {
    const mobileRegEx = /^[6-9]\d{9}$/;
    if (
      type === "alternate" &&
      customer_alternatenumber &&
      !mobileRegEx.test(customer_alternatenumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        customer_alternatenumber: false,
      }));
    }
    if (
      type === "whatsapp" &&
      customer_whatsapppnumber &&
      !mobileRegEx.test(customer_whatsapppnumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        customer_whatsapppnumber: false,
      }));
    }
    if (
      type === "mobile" &&
      customer_phonenumber &&
      !mobileRegEx.test(customer_phonenumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        customer_phonenumber: false,
      }));
    }
  };

  const submitForm = async (e: any) => {
    e.preventDefault();

    let packagesIds = new Array();
    inputList &&
      inputList[0]?.customerPackage.map((item: any) => {
        packagesIds.push(item.id);
      });

    let additionalPacakges = new Array();
    inputList &&
      inputList.length > 1 &&
      inputList.map((item: any, index: number) => {
        if (index > 0) {
          additionalPacakges.push(item);
        }
      });
    let payment = new Array();
    paymentInfo &&
      paymentInfo.length > 1 &&
      paymentInfo.map((item: any) => {
        if (item.isChecked) {
          payment.push({
            amount: item.amount,
            link_type: item.link_type,
            paymentid: item.paymentid,
          });
        }
      });

    const data: any = {
      booking_date: moment().format("YYYY-MM-DD"),
      collection_date: moment().format("YYYY-MM-DD"),
      // collection_slot: 23,
      designation: inputList[0].designation,
      customer_name: inputList[0].customerName,
      customer_age: inputList[0].customerAge,
      customer_gender: inputList[0].customerGender,
      dob: inputList[0].dateOfBirth,

      customer_email: customer_email,
      customer_phonenumber:
        lead_customer_phonenumber !== undefined
          ? lead_customer_phonenumber
          : customer_phonenumber,
      customer_altphonenumber:
        lead_customer_phonenumber !== undefined
          ? lead_customer_phonenumber
          : customer_alternatenumber,
      customer_whatsapppnumber:
        lead_customer_whatsapppnumber !== undefined
          ? lead_customer_whatsapppnumber
          : customer_whatsapppnumber,
      customer_address: customer_address === "" ? null : customer_address,
      address_line2: addressLine2 === "" ? null : addressLine2,
      customer_landmark: favoritePlace === "" ? null : favoritePlace,
      customer_areapincode:
        customer_areapincode === 0 ? null : customer_areapincode,
      customer_longitude: customerLatitude === "" ? null : customerLatitude,
      customer_latitude: customerLongitude === "" ? null : customerLongitude,
      customer_city: customer_city === "" ? null : customer_city,
      customer_aadhar: customer_aadhar,
      passport_number: passport,
      packages: packagesIds,
      cityid: cityId === "" ? null : cityId,
      landmark: customer_landmark,
      center: centre,
      ref_doctor: referringDoctor,
      payment_information: payment,
      coupon_applied: coupon,
      batch_id: batchNo,
      remarks: labRemark !== "Other" ? labRemark : labRemarkComment,
      save_remarks: labRemark ? true : false,
      //histo
      patient_name: inputList[0].customerName,
      contact_number: customer_phonenumber,
      address: customer_address,
      biopsy_size: sizeofbiopsy,
      procedure_date: dateOfProcedure ? dateOfProcedure : null,
      ref_doctor_name: refDoctorName,
      ref_doctor_phone: refDoctorNumber,
      center_lab_name: centerName,
      relevant_clinical_history: relevantClinicalHistory,
      clinical_diagnosis: clinicalDiagnosis,
      radiology_findings: radiologyFindings,
      operative_findings: operativeFindings,
      type_of_specimen: biospyPackageName,
      samples_acceptable: biospySampleName,
      site_specification: biospyTrueOrFalse,
      //nbs
      birth_time: `${dataNbs.birth_time_hh}:${dataNbs.birth_time_mm}:00`,
      consanguinity: dataNbs.consanguinity,
      baby_weight: dataNbs.baby_weight,
      baby_admitted_in_nicu: dataNbs.baby_admitted_in_nicu,
      blood_transfusion: dataNbs.blood_transfusion,
      transfusion_date: dataNbs.transfusion_date,
      gestational_age_weeks: dataNbs.gestational_age_weeks,
      gestational_age_days: dataNbs.gestational_age_days,
      birth_order: dataNbs.birth_order,
      type_of_feed: dataNbs.type_of_feed,
      history: dataNbs.history,
      ch: dataNbs.ch ? true : false,
      cah: dataNbs.cah ? true : false,
      g6pd: dataNbs.g6pd ? true : false,
      gal: dataNbs.gal ? true : false,
      cp: dataNbs.cp ? true : false,
      pku: dataNbs.pku ? true : false,
      bio: dataNbs.bio ? true : false,
    };
    if (dataNbs.transfusion_date === "") {
      delete data.transfusion_date;
    }
    if (sizeofbiopsy === "") {
      delete data.biopsy_size;
    }
    if (batchNo === "") {
      delete data["batch_id"];
    }
    if (labRemark === "") {
      delete data["remarks"];
    }
    if (labRemarkComment === "") {
      delete data["labRemarkComment"];
    }
    if (data.customer_altphonenumber === "") {
      delete data["customer_altphonenumber"];
    }
    if (data.customer_whatsapppnumber === "") {
      delete data["customer_whatsapppnumber"];
    }
    if (customer_aadhar === "") {
      delete data["customer_aadhar"];
    }
    if (passport === "") {
      delete data["passport_number"];
    }
    if (referringDoctor === "") {
      delete data["ref_doctor"];
    }
    if (customer_landmark === "") {
      delete data["landmark"];
    }
    if (withoutPayment) {
      delete data["payment_information"];
    }
    if (data["dob"] === "") {
      delete data["dob"];
    }
    if (data["customer_address"] === null) {
      delete data["customer_address"];
    }
    if (data["address_line2"] === null) {
      delete data["address_line2"];
    }
    if (data["customer_landmark"] === null) {
      delete data["customer_landmark"];
    }
    if (data["customer_areapincode"] === null) {
      delete data["customer_areapincode"];
    }
    if (data["customer_longitude"] === null) {
      delete data["customer_longitude"];
    }
    if (data["customer_latitude"] === null) {
      delete data["customer_latitude"];
    }
    if (data["customer_city"] === null) {
      delete data["customer_city"];
    }
    if (data["cityid"] === null) {
      delete data["cityid"];
    }
    if (data["customer_landmark"] === null) {
      delete data["customer_landmark"];
    }

    if (data["coupon_applied"] === 0) {
      delete data["coupon_applied"];
    }
    if (data["customer_email"] === "") {
      delete data["customer_email"];
    }
    if (
      mobileValidation.customer_alternatenumber &&
      mobileValidation.customer_phonenumber &&
      mobileValidation.customer_whatsapppnumber
    ) {
      setIsBooked(true);
      if (
        validateHistoStatus &&
        biospyPackageName &&
        biospySampleName &&
        relevantClinicalHistory
      ) {
        delete data.birth_time;
        delete data.consanguinity;
        delete data.baby_weight;
        delete data.baby_admitted_in_nicu;
        delete data.blood_transfusion;
        delete data.transfusion_date;
        delete data.gestational_age_weeks;
        delete data.gestational_age_days;
        delete data.birth_order;
        delete data.type_of_feed;
        delete data.history;
        delete data.ch;
        delete data.cah;
        delete data.g6pd;
        delete data.gal;
        delete data.cp;
        delete data.pku;
        delete data.bio;
        await createHistopathologyBooking(data);
      } else if (validateNbsStatus) {
        delete data.patient_name;
        delete data.contact_number;
        delete data.address;
        delete data.biopsy_size;
        delete data.procedure_date;
        delete data.ref_doctor_name;
        delete data.ref_doctor_phone;
        delete data.center_lab_name;
        delete data.relevant_clinical_history;
        delete data.clinical_diagnosis;
        delete data.radiology_findings;
        delete data.operative_findings;
        delete data.type_of_specimen;
        delete data.samples_acceptable;
        delete data.site_specification;
        await createNbsBooking(data);
      } else {
        delete data.birth_time;
        delete data.consanguinity;
        delete data.baby_weight;
        delete data.baby_admitted_in_nicu;
        delete data.blood_transfusion;
        delete data.transfusion_date;
        delete data.gestational_age_weeks;
        delete data.gestational_age_days;
        delete data.birth_order;
        delete data.type_of_feed;
        delete data.history;
        delete data.ch;
        delete data.cah;
        delete data.g6pd;
        delete data.gal;
        delete data.cp;
        delete data.pku;
        delete data.bio;
        delete data.patient_name;
        delete data.contact_number;
        delete data.address;
        delete data.biopsy_size;
        delete data.procedure_date;
        delete data.ref_doctor_name;
        delete data.ref_doctor_phone;
        delete data.center_lab_name;
        delete data.relevant_clinical_history;
        delete data.clinical_diagnosis;
        delete data.radiology_findings;
        delete data.operative_findings;
        delete data.type_of_specimen;
        delete data.samples_acceptable;
        delete data.site_specification;
        await createNewDCBooking(data);
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (createDcBooking.id && isBooked) {
      setBookingId(createDcBooking.id);
    }
  }, [createDcBooking]);
  const handleInputChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    if (e.target.name === "designation") {
      const findGender = GENDER_CHOICE.filter(
        (el: any) => el.display_name === e.target.value
      );
      list[index]["customerGender"] = findGender[0]?.gender;
      list[index]["customerDesignation"] = findGender[0]?.db_name;
    }
    if (e.target.name === "dateOfBirth") {
      handleDOBForAge(e.target.value);
      let today = new Date();
      let birthDate = new Date(e.target.value);
      let age = today.getUTCFullYear() - birthDate.getUTCFullYear();
      var m = today.getUTCMonth() - birthDate.getUTCMonth();
      if (m < 0 || (m === 0 && today.getUTCDate() < birthDate.getUTCDate())) {
        age--;
      }
      list[index]["customerAge"] = age;
    }
    setInputList(list);
  };
  const createHistopathologyBookingModel = () => {
    SetOpenHistoryForm(true);
  };

  const handlePackage = (index: number, customerPackages: any) => {
    const list = [...inputList];
    list[index]["customerPackage"] = customerPackages;
    setInputList(list);
    let accumulatedPrice: any = 0;
    if (customerPackages && customerPackages.length > 0) {
      customerPackages.map((item: any) => {
        if (item?.package_lab_prices !== null) {
          accumulatedPrice = accumulatedPrice + item.package_lab_prices;
        }
      });
    }
    // set price and package for row
    list[index]["packagePrice"] = accumulatedPrice;
    setInputList(list);

    let allPackagePrice = 0;
    inputList &&
      inputList.map((rowItem: any) => {
        allPackagePrice = allPackagePrice + rowItem?.packagePrice;
      });

    let storeData: any = [];
    let perPack: any = 1;
    list?.map((list: any) => {
      list?.customerPackage?.map((list2: any) => {
        storeData.push({
          id: list2?.id,
          name: list2?.name,
          code: list2?.code,
          checkHistoStatus: list2?.histopathology_history_form_needed,
          checkNbsStatus: list2?.babyscreen_form_needed,
        });
        if (list2?.babyscreen_form_needed) {
          perPack =
            list2?.name.match(/\d+/g) !== null &&
            Number(list2?.name.match(/\d+/g)[0]) > perPack
              ? list2?.name.match(/\d+/g)[0]
              : perPack;
        }
      });
    });

    storeData.length > 0 &&
      storeData.map((val: any) => {
        if (val.checkHistoStatus) {
          setBiospyPackageCode(val.code);
          setBiospyPackageName(val.name);
        }
      });

    let btnStatus: boolean = storeData?.some(
      (list: any) => list.checkHistoStatus
    );
    let nbsStatus: boolean = storeData?.some(
      (list: any) => list.checkNbsStatus
    );

    setValidateHistoStatus(btnStatus);
    setValidateNbsStatus(nbsStatus);
    setPerPackNbs(Number(perPack));
    // setCheckForHistoBtn(storeData);
    setPrice(Math.floor(allPackagePrice));

    setTotalPrice(Math.floor(allPackagePrice));
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (centre !== "") {
      // getDCPackage(centre, "");
      getCampCoupons(centre);
    }
  }, [centre]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (campCoupon && campCoupon.results && campCoupon.results.length !== 0) {
      setCampCouponData(campCoupon.results[0]);
    }
  }, [campCoupon]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    let dis = 0;

    if (campCouponData !== "" && inputList[0].customerPackage.length > 0) {
      campCouponData.packages.map((val: any) => {
        inputList[0].customerPackage.map((dat: any) => {
          if (
            dat.id === val.id &&
            campCouponData.to_age >= Number(inputList[0].customerAge) &&
            Number(inputList[0].customerAge) >= campCouponData.from_age
          ) {
            if (
              inputList[0].customerGender === campCouponData.gender ||
              campCouponData.gender === "both"
            ) {
              dis =
                dis + (dat.package_lab_prices * campCouponData.discount) / 100;

              setCoupon(campCouponData.id as Number);
              setCampCouponCode(campCouponData.code as string);
            }
          } else {
            if (dis === 0) {
              setCampCouponCode("");
              setCoupon(0);
            }
          }
        });
      });
      setDiscount(dis);
    }
  }, [campCouponData, inputList]);

  console.log(biospyPackageCode,"biospyPackageCode")
  console.log(biospyPackageName,"biospyPackageName")
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const list = [...inputList];
    const pkgs = [...list[0].customerPackage];
    const rchg = {
      id: environment === "live" ? 7567 : 12421,
      name: "Registration Charges",
      code: "RC10",
      offer_price: 10,
      package_price: 10,
      package_lab_prices: 10,
    };
    if (campCouponCode !== "") {
      pkgs.map((dat: any, index: any) => {
        campCouponData.packages.map((val: any) => {
          if (dat.id !== rchg.id && dat.id === val.id) {
            pkgs.push(rchg);
            // list[0]["customerPackage"] = pkgs;
            // setInputList(list);
            handlePackage(0, pkgs);
          }
        });
      });
    } else {
      pkgs.map((dat: any, index: any) => {
        if (dat.id === rchg.id) {
          pkgs.splice(index, 1);
          // list[0]["customerPackage"] = pkgs;
          // setInputList(list);
          handlePackage(0, pkgs);
        }
      });
    }
  }, [campCouponCode]);

  //doc modal
  const [openDocModal, setOpenDocModal] = useState<any>(false);
  const handleAddDoctor = () => {
    setOpenDocModal(true);
  };

  const [openCollectionModal, setOpenCollectionModal] = useState<any>(false);
  const handleCollectionSample = () => {
    setOpenCollectionModal(true);
  };
  const handleCollectionSampleClose = () => {
    setOpenCollectionModal(false);
    navigate(`/dashboard/la/booking-view/${createDcBooking.id}`);
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (bookingId) {
      handleCollectionSample();
    }
  }, [bookingId]);

  //
  const handleOpenNbs = () => {
    setOpenNbsModal(true);
  };
  //mapmyindia
  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [mapAddressObject, setMapAddressObject] = useState<any>({});
  const [accessToken, setAccessToken] = useState<any>("");

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
      setCustomerLatitude(geoFencesMapMyIndia?.latitude);
      setCustomerLongitude(geoFencesMapMyIndia?.longitude);
    }
  }, [geoFencesMapMyIndia]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (mapmyindiaAccessToken?.accesstoken) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken);
    }
  }, [mapmyindiaAccessToken]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (mapAddressObject?.eLoc) {
      getPinCode(mapAddressObject?.addressTokens?.pincode);
      resetAddressComponent();
      setCustomer_landmark(mapAddressObject?.placeAddress);
      setPinCode(mapAddressObject?.addressTokens?.pincode);
      checkGeoFenchMapMyIndia(mapAddressObject?.eLoc, "NA");
    }
  }, [mapAddressObject]);

  const handleDOBForAge = (dob: any) => {
    const a: any = moment();
    const b: any = moment(dob);
    var years = a.diff(b, "year");
    b.add(years, "years");
    var months = a.diff(b, "months");
    b.add(months, "months");
    var days = a.diff(b, "days");
    inputList[0].customerAgeMonths = months;
    inputList[0].customerAgeDays = days;
  };
  //dob end
  const locationAutoCompleteSearch = (val: string) => {
    if (val.length > 2) {
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        window[callbackName] = (data: any) => {
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          const suggestedData = data?.suggestedLocations;
          if (suggestedData.length > 0) {
            setSuggestedAddres(data?.suggestedLocations);
          }
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    }
  };
  //mapmyindia

  const LAB_REMARKS: any = [
    {
      text: "Test code mismatch with Test name",
      value: "Test code mismatch with Test name",
    },
    {
      text: "Client code mismatch with Client name written on the TRF",
      value: "Client code mismatch with Client name written on the TRF",
    },
    {
      text: "Wrong sample Type Received",
      value: "Wrong sample Type Received",
    },
    {
      text: "Illegible information provided on TRF/History (Blurred details)",
      value: "Illegible information provided on TRF/History (Blurred details)",
    },
    {
      text: "Rejected - due to prolonged sample collection (Received after >72 Hrs)",
      value:
        "Rejected - due to prolonged sample collection (Received after >72 Hrs)",
    },
    {
      text: "Without Barcode",
      value: "Without Barcode",
    },
    {
      text: "Clinical History",
      value: "Clinical History",
    },
    {
      text: "DOC & Time",
      value: "DOC & Time",
    },
    {
      text: "Correct TRF",
      value: "Correct TRF",
    },
    {
      text: "Test Confirmation",
      value: "Test Confirmation",
    },
    {
      text: "Conception Details",
      value: "Conception Details",
    },
    {
      text: "Improper USG Detail",
      value: "Improper USG Detail",
    },
    {
      text: "Form G /Form E Not Received",
      value: "Form G /Form E Not Received",
    },
    {
      text: "DOB/Age Confirmation",
      value: "DOB/Age Confirmation",
    },
    {
      text: "Referred Doctor Name",
      value: "Referred Doctor Name",
    },
    {
      text: "Clinician Mail ID / Contact Number(NGS Samples)",
      value: "Clinician Mail ID / Contact Number(NGS Samples)",
    },
    {
      text: "Platform Confirmation",
      value: "Platform Confirmation",
    },
    {
      text: "Incomplete/Improper TRF",
      value: "Incomplete/Improper TRF",
    },
    {
      text: "Rejected - Sales Request",
      value: "Rejected - Sales Request",
    },
    {
      text: "Rejected - Sample quality (Hemolyzed/Lipemic/Wrong Container/Leaked)",
      value:
        "Rejected - Sample quality (Hemolyzed/Lipemic/Wrong Container/Leaked)",
    },
    {
      text: "Rejected - No Sample Received(Only TRF Received)",
      value: "Rejected - No Sample Received(Only TRF Received)",
    },
    {
      text: "Rejected - Sample Received Without Proper Identification",
      value: "Rejected - Sample Received Without Proper Identification",
    },
    {
      text: "Rejected - Sample in Expired Vial",
      value: "Rejected - Sample in Expired Vial",
    },
    {
      text: "Sample Received Without TRF",
      value: "Sample Received Without TRF",
    },
    {
      text: "USG Not Received",
      value: "USG Not Received",
    },
    {
      text: "USG Not Received &Test Not Mentioned",
      value: "USG Not Received &Test Not Mentioned",
    },
    {
      text: "USG Not Received & Without TRF",
      value: "USG Not Received & Without TRF",
    },
    {
      text: "Sanger Variant Detail Confirmation/Not Received",
      value: "Sanger Variant Detail Confirmation/Not Received",
    },
    {
      text: "USG Not Received &Test Not Mentioned",
      value: "USG Not Received &Test Not Mentioned",
    },
    {
      text: "Client Code / Name Not Mentioned",
      value: "Client Code / Name Not Mentioned",
    },
    {
      text: "Repeat Sample : Technical Reason",
      value: "Repeat Sample : Technical Reason",
    },
    {
      text: "Other",
      value: "Other",
    },
  ];

  return (
    <main className={classes.content}>
      {(notificationData !== "" &&
        notificationData?.type === "prepaid" &&
        notificationData?.minAmount >= notificationData?.balance) ||
      (notificationData !== "" &&
        notificationData?.type === "postpaid" &&
        notificationData?.minAmount >=
          notificationData?.balance + notificationData?.creditLimit) ? (
        <h5 style={{ color: "red" }}>Please Recharge Your Account!!!</h5>
      ) : (
        <></>
      )}

      {notificationData.balance ? (
        <b>Current Centre Balance :{notificationData?.balance}</b>
      ) : (
        ""
      )}

      <div className={classes.contentTable}>
        <Grid container spacing={1}>
          {/* <Grid item xs={12} md={2}>
            <p>Search Patient</p>
          </Grid> */}
          <Grid
            container
            item
            xs={12}
            md={6}
            direction="row"
            justify="flex-start"
          >
            <TextField
              className="input"
              id="search"
              type="search"
              value={search}
              variant="outlined"
              placeholder="Search by Name/Phone No./Booking Id"
              onChange={(e) => handleSearch(e)}
              style={{ margin: "0", padding: "0 9px", width: "80%" }}
              onKeyUp={inputKeyUp}
            />
            <Button
              variant="contained"
              style={{ width: "20%", height: "40px" }}
              disabled={search.length === 0}
              color="primary"
              onClick={searchLead}
            >
              Search
            </Button>
          </Grid>
          {/* <Grid item xs={12} md={2}>
                  <p>Select Centre*</p>
                </Grid> */}
          <Grid item xs={12} md={3}>
            <Autocomplete
              id="center"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setCentre(obj.id);
                  setCentreData(obj);
                  setCentreType(obj?.type);
                  setRateList(obj.ratelist[0]?.name);
                  setCenterName(obj.name);
                }
              }}
              // defaultValue={
              //   centerInfo.results &&
              //   centerInfo.results.find((code: any) => {
              //     return code.id === centre;
              //   })
              // }
              freeSolo
              blurOnSelect
              // disabled={centerInfo.results === undefined}
              options={centerInfo.results || []}
              // disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                (option?.display_name === null
                  ? option?.name
                  : option?.display_name) +
                "(" +
                option?.center_code +
                ")"
              }
              onInputChange={(event, newInputValue) => {
                if (String(newInputValue).length === 0) {
                  setRateList("");
                }
                setTimeout(
                  getCenterInfo(`?c_type=true&name_or_code=${newInputValue}`),
                  1500
                );
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Centre"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                      }
                    },
                  }}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              className="input"
              variant="outlined"
              fullWidth
              label="Service Rate Category"
              value={rateList}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          {/* <Grid item xs={12} md={3}>
            
          </Grid> */}
        </Grid>
        <Dialog
          open={openLeadModal}
          onClose={() => setOpenLeadModal(false)}
          fullWidth
          maxWidth="lg"
        >
          {leads.results && leads.results.length > 0 && (
            <TableContainer className={classes.table}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Lead ID</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Age</TableCell>
                    <TableCell align="center">Gender</TableCell>
                    <TableCell align="center">Mobile Number</TableCell>
                    <TableCell align="center">Whatsapp Number</TableCell>
                    <TableCell align="center">Address</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leads.results.map((lead: any) => {
                    return (
                      <TableRow>
                        <TableCell align="center">{lead.id || ""}</TableCell>
                        <TableCell align="center">
                          {lead.patient_name || ""}
                        </TableCell>
                        <TableCell align="center">
                          {lead.patient_age || ""}
                        </TableCell>
                        <TableCell align="center">
                          {lead.calling_gender || ""}
                        </TableCell>
                        <TableCell align="center">XXXXXXXXXX</TableCell>
                        <TableCell align="center">XXXXXXXXXX</TableCell>
                        <TableCell align="center">
                          {lead.address || ""}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => selectLead(lead.id)}
                          >
                            Select
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Dialog>
      </div>
      <div className={classes.contentTable}>
        <form onSubmit={submitForm}>
          <Grid container spacing={3}></Grid>
          {inputList.map((x: any, index: number) => {
            return (
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-end"
                spacing={2}
                style={{ margin: "1rem auto", background: "#eff" }}
              >
                <Grid item md={2} xs={12}>
                  <p>Salutation*</p>
                  <Select
                    className="input"
                    name="designation"
                    variant="outlined"
                    value={x.designation}
                    style={{ width: "100%", margin: "0", height: "40px" }}
                    onChange={(e) => handleInputChange(e, index)}
                    required
                  >
                    <MenuItem value="Mr">Mr</MenuItem>
                    <MenuItem value="Mrs">Mrs</MenuItem>
                    <MenuItem value="Ms">Ms</MenuItem>
                    <MenuItem value="Dr">Dr</MenuItem>
                    <MenuItem value="Master">Master</MenuItem>
                    <MenuItem value="Baby">Baby</MenuItem>
                  </Select>
                </Grid>
                <Grid item md={3} xs={12}>
                  <p>Patient Name*</p>
                  <TextField
                    className="input"
                    name="customerName"
                    type="text"
                    placeholder="Enter Name"
                    value={x.customerName || ""}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: "100%", margin: "0" }}
                    onChange={(e) => handleInputChange(e, index)}
                    required
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <p>DOB</p>
                  <TextField
                    className="input"
                    name="dateOfBirth"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={x.dateOfBirth}
                    style={{ width: "100%", margin: "0", height: "40px" }}
                    onChange={(e) => handleInputChange(e, index)}
                    fullWidth
                    inputProps={{
                      max: moment().subtract(1, "days").format("YYYY-MM-DD"),
                    }}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <p>Age*</p>
                  <TextField
                    className="input"
                    name="customerAge"
                    type="number"
                    onWheel={(e: any) => e.target.blur()}
                    required
                    label="Years"
                    placeholder="Enter Age"
                    disabled={x.dateOfBirth !== ""}
                    value={x.customerAge}
                    variant="outlined"
                    InputProps={{ inputProps: { min: 0 } }}
                    style={{ width: "33%", margin: "0", height: "40px" }}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                  <TextField
                    className="input"
                    name="customerAgeMonths"
                    type="number"
                    label="Months"
                    disabled
                    value={x.customerAgeMonths}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: "33%", margin: "0", height: "40px" }}
                  />
                  <TextField
                    className="input"
                    name="customerAgeDays"
                    type="number"
                    label="Days"
                    disabled
                    value={x.customerAgeDays}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: "33%", margin: "0", height: "40px" }}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <p>Gender*</p>
                  <Select
                    className="input"
                    name="customerGender"
                    variant="outlined"
                    value={x.customerGender}
                    style={{ width: "100%", margin: "0", height: "40px" }}
                    onChange={(e) => handleInputChange(e, index)}
                    required={true}
                  >
                    <MenuItem value={"male"}>Male</MenuItem>
                    <MenuItem value={"female"}>Female</MenuItem>
                    <MenuItem value={"other"}>Other</MenuItem>
                  </Select>
                </Grid>

                <Grid item md={4} xs={12}>
                  <p>Test Name*</p>
                  <Autocomplete
                    id="customerPackage"
                    key={packageKey}
                    loading={loading}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let packs: any = [];
                        newValue.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          packs.push(obj);
                        });
                        handlePackage(index, packs);
                      }
                    }}
                    multiple
                    disableClearable
                    options={dcpackageList.results || []}
                    limitTags={0}
                    disabled={
                      centre === "" ||
                      x.customerGender === "" ||
                      x.customerAge === ""
                    }
                    freeSolo
                    disableCloseOnSelect
                    aria-required
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getDCPackage(centre, newInputValue);
                      }, 700);
                    }}
                    value={x?.customerPackage}
                    getOptionLabel={(option: any) => {
                      return (
                        String(option?.name) +
                        "(" +
                        String(option?.code) +
                        ")" +
                        ", Rs. " +
                        String(option?.package_lab_prices)
                      );
                    }}
                    getOptionDisabled={(option: any) => {
                      const packID = x?.customerPackage.map(
                        (pack: any) => pack.id
                      );
                      return packID.includes(option.id);
                    }}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {String(option?.name) +
                          "(" +
                          String(option?.code) +
                          ")" +
                          ", Rs. " +
                          String(option?.package_lab_prices)}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Test"
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                {!pincode ? (
                  <Grid item md={2} xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setOpenLocationModal(true)}
                      // disabled={collection_date === ""}
                    >
                      Select Address
                    </Button>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            );
          })}
          {pincode && (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-end"
              spacing={2}
              style={{ margin: "1rem auto", background: "#eff" }}
            >
              <Grid item md={4} xs={12}>
                <p>House No./Ploat No./Flat No./Door No./Shop/C/-</p>
                <TextField
                  name="customer_address"
                  type="text"
                  size="small"
                  placeholder="House No./Ploat No./Flat No./Door No./Shop/C/-"
                  value={customer_address}
                  disabled
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <p>
                  Appartment/Building/Colony/Block/Sector/
                  Street/Gali/Road/Choll
                </p>
                <TextField
                  name="addressLine2"
                  type="text"
                  size="small"
                  disabled
                  placeholder="Appartment/Building/Colony/Block/Sector/Street/Gali/Road/Choll"
                  value={addressLine2}
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <p>Landmark/Sublocality</p>
                <TextField
                  name="favoritePlace"
                  type="text"
                  size="small"
                  disabled
                  placeholder="Landmark/Sublocality"
                  value={favoritePlace}
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <p>Pincode:</p>
                <TextField
                  name="Pincode"
                  type="text"
                  size="small"
                  disabled
                  placeholder="Pincode"
                  value={pincode}
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <p>Zone:</p>
                <TextField
                  name="Zone"
                  type="text"
                  size="small"
                  placeholder="Zone"
                  disabled
                  value={zoneName}
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <p>City:</p>
                <TextField
                  name="City"
                  type="text"
                  size="small"
                  placeholder="City"
                  disabled
                  value={customer_city}
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenLocationModal(true)}
                  // disabled={collection_date === ""}
                >
                  Edit Address
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            spacing={2}
            style={{ margin: "1rem auto", background: "#eff" }}
          >
            <Grid item md={3} xs={12}>
              <p>Mobile Number*</p>
              {lead_customer_phonenumber !== undefined ? (
                <TextField
                  className="input"
                  name="customer_phonenumber"
                  disabled={lead_customer_phonenumber !== undefined}
                  value={"XXXXXXXXXX"}
                  placeholder="Enter Mobile Number"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              ) : (
                <TextField
                  className="input"
                  name="customer_phonenumber"
                  disabled={lead_customer_phonenumber !== undefined}
                  value={customer_phonenumber}
                  placeholder="Enter Mobile Number"
                  variant="outlined"
                  style={{ margin: "0", width: "100%", height: "40px" }}
                  onChange={(e) =>
                    setCustomer_phonenumber(e.target.value as String)
                  }
                  type="number"
                  onWheel={(e: any) => e.target.blur()}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onBlur={() => verifyMobile("mobile")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      customer_phonenumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.customer_phonenumber &&
                    "Incorrect Phone Number"
                  }
                  required
                />
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              <p>Alternate Mobile Number</p>
              {lead_customer_phonenumber !== undefined ? (
                <TextField
                  className="input"
                  name="customer_alternatenumber"
                  disabled={lead_customer_phonenumber !== undefined}
                  value={"XXXXXXXXXX"}
                  placeholder="Enter Alternate Mobile Number"
                  variant="outlined"
                  style={{ margin: "0", width: "100%", height: "40px" }}
                />
              ) : (
                <TextField
                  className="input"
                  name="customer_alternatenumber"
                  type="number"
                  onWheel={(e: any) => e.target.blur()}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  disabled={lead_customer_phonenumber !== undefined}
                  value={customer_alternatenumber}
                  placeholder="Enter Alternate Mobile Number"
                  variant="outlined"
                  style={{ margin: "0", width: "100%", height: "40px" }}
                  onChange={(e) =>
                    setCustomer_alternatenumber(e.target.value as String)
                  }
                  onBlur={() => verifyMobile("alternate")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      customer_alternatenumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.customer_alternatenumber &&
                    "Incorrect Phone Number"
                  }
                />
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              <p>Whatsapp Number</p>
              {lead_customer_whatsapppnumber !== undefined ? (
                <TextField
                  className="input"
                  name="customer_whatsapppnumber"
                  placeholder="Enter Whatsapp Number"
                  disabled={lead_customer_whatsapppnumber !== undefined}
                  value={"XXXXXXXXXX"}
                  variant="outlined"
                  style={{ margin: "0", width: "100%", height: "40px" }}
                />
              ) : (
                <TextField
                  className="input"
                  name="customer_whatsapppnumber"
                  type="number"
                  onWheel={(e: any) => e.target.blur()}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  placeholder="Enter Whatsapp Number"
                  disabled={lead_customer_whatsapppnumber !== undefined}
                  value={customer_whatsapppnumber}
                  variant="outlined"
                  style={{ margin: "0", width: "100%", height: "40px" }}
                  onChange={(e) =>
                    setCustomer_whatsapppnumber(e.target.value as String)
                  }
                  onBlur={() => verifyMobile("whatsapp")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      customer_whatsapppnumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.customer_whatsapppnumber &&
                    "Incorrect Whatsapp Number"
                  }
                />
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              <p>Email</p>
              <TextField
                className="input"
                name="customer_email"
                type="email"
                placeholder="customer.reports@redcliffelabs.com"
                value={customer_email}
                variant="outlined"
                style={{ margin: "0", width: "100%", height: "40px" }}
                onChange={(e) => setCustomer_email(e.target.value as String)}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <p>Aadhaar Number</p>
              <TextField
                className="input"
                name="customer_aadhar"
                type="number"
                onWheel={(e: any) => e.target.blur()}
                onInput={(e: any) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 16);
                }}
                placeholder="Enter Aadhaar Number"
                value={customer_aadhar}
                variant="outlined"
                style={{ margin: "0", width: "100%", height: "40px" }}
                onChange={(e) => setCustomer_aadhar(e.target.value as String)}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <p>Passport Number</p>
              <TextField
                className="input"
                name="passport"
                type="text"
                placeholder="Enter Passport Number"
                value={passport}
                variant="outlined"
                style={{ margin: "0", width: "100%", height: "40px" }}
                onChange={(e) => setPassport(e.target.value as String)}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <p>Referring Doctor</p>
              <div style={{ display: "flex" }}>
                <Autocomplete
                  style={{ width: "90%" }}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setReferringDoctor(obj.id);
                      setRefDoctorName(obj.name ? obj.name : "");
                      setRefDoctorNumber(obj.mobile ? obj.mobile : "");
                    }
                  }}
                  freeSolo
                  blurOnSelect
                  options={refDoctor?.results || []}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getRefDoctor(`?name=${newInputValue}`);
                    }, 1000);
                  }}
                  loading={loading}
                  disableCloseOnSelect
                  disableClearable
                  getOptionLabel={(option: any) => option?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Doctor's Name"
                      variant="outlined"
                    />
                  )}
                />
                <AddCircleIcon
                  style={{ height: "40px", width: "40px" }}
                  onClick={handleAddDoctor}
                />
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              <p>Batch Number</p>
              <TextField
                className="input"
                name="batch"
                type="number"
                placeholder="Batch Number"
                value={batchNo}
                variant="outlined"
                style={{ margin: "0", width: "100%", height: "40px" }}
                onChange={(e) => setBatchNo(e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpenNbsModal(true)}
                disabled={validateNbsStatus === false}
              >
                NBS FORM
              </Button>
            </Grid>
            <Grid item md={3} xs={12}>
              <Button
                variant="contained"
                color="primary"
                disabled={validateHistoStatus === false}
                onClick={createHistopathologyBookingModel}
              >
                HISTOPATHOLOGY FORM
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            direction="row"
            style={{ margin: "1rem auto" }}
          >
            <Grid item xs={12} md={7} style={{ padding: "0px 10px" }}>
              <div className="price-details-new-booking-la">
                <table style={{ width: "100%", margin: "0" }}>
                  <thead>
                    <tr>
                      <td className="cell" align="center">
                        S No.
                      </td>
                      <td className="cell" align="center">
                        Test code
                      </td>

                      <td className="cell" align="center">
                        Test Name
                      </td>

                      <td className="cell" align="center">
                        TAT
                      </td>
                      <td className="cell" align="center">
                        Price
                      </td>
                      <td className="cell" align="center">
                        Camp Discount
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {inputList &&
                      inputList.length > 0 &&
                      inputList.map(
                        (customerDetails: any, index: number) =>
                          customerDetails?.customerPackage &&
                          customerDetails?.customerPackage.length > 0 &&
                          customerDetails?.customerPackage.map(
                            (packageItem: any, index: any) => {
                              return (
                                <tr>
                                  <td className="cell" align="center">
                                    {index + 1}
                                  </td>
                                  <td className="cell" align="center">
                                    {packageItem?.code}
                                  </td>
                                  <td className="cell" align="left">
                                    <a className={classes.packageLink}>
                                      {packageItem?.name}
                                      <br />
                                      Fasting : {packageItem?.fasting_time}
                                    </a>
                                  </td>
                                  <td className="cell" align="center">
                                    {packageItem.tat_time}
                                  </td>
                                  <td className="cell" align="center">
                                    ₹ {packageItem.package_lab_prices}
                                  </td>
                                  <td className="cell" align="center">
                                    {campCouponData !== ""
                                      ? campCouponData.packages.map(
                                          (val: any) => {
                                            if (
                                              packageItem.id === val.id &&
                                              coupon !== 0
                                            ) {
                                              return (
                                                "₹" +
                                                Math.floor(
                                                  (packageItem.package_lab_prices *
                                                    campCouponData.discount) /
                                                    100
                                                )
                                              );
                                            } else {
                                              return "";
                                            }
                                          }
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              );
                            }
                          )
                      )}
                    <tr>
                      <td colSpan={4}>
                        <table style={{ width: "100%" }}>
                          <tr>
                            <td
                              className="cell"
                              align="left"
                              rowSpan={4}
                              colSpan={2}
                              style={{ width: "60%" }}
                              valign="top"
                            >
                              <Grid container spacing={1} direction="row">
                                <Grid item xs={12}>
                                  <p>Enter Coupon</p>
                                </Grid>
                                <Grid item xs={8}>
                                  {campCouponCode !== "" ? (
                                    <TextField
                                      placeholder="Enter Coupon"
                                      value={campCouponCode}
                                      variant="outlined"
                                      style={{
                                        width: "100%",
                                        margin: "0",
                                        padding: "0",
                                      }}
                                    />
                                  ) : (
                                    <Autocomplete
                                      id="coupon"
                                      onChange={(event, newValue) => {
                                        if (newValue) {
                                          let obj = JSON.parse(
                                            JSON.stringify(newValue, null, " ")
                                          );
                                          setCoupon(obj.id as Number);
                                          setCouponCode(obj.code as string);
                                          // setCouponType(obj.is_red_cash);
                                        }
                                      }}
                                      inputValue={couponCode}
                                      options={couponList?.results || []}
                                      freeSolo
                                      blurOnSelect
                                      onInputChange={(event, newInputValue) => {
                                        clearTimeout(timer.current);
                                        setCouponCode(newInputValue);
                                        timer.current = setTimeout(() => {
                                          getCoupons(
                                            `?code=${newInputValue}&org_type=homedx`
                                          );
                                        }, 1000);
                                        if (newInputValue === "") {
                                          setCoupon(0);
                                          setDiscount(0);
                                          setTncMessage("");
                                          setCouponMessage("");
                                        }
                                      }}
                                      getOptionLabel={(option: any) =>
                                        option.code
                                      }
                                      getOptionDisabled={(option: any) =>
                                        option.minimum_price > totalPrice
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Enter Coupon"
                                          variant="outlined"
                                          disabled={
                                            inputList[0]?.customerPackage
                                              ?.length === 0
                                          }
                                          inputProps={{
                                            ...params.inputProps,
                                            onKeyDown: (e) => {
                                              if (e.key === "Enter") {
                                                e.stopPropagation();
                                              }
                                            },
                                          }}
                                          style={{
                                            width: "100%",
                                            margin: "0",
                                            padding: "0",
                                          }}
                                        />
                                      )}
                                    />
                                  )}
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    onClick={applyCoupon}
                                    disabled={campCouponCode !== ""}
                                  >
                                    {campCouponCode !== ""
                                      ? "Camp Coupon Applied"
                                      : "Apply"}
                                  </Button>
                                </Grid>
                                <p
                                  style={{
                                    color: "#8e24aa",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    alignItems: "center",
                                  }}
                                >
                                  {couponMessage} <br />
                                  {tncMessage}
                                </p>
                              </Grid>
                            </td>
                            <td
                              className="cell"
                              align="right"
                              style={{ width: "20%" }}
                            >
                              Gross
                            </td>
                            <td
                              className="cell"
                              align="center"
                              style={{ width: "20%" }}
                            >
                              ₹ {Math.floor(totalPrice)}
                            </td>
                          </tr>
                          {/* <tr>
                            <td
                              className="cell"
                              align="right"
                              style={{ width: "20%" }}
                            >
                              RedCash Discount
                            </td>
                            <td
                              className="cell"
                              align="center"
                              style={{ width: "20%" }}
                            >
                              ₹ {redDisc > 0 ? Math.floor(redDisc) : 0}
                            </td>
                          </tr>
                          <tr>
                            <td className="cell" align="right">
                              Phlebo Cost
                            </td>
                            <td className="cell" align="center">
                              ₹ {extraCharge ? "+ 200" : "0"}
                            </td>
                          </tr> */}
                          <tr>
                            <td
                              className="cell"
                              align="right"
                              style={{ width: "20%" }}
                            >
                              Discount
                            </td>
                            <td
                              className="cell"
                              align="center"
                              style={{ width: "20%" }}
                            >
                              ₹ {Math.floor(discount)}
                            </td>
                          </tr>
                          <tr>
                            <td className="cell" align="right">
                              <h4 className="totalPrice">Net Amount</h4>
                            </td>
                            <td className="cell" align="center">
                              <h4 className="totalPrice">
                                ₹ {Math.floor(netPrice)}
                              </h4>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Grid>
            <Grid item xs={12} md={5} style={{ padding: "10px" }}>
              <FormControlLabel
                value={withoutPayment}
                disabled={withoutPayment && centreType === "postpaid"}
                control={<Checkbox color="primary" />}
                label={"Proceed Without Payment"}
                checked={withoutPayment}
                onChange={(e) => setWithoutPayment(!withoutPayment)}
              />
              {withoutPayment ? (
                ""
              ) : (
                <Payment
                  totalPrice={Math.floor(netPrice)}
                  setPaymentInfo={setPaymentInfo}
                  setDue={setDue}
                  setPaid={setPaid}
                  autoPay={
                    withoutPayment && centreType === "postpaid" ? false : true
                  }
                />
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            spacing={2}
            style={{ margin: "1rem auto", background: "#eff" }}
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" component="h1" gutterBottom align="left">
                History/Remark
              </Typography>
              <Select
                className="input"
                name="disposition"
                variant="outlined"
                value={labRemark}
                style={{
                  width: "100%",
                  margin: "0",
                  padding: "0",
                  height: "40px",
                }}
                onChange={(e) => setLabRemark(e.target.value as string)}
              >
                {LAB_REMARKS.map((item: any) => {
                  return (
                    <MenuItem key={item.text} value={`${item.text}`}>
                      {item.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            {labRemark === "Other" ? (
              <>
                <Grid item xs={12} md={6}>
                  <TextareaAutosize
                    aria-label="minimum height"
                    disabled={
                      labRemark?.includes("Other") === true ? false : true
                    }
                    minRows={3}
                    maxLength={1000}
                    placeholder=" "
                    value={labRemarkComment || ""}
                    className="input"
                    style={{ width: "100%", margin: "0", height: "37px" }}
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                      setLabRemarkComment(e.target.value as string)
                    }
                  />
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{ marginLeft: "1rem", marginBottom: "20px" }}
              disabled={
                withoutPayment
                  ? loading ||
                    (validateHistoStatus && !biospyPackageName
                      ? true
                      : false) ||
                    (validateHistoStatus && !biospySampleName ? true : false) ||
                    (validateHistoStatus && !relevantClinicalHistory
                      ? true
                      : false)
                  : loading ||
                    paid === 0 ||
                    due < 0 ||
                    (validateHistoStatus && !biospyPackageName
                      ? true
                      : false) ||
                    (validateHistoStatus && !biospySampleName ? true : false) ||
                    (validateHistoStatus && !relevantClinicalHistory
                      ? true
                      : false)
              }
            >
              Proceed for booking
            </Button>
          </div>
          <div className={classes.mszContainer}>
            {(validateHistoStatus && !biospyPackageName ? true : false) ||
            (validateHistoStatus && !biospySampleName ? true : false) ||
            (validateHistoStatus && !relevantClinicalHistory ? true : false) ? (
              <p style={{ color: "red" }}>
                Please fill all mandatory fields of histopathology form to
                continue.
              </p>
            ) : (
              ""
            )}
          </div>
          {/* Location Map Modal  */}
          <Dialog
            open={openLocationModal}
            onClose={() => setOpenLocationModal(false)}
            aria-labelledby="form-dialog-title"
            className={classes.dialog}
          >
            <DialogTitle id="form-dialog-title">Add New Address</DialogTitle>
            <DialogContent>
              <Grid
                container
                spacing={2}
                style={{
                  margin: "0px 10px 10px 10px",
                  minWidth: "500px",
                  width: "70%",
                }}
              >
                <Grid item xs={12} md={12}>
                  <p>Locality</p>
                  <Autocomplete
                    id="colony-area-sector"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setMapAddressObject(obj);
                      }
                    }}
                    options={suggestedAddress ? suggestedAddress : []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    getOptionLabel={(option: any) =>
                      `${option?.placeName}, ${option?.placeAddress}`
                    }
                    onInputChange={(event, newInputValue) => {
                      locationAutoCompleteSearch(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search Address"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        disabled={collection_date === ""}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                        required
                      />
                    )}
                  />
                  <p style={{ color: "red" }}>{errorMessage}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>Pin Code</p>
                  <TextField
                    name="zone"
                    type="text"
                    value={pincode || 0}
                    disabled
                    variant="outlined"
                    style={{ width: "100%", margin: "0", background: "#ccc" }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>Area</p>
                  <TextField
                    name="Area Name"
                    type="text"
                    value={zoneName || ""}
                    disabled
                    variant="outlined"
                    style={{ width: "100%", margin: "0", background: "#ccc" }}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <p>House No./Plot No./Flat No./Door No./Shop/ c/o</p>
                  <TextField
                    name="zone"
                    type="text"
                    value={customer_address}
                    variant="outlined"
                    style={{ width: "100%", margin: "0" }}
                    inputProps={{ maxLength: 30 }}
                    required
                    onChange={(e) =>
                      setCustomer_address(e.target.value as string)
                    }
                  />
                  <small>Max Length 30</small>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>
                    Apartment/Building /Colony/ Block/Sector/
                    Street/Gali/Road/Chawl
                  </p>
                  <TextField
                    name="zone"
                    type="text"
                    value={addressLine2 || ""}
                    variant="outlined"
                    style={{ width: "100%", margin: "0" }}
                    inputProps={{ maxLength: 30 }}
                    required
                    onChange={(e) => setAddressLine2(e.target.value as string)}
                  />
                  <small>Max Length 30</small>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>Landmark/Sublocality</p>
                  <TextField
                    placeholder="Ex: School/College/Restaurant/Shop/Bank/Government Office"
                    name="zone"
                    type="text"
                    value={favoritePlace || ""}
                    variant="outlined"
                    style={{ width: "100%", margin: "0" }}
                    inputProps={{ maxLength: 100 }}
                    required
                    onChange={(e) => setFavoritePlace(e.target.value as string)}
                  />
                  <small>Max Length 100</small>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>City</p>
                  <TextField
                    name="city"
                    type="text"
                    value={customer_city}
                    variant="outlined"
                    style={{ width: "100%", margin: "0", background: "#ccc" }}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenLocationModal(false)}
                color="primary"
                disabled={
                  addressLine2 === "" ||
                  customer_address === "" ||
                  favoritePlace === ""
                }
              >
                Select
              </Button>
              <Button
                onClick={() => setOpenLocationModal(false)}
                color="primary"
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </div>
      <Dialog
        open={openCollectionModal}
        onClose={() => handleCollectionSampleClose()}
        fullWidth
        maxWidth="lg"
      >
        <CollectSample id={bookingId} />
      </Dialog>
      <Dialog
        open={openDocModal}
        onClose={() => setOpenDocModal(false)}
        fullWidth
        maxWidth="lg"
      >
        <AddDoctor
          getRefDoctor={getRefDoctor}
          setOpenDocModal={setOpenDocModal}
        />
      </Dialog>
      <Dialog
        open={openNbsModal}
        onClose={() => setOpenNbsModal(false)}
        fullWidth
        maxWidth="xl"
      >
        <NbsForm
          customerName={inputList[0].customerName}
          dob={inputList[0].dateOfBirth}
          data={dataNbs}
          setData={setDataNbs}
          setOpenNbsModal={setOpenNbsModal}
          dataId={""}
          perPackNbs={perPackNbs}
          perPackArr={perPackArr}
          setPerPackArr={setPerPackArr}
        />
      </Dialog>
      <Dialog
        open={openHistoryForm}
        onClose={() => SetOpenHistoryForm(false)}
        fullWidth
        maxWidth="md"
      >
        <HistoryForm
          SetOpenHistoryForm={SetOpenHistoryForm}
          customerName={inputList[0].customerName}
          setSizeOfBiopsy={setSizeOfBiopsy}
          sizeofbiopsy={sizeofbiopsy}
          radiologyFindings={radiologyFindings}
          setRadiologyFindings={setRadiologyFindings}
          setDateOfProcedure={setDateOfProcedure}
          dateOfProcedure={dateOfProcedure}
          operativeFindings={operativeFindings}
          setOperativeFindings={setOperativeFindings}
          setClinicalDiagnosis={setClinicalDiagnosis}
          clinicalDiagnosis={clinicalDiagnosis}
          setRelevantClinicalHistory={setRelevantClinicalHistory}
          relevantClinicalHistory={relevantClinicalHistory}
          centerName={centerName}
          customer_phonenumber={customer_phonenumber}
          setBiospyTrueOrFalse={setBiospyTrueOrFalse}
          biospyTrueOrFalse={biospyTrueOrFalse}
          setBiospySampleName={setBiospySampleName}
          biospySampleName={biospySampleName}
          setBiospyPackageName={setBiospyPackageName}
          biospyPackageCode={biospyPackageCode}
          setBiospyPackageCode={setBiospyPackageCode}
          biospyPackageName={biospyPackageName}
          dataId={""}
          relevantClinicalHistoryform={relevantClinicalHistoryform}
          setRelevantClinicalHistoryForm={setRelevantClinicalHistoryForm}
          clinicalDiagnosisform={clinicalDiagnosisform}
          setClinicalDiagnosisform={setClinicalDiagnosisform}
          radiologyFindingsform={radiologyFindingsform}
          setRadiologyFindingsform={setRadiologyFindingsform}
          operativeFindingsform={operativeFindingsform}
          setOperativeFindingsform={setOperativeFindingsform}
        />
      </Dialog>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  campCoupon: state.LimsAdminReducer.campCoupon,
  refDoctor: state.LimsAdminReducer.refDoctor,
  centerInfo: state.LimsAdminReducer.centerInfo,
  pin_code: state.LimsAdminReducer.pin_code,
  dcpackageList: state.LimsAdminReducer.dcpackageList,
  couponList: state.LimsAdminReducer.couponList,
  createDcBooking: state.LimsAdminReducer.createDcBooking,
  leads: state.LimsAdminReducer.leads,
  lead_details: state.LimsAdminReducer.lead_details,
  booking_slots: state.LimsAdminReducer.booking_slots,
  loading: state.LimsAdminReducer.loading,
  checkGeoFenceStatus: state.LimsAdminReducer.checkGeoFenceStatus,
  geoFencesMapMyIndia: state.LimsAdminReducer.geoFencesMapMyIndia,
  mapmyindiaAccessToken: state.LimsAdminReducer.mapmyindiaAccessToken,
});

export default connect(mapStateToProps, {
  getCampCoupons,
  getRefDoctor,
  getPinCode,
  getDCPackage,
  getPartners,
  getCoupons,
  createNewDCBooking,
  getLeads,
  getLeadDetails,
  resetLeadDetails,
  checkGeoFenceArea,
  getCenterInfo,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken,
  createHistopathologyBooking,
  createNbsBooking,
})(BookingWithLocalitySearch);
