import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { connect } from "react-redux";
import {useParams } from "react-router-dom";
import {
  getBookingById,
  getPinCode,
  resetBookings,
} from "../../../actions/LimsAdminActions";
import Loader from "../../loader";
import moment from "moment";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    formControl: {
      margin: theme.spacing(3),
    },
  })
);

interface Props {
  match: any;
  booking: any;
  getBookingById: any;
  getPinCode: any;
  pin_code: any;

  resetBookings: any;

  loading: Boolean;
}

const OfflineViewBooking: React.FC<Props> = ({
  match,
  booking,
  getBookingById,
  getPinCode,
  pin_code,
  resetBookings,

  loading,
}) => {
  const classes = useStyles();
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    resetBookings();
  }, [resetBookings]);

  const {id} = useParams();
  const bookingId=id
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getBookingById(bookingId);
  }, [bookingId]);

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getPinCode(
      booking?.customer_areapincode && booking?.customer_areapincode.pincode
    );
  }, [booking]);


  const handleDOBForAge = (dob: any, bdate: any) => {
    const a: any = moment(bdate);
    const b: any = moment(dob);
    var years = a.diff(b, "year");
    b.add(years, "years");
    var months = a.diff(b, "months");
    b.add(months, "months");
    var days = a.diff(b, "days");
    var age;
    if (months !== 0) {
       age = months + "Months " + days + "Days";
    } else {
       age = days + "Days";
    }

    return age;
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Paper className={classes.paper} elevation={15}>
          <h3 style={{ textAlign: "center" }}>View Booking Details</h3>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justify="space-between"
                spacing={3}
                style={{ margin: "1rem auto" }}
              >
                <Grid item xs={6} md={3}>
                  <h6>Collection Date</h6>
                  <p style={{ fontWeight: "normal" }}>
                    {booking?.collection_date}
                  </p>
                </Grid>
                <Grid item xs={6} md={3}>
                  <h6>Pincode</h6>
                  <p style={{ fontWeight: "normal" }}>
                    {booking?.customer_areapincode &&
                      booking?.customer_areapincode?.pincode}
                  </p>
                </Grid>
                <Grid item xs={6} md={3}>
                  <h6>Zone Name</h6>
                  <p style={{ fontWeight: "normal" }}>
                    {booking?.customer_areapincode &&
                      booking?.customer_areapincode?.area}
                  </p>
                </Grid>
                <Grid item xs={6} md={3}>
                  <h6>Booking Id</h6>
                  <p style={{ fontWeight: "normal" }}>{booking?.pk}</p>
                </Grid>
              </Grid>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Customer Name</TableCell>
                      <TableCell align="center">Age</TableCell>
                      <TableCell align="center">Gender</TableCell>
                      <TableCell align="center">Package Name</TableCell>
                      {/* <TableCell align="center">Partner OrderId</TableCell> */}
                      <TableCell align="center">Gross Price</TableCell>
                      {/* <TableCell align="center">Phlebo Cost</TableCell> */}
                      <TableCell align="center">Discount</TableCell>
                      <TableCell align="center">Total Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{`${
                        booking?.designation || ""
                      } ${booking?.customer_name}`}</TableCell>
                      <TableCell align="center">
                        {booking?.customer_age === 0
                          ? handleDOBForAge(booking?.dob, booking?.booking_date)
                          : booking?.customer_age}
                      </TableCell>
                      <TableCell align="center">
                        {booking?.customer_gender}
                      </TableCell>
                      <TableCell align="center">
                        <ul>
                          {booking?.packages &&
                            booking?.packages.map((pack: any, index: any) => {
                              return (
                                <li
                                  key={index}
                                  style={{
                                    margin: "1rem 0",
                                    textAlign: "left",
                                  }}
                                >
                                  {pack.name}({pack.code}), {pack.tat_time}
                                </li>
                              );
                            })}
                        </ul>
                      </TableCell>
                      {/* <TableCell align="center">
                        {booking?.partner && booking?.partner?.id}
                      </TableCell> */}
                      <TableCell align="center">
                        Rs.
                        {booking?.final_packages_price +
                          booking?.final_discount}
                      </TableCell>
                      {/* <TableCell align="center">
                        {booking?.discounted_price &&
                          booking?.discounted_price.phlebo_cost &&
                          Math.floor(booking?.discounted_price.phlebo_cost)}
                      </TableCell> */}

                      <TableCell align="center">
                        Rs.{booking?.final_discount}
                      </TableCell>
                      <TableCell align="center">
                        Rs. {booking?.final_packages_price}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <h6
                style={{
                  textAlign: "center",
                  margin: "0",
                  border: "1px solid #ccc",
                  padding: "1rem",
                }}
              >
                Total Amount:{" "}
                {booking?.discounted_price &&
                  booking?.discounted_price.final_total_price &&
                  Math.floor(booking?.discounted_price.final_total_price)}
              </h6> */}
              <Grid
                container
                spacing={4}
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ margin: "1rem auto" }}
              >
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Mobile Number</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_phonenumber"
                      type="text"
                      value={booking?.customer_phonenumber || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Alternate Mobile Number</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_alternatenumber"
                      type="text"
                      value={booking?.customer_altphonenumber || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Email</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_email"
                      type="email"
                      disabled
                      value={booking?.customer_email || ""}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Whatsapp Number</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_whatsapppnumber"
                      type="text"
                      disabled
                      value={booking?.customer_whatsapppnumber || ""}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Aadhar Number</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_aadhar"
                      type="number"
                      onWheel={(e: any) => e.target.blur()}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 16);
                      }}
                      disabled
                      value={booking?.customer_aadhar || ""}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ margin: "1rem auto" }}
              >
                <Grid item xs={12}>
                  <Divider style={{ backgroundColor: "#009fff" }} />
                  <h3 style={{ textAlign: "center" }}>Address</h3>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>House Number</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="houseNumber"
                      type="text"
                      value={
                        (booking?.customer_address &&
                          booking?.customer_address.split(",")[0]) ||
                        ""
                      }
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Full Address</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_address"
                      type="text"
                      value={booking?.customer_address || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Landmark</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_landmark"
                      type="text"
                      value={booking?.customer_landmark || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>City</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="city"
                      type="text"
                      value={
                        (pin_code.results &&
                          pin_code.results.length > 0 &&
                          pin_code.results[0].city) ||
                        ""
                      }
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Area Name</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="areaName"
                      type="text"
                      disabled
                      value={
                        (booking?.customer_areapincode &&
                          booking?.customer_areapincode.area) ||
                        ""
                      }
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Pincode</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="pincode"
                      type="text"
                      disabled
                      value={
                        (booking?.customer_areapincode &&
                          booking?.customer_areapincode.pincode) ||
                        ""
                      }
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ margin: "1rem auto" }}
              >
                {booking?.payment_image &&
                  booking?.payment_image.map((image: any) => {
                    return (
                      <Grid
                        key={image.id}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        spacing={3}
                      >
                        <img
                          src={image.image}
                          alt="payment"
                          style={{ width: "100%", aspectRatio: "1/1" }}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </>
          )}
        </Paper>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  booking: state.LimsAdminReducer.booking,
  pin_code: state.LimsAdminReducer.pin_code,
  loading: state.LimsAdminReducer.loading,
});

export default connect(mapStateToProps, {
  getBookingById,
  getPinCode,
  resetBookings,
})(OfflineViewBooking);
