import React, { useEffect } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
  alpha,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from '@mui/material/Typography';
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ReportIcon from '@mui/icons-material/Report';
import {
  logout,
  panelUserLogout,
} from "../../actions/loginActions";
import { useDispatch } from "react-redux";
import HomeIcon from "@material-ui/icons/Home";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { changePassword } from "../actions/LimsAdminActions";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import NewUpdateModal from "../components/Comments/newUpdate";
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    title: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      color: "#fff",
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    dashbord: {
      color: "#fff",
      fontSize: "18px",
      fontWeight: "normal",
      padding: "20px 0px 10px 10px",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      backgroundColor: "#1C4E80",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  })
);
interface Props {
  passwordStatus: boolean;
}

const SampleAcceDashboard: React.FC<Props> = ({
  passwordStatus,
}) => {
  let dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [changePasswordModal, setchangePasswordModal] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [openRightMenu, setOpenRightMenu] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [anchorElUpdate, setAnchorElUpdate] = React.useState(null);
  const id = open ? "transitions-popper" : undefined;
  const handleClick = (event: any) => {
    setOpenRightMenu(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpenRightMenu(false);
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (!passwordStatus) {
      setchangePasswordModal(true);
    }
  }, [passwordStatus]);


  const updatePassword = async () => {
    const body: any = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    await dispatch(changePassword(body));
    setchangePasswordModal(false);
    dispatch(logout());
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    dispatch(panelUserLogout());
    dispatch(logout());
  };
  const handleUpdateDetails = (event: any) => {
    setAnchorElUpdate(anchorElUpdate ? null : event.currentTarget);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{ backgroundColor: "#1C4E80" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            PRO LIMS
          </Typography>
          <div>
            <Button
              variant="text"
              onClick={(e) => handleClick(e)}
              size="medium"
              endIcon={<KeyboardArrowDownIcon />}
              style={{ color: "#fff" }}
            >
              {localStorage.getItem("USER_NAME")}
            </Button>
            <Menu
              id="fade-menu"
              open={openRightMenu}
              onClose={handleClose}
              TransitionComponent={Fade}
              anchorEl={anchorEl}
            >
              <MenuItem onClick={() => setchangePasswordModal(true)}>
                Change Password
              </MenuItem>
              <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
            </Menu>
            <IconButton>
              <DeveloperModeIcon
                aria-describedby={id}
                onClick={handleUpdateDetails}
              />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Dialog
          open={changePasswordModal}
          onClose={() => setchangePasswordModal(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
          <DialogContent>
            <TextField
              id="name"
              placeholder="Old Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="New Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="Confirm Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => updatePassword()} color="primary">
              Update
            </Button>
            <Button
              onClick={() => setchangePasswordModal(false)}
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.toolbar}>
          <span className={classes.dashbord}>Dashboard</span>
          <IconButton onClick={handleDrawerClose} style={{ color: "#fff" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to="/dashboard/la">
            <ListItem button>
              <Tooltip title="Home" arrow>
                <ListItemIcon>
                  <HomeIcon style={{ color: "#1C4E80" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Home" />
            </ListItem>
          </Link>
        </List>
        <Link to="/dashboard/la/report">
          <ListItem button>
            <Tooltip title="NBS REPORT" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="NBS REPORT" />
          </ListItem>
        </Link>
        <Link to="/dashboard/la/report1">
          <ListItem button>
            <Tooltip title="report1" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="report1" />
          </ListItem>
        </Link>
        <Link to="/dashboard/la/booking_list">
          <ListItem button>
            <Tooltip title="booking_list" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="booking_list" />
          </ListItem>
        </Link>
        <Link to="/dashboard/la/received_ticket">
          <ListItem button>
            <Tooltip title="received_ticket" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="received_ticket" />
          </ListItem>
        </Link>
        <Link to="/dashboard/la/booking-view/:id">
          <ListItem button>
            <Tooltip title="booking-view" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="booking-view" />
          </ListItem>
        </Link>
        <Link to="/dashboard/la/booking">
          <ListItem button>
            <Tooltip title="booking" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="booking" />
          </ListItem>
        </Link>
        <Link to="/dashboard/la/booking_list">
          <ListItem button>
            <Tooltip title="" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="" />
          </ListItem>
        </Link>
        <Link to="/dashboard/la/sample_collection">
          <ListItem button>
            <Tooltip title="sample_collection" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="sample_collection" />
          </ListItem>
        </Link>
        <Link to="/dashboard/la/booking_list">
          <ListItem button>
            <Tooltip title="" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="" />
          </ListItem>
        </Link>
        <Link to="/dashboard/la/booking/tickets">
          <ListItem button>
            <Tooltip title="booking/tickets" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="booking/tickets" />
          </ListItem>
        </Link>
        <Link to="/dashboard/la/centerInfo">
          <ListItem button>
            <Tooltip title="centerInfo" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="centerInfo" />
          </ListItem>
        </Link>
        <Link to="/dashboard/la/booking_list">
          <ListItem button>
            <Tooltip title="" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="" />
          </ListItem>
        </Link>
        <Link to="/dashboard/la/inbox">
          <ListItem button>
            <Tooltip title="inbox" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="inbox" />
          </ListItem>
        </Link>
        <Link to="/dashboard/la/SampleHandOver">
          <ListItem button>
            <Tooltip title="SampleHandOver" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="SampleHandOver" />
          </ListItem>
        </Link>
        <Link to="/dashboard/la/testPackages">
          <ListItem button>
            <Tooltip title="testPackages" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="testPackages" />
          </ListItem>
        </Link>
        <Link to="/dashboard/la/unregister-booking">
          <ListItem button>
            <Tooltip title="unregister-booking" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="unregister-booking" />
          </ListItem>
        </Link>
        <Link to="/dashboard/la/cash-due-on-pha-rider">
          <ListItem button>
            <Tooltip title="cash-due-on-pha-rider" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="cash-due-on-pha-rider" />
          </ListItem>
        </Link>
        <Link to="/dashboard/la/report_not_deliverd">
          <ListItem button>
            <Tooltip title="report_not_deliverd" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="report_not_deliverd" />
          </ListItem>
        </Link>
        <Link to="/dashboard/la/export-bookings">
          <ListItem button>
            <Tooltip title="export-bookings" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="export-bookings" />
          </ListItem>
        </Link>
        <Link to="/dashboard/la/helpdesk">
          <ListItem button>
            <Tooltip title="helpdesk" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="helpdesk" />
          </ListItem>
        </Link>
        <Link to="/dashboard/la/analytics_data">
          <ListItem button>
            <Tooltip title="analytics_data" arrow>
              <ListItemIcon>
                <ReportIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="analytics_data" />
          </ListItem>
        </Link>
        <Divider />
        <List>
          <ListItem button onClick={() => handleLogout()}>
            <Tooltip title="Logout" arrow>
              <ListItemIcon>
                <ExitToAppIcon style={{ color: "#1C4E80" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <NewUpdateModal
        anchorElUpdate={anchorElUpdate}
        id={id}
      />
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  passwordStatus: state.loginReducer.passwordStatus,
});

export default connect(mapStateToProps, {
})(SampleAcceDashboard);
