import {
  ACTIONS,
  PhleboAdminTypes,
} from "../interfaces/actionTypes/LimsAdmin";

type InitialState = {
  pin_code: Array<any>;
  resamplingPackages: Array<any>;
  cities: Array<any>;
  areaList: Array<any>;
  agentsList: Array<any>;
  usersByUsergroup: Array<any>;
  phleboList: Array<any>;
  packageList: Array<any>;
  partnerList: Array<any>;
  couponList: Array<any>;
  timeslots: Array<any>;
  campCoupon: Array<any>;
  createBooking: Object;
  booking: Object;
  todays_positive_calls: Object;
  sampleJourneyComments: Object;
  lead_details: Object;
  leadById: Object;
  leads: Array<any>;
  bookingList: Array<any>;
  comment: any;
  data: any;
  users: any;
  bookingsComment: any;
  ticketComment: any;
  ticketComments: Array<any>;
  lead_id: number;
  read_call_details: object;
  all_recordings: object;
  whatsappComments: object;
  whatsappCommentsByLead: object;
  additional_booking: object;
  callStats: object;
  complaint: object;
  complaints: object;
  lead_source: any;
  booking_slots: object;
  search_results: object;
  updateReport: object;
  call_status: object;
  all_lead: object;
  call_transfer: object;
  createDcBooking: object;
  user_panel: object;
  agentBYUserGroup: object;
  syncData: Object;
  tube_details: Array<any>;
  bookingReceivedPackage: Array<any>;
  smsReportSend: Array<any>;
  loading: boolean;
  bookingLoading: boolean;
  sendSMSLink: Array<any>;
  reportDownload: Array<any>;
  reportStatus: Array<any>;
  ccpackageList: Array<any>;
  dcpackageList: Array<any>;
  centerInfo: Array<any>;
  paymentInformation: Array<any>;
  notificationMessage: Array<any>;
  unmaskedNumber: Array<any>;
  sampleHandOverData: Array<any>;
  unregisterBookingStatus: Array<any>;
  additionMember: Array<any>;
  signature: Array<any>;
  qrcode: Array<any>;
  repetedLead: Array<any>;
  packageTubeDetails: Array<any>;
  unregisterBooking: Array<any>;
  riderList: Array<any>;
  bookingByBarcode: Array<any>;
  hivpackageList: Array<any>;
  mapmyindiaAccessToken: Array<any>;
  refDoctor: Array<any>;
  checkGeoFenceStatus: boolean;
  geoFencesMapMyIndia: Array<any>;
  tubeByBooking: Array<any>;
  receivedPackages: Array<any>;
  doctorSpec: Array<any>;
  doctorQual: Array<any>;
  bookingsByCentre: Array<any>;
  responseRefDoctor: Object;
  biomarkerScreening: Array<any>;
  biomarkerUltasound: Array<any>;
  markerAdditionMember: Array<any>;
  phlebo_rider_due: Array<any>;
  ticketData: Array<any>;
  ticketCategories: Array<any>;
  ticketSubCategories: Array<any>;
  redtechcomment: Array<any>;
  failedReport: Array<any>;
  receivedTasks: Array<any>;
  userFromCategories: Array<any>;
  batchbookingamount: Array<any>;
  bookingoverview: Array<any>;
  manualreportsend: Array<any>;
  uploadTrf: Array<any>;
  syncedBookings: Array<any>;
  zoneList: Object;
  syncLoading: boolean;
  bookingComplaints: Array<any>;
  centreData: Object;
  bookingListLoader: boolean;
  biospypackages: Array<any>;
  ticketDataForId: Array<any>;
  bookinghistoryform: Object;
  bookingnbsform: Object;
  analyticsDataLabCity: Array<any>;
  userDetails: Array<any>;
  analyticsDataNonLabCity: Array<any>;
};

const initialState: InitialState = {
  refDoctor: [],
  bookingsByCentre: [],
  responseRefDoctor: {},
  doctorQual: [],
  doctorSpec: [],
  receivedPackages: [],
  tubeByBooking: [],
  pin_code: [],
  campCoupon: [],
  mapmyindiaAccessToken: [],
  checkGeoFenceStatus: false,
  geoFencesMapMyIndia: [],
  failedReport: [],
  receivedTasks: [],
  userFromCategories: [],
  cities: [],
  areaList: [],
  agentsList: [],
  usersByUsergroup: [],
  phleboList: [],
  packageList: [],
  partnerList: [],
  couponList: [],
  createBooking: {},
  additional_booking: {},
  booking: {},
  lead_details: {},
  leadById: {},
  todays_positive_calls: {},
  sampleJourneyComments: {},
  leads: [],
  unregisterBooking: [],
  unregisterBookingStatus: [],
  createDcBooking: {},
  bookingList: [],
  comment: {},
  data: {},
  users: {},
  loading: false,
  bookingLoading: false,
  read_call_details: {},
  all_recordings: {},
  whatsappComments: {},
  whatsappCommentsByLead: {},
  callStats: {},
  complaint: {},
  complaints: {},
  manualreportsend: [],
  bookingsComment: {},
  ticketComment: {},
  ticketComments: [],
  updateReport: {},
  lead_id: 0,
  lead_source: {},
  booking_slots: {},
  search_results: {},
  call_status: {},
  all_lead: {},
  call_transfer: {},
  agentBYUserGroup: {},
  user_panel: {},
  tube_details: [],
  syncData: [],
  bookingReceivedPackage: [],
  smsReportSend: [],
  sendSMSLink: [],
  reportDownload: [],
  reportStatus: [],
  ccpackageList: [],
  dcpackageList: [],
  centerInfo: [],
  paymentInformation: [],
  notificationMessage: [],
  unmaskedNumber: [],
  sampleHandOverData: [],
  additionMember: [],
  signature: [],
  qrcode: [],
  repetedLead: [],
  packageTubeDetails: [],
  riderList: [],
  bookingByBarcode: [],
  hivpackageList: [],
  biomarkerScreening: [],
  biomarkerUltasound: [],
  markerAdditionMember: [],
  phlebo_rider_due: [],
  ticketData: [],
  ticketCategories: [],
  ticketSubCategories: [],
  timeslots: [],
  redtechcomment: [],
  batchbookingamount: [],
  bookingoverview: [],
  resamplingPackages: [],
  uploadTrf: [],
  syncedBookings: [],
  syncLoading: false,
  zoneList: {},
  bookingComplaints: [],
  centreData: {},
  bookingListLoader: false,
  biospypackages: [],
  ticketDataForId: [],
  bookinghistoryform: {},
  bookingnbsform: {},
  analyticsDataLabCity: [],
  analyticsDataNonLabCity: [],
  userDetails: [],
};

const CustomerLeadReducer = (
  state = initialState,
  action: PhleboAdminTypes
) => {
  switch (action.type) {
    case ACTIONS.GET_PIN_CODE:
      return {
        ...state,
        pin_code: action.payload,
        loading: false,
      };
    case ACTIONS.EXPORT_SYNC_BOOKINGS:
      return {
        ...state,
        syncedBookings: action.payload,
        loading: false,
      };
    case ACTIONS.GET_UPLOAD_TRF:
      return {
        ...state,
        uploadTrf: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RESAMPLING_PACKAGES:
      return {
        ...state,
        resamplingPackages: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BATCH_BOOKING_AMOUNT:
      return {
        ...state,
        batchbookingamount: action.payload,
        loading: false,
      };
    case ACTIONS.REF_DOCTOR_RESET:
      return {
        ...state,
        responseRefDoctor: {},
      };
    case ACTIONS.BIOMARKER_RESET:
      return {
        ...state,
        biomarkerScreening: {},
      };
    case ACTIONS.GET_BOOKINGS_BY_CENTRE:
      return {
        ...state,
        bookingsByCentre: action.payload,
        loading: false,
      };
    case ACTIONS.POST_REF_DOCTOR:
      return {
        ...state,
        responseRefDoctor: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DOCTOR_QUAL:
      return {
        ...state,
        doctorQual: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DOCTOR_SPEC:
      return {
        ...state,
        doctorSpec: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RECEIVED_PACKAGES:
      return {
        ...state,
        receivedPackages: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TUBE_BY_BOOKING:
      return {
        ...state,
        tubeByBooking: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA:
      return {
        ...state,
        checkGeoFenceStatus: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA:
      return {
        ...state,
        geoFencesMapMyIndia: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REF_DOCTOR:
      return {
        ...state,
        refDoctor: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN:
      return {
        ...state,
        mapmyindiaAccessToken: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CAMP_COUPONS:
      return {
        ...state,
        campCoupon: action.payload,
        loading: false,
      };
    case ACTIONS.UPLOAD_FILE:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case ACTIONS.GET_FAILED_REPORT_DATA:
      return {
        ...state,
        failedReport: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RECEIVED_TICKET_TASKS:
      return {
        ...state,
        receivedTasks: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USER_FROM_CATEGORIES:
      return {
        ...state,
        userFromCategories: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBOS:
      return {
        ...state,
        phleboList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITIES:
      return {
        ...state,
        cities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AREA:
      return {
        ...state,
        areaList: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_UNREGISTERD_BOOKING_STATUS:
      return {
        ...state,
        unregisterBookingStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_POSTIVE_CALLS_COMMENTS:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SAMPLE_JOURNEY_COMMENTS:
      return {
        ...state,
        sampleJourneyComments: action.payload,
        loading: false,
      };
    case ACTIONS.SET_COMMENTS:
      return {
        ...state,
        comment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_POSTIVE_CALLS_COMMENT:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_DETAILS:
      return {
        ...state,
        lead_details: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEADS:
      return {
        ...state,
        leads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_BY_ID:
      return {
        ...state,
        leadById: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_REPORT_STATUS:
      return {
        ...state,
        updateReportStatus: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_DETAILS_RESET:
      return {
        ...state,
        tubeByBooking: [],
        receivedPackages: [],
        leadById: {},
        booking: {},
        lead_details: {},
        loading: false,
      };
    case ACTIONS.GET_AGENTS:
      return {
        ...state,
        agentsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AGENTS_BY_USERGROUP:
      return {
        ...state,
        GET_AGENTS_BY_USERGROUP: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USERS_BY_USERGROUP:
      return {
        ...state,
        usersByUsergroup: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGES:
      return {
        ...state,
        packageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PARTNERS:
      return {
        ...state,
        partnerList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COUPONS:
      return {
        ...state,
        couponList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_BOOKING:
      return {
        ...state,
        createBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_LIST:
      return {
        ...state,
        bookingList: action.payload,
        bookingListLoader: false,
      };
    case ACTIONS.GET_BOOKING_BY_ID:
      return {
        ...state,
        booking: action.payload,
        bookingLoading: false,
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.SET_BOOKING_LOADING:
      return {
        ...state,
        bookingLoading: action.payload,
      };
    case ACTIONS.CREATE_CALL:
      return {
        ...state,
        loading: false,
        lead_id: action.payload,
      };
    case ACTIONS.GET_RED_TECH_COMMENT:
      return {
        ...state,
        redtechcomment: action.payload,
        loading: false,
      };

    case ACTIONS.SEND_REPORT_BY_ID:
      return {
        ...state,
        manualreportsend: action.payload,
        loading: false,
      };

    case ACTIONS.GET_RECORDINGS:
      return {
        ...state,
        all_recordings: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_SOURCE:
      return {
        ...state,
        lead_source: action.payload,
        loading: false,
      };
    case ACTIONS.SEARCH_CALL:
      return {
        ...state,
        search_results: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_COMMENT:
      return {
        ...state,
        comments: action.payload,
        loading: false,
      };
    case ACTIONS.BOOKING_RESET:
      return {
        ...state,
        booking: {},
        batchbookingamount: [],
        bookingByBarcode: {},
        syncLoading: false,
      };
    case ACTIONS.GET_BOOKINGS_COMMENTS:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_BOOKINGS_COMMENT:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_COMMENTS:
      return {
        ...state,
        ticketComments: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_TICKET_COMMENT:
      return {
        ...state,
        ticketComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS_BY_LEAD:
      return {
        ...state,
        whatsappCommentsByLead: action.payload,
        loading: false,
      };
    case ACTIONS.SEND_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CALL_STATS:
      return {
        ...state,
        callStats: action.payload,
        loading: false,
      };
    case ACTIONS.RAISE_COMPLAINT:
      return {
        ...state,
        complaint: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COMPLAINTS:
      return {
        ...state,
        complaints: action.payload,
        loading: false,
      };
    case ACTIONS.ASSIGN_DOCTOR:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.SEND_FEEDBACK:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.GET_AVAILABLE_SLOTS:
      return {
        ...state,
        booking_slots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_HIV_PACKAGES:
      return {
        ...state,
        hivpackageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_LEADS:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USERS:
      return {
        ...state,
        user_panel: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_TRANSFER_BY_COMMA:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SYNC_DATA:
      return {
        ...state,
        syncData: action.payload,
        syncLoading: false,
      };
    case ACTIONS.CREATE_DC_BOOKING:
      return {
        ...state,
        createDcBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TUBE_DETAILS:
      return {
        ...state,
        tube_details: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_RECEIVED_PACKAGE:
      return {
        ...state,
        bookingReceivedPackage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CC_PACKAGES:
      return {
        ...state,
        ccpackageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DC_PACKAGES:
      return {
        ...state,
        dcpackageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_REPORT_SEND:
      return {
        ...state,
        smsReportSend: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_LINK:
      return {
        ...state,
        sendSMSLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_DOWNLOAD:
      return {
        ...state,
        reportDownload: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_STATUS:
      return {
        ...state,
        reportStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER_INFORMATION:
      return {
        ...state,
        centerInfo: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_INFORMATION:
      return {
        ...state,
        paymentInformation: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NOTIFICATION_MESSAGE:
      return {
        ...state,
        notificationMessage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_UNMASKED_NUMBER:
      return {
        ...state,
        unmaskedNumber: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SAMPLE_HANDOVER_DATA:
      return {
        ...state,
        sampleHandOverData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDITIONAL_BOOKING:
      return {
        ...state,
        additionMember: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SIGNATURE:
      return {
        ...state,
        signature: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QRCODE_DATA:
      return {
        ...state,
        qrcode: action.payload,
        loading: false,
      };
    case ACTIONS.GET_UNREGISTERD_BOOKING:
      return {
        ...state,
        unregisterBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RIDER_LIST:
      return {
        ...state,
        riderList: action.payload,
        loading: false,
      };
    default:
    case ACTIONS.GET_PACKAGE_TUBE_DETAILS:
      return {
        ...state,
        packageTubeDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_BY_BARCODE:
      return {
        ...state,
        bookingByBarcode: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BIOMARKER_SCREENING:
      return {
        ...state,
        biomarkerScreening: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BIOMARKER_ULTASOUND:
      return {
        ...state,
        biomarkerUltasound: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDITIONAL_MEMBER:
      return {
        ...state,
        markerAdditionMember: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_RIDER_DUE_AMOUNT:
      return {
        ...state,
        phlebo_rider_due: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_DATA:
      return {
        ...state,
        ticketData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_CATEGORIES:
      return {
        ...state,
        ticketCategories: action.payload,
        loading: false,
      };

    case ACTIONS.GET_TICEKT_SUB_CATEGORIES:
      return {
        ...state,
        ticketSubCategories: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_OVERVIEW:
      return {
        ...state,
        bookingoverview: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ZONE:
      return {
        ...state,
        zoneList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER:
      return {
        ...state,
        centreData: action.payload,
        loading: false,
      };
    case ACTIONS.SET_SYNC_LOADING:
      return {
        ...state,
        syncLoading: action.payload,
      };
    case ACTIONS.GET_BOOKING_COMPLAINTS:
      return {
        ...state,
        bookingComplaints: action.payload,
        loading: false,
      };
    case ACTIONS.SET_ALL_BOOKING_LOADING:
      return {
        ...state,
        bookingListLoader: action.payload,
      };

    case ACTIONS.GET_BIOSPYPACKAGES:
      return {
        ...state,
        biospypackages: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_DATA_FOR_ID:
      return {
        ...state,
        ticketDataForId: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_BY_HISTORYFORM:
      return {
        ...state,
        bookinghistoryform: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_BY_NBS:
      return {
        ...state,
        bookingnbsform: action.payload,
        loading: false,
      };
    case ACTIONS.GET_FINANCE_ANALYTICS_DATA_LAB_CITY:
      return {
        ...state,
        analyticsDataLabCity: action.payload,
        loading: false,
      };
    case ACTIONS.GET_FINANCE_ANALYTICS_DATA_NON_LAB_CITY:
      return {
        ...state,
        analyticsDataNonLabCity: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TIMESLOTS:
      return {
        ...state,
        timeslots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };
  }
};

export default CustomerLeadReducer;
