import React, {useEffect } from "react";
import {Grid, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import BarChartTwoToneIcon from "@mui/icons-material/BarChartTwoTone";
import { getBookingOverview } from "../../actions/LimsAdminActions";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import "./dashboard.css";
const useStyles = makeStyles((theme: Theme) =>
createStyles({
  card: {
    height: "100%",
    padding: "20px",
  },
  cardContent: {
    textAlign: "center",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: { color: "black" },
})
);


interface Props {
  getBookingOverview: any;
  bookingoverview: any;
  userDetails: any;
}

const Dashboard: React.FC<Props> = ({
  getBookingOverview,
  bookingoverview,
}) => {
  const classes = useStyles();


useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getBookingOverview("");
  }, []);

  return (
    <div style={{ width: "90%", margin: "60px auto" }}>
      <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "right", display: "flex" }}
        >
         <Grid item xs={12} sm={8}>
          <h4 style={{ fontWeight: "bold" }}>DASHBOARD</h4>
        </Grid>
        <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
          <TextField
            type="date"
            defaultValue={moment().format("YYYY-MM-DD")}
            onChange={(e) =>
              getBookingOverview(
                `overview_date=${moment(e.target.value).format("YYYY-MM-DD")}`
              )
            }
          />
        </Grid>
      </Grid>
      <div style={{ margin: "10px 0px 0px 0px" }} id="lims">
      <h6>Batches</h6>
        <div className="cardsAppointment">
          <div>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{bookingoverview?.total_batch}</h5>
                <span style={{ color: "#ffffff" }}>Total</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "6%" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{bookingoverview?.lab_received_batch}</h5>
                <span style={{ color: "#ffffff" }}>Lab Received</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "6%" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{bookingoverview?.pending_batch}</h5>
                <span style={{ color: "#ffffff" }}>Pending</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginLeft: "6%" }}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{bookingoverview?.non_city_batches}</h5>
                <span style={{ color: "#ffffff" }}>Non City</span>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  bookingoverview: state.LimsAdminReducer.bookingoverview,
  loading: state.LimsAdminReducer.loading,
});

export default connect(mapStateToProps, {
  getBookingOverview,
})(Dashboard);
