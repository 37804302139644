import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import {withStyles} from "@material-ui/core";
import "./index.sass"
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/Loader2";
import moment from "moment";
import { MessageRounded } from "@material-ui/icons";
import {
  getUnSyncBooking,
  updateBookingZone,
} from "../actions/LimsAdminActions";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
// import AdditonalFamilyModal from './AdditionalFamily/AdditionFamilyDetails'
import InvertColorsIcon from "@material-ui/icons/InvertColors";
import { getUserDetails } from "../../actions/loginActions";
import Autocomplete from "@mui/material/Autocomplete";
import SaveIcon from "@mui/icons-material/Save";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 1rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
interface Props {
  getBookings: any;
  bookingList: any;
  page: any;
  setPage: any;
  setopenModal: any;
  setPaymentId: any;
  SyncLoading:any;
  setOpenPaymentModal: any;
  setOpenTubeModal: any;
  setTubeId: any;
  setSyncOpenModal: any;
  setSyncId: any;
  setBookingData: any;
  setSyncAdditionalOpenModal: any;
  setAdditionalSyncId: any;
  setAdditonalBookingData: any;
  setPageNumber: any;
  getUnSyncBooking: any;
  pageNumber: any;
  setBId: any;
  setBookingType: any;
  setBookingId: any;
  userDetails: any;
  getUserDetails: any;
  getZone: any;
  zoneList: any;
  updateBookingZone: any;
  bookingListLoader:boolean;
}

const BookingsTable: React.FC<Props> = ({
  getBookings,
  bookingList,
  page,
  setPage,
  setopenModal,
  setPaymentId,
  setOpenPaymentModal,
  setOpenTubeModal,
  SyncLoading,
  setTubeId,
  setSyncOpenModal,
  setSyncId,
  setBookingData,
  setSyncAdditionalOpenModal,
  setAdditionalSyncId,
  setAdditonalBookingData,
  setPageNumber,
  getUnSyncBooking,
  pageNumber,
  setBId,
  setBookingType,
  setBookingId,
  userDetails,
  getUserDetails,
  getZone,
  zoneList,
  updateBookingZone,
  bookingListLoader,
}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [zoneId, setZoneId] = useState<any>("");
  const [bkId, setBkId] = useState<any>("");

  const handleClick = (id: number) => {
    setopenModal(true);
    setBookingId(id);
  };
  const updateZone = (zid: any, bid: any) => {
    setZoneId(zid);
    setBkId(bid);
  };
  const handleUpdateZone = async () => {
    const data = {
      booking_id: bkId,
      zone_id: zoneId,
    };
    if (bkId !== "" && zoneId !== "") {
      await updateBookingZone(data);
      setTimeout(() => {
        getBookings(`${pageNumber ? pageNumber + "&" : ""}&optimize=true`);
      }, 1000);
    } else {
      alert("please select zone then update");
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = bookingList?.links && bookingList?.links?.next?.split("?")[1];
      getBookings(`${url}&optimize=true`);
      setPageNumber(url);
    } else if (newPage < page) {
      let url = bookingList.links && bookingList?.links?.previous?.split("?")[1];
      setPageNumber(url);
      getBookings(`${url}&optimize=true`);
    }
    setPage(newPage as number);
  };
  const handleSync = async (id: any, data: any) => {
    setSyncId(id);
    setSyncOpenModal(true);
    setBookingData(data);
  };
  const handleUnSyncBooking = (id: any) => {
    getUnSyncBooking(`${id}/`);
    setTimeout(() => {
      getBookings(`${pageNumber}&optimize=true`);
    }, 1000);
  };
  const handleAdditionalUnSyncBooking = (id: any) => {
    getUnSyncBooking(`${id}/?is_add=true`);
    setTimeout(() => {
      getBookings(`${pageNumber}&optimize=true`);
    }, 1000);
  };
  const handleAdditionalSync = async (id: any, data: any) => {
    setAdditionalSyncId(id);
    setSyncAdditionalOpenModal(true);
    setAdditonalBookingData(data);
  };
  const handlePaymentInfo = (id: any) => {
    setPaymentId(id);
    setOpenPaymentModal(true);
    setPaymentId(id);
  };
  const handleTubeModal = (id: any, bId: any, type: any) => {
    setOpenTubeModal(true);
    setTubeId(id);
    setBId(bId);
    setBookingType(type);
  };
  
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getUserDetails();
  }, []);

  return (
    <div style={{ width: "100%" }} className="data-table">
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "700px" }}
      >
        {bookingListLoader ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Action</StyledTableCell>
                <StyledTableCell align="center">Booking Id</StyledTableCell>
                <StyledTableCell align="center">Child Id</StyledTableCell>
                <StyledTableCell align="center">Lims Id</StyledTableCell>
                <StyledTableCell align="center">Source</StyledTableCell>
                <StyledTableCell align="center">Zone Name</StyledTableCell>
                <StyledTableCell align="center">Sync</StyledTableCell>
                {userDetails?.profile?.executive_type==="Admin" ? (
                  <StyledTableCell align="center">UnSync</StyledTableCell>
                ) : (
                  ""
                )}
                <StyledTableCell align="center">Select Tube</StyledTableCell>
                <StyledTableCell align="center">Comment</StyledTableCell>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Age</StyledTableCell>
                <StyledTableCell align="center">Gender</StyledTableCell>
                <StyledTableCell align="center">Created At</StyledTableCell>
                <StyledTableCell align="center">Booking Date</StyledTableCell>
                <StyledTableCell align="center">Booking Status</StyledTableCell>
                <StyledTableCell align="center">Lab Name</StyledTableCell>
                <StyledTableCell align="center">Report Status</StyledTableCell>
                <StyledTableCell align="center">Verification Status</StyledTableCell>
                <StyledTableCell align="center">Pickup Status</StyledTableCell>
                <StyledTableCell align="center">Agent</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">Collection Date</StyledTableCell>
                <StyledTableCell align="center">Collection Time</StyledTableCell>
                <StyledTableCell align="center">Booking Time</StyledTableCell>
                <StyledTableCell align="center">Zone Name</StyledTableCell>
                <StyledTableCell align="center">Mobile Number</StyledTableCell>
                <StyledTableCell align="center">Pickup Receive Amount</StyledTableCell>
                <StyledTableCell align="center">Payment Mode</StyledTableCell>
                <StyledTableCell align="center">Payment Source</StyledTableCell>
                {/* <StyledTableCell align="center">Download Report</StyledTableCell> */}
                <StyledTableCell align="center">Payment Info</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {bookingList && bookingList.results &&
                bookingList.results.length > 0 &&
                bookingList.results.map((booking: any, index: any) => {
                

                  return (
                    <>
                      <StyledTableRow
                        style={{
                          backgroundColor:
                            booking?.booking_type === "b2b" &&
                            booking?.receivable_amount === 0
                              ? "#ffe082"
                              : booking?.booking_type === "b2b" &&
                                booking?.receivable_amount !== 0
                              ? "#f48fb1"
                              : booking?.booking_type === "b2c" &&
                                booking?.receivable_amount===0
                              ? "#64ffda"
                              : "",
                        }}
                        key={index}
                      >
                        <StyledTableCell align="center">
                          <Link
                            to={`/dashboard/la/booking-view/${
                              booking?.pk ? booking?.pk : booking?.id
                            }`}
                            style={{ textDecoration: "none" }}
                          >
                            <Button variant="contained" color="secondary">
                              View
                            </Button>
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.pk ? booking?.pk : booking?.id}
                        </StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.bill_id || "N/A"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.source || "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div style={{ display: "flex" }}>
                            <Autocomplete
                              id="zone"
                              key={booking?.customer_areapincode}
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  let obj = JSON.parse(
                                    JSON.stringify(newValue, null, " ")
                                  );
                                  updateZone(obj.id, booking.pk);
                                }
                              }}
                              defaultValue={ {
                                  id: "",
                                  city: { city: booking?.zone_name },
                                }
                              }
                              options={zoneList?.results || []}
                              freeSolo
                              blurOnSelect
                              onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                  getZone(newInputValue?.split(",")[0]);
                                }, 1000);
                              }}
                              getOptionLabel={(option: any) =>
                                `${
                                  option?.city?.name
                                    ? option?.city?.name
                                    : option?.city?.city
                                }`
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Zone Name"
                                  variant="outlined"
                                  style={{ width: "150px" }}
                                  size="small"
                                />
                              )}
                              disabled={booking?.pickup_status !== "confirmed"}
                              // disabled
                            />
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={() => handleUpdateZone()}
                            >
                              <SaveIcon />
                            </Button>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.bill_id === null &&
                          booking?.pickup_status === "confirmed" &&
                          booking?.is_sync === false ? (
                            <Button
                              disabled={booking?.is_sync}
                              variant="contained"
                              color="inherit"
                              onLoad={SyncLoading}
                              onClick={() =>
                                handleSync(
                                  booking?.pk ? booking?.pk : booking?.id,
                                  booking
                                )
                              }
                            >
                              Receive
                            </Button>
                          ) : (
                            "NA"
                          )}
                          {userDetails?.profile?.executive_type==="Admin" &&
                          booking?.bill_id === null &&
                          booking?.is_sync === true ? (
                            <Button
                              disabled={
                                booking?.is_sync !== true &&
                                booking?.bill_id !== null
                              }
                              variant="contained"
                              color="inherit"
                              onClick={() =>
                                handleSync(
                                  booking?.pk ? booking?.pk : booking?.id,
                                  booking
                                )
                              }
                            >
                              Receive Again
                            </Button>
                          ) : (
                            ""
                          )}
                        </StyledTableCell>
                        {userDetails?.profile?.executive_type==="Admin" ? (
                          <StyledTableCell align="center">
                            {booking?.is_sync ? (
                              <Button
                                variant="contained"
                                color="inherit"
                                onClick={() =>
                                  handleUnSyncBooking(
                                    booking?.pk ? booking?.pk : booking?.id
                                  )
                                }
                              >
                                UnSync
                              </Button>
                            ) : (
                              "NA"
                            )}
                          </StyledTableCell>
                        ) : (
                          ""
                        )}
                        <StyledTableCell align="center">
                          <InvertColorsIcon
                            onClick={() =>
                              handleTubeModal(
                                booking?.pk ? booking?.pk : booking?.id,
                                booking?.pk ? booking?.pk : booking?.id,
                                "booking_id"
                              )
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div>
                            <MessageRounded
                              onClick={() =>
                                handleClick(
                                  booking?.pk ? booking?.pk : booking?.id
                                )
                              }
                            />
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">{`${
                          booking?.designation || ""
                        } ${booking?.customer_name}`}</StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.customer_age}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.customer_gender}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {new Date(booking?.created_at).toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.booking_date}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Chip
                            label={`${booking?.booking_status}`}
                            style={{
                              background:
                                booking?.booking_status === "pending"
                                  ? "#ffee58"
                                  : booking?.booking_status === "confirmed"
                                  ? "#00e676"
                                  : booking?.booking_status === "cancelled"
                                  ? "#ff3d00"
                                  : "#d500f9",
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.lab}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Chip
                            label={`${booking?.report_status}`}
                            style={{
                              background:
                                booking?.report_status === "pending"
                                  ? "#ffee58"
                                  : booking?.report_status === "consolidate"
                                  ? "#00e676"
                                  : booking?.report_status === "partial"
                                  ? "#d500f9"
                                  : "#ff3d00",
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Chip
                            label={`${booking?.verification_status}`}
                            style={{
                              background:
                                booking?.verification_status === "pending"
                                  ? "#ffee58"
                                  : booking?.verification_status === "confirmed"
                                  ? "#00e676"
                                  : booking?.verification_status === "cancelled"
                                  ? "#ff3d00"
                                  : "#d500f9",
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Chip
                            label={`${booking?.pickup_status}`}
                            style={{
                              background:
                                booking?.pickup_status === "pending"
                                  ? "#ffee58"
                                  : booking?.pickup_status === "confirmed"
                                  ? "#00e676"
                                  : booking?.pickup_status === "cancelled"
                                  ? "#ff3d00"
                                  : "#d500f9",
                            }}
                          />
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {(booking?.agent && booking?.agent.name) || "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.customer_email}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.collection_date}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.collection_slot &&
                            moment(
                              `${booking?.collection_slot?.slot?.split("-")[0]}`,
                              ["HH.mm.ss"]
                            ).format("hh:mm A")}
                          {" - "}
                          {booking?.collection_slot &&
                            moment(
                              `${booking?.collection_slot?.slot?.split("-")[1]}`,
                              ["HH.mm.ss"]
                            ).format("hh:mm A")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {" "}
                          {new Date(booking?.created_at).toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.customer_areapincode?.area}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.customer_phonenumber}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.pickup_receive_amount || "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.payment_mode || "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {booking?.payment_source || "NA"}
                        </StyledTableCell>
                        {/* <StyledTableCell align="center">
                          {booking?.report_status === "partial" || booking?.report_status === "consolidate" ? (
                            <GetAppIcon
                              onClick={() => handleDownloadReport(reportDownload, booking?.pk?booking?.pk:booking?.id)}
                            />
                          ) : (<GetAppIcon
                            onClick={() => alert("Report Not Avilable")}
                          />)}
                        </StyledTableCell> */}
                        <StyledTableCell align="center">
                          <LocalAtmIcon
                            onClick={() =>
                              handlePaymentInfo(
                                booking?.pk ? booking?.pk : booking?.id
                              )
                            }
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                      {booking?.additional_members &&
                        booking?.additional_members?.length > 0 &&
                        booking?.additional_members?.map(
                          (data: any, index2: any) => {
                            return (
                              <StyledTableRow
                                key={index*index2}
                                style={{ backgroundColor: "#e0f2f1" }}
                              >
                                <StyledTableCell align="center">
                                  <Link
                                    to={`/dashboard/la/booking-view/${
                                      booking?.pk ? booking?.pk : booking?.id
                                    }`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <Button
                                      variant="contained"
                                      style={{ backgroundColor: "#ff9100" }}
                                    >
                                      View
                                    </Button>
                                  </Link>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {booking?.pk ? booking?.pk : booking?.id}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.id}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.bill_id || "N/A"}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {booking?.source}
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.bill_id === null &&
                                  data?.pickup_status === "confirmed" &&
                                  data?.is_sync === false ? (
                                    <Button
                                      disabled={data.is_sync}
                                      variant="contained"
                                      color="inherit"
                                      onLoad={SyncLoading}
                                      onClick={() =>
                                        handleAdditionalSync(data?.id, data)
                                      }
                                    >
                                      Receive
                                    </Button>
                                  ) : (
                                    "NA"
                                  )}

                                  {userDetails?.profile?.executive_type ===
                                    "Admin" &&
                                  data?.bill_id === null &&
                                  data?.is_sync === true ? (
                                    <Button
                                      disabled={
                                        booking?.is_sync !== true &&
                                        data?.bill_id !== null
                                      }
                                      style={{height: "40px"}}
                                      variant="contained"
                                      color="inherit"
                                      onClick={() =>
                                        handleAdditionalSync(data?.id, data)
                                      }
                                    >
                                      Receive Again
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                                {userDetails?.profile?.executive_type==="Admin" ? (
                                  <StyledTableCell align="center">
                                    {data?.is_sync ? (
                                      <Button
                                        variant="contained"
                                        color="inherit"
                                        onClick={() =>
                                          handleAdditionalUnSyncBooking(
                                            data?.id
                                          )
                                        }
                                      >
                                        UnSync
                                      </Button>
                                    ) : (
                                      "NA"
                                    )}
                                  </StyledTableCell>
                                ) : (
                                  ""
                                )}
                                <StyledTableCell align="center">
                                  <InvertColorsIcon
                                    onClick={() =>
                                      handleTubeModal(
                                        data?.id,
                                        booking?.pk ? booking?.pk : booking?.id,
                                        "add_booking_id"
                                      )
                                    }
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.customer_name || "N/A"}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.customer_age || "N/A"}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.customer_gender || "N/A"}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {new Date(data?.created_at).toLocaleString()}
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center">
                                  <Chip
                                    label={`${data.booking_status}`}
                                    style={{
                                      background:
                                        data?.booking_status === "pending"
                                          ? "#ffee58"
                                          : data?.booking_status === "confirmed"
                                          ? "#00e676"
                                          : data?.booking_status === "cancelled"
                                          ? "#ff3d00"
                                          : "#d500f9",
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {data?.lab}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <Chip
                                    label={`${data?.report_status}`}
                                    style={{
                                      background:
                                        data?.report_status === "pending"
                                          ? "#ffee58"
                                          : data?.report_status === "consolidate"
                                          ? "#00e676"
                                          : data?.report_status === "partial"
                                          ? "#d500f9"
                                          : "#ff3d00",
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <Chip
                                    label={`${data?.report_verification_status}`}
                                    style={{
                                      background:
                                        data?.report_verification_status ===
                                        "pending"
                                          ? "#ffee58"
                                          : data?.report_verification_status ===
                                            "confirmed"
                                          ? "#00e676"
                                          : data?.report_verification_status ===
                                            "cancelled"
                                          ? "#ff3d00"
                                          : "#d500f9",
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <Chip
                                    label={`${data?.pickup_status}`}
                                    style={{
                                      background:
                                        data?.pickup_status === "pending"
                                          ? "#ffee58"
                                          : data?.pickup_status === "confirmed"
                                          ? "#00e676"
                                          : data?.pickup_status === "cancelled"
                                          ? "#ff3d00"
                                          : "#d500f9",
                                    }}
                                  />
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  {(booking?.agent && booking?.agent.name) ||
                                    "NA"}
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                              </StyledTableRow>
                            );
                          }
                        )}
                    </>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={2}
                  count={bookingList?.count || 0}
                  rowsPerPage={bookingList?.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      {bookingList?.results && bookingList?.results.length === 0 && (
        <h6 style={{ textAlign: "center" }}>No Data Found</h6>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  syncLoading: state.LimsAdminReducer.syncLoading,
  userDetails: state.loginReducer.userDetails,
});

export default connect(mapStateToProps, {
  getUnSyncBooking,
  getUserDetails,
  updateBookingZone,
})(BookingsTable);
