import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  TextField,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import { generateOfflineBookingFilterUrl } from "../../helpers/generateUrl";
import { connect } from "react-redux";
import {
  getAllOfflineBookings,
  getCenterInfo,
} from "../actions/LimsAdminActions";
import "./filter.sass";
import { useNavigate } from "react-router";
import BookingsTable from "../tables/offlineBookingTable";
import Loader from "../components/loader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    input: {
      padding: "1px",
    },
  })
);

interface Props {
  children: any;
  bookingList: any;
  getAllOfflineBookings: any;
  loading: boolean;
  getCenterInfo: any;
  centerInfo: any;
}

const OfflineFilterPage: React.FC<Props> = ({
  getAllOfflineBookings,
  bookingList,
  loading,
  getCenterInfo,
  centerInfo,
}) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const [bookingId, setBookingId] = useState<any>("");
  const status="none"
  const partner=0
  const city=0
  const agent=0
  const [source, setSource] = useState<any>([]);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const booking_date=""
  const verificationStatus="none"
  const collection_date=""
  const [name, setName] = useState("");
  const [qrcode, setqrCode] = useState<any>("");
  const phlebo=0;
  const [phone, setPhone] = useState("");
  const [center, setCentre] = useState<any>("");
  const [page, setPage] = useState(0);

  const timer = useRef<any>(0);

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getAllOfflineBookings();
    getCenterInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterBookings = () => {
    const body: any = {
      bookingId,
      center,
      status,
      verificationStatus,
      source: source.join(","),
      agent,
      city,
      start_date,
      end_date,
      booking_date,
      collection_date,
      name,
      phlebo,
      partner,
      phone,
      qrcode,
    };
    const url = generateOfflineBookingFilterUrl(body).substring(2);
    getAllOfflineBookings(url);
    setPage(0);
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <h2>Offline Bookings</h2>
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "left" }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="name"
              type="number"
              label="Booking Id"
              value={bookingId}
              variant="outlined"
              onChange={(e) => setBookingId(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="name"
              type="text"
              label="Name"
              value={name}
              variant="outlined"
              onChange={(e) => setName(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="phone"
              type="number"
              label="Phone"
              value={phone}
              variant="outlined"
              onChange={(e) => setPhone(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="Barcode"
              type="text"
              label="Barcode"
              value={qrcode}
              variant="outlined"
              onChange={(e) => setqrCode(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="start_date"
              type="date"
              label="Start Date"
              value={
                booking_date === "" || collection_date === "" ? start_date : ""
              }
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setStart_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="end_date"
              type="date"
              value={
                booking_date === "" || collection_date === "" ? end_date : ""
              }
              className="input"
              label="End Date"
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setEnd_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="center"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setCentre(obj.id);
                }
              }}
              freeSolo
              blurOnSelect
              options={centerInfo?.results|| []}
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                (option?.display_name === null
                  ? option?.name
                  : option?.display_name) +
                "(" +
                option?.center_code +
                ")"
              }
              loading={loading}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCenterInfo(`?name_or_code=${newInputValue}`);
                }, 1000);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  style={{height: "40px"}}
                  label="Centre"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                          if (e.key === 'Enter') {
                            e.stopPropagation();
                          }
                    },
                  }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              
              fullWidth
              onClick={filterBookings}
              disabled={loading}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              
              fullWidth
              onClick={() => navigate(0)}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <br />
        {loading ? (
          <Loader />
        ) : (
          bookingList.results &&
          bookingList.results.length > 0 && (
            <Box>
              <Container maxWidth={false}>
                <Grid container spacing={3}>
                  <BookingsTable
                    page={page}
                    setPage={setPage}
                    loading={loading}
                    getBookings={getAllOfflineBookings}
                    bookingList={bookingList}
                  />
                </Grid>
              </Container>
            </Box>
          )
        )}
        {bookingList.results && bookingList.results.length === 0 && (
          <h6 style={{ textAlign: "center" }}>No Data Found</h6>
        )}
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  centerInfo: state.LimsAdminReducer.centerInfo,
  bookingList: state.LimsAdminReducer.bookingList,
  loading: state.LimsAdminReducer.loading,
});

export default connect(mapStateToProps, {
  getCenterInfo,
  getAllOfflineBookings,
})(OfflineFilterPage);
