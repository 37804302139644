import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Autocomplete from '@mui/material/Autocomplete';
import {
  getCenterInfo,
  getDoctorQualification,
  getDoctorSpecialization,
  postRefDoctor,
  resetRefDoctor,
} from "../../actions/LimsAdminActions";
import {
  Grid,
  Typography,
  InputLabel,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  Button,
  RadioGroup,
  InputAdornment,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    margin: "25px auto",
  },
  tcolor: {
    color: "black",
    fontWeight: "bolder",
  },
  table: {
    minWidth: 650,
  },
  row: {
    color: "black",
    fontSize: "larger",
    fontWeight: "bolder",
  },
  margin: {
    margin: theme.spacing(1),
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gap: {
    fontSize: "14px",
    margin: "0 -40px 0 40px",
  },
  start: {
    display: "flex",
    justifyContent: "flex-start",
  },
  formContorlLabel: {
    padding: "0 0 0 25px",
    margin: "0 0 0 15px",
    color: "rgba(0, 0, 0, 0.54)",
  },
  select: {
    margin: "0 0 0 -8px",
    minWidth: "155px",
    width: "100%",
    height: "40px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  spacebtm: {
    marginBottom: 15,
  },
  formadd: {
    width: "100%",
  },
  button1: {
    margin: "0 8px",
    height: 42,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //   width: 200,

    // Custom Styling
    width: "155px",
  },
}));

interface Props {
  getDoctorSpecialization: any;
  doctorSpec: any;
  getDoctorQualification: any;
  doctorQual: any;
  getCenterInfo: any;
  postRefDoctor: any;
  centerInfo: any;
  getRefDoctor: any;
  setOpenDocModal: any;
  responseRefDoctor: any;
  resetRefDoctor: any;
}

const ReferringDoctor: React.FC<Props> = ({
  getDoctorSpecialization,
  doctorSpec,
  getCenterInfo,
  centerInfo,
  getDoctorQualification,
  postRefDoctor,
  doctorQual,
  getRefDoctor,
  setOpenDocModal,
  responseRefDoctor,
  resetRefDoctor,
}) => {
  const classes = useStyles();
  const [qualification, setQualification] = useState<any>([]);
  const [specilization, setSpecilization] = useState<any>([]);
  const [name, setName] = useState<any>("");
  const [dob, setDob] = useState<any>("");
  const [gender, setGender] = useState<any>("m");
  const [mobileNo, setMobile] = useState<any>("");
  const [centre, setCentre] = useState<any>([]);
  const [clinicAddress, setClinicAddress] = useState<any>("");
  const [residentialAddress, setResidentialAddress] = useState<any>("");
  const [mobileValidation, setMobileValidation] = useState<any>({
    mobileNo: true,
  });
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getCenterInfo();
    getDoctorSpecialization();
    getDoctorQualification();
  }, []);
  const submitRefferingDoctorForm = async (e: any) => {
    e.preventDefault();
    const data: any = {
      specialization: specilization,
      qualification: qualification,
      name: name,
      dob: dob,
      gender: gender,
      mobile: mobileNo,
      clinic_address: clinicAddress,
      residential_address: residentialAddress,
      centre: centre,
    };
    if (specilization.length===0) {
      delete data.specialization;
    }
    if (qualification.length===0) {
      delete data.qualification;
    }
    if (dob === "") {
      delete data.dob;
    }

    if (gender.length===0) {
      delete data.gender;
    }
    if (mobileNo === "") {
      delete data.mobile;
    }
    if (clinicAddress === "") {
      delete data.clinic_address;
    }
    if (residentialAddress === "") {
      delete data.residential_address;
    }
    if (centre.length===0) {
      delete data.centre;
    }

    await postRefDoctor(data);
    getRefDoctor();
  };
  const verifyMobile = (type: any) => {
    const mobileRegEx = /^[6-9]\d{9}$/;
    if (type === "mobileNo" && !mobileRegEx.test(mobileNo.toString())) {
      setMobile((prev: any) => ({
        ...prev,
        mobileNo: false,
      }));
    }
  };
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (responseRefDoctor.id !== undefined) {
      setOpenDocModal(false);
      resetRefDoctor();
    }
  }, [responseRefDoctor]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid container item xs={12}>
          <Typography variant="h4" component="h1" gutterBottom>
            Add Reffering Doctor
          </Typography>
        </Grid>
        <form
          onSubmit={submitRefferingDoctorForm}
          autoComplete="off"
          style={{ width: "100%" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                required
                fullWidth
                label="Name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Dr.</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Autocomplete
                onChange={(event, newValue) => {
                  let ids: any = [];
                  newValue.map((val: any) => {
                    let obj = JSON.parse(JSON.stringify(val, null, " "));
                    ids.push(obj.id);
                  });
                  setQualification(ids);
                }}
                multiple
                limitTags={1}
                disableClearable
                freeSolo
                blurOnSelect
                options={doctorQual?.results}
                disableCloseOnSelect
                getOptionLabel={(option: any) => option.qualification}
                // onInputChange={(event, newInputValue) => {
                //   getCenterInfo(`?name=${newInputValue}`);
                // }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Qualification/Degree"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Autocomplete
                id="center"
                onChange={(event, newValue) => {
                  let ids: any = [];
                  newValue.map((val: any) => {
                    let obj = JSON.parse(JSON.stringify(val, null, " "));
                    ids.push(obj.id);
                  });
                  setSpecilization(ids);
                }}
                multiple
                limitTags={1}
                disableClearable
                freeSolo
                blurOnSelect
                options={doctorSpec?.results}
                disableCloseOnSelect
                getOptionLabel={(option: any) => option.specialization}
                // onInputChange={(event, newInputValue) => {
                //   getCenterInfo(`?name=${newInputValue}`);
                // }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Specialization"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Mobile No."
                variant="outlined"
                type="number"
                inputProps={{
                  maxLength: "10",
                }}
                value={mobileNo || ""}
                onChange={(e) => {
                  return setMobile(
                    Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10)
                  );
                }}
                onBlur={() => verifyMobile("mobileNo")}
                onFocus={() => {
                  setMobileValidation((prev: any) => ({
                    ...prev,
                    mobileNo: true,
                  }));
                }}
                helperText={
                  !mobileValidation.mobileNo && (
                    <span style={{ color: "red", fontSize: "large" }}>
                      Incorrect Mobile No.
                    </span>
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="DOB"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Clinic Address"
                variant="outlined"
                value={clinicAddress}
                onChange={(e) => setClinicAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Residential Address"
                variant="outlined"
                value={residentialAddress}
                onChange={(e) => setResidentialAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Autocomplete
                id="center"
                onChange={(event, newValue) => {
                  let ids: any = [];
                  newValue.map((val: any) => {
                    let obj = JSON.parse(JSON.stringify(val, null, " "));
                    ids.push(obj.id);
                  });
                  setCentre(ids);
                }}
                multiple
                limitTags={1}
                disableClearable
                freeSolo
                blurOnSelect
                options={centerInfo?.results}
                disableCloseOnSelect
                getOptionLabel={(option: any) => option.name}
                onInputChange={(event, newInputValue) => {
                  getCenterInfo(`?name=${newInputValue}`);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Centre"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} md={1}>
              <InputLabel
                htmlFor="gender"
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: "larger",
                  marginTop: "12px",
                }}
              >
                Gender:
              </InputLabel>
            </Grid>
            <Grid item xs={9} md={5}>
              <FormControl component="fieldset">
                <RadioGroup name="gender" style={{ flexDirection: "row" }}>
                  <FormControlLabel
                    control={<Radio />}
                    label="Male"
                    checked={gender === "male"}
                    value="male"
                    onClick={() => setGender("male")}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Female"
                    checked={gender === "female"}
                    value="female"
                    onClick={() => setGender("female")}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Other"
                    checked={gender === "other"}
                    value="other"
                    onClick={() => setGender("other")}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.center}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="secondary"
            >
              SAVE
            </Button>
          </Grid>
        </form>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  centerInfo: state.LimsAdminReducer.centerInfo,
  doctorSpec: state.LimsAdminReducer.doctorSpec,
  doctorQual: state.LimsAdminReducer.doctorQual,
  responseRefDoctor: state.LimsAdminReducer.responseRefDoctor,
});

export default connect(mapStateToProps, {
  getCenterInfo,
  getDoctorQualification,
  getDoctorSpecialization,
  postRefDoctor,
  resetRefDoctor,
})(ReferringDoctor);
