export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  GET_PIN_CODE: "la/get_pin_code",
  GET_CITIES: "la/get_cities",
  GET_AREA: "la/get_area",
  GET_AGENTS: "la/get_agents",
  GET_AGENTS_BY_USERGROUP: "la/get_agents_by_usergroup",
  GET_USERS_BY_USERGROUP: "la/get_users_by_usergroup",
  GET_PACKAGES: "la/get_packages",
  GET_RESAMPLING_PACKAGES: "la/get_resampling_packages",
  EXPORT_SYNC_BOOKINGS: "la/export_sync_bookings",
  GET_PHLEBOS: "la/get_phlebos",
  GET_PARTNERS: "la/get_partners",
  GET_COUPONS: "la/get_coupons",
  CREATE_BOOKING: "la/create_booking",
  UPDATE_BOOKING: "la/update_booking",
  GET_CAMP_COUPONS: "la/get_camp_coupons",
  GET_BOOKING_LIST: "la/get_booking_list",
  GET_DOCTOR_QUAL: "ccpartner/get_doctor_qual",
  GET_DOCTOR_SPEC: "ccpartner/get_doctor_spec",
  CREATE_ADDITIONAL_BOOKING: "pha/create_additional_booking",
  UPDATE_ADDITIONAL_BOOKING: "pha/update_additional_booking",
  GET_LEAD_DETAILS: "la/get_lead_details",
  GET_RECEIVED_PACKAGES: "la/get_received_packages",
  GET_MAMPMYINDIA_ACCESS_TOKEN: "la/get-mapmyindia-access-token",
  GET_REF_DOCTOR: "la/get_ref_doctor",
  POST_SAMPLE_COLLECTION: "la/post_sample_collection",
  GET_LEAD_BY_ID: "la/get_lead_by_id",
  LEAD_DETAILS_RESET: "la/lead_details_reset",
  GET_LEADS: "la/get_leads",
  GET_BOOKING_BY_ID: "la/get_booking_by_id",
  CLICK_TO_CALL: "la/click_to_call",
  GET_POSTIVE_CALLS_COMMENTS: "la/get_postive_calls_commnets",
  ADD_POSTIVE_CALLS_COMMENT: "la/add_comments",
  SET_COMMENTS: "la/set_commnets",
  UPLOAD_FILE: "la/upload",
  SET_LOADING: "la/set_loading",
  SET_BOOKING_LOADING: "la/set_booking_loading",
  GET_TUBE_BY_BOOKING: "ccpartner/get_tube_by_booking",
  SEARCH_CALL: "la/serach",
  BOOKING_RESET: "la/booking_reset",
  ADD_COMMENT: "positive_calls/add_comment",
  CREATE_CALL: "la/createcall",
  GET_LEAD_SOURCE: "la/getleadsource",
  GET_RECORDINGS: "la/get_recordings",
  GET_BOOKINGS_COMMENTS: "la/get_bookings_commnets",
  ADD_BOOKINGS_COMMENT: "la/add_bookings_comments",
  GET_TICKET_COMMENTS: "la/get_ticket_commnets",
  ADD_TICKET_COMMENT: "la/add_ticket_comments",
  GET_WHATSAPP_COMMENTS: "la/get_whatsapp_comments",
  GET_WHATSAPP_COMMENTS_BY_LEAD: "la/get_whatsapp_comments_by_lead",
  SEND_WHATSAPP_COMMENTS: "la/send",
  GET_CALL_STATS: "la/get_call_stats",
  RAISE_COMPLAINT: "la/raise_complaint",
  GET_COMPLAINTS: "la/get_complaints",
  ASSIGN_DOCTOR: "la/assign_doctor",
  SEND_FEEDBACK: "la/send_feedback",
  GET_AVAILABLE_SLOTS: "la/get_available_slots",
  GET_ALL_LEADS: "la/get_all_leads",
  LEAD_TRANSFER_BY_COMMA: "la/set_comments",
  GET_PANEL_USERS: "la/get_panel_user",
  RESAMPLE_BOOKING: "la/resample_booking",
  GET_SYNC_DATA: "la/get_sync_data",
  GET_TUBE_DETAILS: "la/get_tub_details",
  GET_BOOKING_RECEIVED_PACKAGE: "la/get_booking_received_package",
  GET_SMS_REPORT_SEND: "la/get_sms_report_send",
  GET_SMS_LINK: "la/send_sms_link",
  GET_REPORT_DOWNLOAD: "la/get_report_download",
  GET_REPORT_STATUS: "la/get_report_status",
  GET_CENTER_INFORMATION: "la/get_center_information",
  GET_CENTER: "la/get_center",
  GET_PAYMENT_INFORMATION: "la/get_payment_information",
  GET_NOTIFICATION_MESSAGE: "la/get_notification_message",
  GET_UNMASKED_NUMBER: "la/get_unmasked_number",
  GET_SAMPLE_HANDOVER_DATA: "la/getsample_handover_data",
  GET_SAMPLE_JOURNEY_COMMENTS: "la/get_sample_journey_comments",
  UPDATE_RIDER_TO_LAB_STATUS: "la/update_rider_to_lab_status",
  GET_ADDITIONAL_BOOKING: "la/get_additional_booking",
  GET_SIGNATURE: "la/get_signature",
  GET_REPEATED_LEADS: "la/get_repeted_lead",
  GET_QRCODE_DATA: "la/get_qrcode_data",
  GET_PACKAGE_TUBE_DETAILS: "la/get_package_tube_details",
  UPDATE_HISTOPATHOLOGY_FORM: "la/update_histo",
  UPDATE_NBS_FORM: "la/update_nbs",
  GET_RIDER_LIST: "la/get_rider_list",
  GET_UNREGISTERD_BOOKING: "la/get_unregisterd_booking",
  UPDATE_UNREGISTERD_BOOKING_STATUS: "la/update_unregisterd_booking_status",
  CHANGE_TICKET_STATUS: "la/change_ticket_status",
  GET_UNSYNC_BOOKING: "la/get_unsync_booking",
  GET_BOOKING_BY_BARCODE: "la/get_booking_by_barcode",
  POST_ADD_REMOVE_TEST: "la/post_add_remove_test",
  GET_CC_PACKAGES: "la/get_cc_packages",
  GET_HIV_PACKAGES: "la/get_hiv_package",
  CHECK_GEOFENCE_AREA: "ccpartner/checking-geofence-area",
  CHECK_GEOFENCE_AREA_MAMPMYINDIA:
    "ccpartnera/checking-geofence-area-mapmyindia",
  POST_REF_DOCTOR: "ccpartner/post_ref_doctor",
  GET_BOOKINGS_BY_CENTRE: "ccpartner/get_booking_by_centre",
  GET_DC_PACKAGES: "la/get_dc_packages",
  CREATE_DC_BOOKING: "la/createdc_booking",
  GET_BIOMARKER_SCREENING: "la/biomarker_screening",
  GET_BIOMARKER_ULTASOUND: "la/biomarker_ultasound",
  GET_ADDITIONAL_MEMBER: "la/additional_member",
  UPDATE_REPORT_STATUS: "la/update_report_status",
  GET_PHLEBO_RIDER_DUE_AMOUNT: "la/get_phlebo_rider_due_amount",
  GET_TICKET_DATA: "la/get_ticket_data",
  GET_TICKET_CATEGORIES: "la/get_ticket_category",
  GET_TICEKT_SUB_CATEGORIES: "la/get_ticket_categories",
  CREATE_TASK_TICKET: "la/create_task_ticket",
  GET_RED_TECH_COMMENT: "la/redtech_comments/",
  SEND_REPORT_BY_ID: "la/send_report",
  POST_RED_TECH_COMMENT: "la/postredtech_comments/",
  GET_FAILED_REPORT_DATA: "la/report_failed/",
  GET_RECEIVED_TICKET_TASKS: "la/received_ticket",
  GET_USER_FROM_CATEGORIES: "la/user_from_categories",
  REF_DOCTOR_RESET: "la/ref_doctor_reset",
  BIOMARKER_RESET: "la/biomarker_reset",
  GET_BATCH_BOOKING_AMOUNT: "la/get_batch_amount",
  GET_BOOKING_OVERVIEW: "la/booking_overview",
  TRF_UPLOAD_FORM: "la/trf_upload_form",
  GET_UPLOAD_TRF: "la/get_upload_trf",
  GET_ZONE: "la/get_zone",
  UPDATE_BOOKING_ZONE: "la/update_booking_zone",
  SET_SYNC_LOADING: "la/set_sync_loading",
  GET_BOOKING_COMPLAINTS: "la/get_booking_complaints",
  RAISE_NEW_RAISE_TICEKT_DISPOSITION: "se/cstech_create-ticekt",
  UPDATE_TICKET_STATUS: "la/update_ticekt_status",
  SET_ALL_BOOKING_LOADING: "la/set_all_booking_loading",
  CREATE_HISTOPATHOLOGY_BOOKING: "la/createhistopathology_booking",
  GET_ADVANCE_PAYMENT: "la/advance-payment/",
  GET_BIOSPYPACKAGES: "la/biospy_packages",
  GET_TICKET_DATA_FOR_ID: "la/get_ticket_data_for_id",
  GET_BOOKING_BY_HISTORYFORM: "la/get_booking_by_historyform",
  GET_BOOKING_BY_NBS: "la/get_booking_by_nbs",
  GET_FINANCE_ANALYTICS_DATA_LAB_CITY: "la/get_analytics_data_lab_city",
  GET_FINANCE_ANALYTICS_DATA_NON_LAB_CITY: "la/get_analytics_data_non_lab_city",
  GET_USER_DETAILS: "la/get_user_details",
  GET_TIMESLOTS: "la/get_timeslot",
};
interface GetCampCoupons {
  type: typeof ACTIONS.GET_CAMP_COUPONS;
  payload: Array<any>;
}
interface GetAnalyticsDataLabCity {
  type: typeof ACTIONS.GET_FINANCE_ANALYTICS_DATA_LAB_CITY;
  payload: Array<any>;
}
interface GetAnalyticsDataNonLabCity {
  type: typeof ACTIONS.GET_FINANCE_ANALYTICS_DATA_NON_LAB_CITY;
  payload: Array<any>;
}
interface UpdateTicketStatus {
  type: typeof ACTIONS.UPDATE_TICKET_STATUS;
  payload: Array<any>;
}
interface GetBookingQueryTickets {
  type: typeof ACTIONS.GET_BOOKING_COMPLAINTS;
  payload: boolean;
}
interface UpdateBookingZone {
  type: typeof ACTIONS.UPDATE_BOOKING_ZONE;
  payload: Array<any>;
}
interface GetUploadTrf {
  type: typeof ACTIONS.GET_UPLOAD_TRF;
  payload: Array<any>;
}
interface PostUploadTrf {
  type: typeof ACTIONS.TRF_UPLOAD_FORM;
  payload: Array<any>;
}
interface GetResamplingPackages {
  type: typeof ACTIONS.GET_RESAMPLING_PACKAGES;
  payload: Array<any>;
}
interface ExportSyncBookings {
  type: typeof ACTIONS.EXPORT_SYNC_BOOKINGS;
  payload: Array<any>;
}
interface GetBatchBookingAmount {
  type: typeof ACTIONS.GET_BATCH_BOOKING_AMOUNT;
  payload: boolean;
}
interface RefDoctorReset {
  type: typeof ACTIONS.REF_DOCTOR_RESET;
  payload: boolean;
}
interface BiomarkerReset {
  type: typeof ACTIONS.BIOMARKER_RESET;
  payload: boolean;
}
interface GetPhleboRiderDueAmount {
  type: typeof ACTIONS.GET_PHLEBO_RIDER_DUE_AMOUNT;
  payload: Array<any>;
}
interface GetBookingsByCentre {
  type: typeof ACTIONS.GET_BOOKINGS_BY_CENTRE;
  payload: boolean;
}
interface PostRefDoctor {
  type: typeof ACTIONS.POST_REF_DOCTOR;
  payload: Array<any>;
}
interface updateReportStatus {
  type: typeof ACTIONS.UPDATE_REPORT_STATUS;
  payload: Array<any>;
}
interface CheckGeoFenceArea {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA;
  payload: Array<any>;
}
interface GetReceivedPackages {
  type: typeof ACTIONS.GET_RECEIVED_PACKAGES;
  payload: Array<any>;
}
interface PostSampleCollection {
  type: typeof ACTIONS.POST_SAMPLE_COLLECTION;
  payload: Array<any>;
}
interface GetDoctorQual {
  type: typeof ACTIONS.GET_DOCTOR_QUAL;
  payload: Array<any>;
}
interface GetDoctorSpec {
  type: typeof ACTIONS.GET_DOCTOR_SPEC;
  payload: Array<any>;
}
interface GetTubeByBooking {
  type: typeof ACTIONS.GET_TUBE_BY_BOOKING;
  payload: boolean;
}
interface CheckGeoFenceAreaMapMyIndia {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA;
  payload: Array<any>;
}
interface GetMapMyIndiaAccessToken {
  type: typeof ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN;
  payload: Array<any>;
}
interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface GetBookingByBarcode {
  type: typeof ACTIONS.GET_BOOKING_BY_BARCODE;
  payload: Array<any>;
}
interface GetUnregisterdBooking {
  type: typeof ACTIONS.GET_UNREGISTERD_BOOKING;
  payload: Object;
}
interface GetSignature {
  type: typeof ACTIONS.GET_SIGNATURE;
  payload: Array<any>;
}
interface GetPackageTubeDetails {
  type: typeof ACTIONS.GET_PACKAGE_TUBE_DETAILS;
  payload: Array<any>;
}
interface GetRiderList {
  type: typeof ACTIONS.GET_RIDER_LIST;
  payload: Array<any>;
}
interface createNewDCBooking {
  type: typeof ACTIONS.CREATE_DC_BOOKING;
  payload: boolean;
}
interface GetRepetedLead {
  type: typeof ACTIONS.GET_REPEATED_LEADS;
  payload: Array<any>;
}
interface GetFailedReportData {
  type: typeof ACTIONS.GET_FAILED_REPORT_DATA;
  payload: Array<any>;
}

interface GetReceivedTicketTasks {
  type: typeof ACTIONS.GET_RECEIVED_TICKET_TASKS;
  payload: Array<any>;
}

interface GetUserFromCategories {
  type: typeof ACTIONS.GET_USER_FROM_CATEGORIES;
  payload: Array<any>;
}

interface SendReportById {
  type: typeof ACTIONS.SEND_REPORT_BY_ID;
  payload: Object;
}

interface GetQRCodeData {
  type: typeof ACTIONS.GET_QRCODE_DATA;
  payload: Array<any>;
}
interface GetAdditionalBooking {
  type: typeof ACTIONS.GET_ADDITIONAL_BOOKING;
  payload: Array<any>;
}
interface GetSampleHandOver {
  type: typeof ACTIONS.GET_SAMPLE_HANDOVER_DATA;
  payload: Array<any>;
}
interface GetUnmaskedNumber {
  type: typeof ACTIONS.GET_UNMASKED_NUMBER;
  payload: Array<any>;
}
interface UpdateRiderToLabStatus {
  type: typeof ACTIONS.UPDATE_RIDER_TO_LAB_STATUS;
  payload: Array<any>;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface GetRefDoctor {
  type: typeof ACTIONS.GET_REF_DOCTOR;
  payload: Array<any>;
}
interface UpdateUnregisterdBookingStatus {
  type: typeof ACTIONS.UPDATE_UNREGISTERD_BOOKING_STATUS;
  payload: Object;
}
interface ChangeTicketStatus {
  type: typeof ACTIONS.CHANGE_TICKET_STATUS;
  payload: Object;
}
interface GetHivPackage {
  type: typeof ACTIONS.GET_HIV_PACKAGES;
  payload: Object;
}
interface UploadFile {
  type: typeof ACTIONS.UPLOAD_FILE;
  payload: Object;
}

interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}

interface GetArea {
  type: typeof ACTIONS.GET_AREA;
  payload: Array<any>;
}

interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface GetPhlebo {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}
interface GetSampleJourneyComments {
  type: typeof ACTIONS.GET_SAMPLE_JOURNEY_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}

interface ResampleBooking {
  type: typeof ACTIONS.RESAMPLE_BOOKING;
  payload: Object;
}

interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}
interface SetBookingLoading {
  type: typeof ACTIONS.SET_BOOKING_LOADING;
  payload: boolean;
}

interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}

interface GetArea {
  type: typeof ACTIONS.GET_AREA;
  payload: Array<any>;
}
interface GetPhlebo {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}

interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}
interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}

interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface GetAgentByUserGroup {
  type: typeof ACTIONS.GET_AGENTS_BY_USERGROUP;
  payload: Object;
}
interface GetUserByUserGroup {
  type: typeof ACTIONS.GET_USERS_BY_USERGROUP;
  payload: Object;
}
interface CreateAdditionalBooking {
  type: typeof ACTIONS.CREATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface UpdateAdditionalBooking {
  type: typeof ACTIONS.UPDATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface GetSyncData {
  type: typeof ACTIONS.GET_SYNC_DATA;
  payload: Object;
}
interface GetTubeDetails {
  type: typeof ACTIONS.GET_TUBE_DETAILS;
  payload: Array<any>;
}
interface GetBookingReceivedPackage {
  type: typeof ACTIONS.GET_BOOKING_RECEIVED_PACKAGE;
  payload: Array<any>;
}
interface GetSmsReportSent {
  type: typeof ACTIONS.GET_SMS_REPORT_SEND;
  payload: Object;
}
interface SendSmsLink {
  type: typeof ACTIONS.GET_SMS_LINK;
  payload: Object;
}
interface GetReportDownload {
  type: typeof ACTIONS.GET_REPORT_DOWNLOAD;
  payload: Object;
}
interface GetReportStatus {
  type: typeof ACTIONS.GET_REPORT_STATUS;
  payload: Array<any>;
}
interface GetCenterInfo {
  type: typeof ACTIONS.GET_CENTER_INFORMATION;
  payload: Array<any>;
}
interface GetPaymentInfro {
  type: typeof ACTIONS.GET_PAYMENT_INFORMATION;
  payload: Array<any>;
}
interface GetNotificationMessage {
  type: typeof ACTIONS.GET_NOTIFICATION_MESSAGE;
  payload: Array<any>;
}
interface PostAddRemoveTest {
  type: typeof ACTIONS.POST_ADD_REMOVE_TEST;
  payload: Array<any>;
}
interface getCCPackage {
  type: typeof ACTIONS.GET_CC_PACKAGES;
  payload: Array<any>;
}

interface getDCPackage {
  type: typeof ACTIONS.GET_DC_PACKAGES;
  payload: Array<any>;
}
interface GetBiomarkerScreening {
  type: typeof ACTIONS.GET_BIOMARKER_SCREENING;
  payload: Object;
}
interface GetBiomarkerUltasound {
  type: typeof ACTIONS.GET_BIOMARKER_ULTASOUND;
  payload: Object;
}
interface GetAdditionalMember {
  type: typeof ACTIONS.GET_ADDITIONAL_MEMBER;
  payload: Array<any>;
}
interface GetRedTechComment {
  type: typeof ACTIONS.GET_RED_TECH_COMMENT;
  payload: Array<any>;
}
interface GetTicketData {
  type: typeof ACTIONS.GET_TICKET_DATA;
  payload: Object;
}
interface GetTicketCategory {
  type: typeof ACTIONS.GET_TICKET_CATEGORIES;
  payload: Object;
}
interface GetTicketSubCategories {
  type: typeof ACTIONS.GET_TICEKT_SUB_CATEGORIES;
  payload: Object;
}
interface PostRedTechComment {
  type: typeof ACTIONS.POST_RED_TECH_COMMENT;
  payload: Object;
}
interface CreateTaskTicket {
  type: typeof ACTIONS.CREATE_TASK_TICKET;
  payload: Array<any>;
}
interface GetTicketComments {
  type: typeof ACTIONS.GET_TICKET_COMMENTS;
  payload: Array<any>;
}
interface GetZone {
  type: typeof ACTIONS.GET_ZONE;
  payload: Object;
}
interface GetBookingOverview {
  type: typeof ACTIONS.GET_BOOKING_OVERVIEW;
  payload: Array<any>;
}
interface SyncLoading {
  type: typeof ACTIONS.SET_SYNC_LOADING;
  payload: Array<any>;
}
interface RaiseNewTicektDisposition {
  type: typeof ACTIONS.RAISE_NEW_RAISE_TICEKT_DISPOSITION;
  payload: Array<any>;
}
interface GetCentre {
  type: typeof ACTIONS.GET_CENTER;
  payload: Array<any>;
}
interface GetBookingsLoader {
  type: typeof ACTIONS.SET_ALL_BOOKING_LOADING;
  payload: boolean;
}
interface createHistopathologyBooking {
  type: typeof ACTIONS.CREATE_HISTOPATHOLOGY_BOOKING;
  payload: boolean;
}

interface GetUserDetails {
  type: typeof ACTIONS.GET_USER_DETAILS;
  payload: Object;
}
interface GetAdvancePayment {
  type: typeof ACTIONS.GET_ADVANCE_PAYMENT;
  payload: Array<any>;
}
interface getBiospypackages {
  type: typeof ACTIONS.GET_BIOSPYPACKAGES;
  payload: Array<any>;
}
interface GetTicketDataForId {
  type: typeof ACTIONS.GET_TICKET_DATA_FOR_ID;
  payload: Object;
}
interface GetBookingByHistoryForm {
  type: typeof ACTIONS.GET_BOOKING_BY_HISTORYFORM;
  payload: boolean;
}
interface GetBookingByNbsForm {
  type: typeof ACTIONS.GET_BOOKING_BY_NBS;
  payload: boolean;
}
interface UpdateHistopathologyForm {
  type: typeof ACTIONS.UPDATE_HISTOPATHOLOGY_FORM;
  payload: boolean;
}
interface GetTimeSlot {
  type: typeof ACTIONS.GET_TIMESLOTS;
  payload: Array<any>;
}
interface UpdateNbsForm {
  type: typeof ACTIONS.UPDATE_NBS_FORM;
  payload: boolean;
}
export type PhleboAdminTypes =
  | SearchCall
  | CheckGeoFenceArea
  | CheckGeoFenceAreaMapMyIndia
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetArea
  | GetMapMyIndiaAccessToken
  | GetCampCoupons
  | GetAgents
  | GetPhlebo
  | GetAgentByUserGroup
  | GetUserByUserGroup
  | CreateAdditionalBooking
  | UpdateAdditionalBooking
  | GetPackages
  | CreateBooking
  | UpdateBooking
  | GetPartners
  | GetCoupons
  | GetBookings
  | GetBookingById
  | SetLoading
  | SetBookingLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | GetReceivedPackages
  | UploadFile
  | GetBookingsByCentre
  | PostRefDoctor
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetArea
  | GetAgents
  | GetPhlebo
  | GetPackages
  | GetPartners
  | GetCoupons
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | SearchCall
  | GetRefDoctor
  | ResampleBooking
  | GetSyncData
  | GetTubeDetails
  | GetBookingReceivedPackage
  | GetSmsReportSent
  | SendSmsLink
  | GetReportDownload
  | GetReportStatus
  | GetCenterInfo
  | GetPaymentInfro
  | GetNotificationMessage
  | GetUnmaskedNumber
  | GetSampleHandOver
  | GetSampleJourneyComments
  | UpdateRiderToLabStatus
  | GetAdditionalBooking
  | GetSignature
  | GetQRCodeData
  | GetRepetedLead
  | GetPackageTubeDetails
  | GetRiderList
  | GetUnregisterdBooking
  | UpdateUnregisterdBookingStatus
  | ChangeTicketStatus
  | GetBookingByBarcode
  | PostAddRemoveTest
  | getCCPackage
  | PostSampleCollection
  | GetTubeByBooking
  | GetHivPackage
  | getDCPackage
  | createNewDCBooking
  | GetBiomarkerScreening
  | GetBiomarkerUltasound
  | GetAdditionalMember
  | createNewDCBooking
  | updateReportStatus
  | GetPhleboRiderDueAmount
  | GetTicketData
  | GetTicketCategory
  | GetTicketSubCategories
  | CreateTaskTicket
  | GetRedTechComment
  | GetFailedReportData
  | GetReceivedTicketTasks
  | GetUserFromCategories
  | RefDoctorReset
  | SendReportById
  | GetTicketComments
  | PostRedTechComment
  | GetBatchBookingAmount
  | GetBookingOverview
  | GetResamplingPackages
  | GetUploadTrf
  | PostUploadTrf
  | ExportSyncBookings
  | GetZone
  | UpdateBookingZone
  | SyncLoading
  | GetBookingQueryTickets
  | RaiseNewTicektDisposition
  | UpdateTicketStatus
  | GetCentre
  | GetBookingsLoader
  | createHistopathologyBooking
  | GetAdvancePayment
  | getBiospypackages
  | GetTicketDataForId
  | GetBookingByHistoryForm
  | BiomarkerReset
  | GetTicketDataForId
  | GetAnalyticsDataLabCity
  | GetAnalyticsDataNonLabCity
  | GetBookingByNbsForm
  | UpdateHistopathologyForm
  | UpdateNbsForm
  | GetUserDetails
  | GetTimeSlot
  | GetDoctorQual
  | GetDoctorSpec