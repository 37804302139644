import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TextField,
  withStyles,
  Select,
  MenuItem,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import "./index.sass"
import Stack from '@mui/material/Stack';
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import Modal from "@mui/material/Modal";
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import MessageIcon from "@material-ui/icons/Message";
import EditIcon from "@material-ui/icons/EditRounded";
import { useNavigate } from "react-router";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import SampleJourneyComment from "../components/Comments/sampleJourney";
import PaymentModal from '../components/Comments/batchPaymentModal';
import BatchIdModel from "../components/Comments/BatchIdModel";
import {
  getSampleHandOver,
  updateRiderToLabStatus,
  getRiderList,
  getPhlebos,
  getPaymentInfo,
} from "../actions/LimsAdminActions";
import { genrateSampleHandoverFilter } from "../../helpers/generateUrl";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
    },
    tableHead: {
      background: "rgb(146, 74, 145)!important",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
      width: "98%",
    },
    input: {
      padding: "1px",
    },
    // imageSM: {
    //   width: "60px",
    //   height: "60px",
    //   border: "2px solid #000 ",
    //   borderRadius: "50%"
    // },
    imagePaper: {
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[1],
    },
    dialog: {
      zIndex: 1,
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

interface SampleHandOverProps {
  getSampleHandOver: any;
  sampleHandOverData: any;
  updateRiderToLabStatus: any;
  getRiderList: any;
  riderList: any;
  getPhlebos: any;
  phleboList: any;
  getPaymentInfo: any;
  paymentInformation: any;
  loading: boolean;
}

const BookingsTable: React.FC<SampleHandOverProps> = ({
  getSampleHandOver,
  sampleHandOverData,
  updateRiderToLabStatus,
  getRiderList,
  riderList,
  getPhlebos,
  phleboList,
  getPaymentInfo,
  paymentInformation,
  loading,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const timer = useRef<any>(0);
  const [bookingId, setBookingId] = useState<any>("");
  const [page, setPage] = React.useState(0);
  // const rowsPerPage=20;
  const [open, setOpen] = useState<boolean>(false);
  const [sampleId, setSampleId] = useState<number>(0);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [createdAt, setCreatedAt] = useState<string>("");
  const [batchId, setBatchId] = useState<number>(0);
  const [openBatch, setOpenBatch] = useState<boolean>(false);
  const [rider, setRider] = useState<any>([]);
  const [phlebo, setPhlebo] = useState<any>([]);
  const [barcode, setBarcode] = useState<any>("");
  const masterbarcode=""
  const [batchStatus, setBatchStatus] = useState<any>("none");
  const [openLocationModal, setOpenLocationModal] = useState<boolean>(false);
  const [acceptFrom, setAcceptFrom] = useState<string>("");
  const [acceptRider, setAcceptRider] = useState<string>("");
  const [acceptAmount, setAcceptAmount] = useState<string>("");
  const [labRemark, setLabRemark] = useState<string>("");
  // const [accentFromAgent, setAccentFromAgent] = useState<string>("");
  const [batchNumber, setBatchNumber] = useState<any>("");
  // const [city, setCity] = useState<any>([]);
  const [sampleHandoverID, setSampleHandoverID] = useState<string>("");
  const [receivableAmount, setReceivableAmount] = useState<string>("");
  // const [riderAmount, setRiderAmount] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [modalStyle] = React.useState(getModalStyle);
  const [openPaymentModal, setOpenPaymentModal] = useState<any>(false)
  const [batchType, setBatchType] = useState<any>("")

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps

    getPhlebos();
    getRiderList(`?usergroup=Rider`);
  }, []);

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (acceptFrom === "Rider") {
      setAcceptAmount(receivableAmount || "0");
    } else if (acceptFrom === "Phlebo") {
      setAcceptAmount(receivableAmount || "0");
    } else {
      setAcceptAmount("");
    }
  }, [acceptFrom]);

  const openAcceptPaymentModel = (
    id: string,
    non_lab_city: string,
    cash_amount: string,
    lab_received_from: string,
  ) => {
    setAcceptFrom(lab_received_from)
    setAcceptRider(lab_received_from);
    setSampleHandoverID(id);
    if (cash_amount==="") {
      cash_amount = "0"
    }
    setReceivableAmount(cash_amount);
    setAcceptAmount(cash_amount);
    setMessage((non_lab_city && "Non Lab City Booking") || "");
    setOpenLocationModal(true);
  };

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (status === "completed") {
      setLabRemark("Sample and Amount Received")
    }
    else if (status === "sample_received_from_courier") {
      setLabRemark("Sample Received From Courier")
    }
    else {
      setLabRemark("")
    }
  }, [status])


  const submitHandOver = async () => {
    let error = 0;

    if (acceptFrom==="") {
      alert("Please select sample accept from.");
      error = 1;
    }
    // if(status ==="completed"){
    //   if (acceptAmount !== receivableAmount) {
    //     alert("Amount not matched with Cash Amount.");
    //     error = 1;
    //   }
    // }
    if (status==="") {
      alert("Please select status.");
      error = 1;
    }
    if (labRemark==="") {
      alert("Please select Remark.");
      error = 1;
    }
    if (error === 0) {
      const body = {
        status: status,
        hand_over_amount: acceptAmount,
        handover_by: acceptFrom,
        lab_remarks: labRemark,
      };
      await updateRiderToLabStatus(body, sampleHandoverID);
      // navigate("/dashboard/la/SampleHandOver");
      setAcceptFrom("")
      setStatus("")
      setLabRemark("")
      setOpenLocationModal(false)
      getSampleHandOver("")
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        sampleHandOverData.links && sampleHandOverData.links.next.split("?")[1];
      getSampleHandOver(`${url}${batchType === "" ? "" : `&${batchType}`}`);
    } else if (newPage < page) {
      let url =
        sampleHandOverData.links &&
        sampleHandOverData.links.previous.split("?")[1];
      getSampleHandOver(`${url}${batchType === "" ? "" : `&${batchType}`}`);
    }
    setPage(newPage as number);
  };

  const handleClick = (id: number) => {
    setOpen(true);
    setSampleId(id);
  };
  const handleClickToBactch = (id: number) => {
    setOpenBatch(true);
    setBatchId(id);
  };

  // const handleRiderToLabUpdate = async (id: any) => {
  //   const body = {
  //     lab_received: "confirmed",
  //   };
  //   await updateRiderToLabStatus(body, id);
  //   navigate("/dashboard/la/SampleHandOver");
  // };


  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      filterSampleHandover(e);
    }
  };

  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   if (lab_received_from === "Rider") {
  //     setAcceptAmount(receivableAmount || "0");
  //   } else if (acceptFrom === "Phlebo") {
  //     setAcceptAmount(receivableAmount || "0");
  //   } else {
  //     setAcceptAmount("");
  //   }
  // }, [acceptFrom]);

  const filterSampleHandover = async (e: any) => {
    e.preventDefault()
    const body: any = {
      start_date,
      end_date,
      created_date: createdAt,
      phlebo: phlebo,
      rider,
      booking: bookingId,
      barcode,
      batchStatus,
      id: batchNumber,
      master_barcode: masterbarcode,
      // city:city
    };
    const url = genrateSampleHandoverFilter(body).substring(2);
    getSampleHandOver(`${url}${batchType === "" ? "" : `&${batchType}`}`);
    setPage(0);
  };
  //batch image start
  const [batchImageUrl, setBatchImageUrl] = useState<any>("");
  const [batchImageModalOpen, setBatchImageModalOpen] = useState<any>("");

  const body = (
    <div style={modalStyle} className={classes.imagePaper}>
      <img
        src={batchImageUrl}
        alt="popup"
        style={{ width: "1000px", height: "600px", alignItems: "center" }}
      />
    </div>
  );
  const handleBatchImage = (e: any) => {
    setBatchImageUrl(e);
    setBatchImageModalOpen(true);
  };
  const handleBatchImageClose = () => {
    setBatchImageModalOpen(false);
  };
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (batchId !== 0) {
      getPaymentInfo(`?batch=${batchId}`);
    }
  }, [batchId])
  const handlePaymentInfo = (id: any) => {
    setBatchId(id)
    setOpenPaymentModal(true);
    setBatchId(id);
  };
  //batch image End
  const handleBatchType = (type: any) => {
    setBatchType(type)
  }
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (batchType === "read_only=true") {
      getSampleHandOver(`read_only=true`);
    }
    else {
      getSampleHandOver();
    }
  }, [batchType])
  return (
    <div style={{ width: "95%" }}>
      <div className={classes.toolbar} />
      <h2>Sample Receiving List</h2>
      <Grid container spacing={1} direction="row">
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="batchNumber"
            type="number"
            label="Batch Number"
            value={batchNumber}
            variant="outlined"
            onChange={(e) => setBatchNumber(e.target.value)}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="barcode"
            type="text"
            label="Barcode"
            value={barcode}
            variant="outlined"
            onChange={(e) => setBarcode(e.target.value)}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
        </Grid>
        {/* <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="Master Barcode"
            type="text"
            label="Master Barcode"
            value={masterbarcode}
            variant="outlined"
            onChange={(e) => setMasterBarcode(e.target.value)}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
        </Grid> */}
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="name"
            type="number"
            label="Booking Id"
            value={bookingId}
            variant="outlined"
            onChange={(e) => setBookingId(e.target.value)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="booking_source"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setRider(obj.id);
              }
            }}
            options={riderList?.results || []}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) => option?.user && option?.user?.name}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getRiderList(`?usergroup=Rider&code=${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setRider(0);
              }
            }}
            renderInput={(params) => (
              <TextField
                className={classes.input}
                {...params}
                placeholder="Rider"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="phleboName"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setPhlebo(obj?.id);
              }
            }}
            options={
              phleboList?.results
                ? [
                  ...phleboList?.results,
                  { id: 0, user: { username: "NoPhlebo" } },
                ]
                : []
            }
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) =>
              option?.user && option?.user?.username
            }
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getPhlebos(`code=${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setPhlebo([]);
              }
            }}
            renderInput={(params) => (
              <TextField
                className={classes.input}
                {...params}
                placeholder="Phlebo Name"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="start_date"
            type="date"
            label="Start Date"
            value={start_date}
            variant="outlined"
            onChange={(e) => setStart_date(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            name="end_date"
            type="date"
            value={end_date}
            className="input"
            label="End Date"
            variant="outlined"
            onChange={(e) => setEnd_date(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="Created At"
            type="date"
            label="Created Date"
            value={createdAt}
            variant="outlined"
            onChange={(e) => setCreatedAt(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <FormControl style={{ width: "100%" }} className="input">
            {/* <InputLabel>Batch Status</InputLabel> */}
            <Select
              className="input"
              name="status"
              variant="outlined"
              value={batchStatus}
              style={{ width: "100%", height: "40px" }}
              onChange={(e) => setBatchStatus(e.target.value as string)}
            >
              <MenuItem value={"none"}>Batch Status</MenuItem>
              <MenuItem value={"batch_generated"}>Batch Generated</MenuItem>
              <MenuItem value={"handover_to_rider"}>Handover To Rider</MenuItem>
              <MenuItem value={"handover_to_lab"}>Handover To Lab</MenuItem>
              {/* <MenuItem value={"sample_received_partial_from_rider"}>
                Sample Received Partial From Rider
              </MenuItem> */}
              <MenuItem value={"sample_received_from_courier"}>
                Sample Received From Courier
              </MenuItem>
              {/* <MenuItem value={"partial_sample_received_from_courier"}>
                Partial Sample Received From Courier
              </MenuItem> */}
              <MenuItem value={"amount_received_from_bank_deposit"}>
                Amount Received From Bank Deposit
              </MenuItem>
              <MenuItem value={"completed"}>Completed</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"

            fullWidth
            onClick={filterSampleHandover}
            disabled={loading}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="primary"

            fullWidth
            onClick={() => navigate(0)}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <Stack spacing={2} direction="row">
            <Button variant="outlined" style={{ background: batchType === "" ? "#2979ff" : "", color: "black",marginTop: "10px",marginBottom: "10px" }} fullWidth onClick={() => handleBatchType("")}>Assigned batches</Button>
            <Button variant="outlined" style={{ background: batchType === "read_only=true" ? "#2979ff" : "", color: "black",marginTop: "10px",marginBottom: "10px"  }} fullWidth onClick={() => handleBatchType("read_only=true")}>All Batches</Button>
      </Stack>
      <div style={{ width: "100%" }} className="data-table">
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "540px" }}
        >
          
          <Table stickyHeader aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <StyledTableRow>
                {batchType !== "" ? "" : (
                  <StyledTableCell className={classes.tableHeadCell} style={{"width": "30px"}}>
                    Edit
                  </StyledTableCell>
                )}
                <StyledTableCell className={classes.tableHeadCell}>
                  Sample Journey
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Batch Number
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Phlebo/Center
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Booking IDs
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Booking Amt.
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Cash Amt.
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Amt. Due on Phlebo
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Amt Due on Rider
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Amt Received by Lab
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Status
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Batched Created At
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Rider Received At
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Sample Received At
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  HandOver To Rider
                </StyledTableCell>
                {/* <StyledTableCell className={classes.tableHeadCell}>
                Lab Received
              </StyledTableCell> */}
                <StyledTableCell className={classes.tableHeadCell}>
                  Remarks
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Labs Remarks
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Sample Image
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Courier Number
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Courier Receipt
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Deposit Receipt
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>Payment Info</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {sampleHandOverData &&
                sampleHandOverData?.results &&
                sampleHandOverData.results.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      {batchType !== "" ? "" : (
                        <StyledTableCell align="center">
                          {data?.batch_status !== "completed" && (
                            <EditIcon
                              onClick={() =>
                                openAcceptPaymentModel(
                                  data?.id,
                                  data?.awb_number,
                                  data?.cash_amount,
                                  data?.lab_received_from,
                                )
                              }
                            />
                          )}
                        </StyledTableCell>
                      )}

                      <StyledTableCell align="center">
                        <MessageIcon onClick={() => handleClick(data.id)} />
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">{data?.id}</StyledTableCell> */}
                      <StyledTableCell style={{ cursor: "pointer", color: "blue" }} key={index} align="center" onClick={() => handleClickToBactch(data.id)}>{data?.id}</StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.phlebo?.user && (
                          <span>PH - {data?.phlebo?.user?.name}</span>
                        )}
                        {data?.center && (
                          <span>CC - {data?.center?.name}</span>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {String(data?.items)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.booking_amount}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.cash_amount | 0}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.amount}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.amount_received_by_rider}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.amount_received_by_lab}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.batch_status}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.created_at === null
                          ? ""
                          : data?.created_at.substring(0, 10)}
                        <br />
                        {data?.created_at === null
                          ? ""
                          : data?.created_at.substring(11, 20)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.rider_received_time === null
                          ? ""
                          : data?.rider_received_time.substring(0, 10)}
                        <br />
                        {data?.rider_received_time === null
                          ? ""
                          : data?.rider_received_time.substring(11, 20)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.sample_handover_time === null
                          ? ""
                          : data?.sample_handover_time.substring(0, 10)}
                        <br />
                        {data?.sample_handover_time === null
                          ? ""
                          : data?.sample_handover_time.substring(11, 20)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.given_to}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                      {data?.lab_received}{" "}
                      {data?.lab_received === "pending" ? (
                        <SystemUpdateAltIcon
                          onClick={() => handleRiderToLabUpdate(data?.id)}
                        />
                      ) : (
                        ""
                      )}
                    </StyledTableCell> */}
                      <StyledTableCell align="center">
                        {data?.remarks}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.lab_remarks ? data?.lab_remarks : "NA"}
                      </StyledTableCell>
                      {data?.batch_image !== null ? (
                        <StyledTableCell
                          align="center"
                          onClick={() => handleBatchImage(data?.batch_image)}
                        >
                          <img
                            key={data?.batch_image}
                            src={data?.batch_image}
                            alt="startimage"
                            style={{ width: "150px" }}
                          />
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell align="center">NA</StyledTableCell>
                      )}
                      <StyledTableCell align="center">
                        {data?.awb_number}
                      </StyledTableCell>
                      {data?.courier_receipt !== null ? (
                        <StyledTableCell
                          align="center"
                          onClick={() => handleBatchImage(data?.courier_receipt)}
                        >
                          <img
                            key={data?.courier_receipt}
                            src={data?.courier_receipt}
                            alt="startimage"
                            style={{ width: "150px" }}
                          />
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell align="center">NA</StyledTableCell>
                      )}
                      {data?.bank_receipt !== null ? (
                        <StyledTableCell
                          align="center"
                          onClick={() => handleBatchImage(data?.bank_receipt)}
                        >
                          <img
                            key={data?.bank_receipt}
                            src={data?.bank_receipt}
                            alt="startimage"
                            style={{ width: "150px" }}
                          />
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell align="center">NA</StyledTableCell>
                      )}
                      <StyledTableCell align="center">
                        <LocalAtmIcon
                          onClick={() => handlePaymentInfo(data?.id)}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  colSpan={3}
                  count={sampleHandOverData?.count || 0}
                  rowsPerPageOptions={[]}
                  rowsPerPage={sampleHandOverData?.page_size}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <SampleJourneyComment open={open} setOpen={setOpen} sampleId={sampleId} />
      <BatchIdModel openBatch={openBatch} setOpenBatch={setOpenBatch} batchId={batchId} />
      {/* Location Map Modal  */}
      <Dialog
        open={openLocationModal}
        onClose={() => {
          setOpenLocationModal(false)
          setAcceptFrom("")
          setStatus("")
          setLabRemark("")
        }}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title">Accept Sample</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            style={{
              margin: "0px 10px 10px 10px",
              minWidth: "500px",
              width: "70%",
            }}
          >
            {message && message && (
              <Grid item xs={12} md={12}>
                <p>
                  <strong>{message}</strong>
                </p>
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <p>Accept From</p>
              <Select
                className="input"
                name="status"
                variant="outlined"
                value={acceptFrom}
                style={{ width: "100%" }}
                onChange={(e) => setAcceptFrom(e.target.value as string)}
              >
                <MenuItem value={""}>
                  {" "}
                  - - - - Select Accept From - - -{" "}
                </MenuItem>
                <MenuItem value={"Rider"}>Rider</MenuItem>
                <MenuItem value={"Phlebo"}>Phlebo</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={12}>
              <p>Status</p>
              <Select
                className="input"
                name="status"
                variant="outlined"
                value={status}
                style={{ width: "100%", height: "40px" }}
                onChange={(e) => setStatus(e.target.value as string)}
              >
                <MenuItem value={""}> - - - - Select Status - - - </MenuItem>
                <MenuItem value={"completed"}>
                  Sample and Amount Received
                </MenuItem>
                <MenuItem value={"sample_received_from_courier"}>
                  Sample Received From Courier
                </MenuItem>
                <MenuItem value={"rejected"}>
                  Rejected
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={12}>
              <p>Amount</p>
              <TextField
                className="input"
                name="zone"
                type="number"
                value={acceptAmount || ""}
                variant="outlined"
                style={{ width: "100%", margin: "0" }}
                onChange={(e) => setAcceptAmount(e.target.value as string)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <p>Remark</p>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                placeholder=" "
                value={labRemark || ""}
                required
                className="input"
                style={{ width: "100%", margin: "0" }}
                // onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                //   setLabRemark(e.target.value as string)
                // }
                onChange={(e) => setLabRemark(e.target.value as string)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => submitHandOver()}
            color="primary">
            Submit
          </Button>
          <Button
            onClick={() => setOpenLocationModal(false)}
            color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={batchImageModalOpen}
        onClose={handleBatchImageClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <PaymentModal
        openModalP={openPaymentModal}
        setOpenModalP={setOpenPaymentModal}
        paymentInformation={paymentInformation}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  sampleHandOverData: state.LimsAdminReducer.sampleHandOverData,
  riderList: state.LimsAdminReducer.riderList,
  phleboList: state.LimsAdminReducer.phleboList,
  paymentInformation: state.LimsAdminReducer.paymentInformation,
  loading: state.LimsAdminReducer.loading,
});

export default connect(mapStateToProps, {
  getSampleHandOver,
  updateRiderToLabStatus,
  getRiderList,
  getPhlebos,
  getPaymentInfo
})(BookingsTable);
