import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
    getBookingsComments,
    addBookingsComment,
} from "../../actions/LimsAdminActions";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import Stack from '@mui/material/Stack';

import { Box, Button, Typography, Modal,TextareaAutosize,CircularProgress} from '@mui/material';
import "./index.sass";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px", zIndex:1,
    },
}));

type ModalProps = {
    openModal: boolean;
    setopenModal: Function;
    bookingId: any;
    loading: boolean;
    user: any;
    bookingsComment: any;
    getBookingsComments: any;
    addBookingsComment: any;
};

const CommentsModal: React.FC<ModalProps> = ({
    openModal,
    setopenModal,
    bookingId,
    loading,
    bookingsComment,
    getBookingsComments,
    addBookingsComment,
    user,
}) => {
    const classes = useStyles();
    const [comment, setComment] = React.useState<string>("");
    const [maxHeight, setMaxHeight] = React.useState<any>(300)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "90%",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const handleClose = () => {
        setopenModal(false);
    };

    useEffect(() => {
        if (bookingId !== "") {
            getBookingsComments(bookingId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModal]);

    const submitForm = async (e: any) => {
        e.preventDefault();
        if (comment === "") {
            return;
        } else {
            let body = {
                comment,
                bookingId: bookingId,
            };
            await addBookingsComment(body);
            setComment("");
        }
    };
    return (
        <div>
            <Modal
            open={openModal}
            onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                        <h6
                            style={{
                                marginBottom: "10px",
                                textAlign: "center",
                                borderBottom: "1px solid rgba(0,0,0,0.5)",
                            }}
                            id="transition-modal-title"
                        >
                            Comment History
                            {Number(maxHeight) === Number(300) ? (
                                <Stack direction="row" spacing={2} style={{ display: "block", textAlign: "end" }} >
                                    <ZoomOutMapIcon fontSize="large" onClick={() => Number(maxHeight) === Number(600) ? setMaxHeight(Number(600)) : setMaxHeight(Number(600))} />
                                </Stack>
                            ) : (
                                <Stack direction="row" spacing={2} style={{ display: "block", textAlign: "end" }} >
                                    <ZoomInMapIcon fontSize="large" onClick={() => Number(maxHeight) === Number(300) ? setMaxHeight(Number(300)) : setMaxHeight(Number(300))} />
                                </Stack>
                            )}
                        </h6>
                        <div
                            id="transition-modal-description"
                            className="modal-form-wrapper"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                height: `${maxHeight}px`
                            }}
                        >
                            <div className="comment-history" style={{ height: `${maxHeight}px` }}>
                                {loading
                                    ? "Please Wait"
                                    : Object.keys(bookingsComment).length > 0
                                        ? bookingsComment.map((comment: any) => (
                                            <p>
                                                {comment.created_at.substring(0, 10)} :{" "}
                                                {comment.created_at.substring(11, 19)} :{" "}
                                                {comment.by_user} : {comment.comment}:{" "}
                                            </p>
                                        ))
                                        : ""}
                            </div>
                            {/* {user?.user?.user_group !== "CustomerExecutive" ? ( */}
                            <form className="modal-form chat-form" onSubmit={submitForm}>
                                <TextareaAutosize
                                    aria-label="minimum height"
                                    minRows={3}
                                    placeholder="Add Comment.."
                                    value={comment}
                                    onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                                        setComment(e.target.value as string)
                                    }
                                    style={{ width: "100%", marginRight: "10px" }}
                                />
                                <Button style={{ width: "20%" }} type="submit">
                                    <p>
                                        {loading ? (
                                            <CircularProgress style={{ color: "#fff" }} size={24} />
                                        ) : (
                                            "Add Comment"
                                        )}
                                    </p>
                                </Button>
                            </form>
                            {/* ) : null} */}
                        </div>
                </Box>
            </Modal>

          
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    bookingsComment: state.LimsAdminReducer.bookingsComment,
    loading: state.LimsAdminReducer.loading,
    user: state.loginReducer.user,
});

export default connect(mapStateToProps, {
    getBookingsComments,
    addBookingsComment,
})(CommentsModal);
