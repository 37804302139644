import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {Modal,Fade,Backdrop} from '@mui/material';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper} from "@material-ui/core";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { updateTubeStatus } from '../../actions/LimsAdminActions';
import EditIcon from '@material-ui/icons/Edit';
import EditTube from './editTubeModal'


const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        marginTop: "2rem",
        padding: "0.5rem 2rem",
        width: "100%",
        zIndex:1
    },
    table: {
        maxWidth: "100%",
        margin: "auto"
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem",
    },
}));
interface Props {
    updateTubeStatus: any;
}
type ModalProps = {
    openTubeModal: boolean;
    setOpenTubeModal: Function;
    qrcode: any;
    getQRCodeData: any;
    updateTubeStatus: any;
    setUpdateTube:any
    setTubeId:any;
    tubeId: any;
    bookingType:any;
};

const CreatePhlebo: React.FC<ModalProps> = ({
    setOpenTubeModal,
    openTubeModal,
    qrcode,
    getQRCodeData,
    updateTubeStatus,
    setUpdateTube,
    setTubeId,
    tubeId,
    bookingType,
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState<any>(false)
    const [codeData,setCodeData]=useState<any>("")
    const [selectedTubeId,setSelectedTubeId]=useState<any>("")
    const handleClose = () => {
        setOpenTubeModal(false);
    };
    const handleConfirmStatus = async (id: any) => {
        const tubeData = {
            tube_status: "confirmed"
        }
        await updateTubeStatus(id, tubeData)
        getQRCodeData(`?${bookingType}=${tubeId}`)

    }
    const handleEditTube=(code:any,id:any)=>{
        setSelectedTubeId(id)
        setCodeData(code)
        setOpen(true)
        setOpenTubeModal(false)
    }
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openTubeModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openTubeModal}>
                    <div className={classes.paper}>
                        <Paper elevation={15} className={classes.paper}>
                            <div className={classes.head}>

                                <h4
                                    style={{
                                        marginBottom: "1rem",
                                    }}
                                    id="transition-modal-title"
                                >
                                    TUBE DETAILS
                                </h4>
                                <CloseSharpIcon onClick={() => setOpenTubeModal(false)} />
                            </div>

                            <TableContainer className={classes.table} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Tube Id</TableCell>
                                            <TableCell align="center">Tube name</TableCell>
                                            <TableCell align="center">Code</TableCell>
                                            <TableCell align="center">Tube Volume</TableCell>
                                            <TableCell align="center" >Tube Status</TableCell>
                                            <TableCell align="center">Phlebo Sample Status</TableCell>
                                            <TableCell align="center">Lab Sample Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {qrcode && qrcode.results && qrcode.results.length > 0 ? qrcode.results.map((data: any, index: any) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell align="center">{data?.id}</TableCell>
                                                    <TableCell align="center">{data?.tube.tube.name || "N/A"}</TableCell>
                                                    <TableCell style={{cursor:"pointer"}} align="center" >{data?.code || "N/A"}<EditIcon onClick={() =>handleEditTube(data?.code,data?.id)}/></TableCell>
                                                    <TableCell align="center">{data?.tube.tube.volume || "N/A"}</TableCell>
                                                    <TableCell align="center">{data?.tube_status || "N/A"}</TableCell>
                                                    <TableCell align="center">{data?.is_snr === false ? "Received Sample" : "Not Received"}</TableCell>
                                                    <TableCell align="center">
                                                        {data?.tube_status === "pending" ? (
                                                            <p style={{cursor:"pointer"}} onClick={() => handleConfirmStatus(data.id)} >Click here to confirm status
                                                            <DoneOutlineIcon /></p>
                                                        ) : "Received"}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }) : ""}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                </Fade>
                
            </Modal>
            <EditTube setOpen={setOpen} open={open} codeData={codeData} setCodeData={setCodeData} id={selectedTubeId} tubeId={tubeId} setTubeId={setTubeId} setUpdateTube={setUpdateTube} />
        </div>
    );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {
    updateTubeStatus,
})(CreatePhlebo);
