import React, { useEffect } from "react";
import { connect } from "react-redux";
import {Modal,Fade,Backdrop} from '@mui/material';
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
// import Loader from "../components/loader/index";
import {
    Paper,
    Table,
    withStyles,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { MessageRounded } from "@material-ui/icons";
import {
    getReceivedTicketTasks,
    getUserList,
} from "../../actions/LimsAdminActions";
import "./index.sass";
import moment from "moment";
import Bs from "./changeTicketStatus";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px", zIndex:1,
    },
    tableContainer: {
        marginTop: "2rem",
        position: "sticky",
        top: "200px",
    },
}));
const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

type ModalProps = {
    openBatch: boolean;
    setOpenBatch: Function;
    ticketId: number;
    userFromCategories: any;
    receivedTasks: any;
    userDetails: any;
    getReceivedTicketTasks: any;
    getUserList: any;
    setopenModal: any;
    setIds: any;
    loading: boolean;
};

const CommentsModal: React.FC<ModalProps> = ({
    openBatch,
    setOpenBatch,
    ticketId,
    userFromCategories,
    receivedTasks,
    userDetails,
    getReceivedTicketTasks,
    getUserList,
    setopenModal,
    setIds,

    loading,
}) => {
    const classes = useStyles();
    const handleClose = () => {
        setOpenBatch(false);
    };
    const handleClick = (id: number) => {
        setopenModal(true);
        setIds(id);
      };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps

        if (userDetails?.profile?.usergroup) {
            getReceivedTicketTasks(`?usergroup=${userDetails?.profile?.usergroup}`);
        }
        getUserList("")
    }, [userDetails]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
        let arr:any = [];
        receivedTasks && receivedTasks.results && receivedTasks.results.length > 0 && receivedTasks.results.map((ticket: any, index: any) => {
                arr.push(ticket.id)
            })
     
    }, [receivedTasks, ticketId]);
    const res = userFromCategories.results.filter( (word:any) => word.id === ticketId);
    return (
        <div  style={{ width: "100%" }}>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openBatch}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                style={{maxWidth:1200,margin:"0px auto"}}
            >
                <Fade in={openBatch} style={{margin:20}}>
                    <TableContainer
                        className={classes.tableContainer}
                        component={Paper}
                        style={{ maxHeight: "700px" }}
                    >
                        <Table stickyHeader aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Ticket Number</StyledTableCell>
                                    <StyledTableCell align="center">Category</StyledTableCell>
                                    <StyledTableCell align="center">Sub Category</StyledTableCell>
                                    <StyledTableCell align="center">Title</StyledTableCell>
                                    <StyledTableCell align="center">Status</StyledTableCell>
                                    <StyledTableCell align="center">Created At</StyledTableCell>
                                    <StyledTableCell align="center">Raised By</StyledTableCell>
                                    <StyledTableCell align="center">Resolved At</StyledTableCell>
                                    <StyledTableCell align="center">Resolved By</StyledTableCell>
                                    <StyledTableCell align="center">Priority</StyledTableCell>
                                    <StyledTableCell align="center">Comments</StyledTableCell>
                                    <StyledTableCell align="center">Action</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {
                                // userFromCategories.results &&
                                //     userFromCategories.results.length > 0 &&
                                //     userFromCategories.results
                                    res.map((ticket: any, index: any) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="center">
                                                {ticket?.id}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                {ticket?.category}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                {ticket?.sub_category}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                {ticket?.title}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                {ticket?.status}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                {moment(ticket?.created_at).format("DD-MM-YYYY")}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                {ticket?.created_by_name}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                {ticket?.completed_date}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                {ticket?.assigned_to_name}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                {ticket?.priority}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <div>
                                                        <MessageRounded
                                                        onClick={() =>
                                                            handleClick(
                                                            ticket?.pk ? ticket?.pk : ticket?.id
                                                            )
                                                        }
                                                        />
                                                    </div>
                                                    </StyledTableCell>
                                                <StyledTableCell align="center">
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Bs id={ticket.id} note="received_ticket" />
                                                </div>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    userFromCategories: state.LimsAdminReducer.userFromCategories,
    receivedTasks: state.LimsAdminReducer.receivedTasks,
    userDetails: state.loginReducer.userDetails,
    loading: state.LimsAdminReducer.loading,
});

export default connect(mapStateToProps, {
    getReceivedTicketTasks,
    getUserList,
})(CommentsModal);