import React, { useEffect } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {Modal,Fade,Backdrop} from '@mui/material';
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import {
    getTicketComments,
    addTicketComment,
} from "../../actions/LimsAdminActions";
import "./index.sass";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px", zIndex:1,
    },
}));

type ModalProps = {
    openModal: boolean;
    setopenModal: Function;
    id: any;
    loading: boolean;
    // bookingsComment: any;
    ticketComments: any;
    // getBookingsComments: any;
    // addBookingsComment: any;
    getTicketComments: any,
    addTicketComment: any;
};

const CommentsModal: React.FC<ModalProps> = ({
    openModal,
    setopenModal,
    id,
    loading,
    // bookingsComment,
    ticketComments,
    // getBookingsComments,
    // addBookingsComment,
    getTicketComments,
    addTicketComment,
}) => {
    const classes = useStyles();

    const [comment, setComment] = React.useState<string>("");

    const handleClose = () => {
        setopenModal(false);
    };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
        if (id !== "") {
           
          
            getTicketComments(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModal]);

    const submitForm = async (e: any) => {
        e.preventDefault();
        if (comment === "") {
            return;
        } else {
            let body = {
                comment,
                id: id,
                message: comment,
                ticket: id,
            };
            await addTicketComment(body);
            setComment("");
            getTicketComments(id);
        }
    };
    return (
        <div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <div className={classes.paper}>
                        <h6
                            style={{
                                marginBottom: "10px",
                                textAlign: "center",
                                borderBottom: "1px solid rgba(0,0,0,0.5)",
                            }}
                            id="transition-modal-title"
                        >
                            Comment History
                        </h6>
                        <div
                            id="transition-modal-description"
                            className="modal-form-wrapper"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                            }}
                        >
                            <div className="comment-history">
                                {loading
                                    ? "Please Wait"
                                    : ticketComments?.results?.length > 0
                                        ? ticketComments?.results.map((comment: any) => (
                                            <p>
                                                {moment(comment?.created_at).format('LLL')}{" - "}
                                                {comment?.username} : {comment?.message}{" "}
                                            </p>
                                        ))
                                        : ""}
                            </div>
                            {/* {user?.user?.user_group !== "CustomerExecutive" ? ( */}
                            <form className="modal-form chat-form" onSubmit={submitForm}>
                                <TextareaAutosize
                                    aria-label="minimum height"
                                    minRows={3}
                                    placeholder="Add Comment.."
                                    value={comment}
                                    onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                                        setComment(e.target.value as string)
                                    }
                                    style={{ width: "100%", marginRight: "10px" }}
                                />
                                <button style={{ width: "20%" }}>
                                    <p>
                                        {loading ? (
                                            <CircularProgress style={{ color: "#fff" }} size={24} />
                                        ) : (
                                            "Add Comment"
                                        )}
                                    </p>
                                </button>
                            </form>
                            {/* ) : null} */}
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    ticketComments: state.LimsAdminReducer.ticketComments,
    loading: state.LimsAdminReducer.loading,
});

export default connect(mapStateToProps, {
    // getBookingsComments,
    getTicketComments,
    // addBookingsComment,
    addTicketComment,
})(CommentsModal);
