import React, { useEffect} from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {TableBody, styled, TableContainer, TableCell, Table, tableCellClasses, Paper, TableHead, TableRow } from '@mui/material';
import { getAnalyticsDataLabCity,getAnalyticsDataNonLabCity} from '../../actions/LimsAdminActions'
import "./index.sass"
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },

  })
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#924A91",
    color: theme.palette.common.white,
    fontSize: "14px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface Props {
  getAnalyticsDataLabCity: any;
  analyticsDataLabCity: any;
  getAnalyticsDataNonLabCity:any;
  analyticsDataNonLabCity:any;
}

const LimsAdminFilterPage: React.FC<Props> = ({
  getAnalyticsDataLabCity,
  analyticsDataLabCity,
  getAnalyticsDataNonLabCity,
  analyticsDataNonLabCity,
}) => {
  const classes = useStyles();



useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getAnalyticsDataLabCity()
    getAnalyticsDataNonLabCity()
  }, [])

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div style={{ width: "100%" }} className="data-table">
        <h4 style={{fontWeight:"bold"}}>ANALYTICS TABLE</h4>
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "700px" }}
          >
             <h4 style={{fontWeight:"bold"}}>LAB CITY</h4>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Date o collection</StyledTableCell>
                  <StyledTableCell align="center">Total Samples to be Collected</StyledTableCell>
                   <StyledTableCell align="center">Total Samples Collected By Phlebo </StyledTableCell>
                   <StyledTableCell align="center">Sample Pending With Phlebo</StyledTableCell>
                   <StyledTableCell align="center">Cash Pending With Phlebo</StyledTableCell>
                   <StyledTableCell align="center">Total Samples Collected by Rider</StyledTableCell>
                  <StyledTableCell align="center">Total CC Booking</StyledTableCell>
                  <StyledTableCell align="center">Amount Receive By Rider</StyledTableCell>
                  <StyledTableCell align="center">Amount Receive By Lab</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(analyticsDataLabCity)?.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center" component="th" scope="row">
                        {Object.keys(analyticsDataLabCity)[index]}
                      </StyledTableCell>
                      <StyledTableCell align="center" component="th" scope="row">
                        {data?.total_bookings ? data?.total_bookings : "--"}
                      </StyledTableCell>
                      <StyledTableCell align="center" component="th" scope="row">
                        {data?.total_collected_sample_with_phlebo ? data?.total_collected_sample_with_phlebo : "--"}
                      </StyledTableCell>
                      <StyledTableCell align="center" component="th" scope="row">
                        {data?.total_pending_sample_with_phlebo ? data?.total_pending_sample_with_phlebo : "--"}
                      </StyledTableCell>
                      <StyledTableCell align="center" component="th" scope="row">
                        {data?.amount_with_phlebo ? data?.amount_with_phlebo : "--"}
                      </StyledTableCell>
                      <StyledTableCell align="center" component="th" scope="row">
                        {data?.sample_with_rider ? data?.sample_with_rider : "--"}
                      </StyledTableCell>
                      <StyledTableCell align="center" component="th" scope="row">
                        {data?.total_cc_bookings ? data?.total_cc_bookings : "--"}
                      </StyledTableCell>
                      <StyledTableCell align="center" component="th" scope="row">
                        {data?.amount_received_by_rider ? data?.amount_received_by_rider : "--"}
                      </StyledTableCell>
                      <StyledTableCell align="center" component="th" scope="row">
                        {data?.amount_received_by_lab ? data?.amount_received_by_lab : "--"}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}

              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "700px" }}
          >
             <h4 style={{fontWeight:"bold"}}>NON LAB CITY</h4>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Date o collection</StyledTableCell>
                  <StyledTableCell align="center">Total Samples Couriered By Phlebo/Rider</StyledTableCell>
                   <StyledTableCell align="center">Total Samples Received By Lab </StyledTableCell>
                   {/* <StyledTableCell align="center">Sample Not Received</StyledTableCell> */}
                   <StyledTableCell align="center">Cash Collected By Rider</StyledTableCell>
                   <StyledTableCell align="center">Cash Collected By Phlebo</StyledTableCell>
                   {/* <StyledTableCell align="center">Cash Deposited By Phlebo/Rider</StyledTableCell>
                  <StyledTableCell align="center">Cash Confirmed By Finance</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(analyticsDataNonLabCity)?.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center" component="th" scope="row">
                        {Object.keys(analyticsDataNonLabCity)[index]}
                      </StyledTableCell>
                      <StyledTableCell align="center" component="th" scope="row">
                        {data?.total_courier_booking ? data?.total_courier_booking : "--"}
                      </StyledTableCell>
                      <StyledTableCell align="center" component="th" scope="row">
                        {data?.sample_received_by_lab ? data?.sample_received_by_lab : "--"}
                      </StyledTableCell>
                      <StyledTableCell align="center" component="th" scope="row">
                        {data?.amount_received_by_rider ? data?.amount_received_by_rider : "--"}
                      </StyledTableCell>
                      <StyledTableCell align="center" component="th" scope="row">
                        {data?.amount_with_phlebo ? data?.amount_with_phlebo : "--"}
                      </StyledTableCell>
                    
                    
                    </StyledTableRow>
                  )
                })}

              </TableBody>
            </Table>
          </TableContainer>
        </div>

       
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  analyticsDataLabCity: state.LimsAdminReducer.analyticsDataLabCity,
  analyticsDataNonLabCity: state.LimsAdminReducer.analyticsDataNonLabCity,
});

export default connect(mapStateToProps, {
  getAnalyticsDataLabCity,
  getAnalyticsDataNonLabCity
})(LimsAdminFilterPage);
