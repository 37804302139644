import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: "gray",
    marginTop:"1rem",
    color:"#424242",
    zIndex:999
  },
}));
interface Props {
    anchorElUpdate:any
    id:any
  }
  
  const SystemUpdateDetails: React.FC<Props> = ({
    anchorElUpdate,
    id
  }) => {
  const classes = useStyles();
  const open = Boolean(anchorElUpdate);

  return (
    <div>

      <Popper id={id} open={open} anchorEl={anchorElUpdate} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div className={classes.paper}>
                New Update 7.0.1
                <li>Partner Filter Added in Home Page</li>
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  });
  
  export default connect(mapStateToProps, {})(SystemUpdateDetails);