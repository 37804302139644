import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { MenuItem, Select } from "@material-ui/core";
import "./trf.css";
import {
  getBookingById,
  getAdditionalBookingData,
  getQRCodeData,
  getSignature,
} from "../../../actions/LimsAdminActions";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import { useRef } from "react";
import {useParams} from "react-router-dom";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      height: "40px",
    },
    left: {
      margin: 600,
      width: "50%",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    fontsize13: {
      fontSize: "13px",
    },
    rightContentHeader: {
      width: "88%",
      float: "left",
      textAlign: "left",
      paddingRight: "20px",
    },
  })
);
interface Props {
  match: any;
  booking: any;
  getBookingById: any;
  getAdditionalBookingData: any;
  additionMember: any;
  getQRCodeData: any;
  qrcode: any;
  getSignature: any;
  signature: any;
  loading: Boolean;
}
const PhleboAdminViewBooking: React.FC<Props> = ({
  match,
  booking,
  getBookingById,
  getAdditionalBookingData,
  additionMember,
  getQRCodeData,
  qrcode,
  getSignature,
  signature,
  loading,
}) => {
  const {id} = useParams();
  const bookingId=id
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (Number(bookingId) !== 0) {
      getBookingById(bookingId);
      getSignature(bookingId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);
  const classes = useStyles();
  const [personName, setPersonName] = useState(bookingId);
  // const handleExportWithFunction = () => {
  //   savePDF(contentArea.current, {
  //     paperSize: "A4",
  //     fileName:
  //       personName===bookingId
  //         ? booking.customer_name
  //         : additionMember.customer_name,
  //   });
  // };

  // const spacimenType1 = [
  //   "[] Ambient",
  //   "[] Plasma: EDTA/CIT/ACD",
  //   "[] Blood Clot",
  //   "[] W.Blood ACD",
  //   "[] W.Blood EDTA",
  //   "[] W.Blood Fluoride",
  //   "[] W.Blood Heparin",
  //   "[] W.Blood Sodium Citrate]",
  // ];
  // const spacimenType2 = [
  //   "[ ] Ambient",
  //   "[ ] FN Aspirate*",
  //   "[ ] Tissue-Large* ",
  //   "[ ] Tissue-Small* ",
  //   "[ ] Paraffin Block* ",
  //   "[ ] Smear",
  //   "[ ] Slide (H&E)",
  //   "[ ] Pus]",
  // ];
  // const spacimenType3 = [
  //   "[ ] Ambient",
  //   "[ ] FN Aspirate*",
  //   "[ ] Tissue-Large* ",
  //   "[ ] Tissue-Small* ",
  //   "[ ] Paraffin Block* ",
  //   "[ ] Smear",
  //   "[ ] Slide (H&E)",
  //   "[ ] Pus]",
  // ];
  // const essentialClinical = [
  //   "Provisional diagnosis :",
  //   "H/o Medication : Yes / No",
  //   "If Yes, Name :",
  //   "Status of Medication : Ongoing / Terminated",
  //   "If ongoing, Duration :",
  //   "If terminated, When :",
  //   " LMP (where applicable :",
  //   " Fasting Period :",
  //   "24 hour Urine Volume :",
  //   "For Histopathology / IHC, Attach Detailed History ",
  //   "Attach other relevant information :",
  // ];
  const componentRef = useRef<any>(null);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const reactToPrintTrigger = React.useCallback(() => {
    return <button style={{ marginLeft: "10px" }}>Print Receipt</button>;
  }, []);

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (personName !== "none") {
      if (bookingId ===personName) {
        getBookingById(`${bookingId}`);
        getQRCodeData(`?booking_id=${bookingId}`);
      } else {
        getAdditionalBookingData(`${personName}`);
        getQRCodeData(`?add_booking_id=${personName}`);
      }
    }
  }, [personName]);

  const handleChange = (event: any) => {
    setPersonName(event.target.value);
  };

  return (
    <>
      <div>
        <div className={classes.toolbar} />
        <div>
          <Select
            className="input"
            name="priority"
            variant="outlined"
            style={{
              width: "50%",
              marginLeft: "1rem",
              marginBottom: "1rem",
              alignItems: "flex-start",
            }}
            value={personName}
            onChange={handleChange}
          >
            <MenuItem disabled selected value={"none"}>
              Customer Name
            </MenuItem>
            <MenuItem value={booking?.pk}>{booking?.customer_name}</MenuItem>
            {booking.additional_members &&
              booking.additional_members.length > 0 &&
              booking.additional_members.map((data: any) => {
                return (
                  <MenuItem value={data?.id}>{data?.customer_name}</MenuItem>
                );
              })}
          </Select>
          <ReactToPrint
            content={reactToPrintContent}
            trigger={reactToPrintTrigger}
          />
        </div>
        <div className="page" ref={componentRef}>
          <div className="page_left">
            <div className="header_pink">Patient information:</div>
            <div className="_details">
              <p>
                Patient’s ID :
                {personName===bookingId ? bookingId : additionMember?.id}
              </p>
              <p>
                Patient’s Name :{" "}
                {personName===bookingId
                  ? booking?.customer_name
                  : additionMember?.customer_name}
              </p>
              <p>Patient’s Address :{booking && booking?.customer_address}</p>
              <p>Patient’s Email : {booking && booking?.customer_email}</p>
              <p>Mobile number : {booking && booking?.customer_phonenumber}</p>
              <p>
                Age :{" "}
                {personName===bookingId
                  ? booking?.customer_age
                  : additionMember?.customer_age}{" "}
                Gender :{" "}
                {personName===bookingId
                  ? booking?.customer_gender
                  : additionMember?.customer_gender}
              </p>
              <p>Height: Cm Weight: Kg</p>
            </div>
            <div className="header_pink" style={{ marginTop: "3.17rem" }}>
              {" "}
              EST REQIREMENTS : Please refer to the Directory of Services for
              correct test code, name and specimen type
            </div>
            <div
              className="_details"
              style={{ height: "22.2rem", display: "flex" }}
            >
              <ul>
                {personName===bookingId
                  ? booking?.packages?.map((data: any) => (
                      <li>
                        {data?.code}:{data?.name}
                      </li>
                    ))
                  : additionMember?.packages?.map((data: any) => (
                      <li>
                        {data?.code}:{data?.name}
                      </li>
                    ))}
              </ul>
            </div>
            <div className="header_pink" style={{ marginTop: "0" }}>
              TEMPERATURE SENT TEMPERATURE RECD.
            </div>
            <div className="_detailsBold">
              <p>Frozen( Celcius) [ ] Frozen( Celcius)</p>
              <p>Cold (2-8’ Celcius) [ ] Cold (2-8’ Celcius)</p>
              <p> Ambient [ ] Ambient</p>
            </div>
            <div className="header_pink">SPECIMEN TYPE</div>
            <div className="_details">
              <p>[ ] Ambient [ ] Ambient [ ] Ambient</p>
              <p>Plasma: EDTA/CIT/ACD [ ] FN Aspirate* [ ] FN Aspirate*</p>
              <p>[ ] Blood Clot [ ] Tissue-Large* [ ] Tissue-Large* </p>
              <p> [ ] W.Blood ACD [ ] Tissue-Small* [ ] Tissue-Small*</p>
            </div>
          </div>
          {/**LEFT SIDE */}
          <div className="page_right">
            <div className="header_pink">Bill To:</div>
            <div className="_details">
              <p>
                Client Code :
                {booking?.center?.center_type==="direct_client" ? (
                  <>
                    ({booking && booking?.centre_address?.code}){" "}
                    {booking && booking?.center?.name}
                  </>
                ) : (
                  ""
                )}
              </p>
              <p>
                GE Centre :{booking && booking?.gecode_centre}{" "}
              </p>

              <p>Name & Address :</p>
              <p>Phone No :</p>
              <p>
                Total Amount :{" "}
                {booking && booking?.discounted_price?.final_total_price}
                <br />
                Mode :{" "}
                {booking?.center?.center_type==="direct_client"
                  ? "Advance"
                  : booking?.payment_mode}
              </p>

              <div className="header_pink">REFERRING DOCTOR :</div>
              <div className="_details">
                <p>Doctor’s Name :</p>
                <p>Phone No. :</p>
                <p>Email Address :</p>
                <p>Account Number :</p>
              </div>

              <div className="header_pink">SPECIMEN INFORMATION :</div>
              <div className="_details" style={{ height: "8.5rem" }}>
                <p style={{ fontSize: ".7rem" }}>BARCODE:</p>
                <ul>
                  {qrcode &&
                    qrcode.results &&
                    qrcode.results.length > 0 &&
                    qrcode?.results.map((data: any) => {
                      return (
                        <li
                          style={{ listStyleType: "none", fontSize: "0.5rem" }}
                        >
                          {data.is_snr === false
                            ? `${data.tube.tube.name}:${data.code}`
                            : `${data.tube.tube.name}:Tube not Picked`}
                        </li>
                      );
                    })}
                </ul>
              </div>

              <div className="header_pink">
                Pickup Date : {booking && booking?.pickup_date} Pickup Time :
                {booking && booking?.pickup_time}
              </div>
              <div className="_detailsBold">
                Specimen Collected At :
                {booking?.center?.center_type==="direct_client"
                  ? booking?.center?.name
                  : booking?.customer_address}
              </div>
              <div className="header_pink">RECEIVED IN LAB :</div>
              <div className="_details">
                <p>Date :</p>

                <p>Time :</p>
                <p>Outsource Name :</p>
              </div>

              <div className="header_pink">FOR PEPEAT / FOLLOW-UP PATIENTS</div>
              <div className="_details">
                <p>Old Accession No. : ______</p>
              </div>
              <div className="header_pink" style={{ marginTop: "4.8rem" }}>
                ESSENTIAL CLINICAL INFORMATION
              </div>
              <div className="_details smalltext">
                <p>1. Provisional diagnosis : ___</p>
                <p>2. H/o Medication : Yes / No ___</p>
                <p>3. If Yes, Name : ___</p>
                <p>4. Status of Medication : Ongoing / Terminated ___</p>
                <p>5. If ongoing, Duration : ___</p>
                <p>6. If terminated, When : ___</p>
                <p>7. LMP (where applicable : ___</p>
                <p> 8. Fasting Period : ___</p>
                <p> 9. 24 hour Urine Volume : ___</p>
                <p>
                  {" "}
                  10. For Histopathology / IHC, Attach Detailed History ___
                </p>
                <p> 11. Attach other relevant information : ___</p>
              </div>
            </div>
            <div className="signAture">
              <p>Signature</p>
              <img
              alt="description of image"
                style={{ width: "100px", height: "80px" }}
                src={
                  signature &&
                  signature?.results &&
                  signature.results.length > 0 &&
                  signature.results[0]?.signature_image
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  booking: state.LimsAdminReducer.booking,
  additionMember: state.LimsAdminReducer.additionMember,
  qrcode: state.LimsAdminReducer.qrcode,
  signature: state.LimsAdminReducer.signature,
  loading: state.LimsAdminReducer.loading,
});

export default connect(mapStateToProps, {
  getBookingById,
  getAdditionalBookingData,
  getQRCodeData,
  getSignature,
})(PhleboAdminViewBooking);
