import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {Modal,Backdrop,Fade,Button,Grid,TextField,MenuItem,} from "@mui/material";
import { sendReportById, sendReportByChildId } from "../../actions/LimsAdminActions";
let width = window.innerWidth;
let isMobile = width <= 500;
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "95vw" : "800px",
    maxHeight: "600px",
    overflow: "scroll",
    position: "sticky",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));

type ModalProps = {
  setOpenReportModel: Function;
  openReportModel: any;
  sendReportById: any;
  sendReportByChildId: any;
  bookingId: any;
  objId: any;
  addId: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  sendReportById,
  sendReportByChildId,
  setOpenReportModel,
  openReportModel,
  bookingId,
  objId,
  addId,
}) => {
  const classes = useStyles();
  const [disposition, setDisposition] = useState<any>("");
  const id=0;
  const [customer_email, setCustomer_email] = useState<string>("");
  const [remark, setRemark] = useState<any>("");

  const handleClose = () => {
    setOpenReportModel(false);
  };
  const handleSendEmail = async (id: any) => {
    if ((new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(customer_email) && disposition==="Reports_shared")  ||  (disposition!=="Reports_shared")) {
      if (addId){
        sendReportByChildId(
          bookingId,
          `?email=${customer_email}&failed_report_id=${objId}&remarks=${remark}&disposition=${disposition}`
        );
        alert("Report Send Successfully");
        setOpenReportModel(false);
        setCustomer_email("");
        setDisposition("");
        setRemark("");
      } else  {
        sendReportById(
          bookingId,
          `?email=${customer_email}&failed_report_id=${objId}&remarks=${remark}&disposition=${disposition}`
        );
        alert("Report Send Successfully");
        setOpenReportModel(false);
        setCustomer_email("");
        setDisposition("");
        setRemark("");
      }
    }  else  if (customer_email===""){
      alert("Enter an email!");
    }  else  {
      alert("Email is InValid!");
    }
    
  };

  const handleCanelEmail = () => {
    setOpenReportModel(false);
    setCustomer_email("");
    setDisposition("");
    setRemark("");
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openReportModel}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openReportModel}>
        <div className={classes.paper}>
          <>
            <h3>Send Report From Here</h3>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  className="input"
                  name="name"
                  type="number"
                  label="Booking Id"
                  value={bookingId}
                  variant="outlined"
                  //  onChange={(e) => setBookingId(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="input"
                  label="Select disposition"
                  value={disposition}
                  select
                  variant="outlined"
                  onChange={(e) => setDisposition(e.target.value)}
                  style={{ width: "100%" }}
                >
                  <MenuItem value="Customer_is_not_responding_to_the_calls">
                    Customer is not responding to the calls
                  </MenuItem>
                  <MenuItem value="Customer_has_already_received_the_reports">
                    Customer has already received the reports
                  </MenuItem>
                  <MenuItem value="Reports_shared_over_whatsapp">
                    Reports shared over whatsapp
                  </MenuItem>
                  <MenuItem value="Reports_shared">Reports shared</MenuItem>
                </TextField>
              </Grid>
              {disposition !== "Reports_shared" ? (
                ""
              ) : (
                <Grid item xs={12}>
                  <TextField
                    name="customer_email"
                    type="email"
                    label="Enter Customer Email"
                    value={customer_email}
                    disabled={disposition !== "Reports_shared"}
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_email(e.target.value as string)
                    }
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  name="remark"
                  className="input"
                  // label="Remark"
                  label="Enter Remark"
                  variant="outlined"
                  style={{ margin: "0", width: "100%", marginTop: "1rem" }}
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
              </Grid>

              <Button
                variant="contained"
                style={{
                  marginTop: "20px",
                }}
                color="primary"
                onClick={() => handleSendEmail(id)}
                disabled={disposition === ""}
              >
                SEND REPORT
              </Button>
              <Button
                variant="contained"
                style={{
                  marginTop: "20px",
                  marginLeft: "10px",
                }}
                color="secondary"
                onClick={handleCanelEmail}
              >
                Cancel
              </Button>
            </Grid>
          </>
        </div>
      </Fade>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.LimsAdminReducer.loading,
  user: state.loginReducer.user,
});

export default connect(mapStateToProps, {
  sendReportById,
  sendReportByChildId,
})(CommentsModal2);