import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  InputLabel,
  OutlinedInput,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import {
  TextareaAutosize,
  Grid,
  TextField,
  Select,
  withStyles,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import RemoveTest from "./removePackage";
import AddTest from "./addPackage";
import { connect } from "react-redux";
import {
  updateAdditionalBooking,
  getBookingById,
  getPinCode,
  resetBookings,
  clickToCallBooking,
  updateBooking,
  getAgentList,
  assignDoctor,
  getAvailableSlots,
  getPackage,
  getResamplingPackages,
  getSmsReportSend,
  getSMSLink,
  getReportStatus,
  getSyncData,
  resampleBooking,
  raiseNewTicektDisposition,
  getReportDownload,
  getQRCodeData,
  getBookingByBarcode,
  getPaymentInfo,
  getHivPackage,
  getBiomarkerScreening,
  getBookingByHistoryForm,
  getBookingQueryTickets,
} from "../../../actions/LimsAdminActions";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import PaymentModal from "../../Comments/paymentforModal";
import Loader from "../../loader";
import moment from "moment";
import CallIcon from "@material-ui/icons/Call";
import { useRef } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { GridCloseIcon } from "@material-ui/data-grid";
import { generateEmailFilterUrl } from "../../../../helpers/generateUrl";
import ReportModal from "../../Comments/reportStatusModal";
import MainMemberSyncModal from "./SyncMainMemberModal";
import SyncModal from "../../Comments/syncModal";
import AddDiscriptionModal from "../../Comments/RaiseDescriptionModal";
import "./index.sass";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    tableHead: {
      fontWeight: "bold",
      textTransform: "capitalize",
      textAlign: "center",
    },
    contentHeader: {
      width: "100%",
    },
    rightContentHeader: {
      width: "100%",
    },
    contentTable: {
      width: "100%",
      clear: "both",
    },
    formControl: {
      margin: theme.spacing(3),
    },

    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    heading: {
      fontSize: "1.3rem",
      marginTop: "1rem",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
interface Props {
  match: any;
  booking: any;
  getBookingById: any;
  getPinCode: any;
  pin_code: any;
  partnerList: any;
  getPartners: any;
  resetBookings: any;
  clickToCallBooking: any;
  updateBooking: any;
  getAgentList: any;
  agentsList: any;
  assignDoctor: any;
  getAvailableSlots: any;
  booking_slots: any;
  getPackage: any;
  packageList: any;
  getSyncData: any;
  resampleBooking: any;
  raiseNewTicektDisposition: any;
  getSmsDetails: any;
  getEmailDetails: any;
  emailDetails: any;
  smsDetails: any;
  createAdditionalBooking: any;
  updateAdditionalBooking: any;
  getSMSLink: any;
  getSmsReportSend: any;
  additional_booking: any;
  getReportStatus: any;
  reportStatus: any;
  getReportDownload: any;
  reportDownload: any;
  getQRCodeData: any;
  qrcode: any;
  loading: Boolean;
  getBookingByBarcode: any;
  bookingByBarcode: any;
  getPaymentInfo: any;
  paymentInformation: any;
  getHivPackage: any;
  hivpackageList: any;
  getBiomarkerScreening: any;
  biomarkerScreening: any;
  getResamplingPackages: any;
  resamplingPackages: any;
  syncLoading: any;
  getBookingByHistoryForm: any;
  bookinghistoryform: any;
  biospypackages: any;
  bookingLoading: any;
  histopathologyBooking: any;
  getBookingQueryTickets: any;
  bookingComplaints: any;
}

const ViewBooking: React.FC<Props> = ({
  match,
  booking,
  getBookingById,
  getPinCode,
  pin_code,
  resetBookings,
  clickToCallBooking,
  updateBooking,
  getAgentList,
  agentsList,
  assignDoctor,
  getAvailableSlots,
  getSmsDetails,
  getEmailDetails,
  booking_slots,
  getPackage,
  getSyncData,
  packageList,
  createAdditionalBooking,
  getSMSLink,
  updateAdditionalBooking,
  additional_booking,
  resampleBooking,
  raiseNewTicektDisposition,
  getReportStatus,
  reportStatus,
  getSmsReportSend,
  getReportDownload,
  getQRCodeData,
  qrcode,
  reportDownload,
  getBookingByBarcode,
  bookingByBarcode,
  getPaymentInfo,
  loading,
  paymentInformation,
  getHivPackage,
  hivpackageList,
  getBiomarkerScreening,
  biomarkerScreening,
  getResamplingPackages,
  getBookingByHistoryForm,
  bookinghistoryform,
  biospypackages,
  histopathologyBooking,
  bookingLoading,
  syncLoading,
  resamplingPackages,
  getBookingQueryTickets,
  bookingComplaints,
}) => {
  const classes = useStyles();
  useEffect(() => {
    resetBookings();
    getAgentList(`&usergroup=Doctor`);
  }, []);
  const [raiseComplaint, setRaiseComplaint] = useState(false);
  const [cancelAlert, setCancelAlert] = useState(false);
  const [assignBooking, setAssignBooking] = useState(false);
  const [doctor, setDoctor] = useState(0);
  const timer = useRef<any>(0);
  const [customer_designation, setCustomerDesignation] = useState<String>(
    booking.designation || ""
  );
  const [cityId, setCityId] = useState<any>("");
  const [timeSlots, setTimeSlots] = useState<Array<Object>>([]);
  const [selectedSlot, setSelectedSlot] = useState<Number>(
    booking.collection_slot && booking.collection_slot.id
  );
  const [dob, setDob] = useState<any>("");
  const [openSyncModal, setSyncOpenModal] = useState<boolean>(false);
  const [customer_name, setCustomer_name] = useState<any>("");
  const [pickup_status, setPickup_status] = useState("");
  const [customer_age, setCustomer_age] = useState<any>("");
  const [customer_gender, setCustomer_gender] = useState<any>("male");
  const [customer_aadhar, setCustomer_aadhar] = useState<any>("");
  const [packageDetails, setPackageDetails] = useState<any>([]);
  const [reportSendStatus, setReportSendStatus] = useState(false);
  const [tempId, setTempId] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [openEmailSMSModal, setOpenEmailSMSModal] = useState(false);
  const [openReportModal, setReportModal] = useState(false);
  const [tempTotalPrice, setTempTotalPrice] = useState<number>(0);
  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [price, setPrice] = useState<any>(0);
  const [extraCharge, setExtraCharge] = useState<boolean>(
    booking.discounted_price && booking.discounted_price.phlebo_cost !== 0
  );
  const [resamplingMessage, setResamplinigMessage] = useState<string>("");
  const [packageRemarks, setPackageRemarks] = useState<string>("");
  const [otherReason, setOtherReason] = useState<string>("");
  const [openResapling, setResapling] = React.useState(false);
  const [totalPrice, setTotalPrice] = useState<any>(
    booking.discounted_price && booking.discounted_price.final_total_price
  );
  const [memberPackageID, setMemberPackageID] = useState<string[]>([]);
  const [tubeList, setTubeList] = useState<any>([]);
  const [customer_passport, setCustomer_passport] = useState<any>("");
  const [resampling_tubes, setResamplingTubes] = useState<any>();
  const [personId, setPersoneId] = React.useState("none");
  const [resamplePackages, setResamplePackages] = useState<any>([]);
  const [bill_id, set_BillId] = useState<String>("");
  const [partialCheck, setpartialCheck] = useState<any>("");
  const [snrCheck, setSnrCheck] = useState<boolean>(false);
  const [billId, setBillId] = useState<any>("");
  const [additionalBillId, setAdditionalBillId] = useState<any>("");
  const barcodeId = localStorage.getItem("barcode");
  const [syncId, setSyncId] = useState<any>("");
  const [bookingData, setBookingData] = useState({});
  const [openMainMeberSyncModal, setMainMemberSyncModal] =
    useState<boolean>(false);
  const [paymentId, setPaymentId] = useState<any>(0);
  const [openPaymentModal, setOpenPaymentModal] = useState<any>(false);
  const [bId, setBId] = useState<any>("");
  const [aId, setAId] = useState<any>("");
  const [openRemoveModal, setOpenRemoveModal] = useState<any>(false);
  const [type, setType] = useState<any>("");
  const [is_price_hide, setIsPriceHode] = useState<boolean>(
    booking?.partner?.is_price_hide
  );
  const [sampleReceivingDate, setSampleReceivingDate] = useState<any>("");
  const [sampleCollectionDate, setSampleCollectionDate] = useState<any>("");
  const [openAddModal, setOpenAddModal] = useState<any>(false);
  const [isHistoForm, setIsHistoForm] = useState<any>(false);
  const [isNbsForm, setIsNbsForm] = useState<any>(false);
  const [memberDetails, setMemberDetails] = useState<any>();
  const [memberType, setMemberType] = useState<any>("none");
  const [bookingIdList, setBookingIdList] = useState<any>();
  const [customer_additonal_passport, setAdditionalCustomer_passport] =
    useState<any>("");
  const [srfNo, setSrfNo] = useState<any>("");
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  useEffect(() => {
    if (barcodeId !== "") {
      getBookingByBarcode(barcodeId);
    }
  }, [booking, bookingData]);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    getSlots();
  };
  useEffect(() => {
    let tubeArray = new Array();
    if (qrcode && qrcode.results && qrcode.results.length > 0) {
      qrcode.results.map((data: any, index: any) => {
        tubeArray.push({
          tube: data?.id as any,
          tubeName: data?.tube.tube.name,
          disposition: "",
          otherReason: "",
          isChecked: false,
          packageId: data?.package?.id as any,
        });
      });
    }
    setTubeList(tubeArray);
  }, [qrcode]);

  const updateMessage = () => {
    let message = "";
    const list = [...tubeList];
    const pkgs = [...resamplePackages];

    list.map((data: any) => {
      if (data.isChecked && data.disposition !== "") {
        message =
          message +
          "Tube Name: " +
          data.tubeName +
          ", Disposition: " +
          (data.disposition !== "Other"
            ? data.disposition
            : data.disposition + "-" + data.otherReason) +
          "\n";
      }
      setResamplinigMessage(message);
    });
    pkgs.map((data: any) => {
      message = message + "Test Name: " + data.name + "\n";
      setResamplinigMessage(message);
    });
    if (packageRemarks) {
      message =
        message +
        "Test Disposition: " +
        (packageRemarks !== "Other"
          ? packageRemarks
          : packageRemarks + " - " + otherReason) +
        "\n";
      setResamplinigMessage(message);
    }
    if (snrCheck) {
      message = message + "SNR-Sample not Received" + "\n";
    } else {
    }
    setResamplinigMessage(message);
  };
  useEffect(() => {
    if (snrCheck) {
      updateMessage();
    }
  }, [snrCheck]);
  useEffect(() => {
    updateMessage();
  }, [resamplePackages, packageRemarks, otherReason]);
  const handleCheckBox = (event: any, index: number) => {
    const isCheck = event.target.checked;
    const list = [...tubeList];
    list[index]["isChecked"] = isCheck;
    setTubeList(list);
    updateMessage();
  };
  const handleSNRCheck = (event: any) => {
    setSnrCheck(event.target.checked);
  };
  const handleDisposition = (event: any, index: number) => {
    const selectedDisposition = event.target.value;
    const listDispo = [...tubeList];
    listDispo[index]["disposition"] = selectedDisposition;
    setTubeList(listDispo);
    updateMessage();
  };
  const handleOtherDisposition = (event: any, index: number) => {
    const selectedDisposition = event.target.value;
    const listDispo = [...tubeList];
    listDispo[index]["otherReason"] = selectedDisposition;
    setTubeList(listDispo);
    updateMessage();
  };
  const checkNormalPackage = (packages: any) => {
    let isNormalPackage = false;
    packages &&
      packages.length > 0 &&
      packages.map((item: any) => {
        if (!item.is_addon) {
          isNormalPackage = true;
        }
      });
    return isNormalPackage;
  };

  const handlePackages = (customePackage: any, reason: any) => {
    let originalPackageId = new Array();
    packageDetails.length > 0 &&
      packageDetails.map((item: any) => {
        originalPackageId.push(item.id as string);
      });
    setPackageDetails(customePackage);
    let accumulatedPrice: any = 0;
    let updatePackageId = new Array();
    if (customePackage && customePackage.length > 0) {
      let atLeastOneNormalPackage = checkNormalPackage(customePackage);
      customePackage.map((item: any) => {
        updatePackageId.push(item.id);
        const packageExits = memberPackageID.find((el) => el === item.id);
        if (!packageExits) {
          if (
            atLeastOneNormalPackage &&
            item.is_addon &&
            item.addon_price &&
            item.addon_price > 0
          ) {
            accumulatedPrice = accumulatedPrice + item.addon_price;
          } else {
            if (item.package_city_prices === null) {
              accumulatedPrice = accumulatedPrice + item.offer_price;
            } else {
              accumulatedPrice =
                accumulatedPrice + item.package_city_prices.offer_price;
            }
          }
        } else {
          if (reason === "remove-option") {
            updatePackageId.push(item.id);
          }
        }
      });
    }

    let familyPrice: number = Math.floor(accumulatedPrice);
    let totalPriceWithFamily = tempTotalPrice + familyPrice;
    setTotalPrice(totalPriceWithFamily);
    setPrice(totalPriceWithFamily);
    if (
      reason === "remove-option" &&
      updatePackageId?.length > 0 &&
      originalPackageId?.length > 0
    ) {
    } else if (reason === "remove-option" && updatePackageId?.length === 0) {
      removePackage(customePackage, []);
    }
  };
  const handleAddTest = (bid: any, aid: any, type: any) => {
    setOpenAddModal(true);
    setBId(bid);
    setType(type);
    setAId(aid);
  };
  const handleRemoveTest = (bid: any, aid: any, type: any) => {
    setOpenRemoveModal(true);
    setBId(bid);
    setType(type);
    setAId(aid);
  };

  const removePackage = (customePackage: any, updateArr: any) => {
    if (
      customePackage.length === 0 &&
      memberPackageID.length > 0 &&
      packageList.results?.length > 0
    ) {
      let accumulatedPrice = 0;
      memberPackageID.map((row) => {
        const findPackage = packageList?.results.filter(
          (el: any) => el.id === row
        );
        if (findPackage.length > 0) {
          if (findPackage[0]?.package_city_prices) {
            accumulatedPrice =
              (accumulatedPrice +
                findPackage[0]?.package_city_prices?.offer_price) |
              0;
          } else {
            accumulatedPrice =
              (accumulatedPrice + findPackage[0]?.offer_price) | 0;
          }
        }
      });
      if (accumulatedPrice > 0) {
        let familyPrice: number = Math.floor(accumulatedPrice);
        let totalPriceWithFamily = totalPrice - familyPrice;
        setMemberPackageID([]);
        //setTempTotalPrice(totalPriceWithFamily);
        setPrice(totalPriceWithFamily);
        setTotalPrice(totalPriceWithFamily);
      }
    } else if (
      customePackage.length > 0 &&
      memberPackageID.length > 0 &&
      updateArr.length > 0 &&
      packageList?.results?.length > 0
    ) {
      if (updateArr?.length > 0) {
        let accumulatedPrice = 0;
        let removePackageId = "";
        updateArr.map((row: any) => {
          const findPackage = packageList.results.filter(
            (el: any) => el.id === row
          );

          if (findPackage.length > 0) {
            removePackageId = findPackage[0].id;
            if (findPackage[0]?.package_city_prices) {
              accumulatedPrice =
                (accumulatedPrice +
                  findPackage[0]?.package_city_prices?.offer_price) |
                0;
            } else {
              accumulatedPrice =
                (accumulatedPrice + findPackage[0]?.offer_price) | 0;
            }
          }
        });
        if (accumulatedPrice > 0) {
          let familyPrice: number = Math.floor(accumulatedPrice);
          let totalPriceWithFamily = tempTotalPrice - familyPrice;
          if (removePackageId) {
            let tempArray = new Array();
            memberPackageID.map((row) => {
              if (removePackageId !== row) {
                tempArray.push(row);
              }
            });
            setMemberPackageID(tempArray);
          }
          //setTempTotalPrice(totalPriceWithFamily);
          setPrice(totalPriceWithFamily);
          setTotalPrice(totalPriceWithFamily);
        }
      }
    }
  };

  useEffect(() => {
    booking?.coupon_applied && setCoupon(booking?.coupon_applied.id);
    booking?.coupon_applied && setCouponCode(booking?.coupon_applied?.name);
    booking?.discounted_price &&
      setTotalPrice(booking.discounted_price?.final_total_price);
    booking?.discounted_price &&
      setTempTotalPrice(booking.discounted_price?.final_total_price);
    booking?.discounted_price &&
      setPrice(booking.discounted_price?.final_total_price);
    booking?.cityid && setCityId(booking?.cityid);
    booking?.cityid && getPackage(`city_id=${cityId}`);
  }, [booking, getPackage]);

  useEffect(() => {
    const slots = booking_slots && booking_slots.results;
    setTimeSlots(slots && slots[0].zone_data && slots[0].zone_data.time_slots);
  }, [booking_slots]);

  const { id } = useParams();
  const bookingId = id;
  useEffect(() => {
    getBookingById(`${bookingId}`);
  }, [bookingId, additional_booking, booking.plink_id]);

  const getArea = (pincode: String, colDate: any) => {
    const zone =
      pin_code.results &&
      pin_code.results.find((code: any) => code.pincode === pincode);
    getAvailableSlots(colDate, zone && zone.zone_data && zone.zone_data.id);
  };

  const getSlots = () => {
    getArea(
      booking.customer_areapincode && booking.customer_areapincode.pincode,
      booking.collection_date && booking.collection_date
    );
  };

  const handleCloseAddModal = () => {
    // navigate(`/dashboard/coradmin/booking-view/${booking?.pk}`);
    setOpenRemoveModal(false);
    setOpenAddModal(false);
    getBookingById(`${bookingId}`);
  };
  useEffect(() => {
    getPinCode(
      booking.customer_areapincode &&
        `${booking.customer_areapincode.pincode}&show_code=true`
    );
    setSelectedSlot(booking.collection_slot && booking.collection_slot.id);
    if (booking.packages && booking.packages.length > 0) {
      let storeData: any = [];

      booking.packages?.map((list2: any) => {
        storeData.push({
          id: list2?.id,
          checkHistoStatus:
            list2?.forms.length > 0 &&
            list2?.forms[0].form_name === "Histopathology History Form"
              ? true
              : false,
          checkNbsStatus:
            list2?.forms.length > 0 &&
            list2?.forms[0].form_name === "BabyScreen Form"
              ? true
              : false,
        });
      });

      let btnStatus: boolean = storeData?.some(
        (list: any) => list.checkHistoStatus
      );
      let nbsStatus: boolean = storeData?.some(
        (list: any) => list.checkNbsStatus
      );
      setIsHistoForm(btnStatus);
      setIsNbsForm(nbsStatus);
    }
  }, [booking]);

  const handleCallClick = (id: number, type: string) => {
    clickToCallBooking(id, type);
  };

  const navigate = useNavigate();
  const cancelHandler = async () => {
    const data: any = {
      booking_status: "cancelled",
    };
    await updateBooking(data, bookingId);
    navigate(`/dashboard/la/bookings`);
  };

  const handleAssignDoctor = () => {
    const data: any = {
      doctor,
      booking: Number(bookingId),
    };
    assignDoctor(data);
  };

  const addFamilyMemberHandler = async () => {
    const body: any = {
      customer_aadhar,
      customer_age,
      customer_gender,
      customer_name,
      collection_slot: selectedSlot,
      designation: customer_designation,

      packages: packageDetails.map((pack: any) => {
        return pack.id;
      }),
    };
    await createAdditionalBooking(booking.pk, body);
    setOpen(false);
    setCustomer_aadhar("");
    setCustomer_age("");
    setCustomer_passport("");
    setCustomer_gender("male");
    setCustomer_name("");
    setPackageDetails([]);
    setTempId(0);
  };

  const updateFamilyMemberHandler = async () => {
    const body: any = {
      customer_aadhar,
      customer_age,
      customer_gender,
      customer_name,
      pickup_status: pickup_status,
      collection_slot: selectedSlot,
      designation: customer_designation,

      packages: packageDetails.map((pack: any) => {
        return pack.id;
      }),

      srf_number: srfNo,
      bill_id: bill_id,
      passport_number: customer_passport,
      sample_receiving_time: sampleReceivingDate,
      sample_collection_time: sampleCollectionDate,
      dob: moment(dob).format("YYYY-MM-DD"),
    };
    if (!dob) {
      delete body.dob;
    }
    if (sampleReceivingDate === "") {
      delete body.sample_receiving_time;
    }
    if (sampleCollectionDate === "") {
      delete body.sample_collection_time;
    }
    if (srfNo === "") {
      delete body.srf_number;
    }
    await updateAdditionalBooking(tempId, body);
    setOpen(false);
    setCustomer_aadhar("");
    setCustomer_passport("");
    setCustomer_age("");
    setCustomer_gender("male");
    setCustomer_name("");
    setPackageDetails([]);
    setTempId(0);
  };

  const handleEditMember = (member: any) => {
    setCustomer_aadhar(member.customer_aadhar);
    setCustomer_passport(member?.passport_number);
    setCustomerDesignation(member?.designation);
    setCustomer_age(member?.customer_age);
    setCustomer_gender(member.customer_gender);
    setCustomer_name(member.customer_name);
    setPackageDetails(member.packages);
    setSelectedSlot(booking.collection_slot.id);
    set_BillId(member.bill_id);
    setDob(member?.dob);
    setTempId(member.id);
    handleOpen();
    setEdit(true);
    let pacakgeId = new Array();
    member?.packages &&
      member.packages.map((memberPackage: any) => {
        pacakgeId.push(memberPackage.id as string);
      });
    setMemberPackageID(pacakgeId);
  };
  const handleCancelBooking = () => {
    setCancelAlert(true);
    navigate(`/dashboard/la`);
  };
  const handleSendReportSms = () => {
    getSmsReportSend(`${booking.pk}/booking/`);
    setReportSendStatus(true);
    alert("Report Send Successfully");
  };
  const handleConsolidateReport = () => {
    if (personId !== "none") {
      getSmsReportSend(
        `${String(bookingId) === String(personId) ? bookingId : personId}/${
          String(bookingId) === String(personId) ? `booking` : `add_booking`
        }/?cons=true`
      );
      alert("Report Send Successfully");
    }
  };
  // const handleEmailSMSDetails = () => {
  //   const body: any = {
  //     booking: bookingId,
  //   };
  //   const url = generateEmailFilterUrl(body).substring(2);
  //   getEmailDetails(`${url}`);
  //   getSmsDetails(`${url}`);
  //   setOpenEmailSMSModal(true);
  //   // setPage(0)
  // };

  //Download Report Api call
  useEffect(() => {
    if (personId !== "none") {
      if (String(bookingId) === String(personId)) {
        getQRCodeData(`?booking_id=${bookingId}`);
        getBookingQueryTickets(
          `?ticket_type=resampling&booking_id=${bookingId}`
        );
        getResamplingPackages(`booking=${bookingId}`);
        if (billId !== null) {
          getReportDownload(`${bookingId}/`);
        } else {
        }
      } else if (String(bookingId) !== String(personId)) {
        getQRCodeData(`?add_booking_id=${personId}`);
        getResamplingPackages(`add_booking=${personId}`);
        getBookingQueryTickets(
          `?ticket_type=resampling&add_member_id=${personId}`
        );
        if (additionalBillId !== null) {
          getReportDownload(`${personId}/?additional_id=true`);
        } else {
        }
      }
    }
  }, [personId]);
  //Download Report Api call
  //Report Status
  const handleReportStatus = () => {
    if (personId !== "none") {
      if (String(bookingId) === String(personId)) {
        if (billId !== null) {
          getReportStatus(`?booking_id=${bookingId}`);
          setReportModal(true);
        } else {
          alert("Bill Id is Not Genrated");
        }
      } else if (String(bookingId) !== String(personId)) {
        if (additionalBillId !== null) {
          getReportStatus(`?add_member_booking=${personId}`);
          setReportModal(true);
        } else {
          alert("Family Member Bill Id is Not Genrated");
        }
      }
    }
  };
  //agedob
  const handleDOBForAge = (dob: any, bdate: any) => {
    const a: any = moment(bdate);
    const b: any = moment(dob);
    var years = a.diff(b, "year");
    b.add(years, "years");
    var months = a.diff(b, "months");
    b.add(months, "months");
    var days = a.diff(b, "days");
    if (months !== 0) {
      var age = months + "Months " + days + "Days";
    } else {
      var age = days + "Days";
    }

    return age;
  };
  //Report Status
  // const handleSyncButton = (id: any) => {
  //   getSyncData(`${id}/?is_additional=true`);
  //   setTimeout(() => {
  //     getBookingById(bookingId);
  //   }, 2000);
  // };
  //Report Status
  //Resampling Modal
  const resamplingHandler = () => {
    setResapling(true);
  };
  //Resampling Modal

  const closeResampling = () => {
    setResamplinigMessage("");
    setResapling(false);
  };

  const ProccessResampling = (e: any) => {
    e.preventDefault();
    let resamplingTubeList = new Array<any>();
    if (tubeList) {
      tubeList.map((row: any, index: number) => {
        if (row?.isChecked) {
          if (resamplingTubeList.length > 0) {
            const checkedTubeList = resamplingTubeList.filter(
              (data: any) => data?.packageId === row?.packageId
            );
            if (checkedTubeList?.length > 0) {
              resamplingTubeList.map((item: any, val_index: number) => {
                if (item?.packageId === row?.packageId) {
                  let tubeIdVal = item?.tube;
                  tubeIdVal = tubeIdVal + "," + row?.tube;
                  resamplingTubeList[val_index].tube = tubeIdVal;
                }
              });
            } else {
              resamplingTubeList.push({
                packageId: row?.packageId,
                tube: row?.tube,
              });
            }
          } else {
            resamplingTubeList.push({
              packageId: row?.packageId,
              tube: row?.tube,
            });
          }
        }
      });
    }

    const data: any = {
      disposition: "resampling",
      query: resamplingMessage,
      booking_id: bookingId,
      additional_member_id:
        String(bookingId) === String(personId) ? "" : personId,
      resampling_tubes: resamplingTubeList,
      test_remarks: packageRemarks !== "Other" ? packageRemarks : otherReason,
      // also do same in corporate,corporate client admin
      resampling_packages: resamplePackages.map((val: any) => val.id),
      // remarks: resamplingMessage, // package: package name
    };
    raiseNewTicektDisposition(
      data,
      snrCheck ? "snr" : ""
      // String(bookingId)===String(personId) ? `${bookingId}/` : `${personId}/?is_add=true`
    );
    navigate(`/dashboard/la/booking-view/${bookingId}`);
    setResamplinigMessage("");
    setResapling(false);
  };

  const handleSMSSend = () => {
    getSMSLink(booking.pk);
    alert("SMS Sent Successfully");
  };

  useEffect(() => {
    if (bookingId !== undefined) {
      getHivPackage(`?booking=${bookingId}`);
    }
  }, [booking]);

  useEffect(() => {
    getBiomarkerScreening(`booking_id=${bookingId}`);
  }, [bookingId]);
  useEffect(() => {
    if (personId !== "none" && String(personId) === String(bookingId)) {
      getBiomarkerScreening(`booking_id=${personId}`);
    } else if (personId !== "none" && String(personId) !== String(bookingId)) {
      getBiomarkerScreening(`add_member_booking=${personId}`);
    }
  }, [personId]);

  const TUBEDISPOSITION = [
    {
      id: 1,
      type: "SNR-sample not received",
    },
    {
      id: 2,
      type: "SERUM SAMPLE QUANTITY NOT SUFFICIENT (LEAKED OUT)",
    },
    {
      id: 3,
      type: "URINE SAMPLE NOT RECEIVED.",
    },
    {
      id: 4,
      type: "FLUORIDE SAMPLE NOT RECEIVED.",
    },
    {
      id: 5,
      type: "FLUORIDE & URINE SAMPLE NOT RECEIVED.",
    },
    {
      id: 6,
      type: "URINE SAMPLE QUANTITY NOT SUFFICIENT (LEAKED OUT)",
    },
    {
      id: 7,
      type: "UNLABELED URINE SAMPLE RECEIVED",
    },
    {
      id: 8,
      type: "SAMPLE QUANTITY NOT SUFFICIENT",
    },
    {
      id: 9,
      type: "STOOL SAMPLE NOT RECEIVED.",
    },
    {
      id: 10,
      type: "WRONG SAMPLE TYPE RECEIVED.",
    },
    {
      id: 11,
      type: "ONLY TRF RECEIVED. SAMPLE NOT RECEIVED.",
    },
    {
      id: 12,
      type: "24 HRS. URINE VOLUME NOT MENTION",
    },
    {
      id: 13,
      type: "EMPTY FLUORIDE TUBE RECEIVED",
    },
    {
      id: 14,
      type: "ALL TUBES UNLABELED RECEIVED",
    },
    {
      id: 15,
      type: "HAEMOLYSED SAMPLE",
    },
    {
      id: 16,
      type: "TURBID SAMPLE",
    },
    {
      id: 17,
      type: "RESULT RE-CHECK",
    },
    {
      id: 18,
      type: "LIPEMIC SAMPLE",
    },
    {
      id: 19,
      type: "PATIENT LABELLING MISMATCH ON TUBE",
    },
    {
      id: 20,
      type: "Other",
    },
  ];
  const TESTDISPOSITIONS = [
    "Hemolyzed Sample",
    "Low Fetal Fraction",
    "High Data Noise",
    "Low Concentration",
    "MCC Detected",
    "Thin Pellet",
    "Red Pellet",
    "Inappropriate Tissue",
    "Culture Failure",
    "Improper Sample Collection",
    "Quantity Not Sufficient",
    "Other",
  ];
  useEffect(() => {
    if (personId !== "none") {
      if (String(bookingId) === String(personId) && billId !== null) {
        if (billId !== null) {
          getReportDownload(`${bookingId}/`);
        } else {
        }
      } else {
        if (additionalBillId !== null) {
          getReportDownload(`${personId}/?additional_id=true`);
        } else {
        }
      }
    }
  }, [personId]);

  //Select Customer Name
  const handleChange = (event: any, data: any) => {
    if (Number(event.target.value) === Number(bookingId)) {
      setMemberType("main");
      setBillId(data);
    } else if (Number(event.target.value) !== Number(bookingId)) {
      setMemberType("additional");
      const additionDetails = data?.additional_members.find(
        (bId: any) => bId.id === event.target.value
      );
      setAdditionalBillId(additionDetails.bill_id);
      setMemberDetails(additionDetails);
    }
    setPersoneId(event.target.value);
  };
  //Select Customer Name

  //Download Report
  const handleDownloadReport = (report: any) => {
    if (billId === null && additionalBillId !== null) {
      alert("Bill Id Is Not Enter");
    }
    {
      if (report.message === "Your report is not ready yet") {
        alert(report.message);
      } else {
        var b64 = report.allReportDetails.reportDetails;
        var bin = window.atob(b64);
        var link = document.createElement("a");
        link.download = `${report.allReportDetails.Patient_Name}-${report.allReportDetails.Age}.pdf`;
        link.href = "data:application/octet-stream;base64," + b64;
        link.click();
      }
    }
  };
  //Download Report
  //convert consolidate report
  useEffect(() => {
    if (personId !== "none") {
      if (String(bookingId) === String(personId)) {
        setpartialCheck(booking.report_status);
      } else if (String(bookingId) !== String(personId)) {
        const addRStatus = booking.additional_members.find(
          (data: any) => data.id === personId
        );
        // setpartialCheck(addRStatus.report_status);
      }
    }
  }, [personId]);
  //convert consolidate report
  const handleSyncCCDC = async (id: any, data: any) => {
    setSyncId(id);
    setSyncOpenModal(true);
    setBookingData(data);
  };
  //sync function
  const handleSync = async (id: any, data: any) => {
    setSyncId(id);
    setMainMemberSyncModal(true);
    setBookingData(data);
  };
  useEffect(() => {
    if (paymentId !== 0) {
      getPaymentInfo(`?booking=${paymentId}`);
    }
  }, [paymentId]);
  const handlePaymentInfo = (id: any) => {
    setPaymentId(id);
    setOpenPaymentModal(true);
    setPaymentId(id);
  };

  const handleRaiseComment = (booking: any) => {
    setBookingIdList(booking);
    setRaiseComplaint(!raiseComplaint);
  };

  useEffect(() => {
    if (dob !== "" && dob !== null) {
      let today = new Date();
      let birthDate = new Date(dob);
      let age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      setCustomer_age(age);
    }
  }, [dob]);

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.contentHeader}>
          <div className={classes.rightContentHeader}>
            {booking.pickup_status === "confirmed" && (
              <Link
                to={`/dashboard/la/download/${booking.pk}/download`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  style={{
                    background: "#0091ea",
                    marginLeft: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  E-TRF
                </Button>
              </Link>
            )}
            {booking.booking_status === "pending" &&
              booking.booking_status !== "cancelled" && (
                <Button
                  variant="contained"
                  style={{
                    background: "#d50000",
                    marginLeft: "1rem",
                    marginBottom: "1rem",
                  }}
                  onClick={() => handleCancelBooking()}
                >
                  Cancel
                </Button>
              )}
            <Link
              to={`/dashboard/la/booking-additionalFamily/${booking.pk}/add`}
              style={{ textDecoration: "none" }}
            ></Link>
            {booking.report_status !== "consolidate" ? (
              <Link
                to={`/dashboard/la/booking-edit/${booking.pk}/edit`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  disabled={booking.report_status === "consolidate"}
                  style={{
                    background: "#1976d2",
                    marginLeft: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  Edit Booking
                </Button>
              </Link>
            ) : (
              ""
            )}

            <Button
              onClick={() => handleSendReportSms()}
              disabled={
                booking.report_status === "pending" ||
                booking.report_status === "none" ||
                booking.report_status === null
              }
              variant="contained"
              style={{
                marginLeft: "1rem",
                marginBottom: "1rem",
                background: "#ffd54f",
              }}
            >
              Send Report
            </Button>
            {hivpackageList && hivpackageList.length !== 0 ? (
              <Link
                to={`/dashboard/la/sampleaccessiong-hiv/${booking.pk}`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  disabled={hivpackageList.length === 0}
                  style={{
                    background: "#1976d2",
                    marginLeft: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  Consent Form
                </Button>
              </Link>
            ) : (
              ""
            )}

            {biomarkerScreening &&
            biomarkerScreening.results &&
            biomarkerScreening.results.length !== 0 ? (
              <Link
                to={`/dashboard/la/sampleaccessiong-BiomarkerScreeningreport/${booking.pk}`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  style={{
                    background: "#1976d2",
                    marginLeft: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  Double Marker Form
                </Button>
              </Link>
            ) : (
              ""
            )}
            {isHistoForm ? (
              <Link
                // to={`/dashboard/la/historyform/${booking.pk}`}
                to={`/dashboard/la/historyform/${booking.pk}`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  style={{
                    marginLeft: "1rem",
                    marginBottom: "1rem",
                    background: "#558b2f",
                    color: "white",
                  }}
                >
                  HISTOPATHOLOGY HISTORY FORM
                </Button>
              </Link>
            ) : (
              ""
            )}
            {isNbsForm ? (
              <Link
                to={`/dashboard/la/nbsform/${booking.pk}`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  style={{
                    marginLeft: "1rem",
                    marginBottom: "1rem",
                    background: "magenta",
                    color: "white",
                  }}
                >
                  NBS FORM
                </Button>
              </Link>
            ) : (
              ""
            )}

            <Button
              onClick={() => handleSMSSend()}
              variant="contained"
              style={{
                marginLeft: "1rem",
                marginBottom: "1rem",
                background: "#ffd54f",
              }}
            >
              Send Review SMS
            </Button>
            <Button
              variant={!raiseComplaint ? "contained" : "outlined"}
              style={{
                background: "#1976d2",
                marginLeft: "1rem",
                marginBottom: "1rem",
              }}
              onClick={() => handleRaiseComment(booking)}
            >
              Document Required
            </Button>
            {(memberType === "main" &&
              booking?.pickup_status === "confirmed") ||
            (memberType === "additional" &&
              memberDetails?.pickup_status === "confirmed") ? (
              <Button
                disabled={personId === "none"}
                variant="contained"
                style={{
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                  background: "#c2185b",
                  color: "#e0f2f1",
                }}
                onClick={() => resamplingHandler()}
              >
                PSN/SNR(Resampling)
              </Button>
            ) : (
              ""
            )}

            {/* {booking?.pickup_status === "confirmed" &&
              !booking?.is_rescheduled && (
                <Button
                  disabled={personId === "none"}
                  variant="contained"
                  style={{
                    marginLeft: "1rem",
                    marginBottom: "1rem",
                    background: "#c2185b",
                    color: "#e0f2f1",
                  }}
                  onClick={() => resamplingHandler()}
                >
                  PSN/SNR(Resampling)
                </Button>
              )} */}

            {/* <Button
              onClick={handleEmailSMSDetails}
              variant="contained"
              style={{
                marginLeft: "1rem",
                marginBottom: "1rem",
                background:"#0091ea",
              }}
            >
              Email or SMS Details
            </Button> */}
            {/* {booking.report_status === "partial" || booking.report_status === "consolidate" ? (
              <Button
                variant={!assignBooking ? "contained" : "outlined"}
                style={{ marginLeft: "1rem", marginBottom: "1rem",background:"#ffd54f", }}
                onClick={() => setAssignBooking(!assignBooking)}
              >
                Assign a Doctor
              </Button>
            ) : ""} */}

            {/* {booking.report_status === "partial" || booking.report_status === "consolidate" ? (
              <Button onClick={() => handleDownloadReport(reportDownload)} variant="contained" style={{ marginLeft: "1rem", marginBottom: "1rem", background:"#ffd54f", }}>Download Report</Button>
            ) : ""} */}
            {partialCheck === "partial" ? (
              <Button
                variant="contained"
                disabled={partialCheck !== "partial"}
                style={{
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                  background: "#0091ea",
                }}
                onClick={() => handleConsolidateReport()}
              >
                Convert Partial To Consolidate
              </Button>
            ) : (
              ""
            )}
            <FormControl sx={{ m: 1, width: "20%" }}>
              <InputLabel id="demo-multiple-name-label">
                Customer Name
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                className="input"
                name="priority"
                variant="outlined"
                label="Customer Name"
                input={<OutlinedInput label="Customer Name" />}
                value={personId}
                onChange={(e: any) => handleChange(e, booking)}
              >
                <MenuItem value={booking?.pk}>
                  {booking?.customer_name}
                </MenuItem>
                {booking.additional_members &&
                  booking.additional_members.length > 0 &&
                  booking.additional_members.map((data: any) => {
                    return (
                      <MenuItem value={data?.id}>
                        {data?.customer_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <Button
              onClick={handleReportStatus}
              variant="contained"
              disabled={personId === "none"}
              style={{
                marginLeft: "1rem",
                marginBottom: "1rem",
                backgroundColor: "#ffbf00",
              }}
            >
              REPORT STATUS
            </Button>

            <Button
              disabled={
                personId === "none" ||
                booking.report_status === "na" ||
                booking.report_status === "pending" ||
                booking.report_status === "none" ||
                booking.report_status === null
              }
              onClick={() => handleDownloadReport(reportDownload)}
              variant="contained"
              style={{
                marginLeft: "1rem",
                marginBottom: "1rem",
                backgroundColor: "#2962ff",
              }}
            >
              Download Report
            </Button>
            <Link
              to={`/dashboard/la/uploadTrf/${booking.pk}`}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                style={{
                  background: "#00a0b2",
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                  color: "white",
                }}
              >
                Upload Trf
              </Button>
            </Link>
          </div>
          <div
            style={{
              alignItems: "left",
              display: "inline-block",
              marginLeft: "1rem",
              color: "#c2185b",
            }}
          >
            <small>
              Note:
              <li style={{ fontWeight: "bold", color: "#c2185b" }}>
                For Download report and Send Partial Report Select Customer Name
              </li>
              <li style={{ fontWeight: "bold", color: "#c2185b" }}>
                Select Customer Name to Enable Convert Partial To Consolidate
                button/
              </li>
              <li style={{ fontWeight: "bold", color: "#c2185b" }}>
                send Partial Report Enable if report status is Partial
              </li>
            </small>
          </div>
        </div>
        <Dialog
          open={openResapling}
          onClose={closeResampling}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <form onSubmit={ProccessResampling}>
            <DialogContent>
              <TableContainer  style={{ maxHeight: "300px",marginBottom:10,border:"1px solid grey" }}>
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="center">
                        Ticekt Id
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Booking Id
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Additional Id
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Disposition
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Sub Disposition
                      </StyledTableCell>
                      <StyledTableCell align="center">Query</StyledTableCell>
                      <StyledTableCell align="center">Status</StyledTableCell>
                      <StyledTableCell align="center">
                        Created At
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Follow Up{" "}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Attempted{" "}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Updated At{" "}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Created By
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Received By
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {bookingComplaints?.results &&
                      bookingComplaints?.results.length > 0 &&
                      bookingComplaints?.results?.map(
                        (ticket: any, index: any) => {
                          return (
                            <StyledTableRow key={index}>
                              <StyledTableCell align="center">
                                {ticket?.id}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                        {ticket?.booking !== null
                          ? ticket?.booking?.id || ticket?.booking?.pk
                          : ticket?.add_booking?.mainbooking}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {ticket?.add_booking&&ticket?.add_booking?.id}
                      </StyledTableCell>
                              <StyledTableCell align="center">
                                {ticket?.action_disposition}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {ticket?.action_sub_disposition}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <div style={{ width: 300 }}>
                                  {" "}
                                  {ticket?.query}
                                </div>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {ticket?.action_status_name}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {new Date(ticket?.created_at).toLocaleString()}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {new Date(
                                  ticket?.call_later_time
                                ).toLocaleString()}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {ticket?.attempted}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {new Date(ticket?.updated_at).toLocaleString()}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {ticket?.panel_user && ticket?.panel_user?.name}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {ticket?.received_by?.name}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        }
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TextareaAutosize
                maxRows={3}
                style={{ height: "120px", width: "100%" }}
                aria-label="maximum height"
                placeholder="Message..."
                value={resamplingMessage}
                onChange={(e) =>
                  setResamplinigMessage(e.target.value as string)
                }
              />
              <FormControl component="fieldset" style={{ width: "100%" }}>
                <FormLabel component="legend">SELECT TESTS</FormLabel>
                <Autocomplete
                  onChange={(event, newValue: any, reason: any) => {
                    if (newValue) {
                      let packs: any = [];

                      newValue.map((val: any) => {
                        let obj = JSON.parse(JSON.stringify(val, null, " "));
                        packs.push(obj);
                      });
                      setResamplePackages(packs);
                    }
                  }}
                  multiple
                  options={resamplingPackages.results}
                  disableClearable
                  limitTags={0}
                  getOptionLabel={(option: any) =>
                    option.name && option.name.toString()
                  }
                  getOptionDisabled={(option: any) => {
                    return resamplePackages
                      .map((val: any) => val.id)
                      .includes(option.id);
                  }}
                  freeSolo
                  disableCloseOnSelect
                  renderTags={(value: string[], getTagProps) =>
                    value.map((option: any, index: number) => (
                      <Chip
                        variant="outlined"
                        color="primary"
                        label={option.name && option?.name.toString()}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" fullWidth />
                  )}
                />
                {resamplePackages.length !== 0 ? (
                  <>
                    <TextField
                      size="small"
                      select
                      name="customer_designation"
                      variant="outlined"
                      value={packageRemarks}
                      label="Select Disposition"
                      style={{ width: "100%", margin: "7px 0px" }}
                      onChange={(e: any) => setPackageRemarks(e.target.value)}
                      required={resamplePackages.length !== 0}
                    >
                      {TESTDISPOSITIONS.map((tube: any, index: any) => {
                        return (
                          <MenuItem value={tube}>
                            {index + 1 + "." + tube}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    {packageRemarks === "Other" ? (
                      <TextField
                        size="small"
                        name="customer_designation"
                        label="Type the Other reason"
                        variant="outlined"
                        value={otherReason}
                        style={{ width: "100%", margin: "5px 0px" }}
                        onChange={(e: any) => setOtherReason(e.target.value)}
                        required={packageRemarks === "Other"}
                      />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
                <FormLabel component="legend">TUBE DETAILS</FormLabel>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={snrCheck}
                      onChange={(e: any) => handleSNRCheck(e)}
                      name="SNR"
                    />
                  }
                  label="SNR"
                />
                {tubeList && tubeList?.length > 0
                  ? tubeList.map((data: any, index: any) => {
                      return (
                        <FormGroup key={index}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={data.isChecked}
                                onChange={(e) => handleCheckBox(e, index)}
                                name="gilad"
                              />
                            }
                            label={data?.tubeName}
                          />
                          {data.isChecked === true ? (
                            <>
                              <TextField
                                size="small"
                                select
                                name="customer_designation"
                                label="Select Disposition"
                                variant="outlined"
                                value={data.disposition}
                                style={{ width: "100%", margin: "0" }}
                                onChange={(e: any) =>
                                  handleDisposition(e, index)
                                }
                                required={data.isChecked === true}
                              >
                                {TUBEDISPOSITION.map(
                                  (tube: any, index: any) => {
                                    return (
                                      <MenuItem value={tube.type}>
                                        {tube.id + ". " + tube.type}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </TextField>
                              {data.disposition === "Other" ? (
                                <TextField
                                  size="small"
                                  name="customer_designation"
                                  label="Type the Other reason"
                                  variant="outlined"
                                  value={data.otherReason}
                                  style={{ width: "100%", margin: "5px 0px" }}
                                  onChange={(e: any) =>
                                    handleOtherDisposition(e, index)
                                  }
                                  required={packageRemarks === "Other"}
                                />
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </FormGroup>
                      );
                    })
                  : ""}
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeResampling} color="primary">
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={
                  resamplingMessage === "" || resamplePackages.length === 0
                }
              >
                Proceed
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog open={openAddModal}>
          <Grid container md={12} style={{ padding: "15px 20px 0 20px" }}>
            <Grid item xs={6} style={{ textAlign: "left" }}>
              <h4>Test Addon</h4>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCloseAddModal}
              >
                Close
              </Button>
            </Grid>
          </Grid>

          <AddTest id={bId} aid={aId} type={type} />
        </Dialog>
        <Dialog open={openRemoveModal}>
          <Grid container md={12} style={{ padding: "15px 20px 0 20px" }}>
            <Grid item xs={6} style={{ textAlign: "left" }}>
              <h4>Remove Test</h4>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCloseAddModal}
              >
                Close
              </Button>
            </Grid>
          </Grid>

          <RemoveTest id={bId} aid={aId} type={type} />
        </Dialog>

        <div className={classes.contentTable}>
          <Dialog
            open={cancelAlert}
            onClose={() => setCancelAlert(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure!!</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your booking with booking Id: {bookingId} will be cancelled.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCancelAlert(false)} color="primary">
                Discard
              </Button>
              <Button onClick={() => cancelHandler()} color="primary" autoFocus>
                Proceed
              </Button>
            </DialogActions>
          </Dialog>
          {booking?.center?.center_type === "corporate" ? (
            <Dialog fullScreen open={open} onClose={handleClose}>
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <GridCloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    Edit Family Member
                  </Typography>
                  {edit ? (
                    <Button
                      autoFocus
                      color="inherit"
                      onClick={updateFamilyMemberHandler}
                      disabled={packageDetails?.length === 0}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      autoFocus
                      color="inherit"
                      onClick={addFamilyMemberHandler}
                    >
                      Save
                    </Button>
                  )}
                </Toolbar>
              </AppBar>
              <Grid
                container
                spacing={3}
                style={{ margin: "1rem auto", padding: "0 1rem" }}
              >
                <Grid item xs={12} md={6}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Salutation*</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Select
                        className="input"
                        name="customer_designation"
                        variant="outlined"
                        value={customer_designation}
                        style={{ width: "100%", margin: "0" }}
                        onChange={(e) =>
                          setCustomerDesignation(e.target.value as String)
                        }
                        required
                      >
                        <MenuItem value={"Mr"}>Mr</MenuItem>
                        <MenuItem value={"Mrs"}>Mrs</MenuItem>
                        <MenuItem value={"Master"}>Master</MenuItem>
                        <MenuItem value={"Miss"}>Miss</MenuItem>
                        <MenuItem value={"Smt"}>Smt</MenuItem>
                        <MenuItem value={"Dr."}>Dr.</MenuItem>
                        <MenuItem value={"Baby or Just Born"}>
                          Baby or Just Born
                        </MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Customer Name</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <input
                        className="input"
                        name="collection_date"
                        type="name"
                        placeholder="Customer Name"
                        value={customer_name}
                        onChange={(e) => setCustomer_name(e.target.value)}
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "18.5px 14px",
                        }}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Customer Aadhar</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <input
                        name="collection_date"
                        type="number"
                        placeholder="Customer Aadhar"
                        value={customer_aadhar}
                        onChange={(e) => setCustomer_aadhar(e.target.value)}
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "18.5px 14px",
                        }}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Customer Passport</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        className="input"
                        name="customer_additonal_passport"
                        type="text"
                        placeholder="Enter passport Number"
                        value={customer_passport}
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                        onChange={(e) =>
                          setCustomer_passport(e.target.value as String)
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Customer Age</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <input
                        name="collection_date"
                        type="number"
                        placeholder="Customer Age"
                        value={customer_age}
                        onChange={(e) => setCustomer_age(e.target.value)}
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "18.5px 14px",
                        }}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Customer DOB</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        name="dateOfBirth"
                        type="date"
                        variant="outlined"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                        fullWidth
                        inputProps={{
                          max: moment()
                            .subtract(1, "days")
                            .format("YYYY-MM-DD"),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Customer Gender</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Select
                        className="input"
                        name="customer_gender"
                        variant="outlined"
                        value={customer_gender}
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        onChange={(e) => setCustomer_gender(e.target.value)}
                        required
                      >
                        <MenuItem selected hidden value={"none"}>
                          Please Select
                        </MenuItem>
                        <MenuItem value={"male"}>Male</MenuItem>
                        <MenuItem value={"female"}>Female</MenuItem>
                        <MenuItem value={"other"}>Other</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <p>SRF Number</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        name="srfNo"
                        type="text"
                        value={srfNo}
                        placeholder="SRF No."
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                        onChange={(e) => setSrfNo(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <p>Sample Receiving Date & Time</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        type="datetime-local"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={sampleReceivingDate}
                        variant="outlined"
                        onChange={(e) => setSampleReceivingDate(e.target.value)}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <p>Sample Collection Date & Time</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        type="datetime-local"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={sampleCollectionDate}
                        variant="outlined"
                        onChange={(e) =>
                          setSampleCollectionDate(e.target.value)
                        }
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs>
                      <p style={{ textAlign: "center", fontWeight: "bold" }}>
                        Add Test
                      </p>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ height: 54 }}
                        fullWidth
                        onClick={() =>
                          handleAddTest(
                            booking.id === undefined ? booking.pk : booking.id,
                            id,
                            "add_member"
                          )
                        }
                      >
                        Test Addon
                      </Button>
                    </Grid>
                    <Grid item xs>
                      <p style={{ textAlign: "center", fontWeight: "bold" }}>
                        Remove Test
                      </p>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ height: 54 }}
                        fullWidth
                        onClick={() =>
                          handleRemoveTest(
                            booking.id === undefined ? booking.pk : booking.id,
                            id,
                            "add_member"
                          )
                        }
                      >
                        Test Remove
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Card
                    className="sampleCollectionTimeFamily"
                    style={{
                      height: "428px !important",
                      overflow: "auto",
                      backgroundColor: "#33bfff",
                    }}
                  >
                    <h3
                      style={{
                        marginBottom: "1rem",
                        backgroundColor: "#00b0ff",
                        fontWeight: "bold",
                        height: "60px",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Sample Collection Time
                    </h3>
                    <div className={classes.slotContainer}>
                      {timeSlots &&
                        timeSlots.length > 0 &&
                        timeSlots.map((slot: any) => {
                          return (
                            slot.available_slots > 0 && (
                              <Card
                                key={slot.id}
                                className={
                                  slot.id === selectedSlot
                                    ? classes.selectedSlot
                                    : classes.slot
                                }
                                style={{ cursor: "crosshair" }}
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "black",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {slot.start_time}-{slot.end_time}
                                </p>

                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "green",
                                    fontWeight: "bold",
                                    fontSize: "17px",
                                  }}
                                >
                                  {" "}
                                  Availabe slots: {slot.available_slots}
                                </p>
                              </Card>
                            )
                          );
                        })}
                    </div>
                  </Card>
                </Grid>
                <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ padding: "1rem", justifyContent: "right" }}
                  >
                    <div className="priceDetails" style={{ padding: "1rem" }}>
                      <table style={{ width: "100%", margin: "0" }}>
                        <thead>
                          <tr>
                            <td className="cell">Customer Name</td>
                            <td className="cell">Packages</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="cell">{customer_name}</td>
                            <td className="cell">
                              <ul>
                                {packageDetails.map((pack: any, index: any) => {
                                  return (
                                    <li key={index}>
                                      {pack.name}
                                      <br />
                                      DESCRIPTION : {pack.description}
                                      <br />
                                      TaT:{" "}
                                      {pack.package_city_prices
                                        ? pack.package_city_prices.tat_time
                                        : pack.tat}
                                      <br />
                                      Price:{" "}
                                      {pack.package_city_prices
                                        ? pack.package_city_prices.offer_price
                                        : pack.price}
                                    </li>
                                  );
                                })}
                              </ul>
                            </td>
                          </tr>
                          {booking.additional_members &&
                            booking.additional_members.map((val: any) => {
                              if (val.id !== id) {
                                return (
                                  <tr>
                                    <td className="cell">
                                      {val.customer_name}
                                    </td>
                                    <td className="cell">
                                      <ul>
                                        {val.ccpackages.map(
                                          (pack: any, index: any) => {
                                            return (
                                              <li key={index}>
                                                {pack.name}
                                                <br />
                                                DESCRIPTION : {pack.description}
                                                <br />
                                                TaT: {pack.tat}
                                                <br />
                                                Price: {pack.price}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              }
                            })}

                          <tr>
                            <td className="cell">{booking.customer_name}</td>
                            <td className="cell">
                              <ul>
                                {booking.packages &&
                                  booking.packages.map(
                                    (pack: any, index: any) => {
                                      return (
                                        <li key={index}>
                                          {pack.name}
                                          <br />
                                          DESCRIPTION : {pack.description}
                                          <br />
                                          TaT:{" "}
                                          {pack.package_city_prices
                                            ? pack.package_city_prices.tat_time
                                            : pack.tat}
                                          <br />
                                          Price:{" "}
                                          {is_price_hide
                                            ? "XXX"
                                            : pack.package_city_prices
                                            ? pack.package_city_prices
                                                .offer_price
                                            : pack.price}
                                        </li>
                                      );
                                    }
                                  )}
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ padding: "1rem", justifyContent: "right" }}
                  >
                    <div
                      className="priceDetails"
                      style={{ padding: "1rem 1rem 0 1rem" }}
                    >
                      <h6 className="totalPrice">
                        Price: ₹ {is_price_hide ? "XXX" : Math.floor(price)}{" "}
                        {is_price_hide ? "XXX" : extraCharge ? "+ 200" : ""}
                      </h6>
                      <h6 className="totalPrice">
                        Total Amount: ₹{" "}
                        {is_price_hide === true ? "XXX" : totalPrice}
                      </h6>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Dialog>
          ) : (
            <Dialog fullScreen open={open} onClose={handleClose}>
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <GridCloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    Edit Family Member
                  </Typography>
                  {edit ? (
                    <Button
                      autoFocus
                      color="inherit"
                      onClick={updateFamilyMemberHandler}
                      disabled={packageDetails?.length === 0}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      autoFocus
                      color="inherit"
                      onClick={addFamilyMemberHandler}
                    >
                      Save
                    </Button>
                  )}
                </Toolbar>
              </AppBar>
              <Grid
                container
                spacing={3}
                style={{ margin: "1rem auto", padding: "0 1rem" }}
              >
                <Grid item xs={12} md={6}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Title</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Select
                        className="input"
                        name="customer_designation"
                        variant="outlined"
                        value={customer_designation}
                        style={{ width: "100%", margin: "0" }}
                        onChange={(e) =>
                          setCustomerDesignation(e.target.value as String)
                        }
                        required
                      >
                        <MenuItem value={"Mr"}>Mr</MenuItem>
                        <MenuItem value={"Mrs"}>Mrs</MenuItem>
                        <MenuItem value={"Master"}>Master</MenuItem>
                        <MenuItem value={"Miss"}>Miss</MenuItem>
                        <MenuItem value={"Smt"}>Smt</MenuItem>
                        <MenuItem value={"Dr."}>Dr.</MenuItem>
                        <MenuItem value={"Baby or Just Born"}>
                          Baby or Just Born
                        </MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Customer Name</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <input
                        className="input"
                        name="collection_date"
                        type="name"
                        placeholder="Customer Name"
                        value={customer_name}
                        onChange={(e) => setCustomer_name(e.target.value)}
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "18.5px 14px",
                        }}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Customer Aadhaar</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <input
                        name="collection_date"
                        type="number"
                        placeholder="Customer Aadhaar "
                        value={customer_aadhar}
                        onChange={(e) => setCustomer_aadhar(e.target.value)}
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "18.5px 14px",
                        }}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Customer Passport</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        className="input"
                        name="customer_additonal_passport"
                        type="text"
                        placeholder="Enter passport Number"
                        // onWheel={(e: any) => e.target.blur()}
                        // onInput={(e: any) => {
                        //   e.target.value = Math.max(0, parseInt(e.target.value))
                        //     .toString()
                        //     .slice(0, 12);
                        // }}
                        value={customer_passport}
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                        onChange={(e) =>
                          setCustomer_passport(e.target.value as String)
                        }
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <p>Bill Id</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        name="bill_id"
                        type="text"
                        placeholder="Bill Id"
                        //disabled={bill_id !== null}
                        value={bill_id}
                        variant="outlined"
                        // disabled={bill_id !== null}
                        style={{ margin: "0", width: "100%" }}
                        onChange={(e) => set_BillId(e.target.value as String)}
                        // onSubmit={(e)=>setBillId(bill_id)}
                        // required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Packages</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Autocomplete
                        id="package"
                        key="1"
                        onChange={(event, newValue: any, reason: any) => {
                          let changeReason = !!reason && reason ? reason : "";
                          if (newValue) {
                            let packs: any = [];
                            newValue.map((val: any) => {
                              let obj = JSON.parse(
                                JSON.stringify(val, null, " ")
                              );
                              packs.push(obj);
                            });
                            handlePackages(packs, changeReason);
                          }
                        }}
                        multiple
                        options={packageList.results}
                        value={packageDetails}
                        disableClearable
                        limitTags={0}
                        getOptionLabel={(option: any) =>
                          option.name &&
                          option.name.toString() + "(" + option?.code + ")"
                        }
                        getOptionDisabled={(option: any) => {
                          const packID =
                            packageDetails &&
                            packageDetails.map((pack: any) => pack.id);
                          return packID.includes(option.id);
                        }}
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            getPackage(`${newInputValue}&city_id=${cityId}`);
                          }, 1000);
                        }}
                        freeSolo
                        disableCloseOnSelect
                        renderTags={(value: string[], getTagProps) =>
                          value.map((option: any, index: number) => (
                            <Chip
                              variant="outlined"
                              color="primary"
                              label={
                                option.name &&
                                option?.name.toString() +
                                  "(" +
                                  option?.code +
                                  ")"
                              }
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Package"
                            variant="outlined"
                            style={{ width: "100%", margin: "0", padding: "0" }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Customer Age</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <input
                        name="collection_date"
                        type="number"
                        placeholder="Customer Age"
                        value={customer_age}
                        onChange={(e) => setCustomer_age(e.target.value)}
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "18.5px 14px",
                        }}
                        disabled={dob !== "" && dob !== null}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Customer DOB</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        name="dateOfBirth"
                        type="date"
                        variant="outlined"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                        fullWidth
                        inputProps={{
                          max: moment()
                            .subtract(1, "days")
                            .format("YYYY-MM-DD"),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <p>Customer Gender</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Select
                        className="input"
                        name="customer_gender"
                        variant="outlined"
                        value={customer_gender}
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        onChange={(e) => setCustomer_gender(e.target.value)}
                        required
                      >
                        <MenuItem selected hidden value={"none"}>
                          Please Select
                        </MenuItem>
                        <MenuItem value={"male"}>Male</MenuItem>
                        <MenuItem value={"female"}>Female</MenuItem>
                        <MenuItem value={"other"}>Other</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <p>SRF Number</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        name="srfNo"
                        type="text"
                        value={srfNo}
                        placeholder="SRF No."
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                        onChange={(e) => setSrfNo(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <p>Sample Receiving Date & Time</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        type="datetime-local"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={sampleReceivingDate}
                        variant="outlined"
                        onChange={(e) => setSampleReceivingDate(e.target.value)}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <p>Sample Collection Date & Time</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        type="datetime-local"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={sampleCollectionDate}
                        variant="outlined"
                        onChange={(e) =>
                          setSampleCollectionDate(e.target.value)
                        }
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Card
                    className="sampleCollectionTimeFamily"
                    style={{ overflow: "auto" }}
                  >
                    <p style={{ marginBottom: "1rem" }}>
                      Sample Collection Time
                    </p>
                    <div className={classes.slotContainer}>
                      {timeSlots &&
                        timeSlots.length > 0 &&
                        timeSlots.map((slot: any) => {
                          return (
                            slot.available_slots > 0 && (
                              <Card
                                key={slot.id}
                                className={
                                  slot.id === selectedSlot
                                    ? classes.selectedSlot
                                    : classes.slot
                                }
                                style={{ cursor: "crosshair" }}
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "black",
                                    fontSize: "14px",
                                  }}
                                >
                                  {slot.start_time}-{slot.end_time}
                                </p>
                                {/* <p style={{ textAlign: "center", color: "black" }}>{moment(`${slot.start_time}`, ["HH.mm.ss"]).format("hh:mm A")} - {moment(`${slot.end_time}`, ["HH.mm.ss"]).format("hh:mm A")}</p> */}
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "green",
                                  }}
                                >
                                  {" "}
                                  Availabe slots: {slot.available_slots}
                                </p>
                              </Card>
                            )
                          );
                        })}
                    </div>
                  </Card>
                </Grid>

                {/* <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                  <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    spacing={3}
                    style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}
                  >
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <p>Bill Id</p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <TextField
                        name="bill_id"
                        type="text"
                        placeholder="Bill Id"
                        disabled={bill_id !== null}
                        value={bill_id}
                        variant="outlined"
                        // disabled={bill_id !== null}
                        style={{ margin: "0", width: "100%" }}
                        onChange={(e) => set_BillId(e.target.value as String)}
                        // onSubmit={(e)=>setBillId(bill_id)}
                        // required
                      />
                    </Grid>
                  </Grid>
                </Grid> */}
                <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ padding: "1rem", justifyContent: "right" }}
                  >
                    <div className="priceDetails" style={{ padding: "1rem" }}>
                      <table style={{ width: "100%", margin: "0" }}>
                        <thead>
                          <tr>
                            <td className="cell">Customer Name</td>
                            <td className="cell">Packages</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="cell">{customer_name}</td>
                            <td className="cell">
                              <ul>
                                {packageDetails.map((pack: any, index: any) => {
                                  return (
                                    <li key={index}>
                                      {pack.name}
                                      <br />
                                      DESCRIPTION : {pack.description}
                                      <br />
                                      TaT:{" "}
                                      {pack.package_city_prices
                                        ? pack.package_city_prices.tat_time
                                        : pack.tat_time}
                                      <br />
                                      Price:{" "}
                                      {pack.package_city_prices
                                        ? pack.package_city_prices.offer_price
                                        : pack.offer_price}
                                    </li>
                                  );
                                })}
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <td className="cell">{booking.customer_name}</td>
                            <td className="cell">
                              <ul>
                                {booking.packages &&
                                  booking.packages.map(
                                    (pack: any, index: any) => {
                                      return (
                                        <li key={index}>
                                          {pack.name}
                                          <br />
                                          DESCRIPTION : {pack.description}
                                          <br />
                                          TaT:{" "}
                                          {pack.package_city_prices
                                            ? pack.package_city_prices.tat_time
                                            : pack.tat_time}
                                          <br />
                                          Price:{" "}
                                          {pack.package_city_prices
                                            ? pack.package_city_prices
                                                .offer_price
                                            : pack.offer_price}
                                        </li>
                                      );
                                    }
                                  )}
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ padding: "1rem", justifyContent: "right" }}
                  >
                    <div
                      className="priceDetails"
                      style={{ padding: "1rem 1rem 0 1rem" }}
                    >
                      <h6 className="totalPrice">
                        Price: ₹ {Math.floor(price)}{" "}
                        {extraCharge ? "+ 200" : ""}
                      </h6>
                      <h6 className="totalPrice">
                        Total Amount: ₹ {totalPrice}
                      </h6>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Dialog>
          )}
          {/* <RaiseComplaintModal
            open={raiseComplaint}
            setOpen={setRaiseComplaint}
            bookingId={Number(bookingId)}
            leadId={0}
          /> */}
          {assignBooking && (
            <Paper className={classes.paper} elevation={15}>
              <h3 className={classes.heading} style={{ textAlign: "center" }}>
                Assign Booking To Doctor
              </h3>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justify="space-between"
                spacing={3}
                style={{ margin: "1rem auto" }}
              >
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="doctor"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setDoctor(obj.id);
                      }
                    }}
                    options={agentsList.results || []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    getOptionLabel={(option: any) =>
                      option.user && option.user.name
                    }
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getAgentList(`?code=${newInputValue}&usergroup=Doctor`);
                      }, 1000);
                      if (newInputValue.length === 0) {
                        setDoctor(0);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Doctor Name"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ height: "50px" }}
                    onClick={handleAssignDoctor}
                  >
                    Assign
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          )}
          {booking?.center?.center_type === "collection_center" ||
          booking?.center?.center_type === "direct_client" ||
          booking?.center?.center_type === "GE Lab" ? (
            <Grid container>
              <Grid
                sm={6}
                style={{ fontSize: 18, fontWeight: "bold", padding: 5 }}
              >
                Centre: {booking?.centre_address?.name} (
                {booking?.centre_address?.code})
              </Grid>
              <Grid sm={6}></Grid>
            </Grid>
          ) : (
            ""
          )}
          {loading ? (
            <Loader />
          ) : (
            <>
              {barcodeId !== "" ? (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHead}>
                          Booking Id
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Bill Id
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Customer Name
                        </TableCell>
                        <TableCell className={classes.tableHead}>Age</TableCell>
                        <TableCell className={classes.tableHead}>
                          Booking Member
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Package Name
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Aadhaar No
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Payment Source
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Payment Mode
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Payment Information
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Sync
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={1}>
                        <TableCell align="center">
                          {bookingByBarcode?.id}
                        </TableCell>
                        <TableCell align="center">
                          {bookingByBarcode?.bill_id !== null ||
                          bookingByBarcode?.bill_id !== ""
                            ? bookingByBarcode?.bill_id
                            : "NA"}
                        </TableCell>
                        <TableCell align="center">
                          {bookingByBarcode?.customer_name}
                        </TableCell>
                        <TableCell align="center">
                          {bookingByBarcode?.customer_age}
                        </TableCell>
                        <TableCell align="center">
                          {bookingByBarcode?.type}
                        </TableCell>
                        <TableCell align="center">
                          <ul>
                            {bookingByBarcode?.packages &&
                              bookingByBarcode?.packages.map(
                                (pack: any, index: any) => {
                                  return (
                                    <li
                                      key={index}
                                      style={{
                                        margin: "1rem 0",
                                        textAlign: "left",
                                      }}
                                    >
                                      {pack.name}, {pack.tat_time}
                                    </li>
                                  );
                                }
                              )}
                          </ul>
                        </TableCell>
                        <TableCell align="center">
                          {bookingByBarcode?.customer_aadhar}
                        </TableCell>
                        <TableCell align="center">
                          {bookingByBarcode?.payment_source}
                        </TableCell>
                        <TableCell align="center">
                          {bookingByBarcode?.payment_mode}
                        </TableCell>

                        <TableCell align="center">
                          <LocalAtmIcon
                            onClick={() =>
                              handlePaymentInfo(
                                bookingByBarcode?.type === "addbooking"
                                  ? bookingByBarcode?.bookingid
                                  : bookingByBarcode?.id
                              )
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          {bookingByBarcode?.bill_id === null ||
                          (bookingByBarcode?.bill_id === "" &&
                            bookingByBarcode?.pickup_status === "confirmed") ? (
                            <Button
                              style={{
                                marginLeft: "1rem",
                                marginBottom: "1rem",
                                backgroundColor: "#2962ff",
                              }}
                              disabled={bookingByBarcode?.is_sync}
                              variant="contained"
                              color="inherit"
                              onLoad={syncLoading}
                              onClick={() =>
                                handleSync(
                                  bookingByBarcode?.id,
                                  bookingByBarcode
                                )
                              }
                            >
                              Sync
                            </Button>
                          ) : (
                            "Booking Already Synced"
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (booking &&
                  booking?.center &&
                  booking?.center?.center_type === "collection_center") ||
                (booking &&
                  booking?.center &&
                  booking?.center?.center_type === "direct_client") ||
                (booking &&
                  booking?.center &&
                  booking?.center?.center_type === "GE Lab") ? (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHead}>
                          Booking Id
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Bill Id
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Customer Name
                        </TableCell>
                        <TableCell className={classes.tableHead}>Age</TableCell>
                        <TableCell className={classes.tableHead}>
                          Booking Member
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Package Name
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Aadhaar No
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Payment Source
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Payment Mode
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Payment Information
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Sync
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={1}>
                        <TableCell align="center">{booking?.pk}</TableCell>
                        <TableCell align="center">
                          {booking?.bill_id !== null || booking?.bill_id !== ""
                            ? booking?.bill_id
                            : "NA"}
                        </TableCell>
                        <TableCell align="center">
                          {booking?.customer_name}
                        </TableCell>
                        <TableCell align="center">
                          {/* {booking?.customer_age} */}
                          {booking?.customer_age === 0
                            ? handleDOBForAge(
                                booking?.dob,
                                booking?.booking_date
                              )
                            : booking?.customer_age}
                        </TableCell>
                        <TableCell align="center">{booking?.type}</TableCell>
                        <TableCell align="center">
                          <ul>
                            {booking?.packages &&
                              booking?.packages.map((pack: any, index: any) => {
                                return (
                                  <li
                                    key={index}
                                    style={{
                                      margin: "1rem 0",
                                      textAlign: "left",
                                    }}
                                  >
                                    {pack.name}, {pack.tat_time}
                                  </li>
                                );
                              })}
                          </ul>
                        </TableCell>
                        <TableCell align="center">
                          {booking?.customer_aadhar}
                        </TableCell>
                        <TableCell align="center">
                          {booking?.payment_source}
                        </TableCell>
                        <TableCell align="center">
                          {booking?.payment_mode}
                        </TableCell>

                        <TableCell align="center">
                          <LocalAtmIcon
                            onClick={() =>
                              handlePaymentInfo(
                                booking?.type === "addbooking"
                                  ? booking?.bookingid
                                  : booking?.pk
                              )
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          {!booking?.is_sync ? (
                            <Button
                              style={{
                                marginLeft: "1rem",
                                marginBottom: "1rem",
                                backgroundColor: "#2962ff",
                              }}
                              disabled={booking?.is_sync}
                              variant="contained"
                              color="inherit"
                              onClick={() =>
                                handleSyncCCDC(booking?.pk, booking)
                              }
                            >
                              RECEIVE
                            </Button>
                          ) : (
                            "Booking Already Recieved"
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                ""
              )}
            </>
          )}

          <Paper className={classes.paper} elevation={15}>
            {loading ? (
              <Loader />
            ) : (
              <>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  justify="space-between"
                  spacing={3}
                  style={{ margin: "1rem auto" }}
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      alignItems="flex-start"
                      justify="space-between"
                      spacing={3}
                      style={{ margin: "1rem auto" }}
                    >
                      <Grid item xs={6} sm={6} md={6}>
                        <h3
                          style={{
                            paddingTop: "10px",
                            fontSize: "24px",
                            fontWeight: "bold",
                          }}
                        >
                          BOOKING DETAILS
                        </h3>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <h3
                          style={{
                            paddingTop: "10px",
                            fontSize: "24px",
                            fontWeight: "bold",
                            textAlign: "right",
                          }}
                        >
                          BOOKING (ID: {bookingId})
                        </h3>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <h6>Collection Date</h6>
                    <p style={{ fontWeight: "normal" }}>
                      {booking.collection_date}
                    </p>
                  </Grid>
                  {/* <Grid item xs={6} md={3}>
                    <h6>Pincode</h6>
                    <p style={{ fontWeight: "normal" }}>
                      {booking.customer_areapincode &&
                        booking.customer_areapincode.pincode}
                    </p>
                  </Grid> */}
                  <Grid item xs={6} md={3}>
                    <h6>Collection Time</h6>
                    <p style={{ fontWeight: "normal" }}>
                      {booking.collection_slot &&
                        moment(
                          `${booking.collection_slot.slot.split("-")[0]}`,
                          ["HH.mm.ss"]
                        ).format("hh:mm A")}
                      {" - "}
                      {booking.collection_slot &&
                        moment(
                          `${booking.collection_slot.slot.split("-")[1]}`,
                          ["HH.mm.ss"]
                        ).format("hh:mm A")}
                    </p>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <h6>Org Id</h6>
                    <p style={{ fontWeight: "normal" }}>{booking?.org_code}</p>
                  </Grid>
                </Grid>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHead}>
                          S No.
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Customer Name
                        </TableCell>
                        <TableCell className={classes.tableHead}>DOB</TableCell>
                        <TableCell className={classes.tableHead}>Age</TableCell>
                        <TableCell className={classes.tableHead}>
                          Gender
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Package Name
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Partner OrderId
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Aadhaar No
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Edit
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={1}>
                        <TableCell align="center">1.</TableCell>
                        <TableCell align="center">{`${
                          booking.designation || ""
                        } ${booking.customer_name}`}</TableCell>
                        <TableCell align="center">
                          {booking?.dob !== "" && booking?.dob !== null
                            ? booking?.dob
                            : ""}
                        </TableCell>
                        <TableCell align="center">
                          {/* {booking.customer_age} */}
                          {booking?.customer_age === 0
                            ? handleDOBForAge(
                                booking?.dob,
                                booking?.booking_date
                              )
                            : booking?.customer_age}
                        </TableCell>
                        <TableCell align="center">
                          {booking.customer_gender}
                        </TableCell>
                        <TableCell align="center">
                          <ul>
                            {booking.packages &&
                              booking.packages.map((pack: any, index: any) => {
                                return (
                                  <li
                                    key={index}
                                    style={{
                                      margin: "1rem 0",
                                      textAlign: "left",
                                    }}
                                  >
                                    {/* {pack.name}, {pack.tat_time} */}
                                    {pack.name && pack.package_city_prices
                                      ? pack.name.toString() +
                                        ", " +
                                        pack.package_city_prices.tat_time
                                      : pack.name +
                                        ", " +
                                        (pack.tat_time
                                          ? pack.tat_time
                                          : pack.tat)}
                                  </li>
                                );
                              })}
                          </ul>
                        </TableCell>
                        <TableCell align="center">
                          {booking.partner && booking.partner.id}
                        </TableCell>
                        <TableCell align="center">
                          {booking.customer_aadhar || ""}
                        </TableCell>
                        <TableCell align="center">
                          <Link
                            to={`/dashboard/la/booking-edit/${booking.pk}/edit`}
                            style={{ textDecoration: "none" }}
                          >
                            <Button color="primary" variant="text">
                              Edit
                            </Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                      {booking.additional_members &&
                        booking.additional_members.length > 0 &&
                        booking.additional_members.map(
                          (member: any, index: number) => {
                            return (
                              <TableRow key={index + 1}>
                                <TableCell align="center">
                                  {index + 2}.
                                </TableCell>
                                <TableCell align="center">
                                  {`${member.designation || ""} ${
                                    member.customer_name
                                  }`}
                                </TableCell>
                                <TableCell align="center">
                                  {member?.dob !== "" && member?.dob !== null
                                    ? member?.dob
                                    : ""}
                                </TableCell>
                                <TableCell align="center">
                                  {member.customer_age}
                                </TableCell>
                                <TableCell align="center">
                                  {member.customer_gender}
                                </TableCell>
                                <TableCell align="center">
                                  <ul>
                                    {member.packages &&
                                      member.packages.map(
                                        (pack: any, index: any) => {
                                          return (
                                            <li
                                              key={index}
                                              style={{
                                                margin: "0 0 1rem 0",
                                                textAlign: "left",
                                              }}
                                            >
                                              {/* {pack.name}, {pack.price} */}
                                              {pack.name &&
                                              pack.package_city_prices
                                                ? pack.name.toString() +
                                                  ", " +
                                                  pack.package_city_prices
                                                    .tat_time
                                                : pack.name +
                                                  ", " +
                                                  pack.tat_time}
                                            </li>
                                          );
                                        }
                                      )}
                                  </ul>
                                </TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center">
                                  {member.customer_aadhar}
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    color="primary"
                                    variant="text"
                                    onClick={() => handleEditMember(member)}
                                  >
                                    Edit
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      <TableRow></TableRow>
                      <TableRow>
                        <TableCell align="right" colSpan={10}>
                          Package Price
                        </TableCell>
                        <TableCell align="center">
                          {booking.discounted_price &&
                            booking.discounted_price.total_price_package &&
                            Math.floor(
                              booking.discounted_price.total_price_package
                            )}
                          /-
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="right" colSpan={10}>
                          Phlebo Cost
                        </TableCell>
                        <TableCell align="center">
                          {booking.discounted_price &&
                            booking.discounted_price.phlebo_cost &&
                            Math.floor(booking.discounted_price.phlebo_cost)}
                          /-
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="right" colSpan={10}>
                          Discount
                        </TableCell>
                        <TableCell align="center">
                          {booking.discounted_price &&
                            booking.discounted_price.counpon_discount &&
                            Math.floor(
                              booking.discounted_price.counpon_discount
                            )}
                          /-
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="right" colSpan={10}>
                          RedCash Discount
                        </TableCell>
                        <TableCell align="center">
                          {booking?.redcash_discounted_amount}/-
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={10} align="right">
                          <h6
                            style={{
                              padding: "5px",
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                          >
                            Total Amount
                          </h6>
                        </TableCell>
                        <TableCell align="center">
                          <h6
                            style={{
                              padding: "5px",
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                          >
                            {booking.discounted_price &&
                              booking.discounted_price.final_total_price &&
                              Math.floor(
                                booking.discounted_price.final_total_price
                              ) - Math.floor(booking.redcash_discounted_amount)}
                            /-
                          </h6>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid
                  container
                  spacing={4}
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{ margin: "1rem auto" }}
                >
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Referring Doctor</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        type="text"
                        value={booking?.ref_doctor?.name || ""}
                        disabled
                        className="input"
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Dr.
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>AgentName</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        className="input"
                        name="agentName"
                        type="text"
                        value={(booking.agent && booking.agent.name) || ""}
                        disabled
                        variant="outlined"
                        style={{ margin: "0", width: "100%", height: "40px" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>
                        Phlebo
                        {booking.phlebo && booking.phlebo.name ? (
                          <CallIcon
                            onClick={() =>
                              handleCallClick(Number(bookingId), "phlebo")
                            }
                          />
                        ) : (
                          ""
                        )}
                      </p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        className="input"
                        name="phlebo"
                        type="text"
                        value={(booking.phlebo && booking.phlebo.name) || ""}
                        disabled
                        variant="outlined"
                        style={{ margin: "0", width: "100%", height: "40px" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>
                        Mobile Number
                        <CallIcon
                          onClick={() =>
                            handleCallClick(Number(bookingId), "call")
                          }
                        />
                      </p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        className="input"
                        name="customer_phonenumber"
                        type="text"
                        value={booking.customer_phonenumber || ""}
                        disabled
                        variant="outlined"
                        style={{ margin: "0", width: "100%", height: "40px" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>
                        Alternate Mobile Number
                        <CallIcon
                          onClick={() =>
                            handleCallClick(Number(bookingId), "contact")
                          }
                        />
                      </p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        className="input"
                        name="customer_alternatenumber"
                        type="text"
                        value={booking.customer_altphonenumber || ""}
                        disabled
                        variant="outlined"
                        style={{ margin: "0", width: "100%", height: "40px" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Email</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        className="input"
                        name="customer_email"
                        type="email"
                        disabled
                        value={booking.customer_email || ""}
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>
                        Whatsapp Number
                        <CallIcon
                          onClick={() =>
                            handleCallClick(Number(bookingId), "whatsapp")
                          }
                        />
                      </p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        className="input"
                        name="customer_whatsapppnumber"
                        type="text"
                        disabled
                        value={booking.customer_whatsapppnumber || ""}
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Aadhaar Number</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        className="input"
                        name="customer_aadhar"
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                        onInput={(e: any) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 16);
                        }}
                        disabled
                        value={booking.customer_aadhar || ""}
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <p>Passport Number</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        className="input"
                        name="Passport"
                        type="text"
                        disabled
                        value={booking.passport_number || ""}
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Finance Amount</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        className="input"
                        name="financeamount"
                        type="text"
                        value={booking.finance_amount || ""}
                        disabled
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Finance Status</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        className="input"
                        name="financestatus"
                        type="text"
                        value={booking.finance_status || ""}
                        disabled
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Finance Remarks</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        className="input"
                        name="financeremark"
                        type="text"
                        value={booking.finance_remarks || ""}
                        disabled
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>History/Remarks</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={3}
                        maxLength={1000}
                        placeholder=" "
                        value={booking.remarks || ""}
                        className="input"
                        style={{ width: "100%", margin: "0", height: "37px" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={4}
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{ margin: "1rem auto" }}
                >
                  <Grid item xs={12}>
                    <h3
                      style={{
                        borderTop: "dotted 1px #cccc",
                        paddingTop: "10px",
                        fontSize: "24px",
                        fontWeight: "bold",
                      }}
                    >
                      Address
                    </h3>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>City</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        className="input"
                        name="city"
                        type="text"
                        value={
                          (pin_code.results &&
                            pin_code.results.length > 0 &&
                            pin_code.results[0].city) ||
                          ""
                        }
                        disabled
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>locality</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        className="input"
                        name="customer_landmark"
                        type="text"
                        value={booking.customer_landmark || ""}
                        disabled
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>House No./Plot No./Flat No./Door No./Shop/ c/o</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        className="input"
                        name="customer_address"
                        type="text"
                        value={
                          (booking.customer_address &&
                            booking.customer_address) ||
                          ""
                        }
                        disabled
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>
                        Apartment/Building /Colony/ Block/Sector/
                        Street/Gali/Road/Chawl
                      </p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        className="input"
                        name="customer_address"
                        type="text"
                        value={
                          (booking?.address_line2 && booking?.address_line2) ||
                          ""
                        }
                        disabled
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Landmark/Sublocality</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        className="input"
                        name="customer_address"
                        type="text"
                        value={(booking?.landmark && booking?.landmark) || ""}
                        disabled
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  {/* <Grid
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    container
                    item
                    xs={12}
                    md={6}
                    spacing={3}
                  >
                    <Grid item xs={12} md={5}>
                      <p>Pincode</p>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        name="pincode"
                        type="text"
                        disabled
                        value={
                          (booking.customer_areapincode &&
                            booking.customer_areapincode.pincode) ||
                          ""
                        }
                        variant="outlined"
                        style={{ margin: "0", width: "100%" }}
                      />
                    </Grid>
                  </Grid> */}
                </Grid>
                <Grid
                  container
                  spacing={4}
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{ margin: "1rem auto" }}
                >
                  {booking.payment_image &&
                    booking.payment_image.map((image: any) => {
                      return (
                        <Grid
                          key={image.id}
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          spacing={3}
                        >
                          <img
                            src={image.image}
                            alt="payment"
                            style={{ width: "100%", aspectRatio: "1/1" }}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </>
            )}
          </Paper>
          {/* <EmailSMSDetailsModal
            loading={loading}
            openEmailSMSModal={openEmailSMSModal}
            setOpenEmailSMSModal={setOpenEmailSMSModal}
            emailDetails={emailDetails}
            smsDetails={smsDetails} open={false} setOpen={setOpen}
          /> */}
          <ReportModal
            loading={loading}
            openReportModal={openReportModal}
            setReportModal={setReportModal}
            reportStatus={reportStatus}
          />
          <MainMemberSyncModal
            openMainMeberSyncModal={openMainMeberSyncModal}
            setMainMemberSyncModal={setMainMemberSyncModal}
            bookingByBarcode={bookingByBarcode}
            syncId={syncId}
            barcodeId={barcodeId}
            syncLoading={syncLoading}
            getBookingByBarcode={getBookingByBarcode}
          />
          <SyncModal
            openSyncModal={openSyncModal}
            setSyncOpenModal={setSyncOpenModal}
            syncId={syncId}
            bookingData={bookingData}
            getAllBookings={getBookingById}
            filterBookings={""}
          />
        </div>
        <PaymentModal
          openModalP={openPaymentModal}
          setOpenModalP={setOpenPaymentModal}
          paymentInformation={paymentInformation}
        />
        <AddDiscriptionModal
          open={raiseComplaint}
          setOpen={setRaiseComplaint}
          bookingId={Number(bookingId)}
          bookingIdList={bookingIdList}
          leadId={0}
        />
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  booking: state.LimsAdminReducer.booking,
  agentsList: state.LimsAdminReducer.agentsList,
  pin_code: state.LimsAdminReducer.pin_code,
  booking_slots: state.LimsAdminReducer.booking_slots,
  packageList: state.LimsAdminReducer.packageList,
  additional_booking: state.LimsAdminReducer.additional_booking,
  emailDetails: state.LimsAdminReducer.emailDetails,
  smsDetails: state.LimsAdminReducer.smsDetails,
  reportStatus: state.LimsAdminReducer.reportStatus,
  reportDownload: state.LimsAdminReducer.reportDownload,
  qrcode: state.LimsAdminReducer.qrcode,
  bookingByBarcode: state.LimsAdminReducer.bookingByBarcode,
  loading: state.LimsAdminReducer.loading,
  paymentInformation: state.LimsAdminReducer.paymentInformation,
  hivpackageList: state.LimsAdminReducer.hivpackageList,
  biomarkerScreening: state.LimsAdminReducer.biomarkerScreening,
  resamplingPackages: state.LimsAdminReducer.resamplingPackages,
  syncLoading: state.LimsAdminReducer.syncLoading,
  biospypackages: state.LimsAdminReducer.biospypackages,
  histopathologyBooking: state.LimsAdminReducer.histopathologyBooking,
  bookinghistoryform: state.LimsAdminReducer.bookinghistoryform,
  bookingLoading: state.LimsAdminReducer.bookingLoading,
  bookingComplaints: state.LimsAdminReducer.bookingComplaints,
});

export default connect(mapStateToProps, {
  getBookingById,
  getPinCode,
  resetBookings,
  updateBooking,
  getAgentList,
  assignDoctor,
  getAvailableSlots,
  getPackage,
  updateAdditionalBooking,
  clickToCallBooking,
  getSmsReportSend,
  resampleBooking,
  raiseNewTicektDisposition,
  getSMSLink,
  getSyncData,
  getReportStatus,
  getReportDownload,
  getQRCodeData,
  getBookingByBarcode,
  getHivPackage,
  getPaymentInfo,
  getBiomarkerScreening,
  getResamplingPackages,
  getBookingByHistoryForm,
  getBookingQueryTickets,
})(ViewBooking);
