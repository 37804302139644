import React  from "react";
import { connect } from "react-redux";
import {
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import {
  TextField
} from "@material-ui/core";
import Button from '@mui/material/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useNavigate } from "react-router";
import { changeTicketStatus, addTicketComment } from "../../actions/LimsAdminActions";
import ModeEditOutlineTwoToneIcon from '@mui/icons-material/ModeEditOutlineTwoTone';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: 500,
      // height: 500,
    },
  })
);
interface Props {
  changeTicketStatus: any;
  addTicketComment: any;
  loading: boolean;
}
type ModalProps = {
  id: number;
  changeTicketStatus: any;
  addTicketComment: any;
  note: any;
};

const ChangeStatusModal: React.FC<ModalProps> = ({
  id,
  changeTicketStatus,
  addTicketComment,
  note,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [remark, setRemark] = React.useState<any>("");
  const handleChange = (event: any) => {
    setStatus(event.target.value);
  };


  const handleClickOpen = () => {
    setOpen(true);
  };
//   const raiseComplaint = () => {
//     const body: any = {
//         status,
//         _remarks: remark,
//     }
//     if(remark===""){

        
//     }else{
//         changeTicketStatus(body, id)
//         setRemark("")
//         // handleSubmit()
//     }
// };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (status === "" ? false :  true) {
      const data: any = {
        status,
        remarks: remark,
      };
      let body = {
        remark,
        id: id,
        message: remark,
        ticket: id,
      };
      if (body.message !== ""){
        await addTicketComment(body);
        setRemark("");
      }
      await changeTicketStatus(data, id);
      if (note==="helpdesk")  {
        navigate("/dashboard/la/helpdesk");
      } else if (note==="received_ticket")  {
        // navigate("/dashboard/la/received_ticket");
      }
      setOpen(false);
    }
  };
  const handleCancel = async (e: any) => {
    setStatus("");
    setRemark("");
    setOpen(false);
  }

  return (
    <div>
      <Button variant="contained" color="primary"style={{ marginRight: "5px" }} onClick={handleClickOpen}><ModeEditOutlineTwoToneIcon /></Button>
      <Dialog open={open} onClose={handleCancel}>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="demo-dialog-native">Status</InputLabel>
              <Select
                className="input"
                native
                value={status}
                onChange={handleChange}
                input={<Input id="demo-dialog-native" />}
              >
                <option aria-label="None" value="" />
                <option value="To Do">To Do</option>
                <option value="In Review">In Review</option>
                <option value="Reopen">Reopen</option>
                <option value="In Quality assessment">In Quality Assessment</option>
                <option value="Done">Done</option>
                <option value="Rejected">Rejected</option>
                <option value="No Issue Found">No Issue Found</option>
              </Select>
              <div className="container" style={{ marginTop: "40px" }}>
                <TextField 
                    placeholder="Write Remarks"
                    className="input"
                    label="Remarks"
                    name="remark"
                    type="text"
                    value={remark}
                    variant="outlined"
                    multiline
                    rows={5}
                    onChange={(e) => setRemark(e.target.value as string)}
                    style={{ width: "100%" }}
                >

                </TextField>
              </div>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={status === ""}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {
  changeTicketStatus,
  addTicketComment,
})(ChangeStatusModal);