import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import image from "../../../../images/logo1.png";
import {
  getBookingByNbsForm,
  getBookingById,
} from "../../../actions/LimsAdminActions";
import NbsForm from "../../Forms/nbsForm";
import {
  Button,
  Dialog,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  MenuItem,
  TextField,
  FormGroup,
  Radio,
  InputAdornment,
  TextareaAutosize,
  Select,
} from "@material-ui/core";
import { connect } from "react-redux";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
const useStyles = makeStyles({
  table: {
    height: "40px",
  },
  left: {
    margin: 600,
    width: "50%",
  },
  fontsize13: {
    fontSize: "13px",
  },
  rightContentHeader: {
    float: "left",
    textAlign: "left",
    paddingRight: "20px",
  },
  style: {
    background: "#AD70A6",
    color: "#fff",
    padding: 10,
    marginLeft: 5,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
    marginTop: "40px",
  },
});
interface Props {
  match: any;
  booking: any;
  getBookingById: any;
  getBookingByNbsForm: any;
  bookingnbsform: any;
}
const NbsFormView: React.FC<Props> = ({
  match,
  booking,
  getBookingById,
  getBookingByNbsForm,
  bookingnbsform,
}) => {
  const bookingId = match?.params?.id;
  const [dataId, setDataId] = useState<any>("");
  const classes = useStyles();
  const componentRef = useRef<any>(null);
  const [name, setName] = useState<string>("");
  const [perPackNbs, setPerPackNbs] = useState<any>(1);
  const [data, setData] = useState<any>({
    birth_time: "",
    birth_time_hh: "",
    birth_time_mm: "",
    consanguinity: "",
    baby_weight: "",
    baby_admitted_in_nicu: "",
    blood_transfusion: "",
    transfusion_date: "",
    gestational_age_weeks: "",
    gestational_age_days: "",
    birth_order: "",
    type_of_feed: "",
    history: "",
    ch: "",
    cah: "",
    g6pd: "",
    gal: "",
    cp: "",
    pku: "",
    bio: "",
  });
  const [personName, setPersonName] = useState<any>("main");

  const [openHistoryForm, SetOpenHistoryForm] = useState<boolean>(false);

  const [perPackArr, setPerPackArr] = useState<any>([]);
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (bookingId) {
      getBookingByNbsForm(bookingId);
      getBookingById(bookingId);
    }
  }, [bookingId]);

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (bookingnbsform && bookingnbsform.length > 0) {
      let temp = bookingnbsform[0];

      let arr =
        (temp.birth_time &&
          temp.birth_time.split(":").length > 0 &&
          temp.birth_time.split(":")) ||
        [];
      if (arr.length > 0) {
        temp["birth_time_hh"] = arr[0];
        temp["birth_time_mm"] = arr[1];
      }
      setData(temp);
      setDataId(bookingnbsform[0].id);
    }
  }, [bookingnbsform]);
useEffect(() => {
 if(booking.packages){
  let perPack: any = 1;
  booking.packages?.map((list2: any) => {
      if (list2?.forms.length > 0 &&
        list2?.forms[0].form_name === "BabyScreen Form") {
        perPack =
          list2?.name.match(/\d+/g) !== null &&
          Number(list2?.name.match(/\d+/g)[0]) > perPack
            ? list2?.name.match(/\d+/g)[0]
            : perPack;
      }
  });
  setPerPackNbs(Number(perPack));
 }
}, [booking])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <Button variant="contained" color="secondary" style={{ width: 250 }}>
        Print NBS FORM
      </Button>
    );
  }, []);

  const createHistopathologyBookingModel = () => {
    SetOpenHistoryForm(true);
  };
  return (
    <>
      <div
        style={{
          width: 270,
        }}
      >
        <div style={{ height: "20px", marginTop: "35%" }}>
          <div className={classes.rightContentHeader}>
            <Select
              className="input"
              name="priority"
              variant="outlined"
              style={{
                width: "250px",
                marginBottom: "1rem",
                alignItems: "flex-start",
              }}
              value={personName}
              onChange={(e) => setPersonName(e.target.value)}
            >
              {/* <MenuItem disabled selected value={'none'}>Customer Name</MenuItem> */}
              <MenuItem value={"main"}>{booking?.customer_name}</MenuItem>
              {booking?.additional_members &&
                booking?.additional_members.length > 0 &&
                booking?.additional_members.map((data: any) => {
                  return (
                    <MenuItem value={data?.id}>{data?.customer_name}</MenuItem>
                  );
                })}
            </Select>
            <br />
            <ReactToPrint
              content={reactToPrintContent}
              trigger={reactToPrintTrigger}
              documentTitle={name + "-" + bookingId}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={createHistopathologyBookingModel}
              style={{ width: 250, marginTop: 15 }}
            >
              EDIT NBS FORM
            </Button>
          </div>
        </div>
      </div>
      <div
        style={{
          width: 842,
          background: "#f1f1f1",
          margin: "50px auto",
        }}
      >
        <div ref={componentRef} style={{ padding: 25 }}>
          <div
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <div style={{ width: "50%", textAlign: "left" }}>
              <h3>
                <img src={image} alt="logo" style={{ width: "150px" }} />
              </h3>
            </div>
            <div style={{ width: "50%", textAlign: "right" }}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                New Baby Screening Form
              </div>
            </div>
          </div>
          <hr />
          <Grid container spacing={2}>
            <Grid item container xs={6}>
              <Grid item xs={5}>
                Name<sup>*</sup>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  value={booking?.customer_name}
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <Grid item container xs={6}>
              <Grid item xs={5}>
                DOB and Time<sup>*</sup>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={booking?.dob}
                  variant="outlined"
                  fullWidth
                  type="date"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={data?.birth_time}
                />
              </Grid>
            </Grid>

            <Grid item container xs={6}>
              <Grid item xs={5}>
                Consangunity
              </Grid>
              <Grid item xs={7}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={data?.consanguinity}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Grid>
            </Grid>

            <Grid item container xs={6}>
              <Grid item xs={5}>
                Baby's weight
              </Grid>
              <Grid item xs={7}>
                <TextField
                  value={data?.baby_weight}
                  type="number"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">kg</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Grid item container xs={6}>
              <Grid item xs={5}>
                Baby admitted in NICU
              </Grid>
              <Grid item xs={7}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={data?.baby_admitted_in_nicu}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Grid>
            </Grid>

            <Grid item container xs={6}>
              <Grid item xs={5}>
                Any blood transfusion
              </Grid>
              <Grid item xs={7}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={data?.blood_transfusion}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Grid>
            </Grid>

            <Grid item container xs={6}>
              <Grid item xs={5}>
                Date of transfusion<sup>*</sup>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  value={data?.transfusion_date}
                  variant="outlined"
                  fullWidth
                  type="date"
                />
              </Grid>
            </Grid>

            <Grid item container xs={6}>
              <Grid item xs={5}>
                Gestational Age
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={data?.gestational_age_weeks}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">{`Weeks `}</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  value={data?.gestational_age_days}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">{`Days `}</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Grid item container xs={6}>
              <Grid item xs={5}>
                Birth Order
              </Grid>
              <Grid item xs={7}>
                <TextField
                  value={data?.birth_order}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid item container xs={6}>
              <Grid item xs={5}>
                Type of feed
              </Grid>
              <Grid item xs={7}>
                <TextField
                  value={data?.type_of_feed}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid item container xs={12}>
              <Grid item style={{ width: "20.5%" }}>
                Clinical & Family history
              </Grid>
              <Grid item style={{ width: "79.5%" }}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  style={{ width: "100%" }}
                  value={data?.history}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item style={{ width: "20.5%" }}>
                Select as per package
              </Grid>
              <Grid item style={{ width: "79.5%" }}>
                <FormGroup row={true}>
                  <FormControlLabel
                    control={<Checkbox checked={data?.ch} />}
                    label="CH"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={data?.cah} />}
                    label="CAH"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={data?.g6pd} />}
                    label="G6PD"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={data?.gal} />}
                    label="GAL"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={data?.cp} />}
                    label="CP"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={data?.pku} />}
                    label="PKU"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={data?.bio} />}
                    label="BIO"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog
        open={openHistoryForm}
        onClose={() => SetOpenHistoryForm(false)}
        fullWidth
        maxWidth="xl"
      >
        <NbsForm
          customerName={booking?.customer_name}
          dob={booking?.dob}
          data={data}
          setData={setData}
          setOpenNbsModal={SetOpenHistoryForm}
          dataId={dataId}
          perPackNbs={perPackNbs}
          perPackArr={perPackArr}
          setPerPackArr={setPerPackArr}
        />
      </Dialog>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  booking: state.LimsAdminReducer.booking,
  loading: state.LimsAdminReducer.loading,
  bookingnbsform: state.LimsAdminReducer.bookingnbsform,
});

export default connect(mapStateToProps, {
  getBookingByNbsForm,
  getBookingById,
})(NbsFormView);
