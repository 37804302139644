import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { getPackage, getPinCode } from "../actions/LimsAdminActions";
import "./filter.sass";
import {
  Paper,
  Grid,
  Typography,
  Card,
  TextField,
} from "@material-ui/core";
import './index.sass'
import Pagination from "@material-ui/lab/Pagination";
import Autocomplete from '@mui/material/Autocomplete';
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      width: "100%",
      padding: theme.spacing(1),
      display: "grid",
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    spacebtm: {
      marginBottom: 15,
    },
  })
);

interface Props {
  children: any;
  getPackage: any;
  packageList: any;
  getPinCode: any;
  pin_code: any;
}

const LeadsPage: React.FC<Props> = ({
  getPackage,
  packageList,
  getPinCode,
  pin_code,
}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [pincode, setPinCode] = useState<String>("");
  const [cityId, setCityId] = useState<any>("");
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getPackage();
    // getPinCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [page, setPage] = useState(1);

  const handlePage = (event: any, value: any) => {
    getPackage(`page=${value}`);
    setPage(value);
  };
  const handlePinCode = (val: String) => {
    setPinCode(val as string);
    const { cityid } = pin_code.results.find(
      (code: any) => code.pincode === val
    );
    setCityId(cityid);
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Grid container spacing={2} md={12}>
          <Grid item md={3} xs={12}>
            <Typography variant="h5" align="left">
              Test Packages
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} md={12}>
          <Grid item md={6} xs={3}>
            <Autocomplete
              id="pincode"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  handlePinCode(obj.pincode as String);
                }
              }}
              options={pin_code.results || []}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) =>
                `${option.pincode}, ${option.area}`
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPinCode(newInputValue.split(",")[0]);
                }, 1000);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Pin Code"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                          if (e.key === 'Enter') {
                            e.stopPropagation();
                          }
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={3}>
            <Autocomplete
              freeSolo
              id="test-name-filter"
              options={packageList.results || []}
              // onChange={(newValue) => {
              //   getPackage(`code=${newValue}`);
              // }}
              disableClearable
              getOptionLabel={(option: any) => option.name}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPackage(`city_id=${cityId}&code=${newInputValue}`);
                }, 200);
              }}
              renderInput={(params) => (
                <TextField
                className="input"
                  {...params}
                  variant="outlined"
                  label="Search by Test name"
                  InputProps={{ ...params.InputProps, type: "search" }}
                />
              )}
              disabled={pincode === ""}
            />
          </Grid>
          <Grid item md={12}>
            <Divider></Divider>
          </Grid>
          <Grid container spacing={2} md={12} className={classes.spacebtm}>
            {packageList.results &&
              packageList.results.length > 0 &&
              packageList.results.map((data: any, index: any) => {
                return (
                  <Grid container item xs={12} md={4} xl={3}>
                    <Card
                      variant="outlined"
                      className={classes.card}
                      elevation={3}
                      component={Paper}
                    >
                      <Grid item md={12}>
                        <Typography variant="h6" align="center">
                          <b> {data.name}</b>
                        </Typography>
                      </Grid>
                      <Grid item md={12}>
                        <Divider></Divider>
                      </Grid>
                      <Grid item md={12}>
                        <div>
                          <Typography
                            variant="h6"
                            align="left"
                            style={{ padding: "10px" }}
                          >
                            Parameters:<span>{data.parameter}</span>
                          </Typography>
                          <Typography
                            variant="h6"
                            align="right"
                            style={{ padding: "10px" }}
                          >
                            TaT Time:
                            <span>
                              {data.package_city_prices
                                ? data.package_city_prices.tat_time
                                : data.tat_time}
                            </span>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item md={12}>
                        <p>{data.description}</p>
                      </Grid>

                      <Grid container item md={12}>
                        <Grid item container md={6}>
                          <Card
                            className={classes.card}
                            style={{ height: 100 }}
                            variant="outlined"
                          >
                            <Grid item md={12}>
                              <Typography variant="h6" align="center">
                                Package Price
                              </Typography>
                            </Grid>
                            <Grid item md={12}>
                              <Typography variant="h6" align="center">
                                <b>{data.package_price}</b>
                              </Typography>
                            </Grid>
                          </Card>
                        </Grid>
                        <Grid item container md={6}>
                          <Card
                            className={classes.card}
                            style={{ height: 100 }}
                            variant="outlined"
                          >
                            <Grid item md={12}>
                              <Typography variant="h6" align="center">
                                Offer Price
                              </Typography>
                            </Grid>
                            <Grid item md={12}>
                              <Typography variant="h6" align="center">
                                <b>
                                  {" "}
                                  {data.package_city_prices === null
                                    ? data.offer_price
                                    : data.package_city_prices.offer_price}
                                </b>
                              </Typography>
                            </Grid>
                          </Card>
                        </Grid>
                        <Grid item container md={12}>
                          <a href={data.web_link} target="_blank" rel="noopener noreferrer">
                            {data.web_link}
                          </a>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
          <Grid item md={12}>
            <Pagination
              count={Math.ceil(parseInt(packageList.count) / 50)}
              page={page}
              variant="outlined"
              color="primary"
              onChange={handlePage}
            />
          </Grid>
        </Grid>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  packageList: state.LimsAdminReducer.packageList,
  pin_code: state.LimsAdminReducer.pin_code,
});

export default connect(mapStateToProps, {
  getPackage,
  getPinCode,
})(LeadsPage);
