import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../../components/loader";
import SendReportModel from "./sendReportModel";
import CallIcon from "@material-ui/icons/Call";
import { CALL_DISABLE_TIME } from "../../../CallTimeDisable";
import { clickToCall } from "../../actions/LimsAdminActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    conlorChange: {
      background: "red",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);
const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  page: any;
  setPage: any;
  setPaymentId: any;
  paymentId: any;
  failedReport: any;
  getFailedReportData: any;
  loading: Boolean;

  clickToCall: Function;
}

const BookingsTable: React.FC<Props> = ({
  page,
  setPage,
  clickToCall,
  failedReport,
  getFailedReportData,
  loading,
}) => {
  const classes = useStyles();
  const [bookingId, setBookingId] = useState<any>("");
  const [objId, setObjId] = useState<any>("");
  const [openReportModel, setOpenReportModel] = useState<boolean>(false);
  const [callButton, setCallButton] = useState<boolean>(true);
  const [addId, setAddId] = useState<boolean>(false);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = failedReport.links && failedReport.links.next.split("?")[1];
      getFailedReportData(url);
    } else if (newPage < page) {
      let url = failedReport.links && failedReport.links.previous.split("?")[1];
      getFailedReportData(url);
    }
    setPage(newPage as number);
  };

  const handleSendReport = (data: any) => {
    setBookingId(
      data.add_member_booking === null
        ? data?.booking?.id
        : data.add_member_booking.id
    );
    setObjId(data?.id);
    data.add_member_booking === null ? setAddId(false) : setAddId(true);
    setOpenReportModel(!openReportModel);
  };

  const handleCallClick = (id: number, type: string) => {
    // setLeadId(id);
    setCallButton(false);
    clickToCall(id, type);
    setTimeout(() => {
      setCallButton(true);
    }, CALL_DISABLE_TIME);
  };

  return (
    <main className={classes.content}>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "700px" }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Send_Report</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
                <StyledTableCell align="center">Booking Id</StyledTableCell>
                <StyledTableCell align="center">Child Id</StyledTableCell>
                <StyledTableCell align="center">Lead</StyledTableCell>
                <StyledTableCell align="center">Call</StyledTableCell>
                <StyledTableCell align="center">Patient Name</StyledTableCell>
                <StyledTableCell align="center">Report Status</StyledTableCell>
                <StyledTableCell align="center">
                  Collection Date
                </StyledTableCell>
                <StyledTableCell align="center">Booking Status</StyledTableCell>
                <StyledTableCell align="center">Remarks </StyledTableCell>
                <StyledTableCell align="center">Reason</StyledTableCell>
                <StyledTableCell align="center">Create Date </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {failedReport?.results &&
                failedReport?.results?.length > 0 &&
                failedReport?.results.map((booking: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        <Button
                          variant="contained"
                          color="secondary"
                          fullWidth
                          style={{height: "40px"}}
                          onClick={() => handleSendReport(booking)}
                        >
                          Send Report
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Link
                          to={`/dashboard/la/booking-view/${booking?.booking?.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Button variant="contained" color="secondary">
                            View
                          </Button>
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.booking?.id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.add_member_booking?.id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.booking?.lead}
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        <Button
                          disabled={!callButton}
                          className="addDeck"
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            handleCallClick(booking?.booking?.id, "call")
                          }
                          startIcon={<CallIcon />}
                        ></Button>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.booking?.name || "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip
                          label={`${booking?.report_sent_status}`}
                          style={{
                            background:
                              booking?.report_status === "pending"
                                ? "#ffee58"
                                : booking.report_status === "consolidate"
                                ? "#00e676"
                                : booking?.report_status === "partial"
                                ? "#d500f9"
                                : "#ff3d00",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(
                          booking?.booking?.collection_date
                        ).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip
                          label={`${booking?.booking?.booking_status}`}
                          style={{
                            background:
                              booking?.booking?.booking_status === "pending"
                                ? "#ffee58"
                                : booking?.booking?.booking_status ===
                                  "consolidate"
                                ? "#00e676"
                                : booking?.booking?.booking_status === "partial"
                                ? "#d500f9"
                                : "#ff3d00",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.remarks || "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.reason || "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(booking?.created_at).toLocaleString()}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  count={failedReport?.count || 0}
                  rowsPerPage={failedReport?.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      <SendReportModel
        setOpenReportModel={setOpenReportModel}
        openReportModel={openReportModel}
        bookingId={bookingId}
        objId={objId} addId={addId}      />
    </main>
  );
};

const mapStateToProps = (state: any) => ({
    
});

export default connect(mapStateToProps, {
  clickToCall,
})(BookingsTable);
