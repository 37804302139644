import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { updateNbsForm } from "../../actions/LimsAdminActions";
import {
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  MenuItem,
  TextField,
  FormGroup,
  Radio,
  InputAdornment,
  TextareaAutosize,
  Button,
} from "@material-ui/core";

interface PropsCR {
  customerName: any;
  dob: any;
  data: any;
  setData: any;
  setOpenNbsModal: any;
  updateNbsForm: any;
  dataId: any;
  perPackNbs: any;
  setPerPackArr: any;
  perPackArr: any;
}

const NbsForm: React.FC<PropsCR> = ({
  customerName,
  dob,
  data,
  setData,
  setOpenNbsModal,
  updateNbsForm,
  dataId,
  perPackNbs,
  perPackArr,
  setPerPackArr,
}) => {
  const { id } = useParams<{ id: string }>();
useEffect(() => {
 if(dataId){
  let arr = []
if(data.ch===true){
  arr.push("ch")
}
if(data.cah===true){
  arr.push("cah")
}
if(data.g6pd===true){
  arr.push("g6pd")
}
if(data.gal===true){
  arr.push("gal")
}
if(data.cp===true){
  arr.push("cp")
}
if(data.pku===true){
  arr.push("pku")
}
if(data.bio===true){
  arr.push("bio")
}
setPerPackArr(arr)
 }
}, [])

  const updateData = (key: any, value: any) => {
    let obj = {
      birth_time: data.birth_time,
      birth_time_hh: data.birth_time_hh,
      birth_time_mm: data.birth_time_mm,
      consanguinity: data.consanguinity,
      baby_weight: data.baby_weight,
      baby_admitted_in_nicu: data.baby_admitted_in_nicu,
      blood_transfusion: data.blood_transfusion,
      transfusion_date: data.transfusion_date,
      gestational_age_weeks: data.gestational_age_weeks,
      gestational_age_days: data.gestational_age_days,
      birth_order: data.birth_order,
      type_of_feed: data.type_of_feed,
      history: data.history,
      ch: data.ch,
      cah: data.cah,
      g6pd: data.g6pd,
      gal: data.gal,
      cp: data.cp,
      pku: data.pku,
      bio: data.bio,
    };
    let arr1 = [...perPackArr];
    if (
      (key === "ch" ||
        key === "cah" ||
        key === "g6pd" ||
        key === "gal" ||
        key === "cp" ||
        key === "pku" ||
        key === "bio") &&
      value === true
    ) {
      arr1.push(key);
      setPerPackArr(arr1);
    } else if (
      (key === "ch" ||
        key === "cah" ||
        key === "g6pd" ||
        key === "gal" ||
        key === "cp" ||
        key === "pku" ||
        key === "bio") &&
      value === false
    ) {
      var filtered = arr1.filter(function (val, index, arr) {
        return val !== key;
      });
      setPerPackArr(filtered);
    }
    obj[key] = value;
    setData(obj);
  };
  const handleUpdate = () => {
    let obj = {
      booking: id,
      birth_time: `${data.birth_time_hh}:${data.birth_time_mm}:00`,
      consanguinity: data.consanguinity,
      baby_weight: data.baby_weight,
      baby_admitted_in_nicu: data.baby_admitted_in_nicu,
      blood_transfusion: data.blood_transfusion,
      transfusion_date: data.transfusion_date,
      gestational_age_weeks: data.gestational_age_weeks,
      gestational_age_days: data.gestational_age_days,
      birth_order: data.birth_order,
      type_of_feed: data.type_of_feed,
      history: data.history,
      ch: data.ch,
      cah: data.cah,
      g6pd: data.g6pd,
      gal: data.gal,
      cp: data.cp,
      pku: data.pku,
      bio: data.bio,
    };
    if (data.transfusion_date === "") {
      delete obj.transfusion_date;
    }
    updateNbsForm(dataId, obj);
    setOpenNbsModal(false);
  };
  return (
    <form style={{ padding: 15, width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>New Baby Screening Form</h3>
          <hr />
        </Grid>

        <Grid item container xs={12} md={6}>
          <Grid item xs={5}>
            Name<sup>*</sup>
          </Grid>
          <Grid item xs={7}>
            <TextField
              value={customerName}
              size="small"
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} md={6}>
          <Grid item xs={5}>
            DOB and Time<sup>*</sup>
          </Grid>
          <Grid item xs={3}>
            <TextField
              value={dob}
              size="small"
              variant="outlined"
              fullWidth
              type="date"
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              size="small"
              label="HH"
              variant="outlined"
              fullWidth
              select
              value={data.birth_time_hh}
              onChange={(e) => updateData("birth_time_hh", e.target.value)}
            >
              {Array.from(Array(25).keys()).map((val: any) => {
                return (
                  <MenuItem value={String(val).length === 1 ? `0${val}` : val}>
                    {String(val).length === 1 ? `0${val}` : val}
                  </MenuItem>
                );
              })}
            </TextField>{" "}
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="MM"
              size="small"
              variant="outlined"
              fullWidth
              select
              value={data.birth_time_mm}
              onChange={(e) => updateData("birth_time_mm", e.target.value)}
            >
              {Array.from(Array(25).keys()).map((val: any) => {
                return (
                  <MenuItem value={String(val).length === 1 ? `0${val}` : val}>
                    {String(val).length === 1 ? `0${val}` : val}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
        </Grid>

        <Grid item container xs={12} md={6}>
          <Grid item xs={5}>
            Consangunity<sup>*</sup>
          </Grid>
          <Grid item xs={7}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={data.consanguinity}
              onChange={(e) =>
                updateData(
                  "consanguinity",
                  e.target.value === "true" ? true : false
                )
              }
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
        </Grid>

        <Grid item container xs={12} md={6}>
          <Grid item xs={5}>
            Baby's weight
          </Grid>
          <Grid item xs={7}>
            <TextField
              value={data.baby_weight}
              onChange={(e) => updateData("baby_weight", e.target.value)}
              size="small"
              type="number"
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">kg</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} md={6}>
          <Grid item xs={5}>
            Baby admitted in NICU<sup>*</sup>
          </Grid>
          <Grid item xs={7}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={data.baby_admitted_in_nicu}
              onChange={(e) =>
                updateData(
                  "baby_admitted_in_nicu",
                  e.target.value === "true" ? true : false
                )
              }
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
        </Grid>

        <Grid item container xs={12} md={6}>
          <Grid item xs={5}>
            Any blood transfusion<sup>*</sup>
          </Grid>
          <Grid item xs={7}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={data.blood_transfusion}
              onChange={(e) =>
                updateData(
                  "blood_transfusion",
                  e.target.value === "true" ? true : false
                )
              }
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
        </Grid>
        {data.blood_transfusion ? (
          <Grid item container xs={12} md={6}>
            <Grid item xs={5}>
              Date of transfusion<sup>*</sup>
            </Grid>
            <Grid item xs={7}>
              <TextField
                value={data.transfusion_date}
                onChange={(e) => updateData("transfusion_date", e.target.value)}
                size="small"
                variant="outlined"
                fullWidth
                type="date"
              />
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        <Grid item container xs={12} md={6}>
          <Grid item xs={5}>
            Gestational Age
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={data.gestational_age_weeks}
              onChange={(e) =>
                updateData("gestational_age_weeks", e.target.value)
              }
              size="small"
              variant="outlined"
              fullWidth
              placeholder="select"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">{`Weeks `}</InputAdornment>
                ),
              }}
              select
            >
              {Array.from(Array(53).keys()).map((val: any) => {
                return <MenuItem value={val}>{val}</MenuItem>;
              })}
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              value={data.gestational_age_days}
              onChange={(e) =>
                updateData("gestational_age_days", e.target.value)
              }
              size="small"
              variant="outlined"
              placeholder="select"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">{`Days `}</InputAdornment>
                ),
              }}
              select
            >
              {Array.from(Array(7).keys()).map((val: any) => {
                return <MenuItem value={val}>{val}</MenuItem>;
              })}
            </TextField>
          </Grid>
        </Grid>

        <Grid item container xs={12} md={6}>
          <Grid item xs={5}>
            Birth Order
          </Grid>
          <Grid item xs={7}>
            <TextField
              value={data.birth_order}
              onChange={(e) => updateData("birth_order", e.target.value)}
              size="small"
              variant="outlined"
              fullWidth
              placeholder="select"
              select
            >
              {Array.from(Array(10).keys()).map((val: any) => {
                return <MenuItem value={val + 1}>{val + 1}</MenuItem>;
              })}
            </TextField>
          </Grid>
        </Grid>

        <Grid item container xs={12} md={6}>
          <Grid item xs={5}>
            Type of feed
          </Grid>
          <Grid item xs={7}>
            <TextField
              value={data.type_of_feed}
              onChange={(e) => updateData("type_of_feed", e.target.value)}
              size="small"
              variant="outlined"
              fullWidth
              select
            >
              <MenuItem value="breast">Breast</MenuItem>
              <MenuItem value="TPN">TPN</MenuItem>
              <MenuItem value="formula">Formula</MenuItem>
              <MenuItem value="iv_nutrition">IV Nutrition</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid item container xs={12}>
          <Grid item style={{ width: "20.5%" }}>
            Clinical & Family history
          </Grid>
          <Grid item style={{ width: "79.5%" }}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              style={{ width: "100%" }}
              value={data.history}
              onChange={(e) => updateData("history", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item style={{ width: "20.5%" }}>
            Select as per package
          </Grid>
          <Grid item style={{ width: "79.5%" }}>
            <FormGroup row={true}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.ch}
                    onChange={(e) => updateData("ch", e.target.checked)}
                    disabled={perPackNbs === perPackArr.length && !data.ch}
                  />
                }
                label="CH"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.cah}
                    onChange={(e) => updateData("cah", e.target.checked)}
                    disabled={perPackNbs === perPackArr.length && !data.cah}
                  />
                }
                label="CAH"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.g6pd}
                    onChange={(e) => updateData("g6pd", e.target.checked)}
                    disabled={perPackNbs === perPackArr.length && !data.g6pd}
                  />
                }
                label="G6PD"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.gal}
                    onChange={(e) => updateData("gal", e.target.checked)}
                    disabled={perPackNbs === perPackArr.length && !data.gal}
                  />
                }
                label="GAL"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.cp}
                    onChange={(e) => updateData("cp", e.target.checked)}
                    disabled={perPackNbs === perPackArr.length && !data.cp}
                  />
                }
                label="CP"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.pku}
                    onChange={(e) => updateData("pku", e.target.checked)}
                    disabled={perPackNbs === perPackArr.length && !data.pku}
                  />
                }
                label="PKU"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.bio}
                    onChange={(e) => updateData("bio", e.target.checked)}
                    disabled={perPackNbs === perPackArr.length && !data.bio}
                  />
                }
                label="BIO"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item>
            {dataId ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenNbsModal(false)}
              >
                Proceed
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {
  updateNbsForm,
})(NbsForm);
