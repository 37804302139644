import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from '@mui/material/Typography';
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  postSampleCollection,
  getBookingById,
  getTubeByBooking,
  getReceivedPackages,
} from "../../../actions/LimsAdminActions";

import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core";
import Loader from "../../loader/index";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
    button: {
      display: "block",
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    container: {
      // display: "flex",
      // justifyContent: "center",
      // alignItems: "center",
    },
    select: {
      margin: "0 0 0 -8px",
      minWidth: "145px",
      width: "100%",
      height: "40px",
    },
    textField: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

interface Props {
  id: any;
  getReceivedPackages: any;
  receivedPackages: any;
  postSampleCollection: any;
  getTubeByBooking: any;
  tubeByBooking: any;
  getBookingById: any;
  booking: any;
  loading: boolean;
}
const SampleCollection: React.FC<Props> = ({
  id,
  getReceivedPackages,
  receivedPackages,
  postSampleCollection,
  getTubeByBooking,
  tubeByBooking,
  getBookingById,
  booking,
  loading,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [testArrayScanned, setTestArrayScanned] = useState<any>([]);
  const [testArrayUnscanned, setTestArrayUnscanned] = useState<any>([]);
useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (id) {
      getBookingById(id);
      getTubeByBooking(id);
      getReceivedPackages(id);
    }
  }, [id]);

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setTestArrayUnscanned([]);
    if (id) {
      let newObj: any = [...testArrayUnscanned];

      tubeByBooking &&
        tubeByBooking.length > 0 &&
        tubeByBooking.map((data: any) => {
          newObj.push({
            bId: id,
            tId: data?.package?.id,
            bcode: "",
            sample_collection_date_time: moment().format("YYYY-MM-DDTHH:mmZ"),
            sample_collected: false,
            container: data?.tube?.tube?.name,
            containerId: data?.tube?.id,
          });
        });

      setTestArrayUnscanned(newObj);
    }
  }, [tubeByBooking]);

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setTestArrayScanned([]);
    if (id) {
      let newObj: any = [...testArrayScanned];

      receivedPackages &&
        receivedPackages.results &&
        receivedPackages.results.length > 0 &&
        receivedPackages.results.map((data: any) => {
          newObj.push({
            bId: id,
            tId: data?.package?.id,
            bcode: data?.code,
            sample_collection_date_time: moment(data?.created_at).format(
              "YYYY-MM-DDTHH:mmZ"
            ),
            sample_collected: true,
            container: data?.tube?.tube?.name,
            containerId: data?.tube?.id,
          });
        });

      setTestArrayScanned(newObj);
    }
  }, [receivedPackages]);

  const updateResultData = (cID: any, tId: any, key: any, val: any) => {
    let temp = [...testArrayUnscanned];
    testArrayUnscanned.map((val1: any, index1: any) => {
      if (cID===val1.containerId && tId===val1.tId) {
        temp[index1][key] = val;
        setTestArrayUnscanned(temp);
      }
    });
  };
  const submitCollectionStatus = async (val: any) => {
    const arr: any = [];
    if (val.containerId !== "") {
      arr.push({
        tube: val.containerId,
        package: val.tId,
        barcode: val.bcode,
      });
    }
    const data: any = { code: arr };
    if (arr.length > 0) {
      await postSampleCollection(val.bId, data);
    }
    setTestArrayScanned([]);
    setTestArrayUnscanned([]);
    getTubeByBooking(id);
    getReceivedPackages(id);
  };
  return (
    <div style={{ width: "90%", margin: "80px auto" }}>
      <Grid container spacing={3}>
        <Grid container item xs={12} className={classes.center}>
          <Typography variant="h4" component="h1" align="center">
            Sample Collection
          </Typography>
          <Box margin={1}>
            <Grid container xs={12}>
              <Grid item xs={11}>
                <Typography variant="h6" gutterBottom component="div">
                  Patient Test Details
                </Typography>
              </Grid>
              <Grid item xs={1} style={{ textAlign: "right" }}></Grid>
            </Grid>

            {loading ? (
              <Loader />
            ) : (
              <TableContainer style={{ width: "100%", height: 300 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell align="center">
                        <b>Test Name</b>
                      </TableCell> */}

                      <TableCell align="center">
                        <b>Container Name</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>Barcode</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>Collection Date & Time</b>
                      </TableCell>

                      <TableCell align="center">
                        <b>Collection status</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>Save</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {testArrayUnscanned &&
                      testArrayUnscanned.length > 0 &&
                      testArrayUnscanned.map((qual: any) => {
                        return (
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {qual.container}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <TextField
                                variant="outlined"
                                fullWidth
                                style={{ width: "100px" }}
                                value={qual.bcode}
                                onChange={(e) =>
                                  updateResultData(
                                    qual.containerId,
                                    qual.tId,
                                    "bcode",
                                    String(e.target.value).replace(/\s+/g, "")
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <TextField
                                key={qual.containerId}
                                id="datetime-local"
                                variant="outlined"
                                type="datetime-local"
                                fullWidth
                                disabled
                                value={moment().format("YYYY-MM-DDTHH:mm")}
                              />
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <p style={{ marginBottom: 4 }}>
                                <Chip
                                  size="small"
                                  label="Not Collected"
                                  style={{
                                    backgroundColor: "#f44336",
                                    color: "#fff",
                                  }}
                                />
                              </p>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={() => submitCollectionStatus(qual)}
                              >
                                Collect
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {testArrayScanned &&
                      testArrayScanned.length > 0 &&
                      testArrayScanned.map((qual: any) => {
                        return (
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {qual.container}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <TextField
                                variant="outlined"
                                fullWidth
                                disabled
                                style={{ width: "100px" }}
                                value={qual.bcode}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <TextField
                                key={qual.containerId}
                                id="datetime-local"
                                variant="outlined"
                                type="datetime-local"
                                fullWidth
                                disabled
                                value={moment(
                                  qual.sample_collection_date_time
                                ).format("YYYY-MM-DDTHH:mm")}
                              />
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <p style={{ marginBottom: 4 }}>
                                <Chip
                                  size="small"
                                  label="Collected"
                                  style={{
                                    backgroundColor: "#4caf50",
                                    color: "#fff",
                                  }}
                                />
                              </p>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled
                              >
                                Collected
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
          <Grid sm={12} item style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="secondary"
              style={{ width: "200px", height: 50 }}
              onClick={() => navigate(`/dashboard/la/booking-view/${id}`)}
            >
              Proceed To View
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  booking: state.LimsAdminReducer.booking,
  receivedPackages: state.LimsAdminReducer.receivedPackages,
  tubeByBooking: state.LimsAdminReducer.tubeByBooking,
  loading: state.LimsAdminReducer.loading,
});

export default connect(mapStateToProps, {
  getBookingById,
  getTubeByBooking,
  postSampleCollection,
  getReceivedPackages,
})(SampleCollection);
